import * as React from "react";
import {MaskedTextField, ITextFieldStyles} from "office-ui-fabric-react";

const fieldS: Partial<ITextFieldStyles> = {
  root: {
    maxWidth: 70,
  },
  fieldGroup: {
    borderRight: 0
  }
};

export interface PostalCodeSearchProps {
  handleChange: any;
  val: any;
}

const PostalCodeSearch: React.SFC<PostalCodeSearchProps> = ({
  handleChange, val
}) => {
  const [componentRefreshCounter, setComponentRefreshCounter] = React.useState<number>(0);
  const [prevVal, setPrevVal] = React.useState<string>('');

  if(val === '' && val !== prevVal) {
    setComponentRefreshCounter(componentRefreshCounter + 1);
    setPrevVal(val);
  } else if(val !== '' && val !== prevVal) {
    setPrevVal(val);
  }

  return (
    <>
    <MaskedTextField
      key={`postalCodeSearch${componentRefreshCounter}`}
      placeholder="09-410"
      name="postalCode"
      mask="99-999"
      maskChar="_"
      styles={fieldS}
      onChange={handleChange}
      defaultValue={val}
    />
    </>
  );
};

export default PostalCodeSearch;
