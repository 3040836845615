import React from "react";
import { RootStateOrAny, useSelector, useDispatch } from "react-redux";
import { Panel } from "office-ui-fabric-react";
import { panelS } from "../../../common/styles/panelStyles";
import {
  setSelectedPrice,
  togglePricesPanel,
} from "../../../../config/redux/pricing/pricingActions";
import AddPrice from "../forms/AddPrice";
import EditPrice from "../forms/EditPrice";
import { clearAlerts } from "../../../../config/redux/alerts/alertsActions";


const PricingPanel: React.FC = () => {
  const dispatch = useDispatch();
  const { prcingPanelOpen, selectedPrice } = useSelector(
    (state: RootStateOrAny) => state.pricing
  );

  const dismissPanel = () => {
    dispatch(setSelectedPrice(""));
    dispatch(togglePricesPanel(false));
    dispatch(clearAlerts())
  };

  return (
    <Panel
      isOpen={prcingPanelOpen}
      closeButtonAriaLabel="Close"
      headerText="Cena"
      onDismiss={dismissPanel}
      type={1}
      styles={panelS}
      isHiddenOnDismiss={false}
    >
      {selectedPrice ? <EditPrice /> : <AddPrice />}
    </Panel>
  );
};

export default PricingPanel;