import * as React from "react";
import {
  CommandBar,
} from "office-ui-fabric-react";
import { comandBarS } from "../../styles/layout/commandBar.styles";
import { useReceptionPlacesCommandBar } from "../../hooks/receptionPlaces/useReceptionPlaceCommandBar";

const ClientReceptionPlacesCommandBar: React.FC= () => {
    const {items} = useReceptionPlacesCommandBar()
    return <CommandBar items={items} styles={comandBarS} />;
}
 
export default ClientReceptionPlacesCommandBar;