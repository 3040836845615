import React from "react";
import { Stack, StackItem } from "office-ui-fabric-react";
import { Formik, Form } from "formik";
import {
    containerS,
} from "../../../../styles/registerCompany.styles";
import { useRegistration } from "../../../../hooks/useRegistration";
import { validationSchemaCompany, validationSchemaCompanyNotRequired, validationSchemaNotPolishCompany } from "../../../../actions/validationSchema";
import InputSection from "./InputSection";
import RadioBtn from "../../../../../common/layout/form/Radio";
import FormBtns from "../../../../../common/layout/form/NewFormBtns";
import MessageContainer from "../MessageContainer";
import ActivityIndicator from "../../../../../common/layout/ActivityIndicator";
import DisplayText from "../../../../../clients/components/edditionPanel/displayData/DisplayText";
import { textFieldsContainer } from "../../../../../clients/styles/pickUpPointsForm.styles";
import { LangEnum } from "../../../../../common/enums/lang.enum";

const RegisterCompany: React.FC = () => {
    const { register, initValues, isLoading, cancelForm } = useRegistration();
    const isPolishCompany = initValues.country == LangEnum.Polish;

    let validationSchema = () => {
        if (initValues && !!initValues.taxNumber) {
            return isPolishCompany ? validationSchemaCompany : validationSchemaNotPolishCompany;
        }
        else {
            return isPolishCompany ? validationSchemaCompanyNotRequired : validationSchemaNotPolishCompany;
        }
    }

    return (
        <Formik
            initialValues={initValues}
            onSubmit={register}
            validateOnChange={false}
            validateOnBlur={false}
            enableReinitialize={true}
            validationSchema={validationSchema}
        >
            {({ handleSubmit }) => (
                <Form translate="yes" onSubmit={handleSubmit}>
                    <Stack verticalFill styles={containerS}>
                        <div className={textFieldsContainer}>
                            <DisplayText name={"Kraj"} value={initValues.countryText} />
                        </div>
                        <InputSection requireCompanyData={initValues && !!initValues.taxNumber} isPolishCompany={isPolishCompany} />
                        <StackItem align="start">
                            {initValues && initValues.taxNumber ? (
                                <div>
                                    <RadioBtn
                                        name="multiDepartment"
                                        label="Firma wielooddziałowa"
                                        options={[
                                            { key: "yes", text: "Tak" },
                                            { key: "no", text: "Nie" },
                                        ]}
                                    />
                                </div>
                            ) : null}
                            {isLoading ? <ActivityIndicator /> : <MessageContainer />}
                            <FormBtns
                                cancelForm={cancelForm}
                                submitBtnText="Zarejestruj"
                                disabled={isLoading}
                            />
                        </StackItem>
                    </Stack>
                </Form>
            )}
        </Formik>
    );
};

export default RegisterCompany;