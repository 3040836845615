import {
  getOrderTypeItems,
  getOrderTypes,
} from "./../../../connectors/orderTypes/connectors/index";
import { useEffect, useState } from "react";
import { getCompany, getDepartmentReceptionPlaces } from "../../../connectors/clients/connectors/index";
import { getPickUpPoints } from "../../../connectors/orders/connectors/index";

export const useCompanyData = (companyData: any, department: any, receptionPlace: any) => {
  const [departments, setDepartments] = useState([]);
  const [receptionPlaces, setReceptionPlaces] = useState<any[]>([]);
  const [orderTypeItems, setOrderTypeItems] = useState([]);
  const [orderType, setOrderType] = useState({ name: "", id: "" });
  const [clear, setClear] = useState<Boolean>(false);

  useEffect(() => {
    if (companyData && companyData.key) {
      setCompany();
    }
  }, [companyData]);

  useEffect(() => {
    if (clear) {
      setReceptionPlaces([]);
      setDepartments([])
    }

  }, [clear])

  useEffect(() => {
    if (department && department.key) {
      setReceptionPlaces([]);
      getReceptionPlaces(department.key);
    } else {
      setReceptionPlaces([]);
    }
  }, [department]);

  useEffect(() => {
    if (receptionPlace && receptionPlace.key != "") {
      if (departments.length == 0) {
        setCompany();
      }
      if (receptionPlaces.length == 0) {
        getReceptionPlacesAndOrderTypes(receptionPlace.data.departmentId, receptionPlace.key)
      }
      else {
        setOrderTypes(receptionPlace.key);
      }
    }
  }, [receptionPlace])

  const setOrderTypes = async (receptionPlaceKey: number) => {
    if (receptionPlaceKey !== undefined && receptionPlaces !== undefined) {

      const resid = receptionPlaces.find((item: any) =>
        item.key === receptionPlaceKey
      )
      if (resid == null) return; //przetestowac
      const res = await getOrderTypeItems(resid.orderTypeId);
      const data = await getOrderTypes();

      const arr = res.data.data.map((item: any) => {
        return { key: item.id, text: item.name, data: item.value, orderTypeId: item.orderTypeId };
      });

      const result = data.data.data.find(
        (item: any) => item.id === resid.orderTypeId
      );

      setOrderTypeItems(arr);
      if (result !== undefined) {
        setOrderType(result);
      }
    }
  };

  const setCompany = async () => {
    const res = await getCompany(companyData.key);
    const company = res.data.data;

    setDepartments(
      company.departments.map((department: any) => {
        return {
          key: department.id,
          text: `${department.city} ${department.street}`,
        };
      })
    );
  };

  const getReceptionPlaces = async (departmentKey: string) => {
    const res = await getDepartmentReceptionPlaces(departmentKey);
    const options = res.data.data.map((option: any) => {
      let msg = !option.isActive ? '(Nieaktywny)' : ''
      return {
        key: option.id,
        text: `${option.city} ${option.street} ${option.postalCode} ${msg}`,
        orderTypeId: option.orderTypeId,
        disabled: !option.isActive,
        data: option
      };
    });

    setReceptionPlaces(options);
  };

  const getReceptionPlacesAndOrderTypes = async (departmentKey: string, receptionPlaceKey: string) => {
    const res = await getDepartmentReceptionPlaces(departmentKey);
    const options = res.data.data.map((option: any) => {
      let msg = !option.isActive ? '(Nieaktywny)' : ''
      return {
        key: option.id,
        text: `${option.city} ${option.street} ${option.postalCode} ${msg}`,
        orderTypeId: option.orderTypeId,
        disabled: !option.isActive,
        data: option
      };
    });

    setReceptionPlaces(options);

    if (receptionPlaceKey !== undefined && options !== undefined) {

      const resid = options.find((item: any) =>
        item.key === receptionPlaceKey
      )
      if (resid == null) return;
      const res = await getOrderTypeItems(resid.orderTypeId);
      const data = await getOrderTypes();

      const arr = res.data.data.map((item: any) => {
        return { key: item.id, text: item.name, data: item.value, orderTypeId: item.orderTypeId };
      });

      const result = data.data.data.find(
        (item: any) => item.id === resid.orderTypeId
      );

      setReceptionPlaces(options);
      setOrderTypeItems(arr);
      if (result !== undefined) {
        console.log(result)
        setOrderType(result);
      }
    }

  };

  return {
    departments,
    receptionPlaces,
    orderTypeItems,
    orderType,
    setClear
  };
};