import {
  ICommandBarStyles,
  getTheme,
} from "office-ui-fabric-react";


const theme = getTheme();

export const comandBarS: ICommandBarStyles = {
  root: {
    position: "fixed",
    top: 50,
    left: 230,
    width: "100%",
    zIndex: 10,
    borderBottom: `2px solid ${theme.palette.neutralLighter}`,
  },
};