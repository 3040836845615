import { addDepartment } from './../../../connectors/clients/connectors/index';
import { Messages } from './../models/message.enum';
import { toggleEditing, setSelectedIds, refetchClientPanelData } from './../../../config/redux/clients/panel/clientPanelActions';
import { useState } from "react";
import { FormikValues } from "formik";
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";

import { setErrorAlert, setSuccessAlert } from './../../../config/redux/alerts/alertsActions';
import { editDepartment } from "../../../connectors/clients/connectors/index";
import { IResetForm } from "../../common/models/resetForm.type";
import { ClientsAlerts } from "../models/clientsAlerts.enum"


export const useDepartmentForm = () => {
  const [isLoading, setIsLoading] = useState(false)
  const disptach = useDispatch();
  const { activeDepartment, basicInfo, selectedIds } = useSelector(
    (state: RootStateOrAny) => state.clientPanel
  );
  const { activeClient } = useSelector(
    (state: RootStateOrAny) => state.clients
  );
  const initValues = selectedIds[0]
    ? {
        city: selectedIds[0].city,
        street: selectedIds[0].street,
        postalCode: selectedIds[0].postalCode,
        description: ""
      }
    : {};

  const edit = async (
    values: FormikValues,
    { resetForm }: IResetForm
  ) => {
    setIsLoading(true)
    try {
      await editDepartment(selectedIds[0].id, {
        city: values.city,
        street: values.street,
        postalCode: values.postalCode,
      });
    resetForm();
    disptach(setSelectedIds({selectedIds: []}))
    disptach(toggleEditing({editing: false}))
    disptach(setSuccessAlert(Messages.OPERATION_SUCCESS))
    } catch (err) {
      disptach(setErrorAlert(ClientsAlerts.USER_ALREDY_EXISTS))
    }
    setIsLoading(false)
  };

  const add = async ( values: FormikValues,
    { resetForm }: IResetForm) => {
      setIsLoading(true)
      try {
        await addDepartment(basicInfo.basicInformations.id, {
          city: values.city,
          street: values.street,
          postalCode: values.postalCode,
          description: values.description
        });
      resetForm();
      disptach(refetchClientPanelData(true));
      disptach(toggleEditing({editing: false}))
      disptach(setSuccessAlert(Messages.OPERATION_SUCCESS))
      } catch (err) {
        disptach(setErrorAlert(ClientsAlerts.USER_ALREDY_EXISTS))
      }
      setIsLoading(false)
    };
  
  return {
    edit,
    initValues,
    selectedIds,
    isLoading,
    add
  }
}