import {
  ISelectedValidators,
  IRemove,
  IActiveValidator,
} from "./validatorsActions.types";
import { ValidatorsActions } from "./validatorsActions.enum";

export const setActiveValidator = (data: IActiveValidator) => {
  return {
    type: ValidatorsActions.SET_ACTIVE_VALIDATOR,
    payload: data,
  };
};

export const setSelectedValidators = (data: ISelectedValidators) => {
  return {
    type: ValidatorsActions.SET_VALIDATORS,
    payload: data,
  };
};

export const removeData = (data: IRemove) => {
  return {
    type: ValidatorsActions.REMOVE_DATA,
    payload: data,
  };
};

export const reloadValidators = (data: boolean) => {
  return {
    type: ValidatorsActions.RELOAD,
    payload: data,
  };
};

export const toggleValidatorAccept = (validatorAccepted: boolean) => {
  return {
    type: ValidatorsActions.ACCEPT_VALIDATOR,
    payload: validatorAccepted,
  };
};

export const toggleValidatorCancel = (validatorCanceled: boolean) => {
  return {
    type: ValidatorsActions.CANCEL_VALIDATOR,
    payload: validatorCanceled,
  };
};