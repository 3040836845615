export enum Breakpoints  {
    SMALL = "(min-width: 320px)",
    SMALL_MAX = "(max-width: 320px)",
    MEDIUM = "(min-width: 480px)",
    MEDIUM_MAX = "(max-width: 480px)",
    LARGE = "(min-width: 640px)",
    LARGE_MAX = "(max-width: 640px)",
    EXTRA_LARGE = "(min-width: 1024px)",
    EXTRA_EXTRA_LARGE = "(min-width: 1366px)",
    EXTRA_EXTRA_EXTRA_LARGE = "(min-width: 1920px)"
}