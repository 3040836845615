import * as React from "react";
import {
    CommandBar,
} from "office-ui-fabric-react";
import { comandBarS } from "../../commandBar/styles/commandBar.styles";
import { useContainerCommandBar } from "../hooks/useContainerCommandBar";

const ContainersCommandBar: React.FC = () => {
    const { items } = useContainerCommandBar()
    return <CommandBar items={items} styles={comandBarS} />;
}

export default ContainersCommandBar;