import { setListMessage } from './../../../config/redux/alerts/alertsActions';
import { useDispatch } from "react-redux";
import { FormikValues } from "formik";
import { useState, useEffect, useMemo } from "react";
import { useSelector, RootStateOrAny } from "react-redux";
import { getDepartmentReceptionPlaces } from "../../../connectors/clients/connectors/index";
import { IResetForm } from "../../common/models/resetForm.type";
import { editOrder, getNetto, IGetNetto } from "../../../connectors/orders/connectors";
import { OrdersAlerts } from "../models/Alerts.enum";
import { setOrderEditing, toggleOrderPanel } from './../../../config/redux/orders/ordersPanel/orderPanelActions';
import {
  setErrorAlert,
} from "../../../config/redux/alerts/alertsActions";
import {
  reloadOrders,
} from "../../../config/redux/orders/ordersActions";
import { getOrderTypeItems, getOrderTypes } from "../../../connectors/orderTypes/connectors/index";
import { toggleFiltration } from '../../../config/redux/filters/filterActions';
import { MethodsOfPayment } from "../models/MethodsOfPayment.enum";

export const useOrderDetails = (receptionPlace: any) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { selectedOrders } = useSelector(
    (state: RootStateOrAny) => state.orders
  );
  const { orderDetails } = useSelector(
    (state: RootStateOrAny) => state.orderPanel
  );
  const [receptionPlaces, setReceptionPlaces] = useState<any[]>([]);
  const [orderTypeItems, setOrderTypeItems] = useState([]);
  const [orderType, setOrderType] = useState({ text: "", key: "" });

  const fetchData = async () => {

    let departmentId = orderDetails.departmentId;//?? selectedOrders[0].departmentId
    const departmentsData = await getDepartmentReceptionPlaces(departmentId

    );
    // const orderTypeData = await getOrderTypeItems(orderDetails.orderTypeId);

    // const parsedOrderTypeData = orderTypeData.data.data.map((item: any) => {
    //   return { key: item.id, text: item.name, data: item.value };
    // });

    const parsedData = departmentsData.data.data.map(
      ({ id, city, street, postalCode, orderTypeId }: any) => ({
        key: id,
        text: `${city} ${street} ${postalCode}`,
        orderTypeId: orderTypeId
      })
    );
    setReceptionPlaces(parsedData);
  };

  useEffect(() => {
    if (orderDetails) {
      fetchData();
    }
  }, [orderDetails, selectedOrders]);

  useEffect(() => {
    if (receptionPlace.key == undefined && orderDetails) {

      if (receptionPlace) {
        localStorage.setItem("receptionPlace", orderDetails.receptionPlaceId.toString())
      }
      setOrderTypes(orderDetails.receptionPlaceId)
    }
    else {
      setOrderTypes(receptionPlace.key);
    }

  }, [receptionPlace, receptionPlaces]);

  const changePrice = async (receptionPlace?: number, orderTypeItemId?: number) => {
    if (receptionPlace) {
      localStorage.setItem("receptionPlace", receptionPlace.toString())
    }
    else {
      receptionPlace = localStorage.getItem("receptionPlace") ? Number(localStorage.getItem("receptionPlace")) : 1
    }
    if (orderTypeItemId) {
      localStorage.setItem("orderTypeItemId", orderTypeItemId.toString())
    }
    else {
      orderTypeItemId = localStorage.getItem("orderTypeItemId") ? Number(localStorage.getItem("orderTypeItemId")) : 1
    }
    if (!receptionPlace || !orderTypeItemId)
      return 0;
    var data: IGetNetto = {
      itemId: orderTypeItemId,
      receptionPlaceId: receptionPlace
    };
    //
    try {
      var res = await getNetto(data);
      return res.data.data.netPrice
      //setPrice(res.data.data.netPrice);
    } catch (err) {
      console.error(err);
    }
  }

  const setOrderTypes = async (receptionPlaceKey: number) => {
    if (receptionPlaceKey !== undefined && receptionPlaces !== undefined) {
      const resid = receptionPlaces.find((item: any) =>
        item.key === receptionPlaceKey
      )
      if (resid !== undefined) {

        const res = await getOrderTypeItems(resid.orderTypeId);
        const data = await getOrderTypes();

        const arr = res.data.data.map((item: any) => {
          return { key: item.id, text: item.name, data: item.value, orderTypeId: item.orderTypeId };
        });

        const result = data.data.data.find(
          (item: any) => item.id === resid.orderTypeId
        );
        const res1 = result.items.find(
          (item: any) => item.id === orderDetails.orderTypeItemId
        )

        setOrderTypeItems(arr);
        if (result !== undefined) {

          setOrderType({ key: res1.id, text: res1.name });
        }
      }
    }
  };

  const handleSubmit = async (
    values: FormikValues,
    { resetForm }: IResetForm
  ) => {
    setIsLoading(true);
    const { receptionPlace, additionalInfo, quantity, orderTypeItemId, netPrice, paymentType, updatedPrice } = values;
    const parsedQuantity = parseInt(quantity);
    const data = {
      grossPrice: netPrice * 1.08,
      updatedGrossPrice: updatedPrice * 1.08,
      netPrice,
      receptionPlace,
      additionalInfo,
      quantity: parsedQuantity,
      orderTypeItemId,
      paymentType: paymentType
    };

    try {
      await editOrder(selectedOrders[0].id, data);
      localStorage.removeItem("receptionPlace");
      localStorage.removeItem("orderTypeItemId");
      setIsLoading(false);
      dispatch(toggleFiltration({ filter: true }));
      dispatch(reloadOrders(true));
      setIsLoading(false);
      dispatch(toggleOrderPanel(false))
      dispatch(setListMessage("Pomyślnie edytowano zamówienie"))
    } catch (err) {
      setIsLoading(false);
      dispatch(setErrorAlert(OrdersAlerts.FILL_ALL_FILEDS));
    }
  };

  const cancelForm = () => {
    localStorage.removeItem("receptionPlace");
    localStorage.removeItem("orderTypeItemId");
    dispatch(setOrderEditing(false))
  };

  const initValues = useMemo(() => ({
    receptionPlace: orderDetails ? orderDetails.receptionPlaceId : "",
    additionalInfo: orderDetails ? orderDetails.additionalInfo : "",
    quantity: orderDetails ? orderDetails.quantity : "1",
    orderTypeItemId: orderDetails ? orderDetails.orderTypeItemId : "",
    netPrice: orderDetails ? orderDetails.netPrice : 0.0,
    grossPrice: orderDetails ? orderDetails.grossPrice : 0.0,
    paymentType: orderDetails ? orderDetails.paymentType : MethodsOfPayment.TRANSFER
  }), [JSON.stringify(receptionPlaces)])

  return {
    receptionPlaces,
    orderTypeItems,
    handleSubmit,
    cancelForm,
    initValues,
    isLoading,
    changePrice,
    orderType
  };
};