import React from "react";
import { RootStateOrAny, useSelector, useDispatch } from "react-redux";
import { Panel } from "office-ui-fabric-react";
import { toggleSelfEditPanel } from "../../../config/redux/client/clientActions";
import { panelS } from "../../../features/common/styles/panelStyles";
import SelfEditForm from "../forms/SelfEditForm";
import { useTranslation } from "react-i18next";

const SelfEditPanel: React.FC = () => {
  const dispatch = useDispatch();
  const { selfEditPanelOpen } = useSelector(
    (state: RootStateOrAny) => state.client
  );

  const dismissPanel = () => {
    dispatch(toggleSelfEditPanel(false));
  };
  const { t } = useTranslation("profile");
  const panelName: string = t("panelName");

  return (
    <Panel
      isOpen={selfEditPanelOpen}
      closeButtonAriaLabel="Close"
      headerText={panelName}
      onDismiss={dismissPanel}
      type={1}
      styles={panelS}
      isHiddenOnDismiss={false}
    >
      <SelfEditForm />
    </Panel>
  );
};

export default SelfEditPanel;