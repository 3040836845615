import { NavigationPath } from "./../../../config/routing/NavigationPath";
import { ValidatorType } from "../models/ValidatorType.enum";

export const getParams = (pathname: string) => {
  let params = {};
  switch (pathname) {
    case NavigationPath.CONTAINERS_VALIDATORS:
      return (params = { ValidatorType: ValidatorType.CONTAINERS });
    case NavigationPath.RECEPTION_PLACES_VALIDATORS:
      return (params = { ValidatorType: ValidatorType.RECEPTION_PLACE });
    case NavigationPath.ORDERS_VALIDATORS:
      return (params = { ValidatorType: ValidatorType.ORDERS });
  }

  return params;
};