import React, { useEffect } from "react";
import { useField } from "formik";
import { TextField } from "@fluentui/react";
import { textfieldS } from "../../../common/styles/formField.styles";

interface NewFormFieldProps {
  name: string;
  label: string;
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
  value?: number;
  min?: string;
  max?: string;
  notRequired?: boolean;
}

const Price: React.FC<NewFormFieldProps> = ({
  name,
  label,
  placeholder,
  disabled,
  required = true,
  value,
  min = "",
  max = "",
  notRequired = false,
}) => {
  const [field, { error, touched }, { setValue }] = useField(name);
  const isRouteInactive = window.location.href.split('/').pop() === 'inactive';

  useEffect(() => {
    if (typeof value === 'number' && isRouteInactive) {
      setValue(value.toFixed(2).toString());
    }
  }, []);

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    const currentValue = event.currentTarget.value;
    const regex = /^[0-9]*$/;

    if (!regex.test(currentValue + keyValue)) {
      event.preventDefault();
    }
  };

  if (notRequired) {
    required = false;
  }

  return (
    <TextField
      inputMode="numeric"
      label={label}
      placeholder={placeholder}
      errorMessage={touched ? error : ""}
      required={required}
      disabled={disabled}
      {...field}
      styles={textfieldS}
      min={min}
      max={max}
      onKeyPress={handleKeyPress}
    />
  );
};

export default Price;
