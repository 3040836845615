import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { setSelectedListItem, reloadOrders as reloadClientOrders } from "../../../../config/redux/client/clientActions";
import { getOrders } from "../../../../connectors/orders/connectors";
import { useList } from "../../../../features/common/hooks/useList";
import i18n from "../../../../localization/i18n";
import { useOrdersList } from "../../../hooks/orders/useOrdersList";
import OrdersList from "./OrdersList";
import { IOrdersOrderStatusLang, IOrdersOrderTypeLang } from "../../../../localization/abstract/client/orders";

const OrdersListContainer: React.FC = () => {
  const [data, setData] = useState<any[]>([]);
  const { reloadOrders } = useSelector(
    (state: RootStateOrAny) => state.client
  );
  const { t } = useTranslation("orders");
  const statusNames: IOrdersOrderStatusLang = t("orderStatus", {
    returnObjects: true,
  });
  const orderTypeNames: IOrdersOrderTypeLang = t("orderType", {
    returnObjects: true,
  });
  const getStatusStr = (statusId: number): string => {
    switch (statusId) {
      case 0:
        return statusNames.placed
      case 1:
        return statusNames.accepted
      case 2:
        return statusNames.inRealization
      case 3:
        return statusNames.realized
      case 4:
        return statusNames.canceled
      case 5:
        return statusNames.invoiced
    }
    return "";
  }

  const getOrderTypeStr = (orderTypeId: number): string =>{
    switch (orderTypeId) {
      case 1:
        return orderTypeNames.vehicle;
      case 2:
        return orderTypeNames.tonnage;
      case 3:
        return orderTypeNames.containner;
    }
    return "";
  }

  const prepareOrders = async () => {
    var ords = await getOrders({OrderBy:"CreatedAt", Desc:true, page:1, limit: 200});
    var data: any[] = []
    ords.data.data.map((ord: any) => {
      var status = getStatusStr(ord.currentStatusId)
      var orderType = getOrderTypeStr(ord.orderTypeId)
      data.push({
        key: ord.id,
        number: ord.number,
        date: ord.createdAt.split("T").splice(0, 1).join(""),
        receptionPlace: ord.receptionPlace,
        status: status,
        statusId: ord.currentStatusId,
        type: orderType,
        quantity: ord.quantity
      })
    })
    setData(data);
  }

  const dispatch = useDispatch();
  const {
    selectedItem,
    setItemOnSelection,
    setPanelOpen,
  } = useOrdersList();
  const { handleInvoke, selection, items } = useList(
    data,
    setPanelOpen,
    setItemOnSelection
  );

  useEffect(() => {
    if (!selectedItem) {
      selection.selectToKey("", true);
    }
  }, [selectedItem]);

  useEffect(() => {
    prepareOrders();
    return () => {
      dispatch(setSelectedListItem(""));
    };
  }, [i18n.language]);

  useEffect(() => {
    if (reloadOrders === true) {
      prepareOrders();
      dispatch(reloadClientOrders(false));
    }
  }, [reloadOrders])

  return (
    <OrdersList
      handleInvoke={handleInvoke}
      selection={selection}
      items={items}
    />
  );
};

export default OrdersListContainer;