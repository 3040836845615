import { IQuantity, Quantity } from "../../../../common/models/quantity.model";

export const generateNew = (quantity: IQuantity, actions: any) => {
  const items = [{
    key: "delete",
    text: "Anuluj zamówienie",
    iconProps: { iconName: "delete" },
    name: "delete",
    onClick: actions.setOrderCanceled,
  },
  {
    key: "activate",
    text: "Akceptuj zamówienie",
    iconProps: { iconName: "followUser" },
    name: "activate",
    onClick: actions.setOrderAccepted,
  },]

  switch (quantity) {
    case Quantity.NONE:
      return [
        {
          key: "add",
          text: "Dodaj",
          iconProps: { iconName: "add" },
          onClick: actions.addNewOrder,
        },
      ];
    case Quantity.ONE:
      return [
        {
          key: "see",
          text: "Zobacz",
          iconProps: { iconName: "textDocument" },
          onClick: actions.openPanelToSee,
        },
        {
          key: "add",
          text: "Edytuj",
          iconProps: { iconName: "edit" },
          onClick: actions.openPanel,
        },
        ...items
      ];
    case Quantity.MANY:
      return [
        ...items
      ];
  }
};
