import React, { useEffect } from "react";
import { useHistory } from "react-router-dom"
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import PostalCodeSearch from "./postalCodeSearch";
import { reloadOrders, setPostalCode } from "../../../../config/redux/orders/ordersActions";
import { toggleFiltration } from "../../../../config/redux/filters/filterActions";
import { FontIcon, IStackTokens, mergeStyles, Stack } from "office-ui-fabric-react";

const horizontalGapStackTokens: IStackTokens = {
  childrenGap: 0,
};

const PostalCodeFilter: React.FC = () => {
  const history = useHistory()
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: { postalCode: "" },
    onSubmit: (values: any) => {
    },
  });

  const handleChange = (e: any, nextValue: string) => {
      setvalue(nextValue !== "__-___" ? nextValue : "");
  };

  const [value, setvalue] = React.useState<string>("");
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      dispatch(setPostalCode({ postalCode: value }));
      dispatch(toggleFiltration({ filter: true }))
      dispatch(reloadOrders(true))
    }, 500)
    return () => clearTimeout(delayDebounceFn)
  }, [value])
  
  
  const clear = () => {
    setvalue("");
  };

  useEffect(() => {
    const unlisten = history.listen(location => {
      dispatch(setPostalCode({ postalCode: "" }));
    })

    return () => {
      unlisten()
    }
  }, []);

  const buttonS = mergeStyles({
    borderRadius: "0",
    border: "1px solid ",
    borderLeft: 0,
    borderColor: "rgb(138, 136, 134)",
    paddingTop: '4px',
    paddingRight: '8px',
    paddingLeft: '2px',
    marginLeft: "-2px !important",
    marginRight: "30px",
    selectors: {
      ":hover": {
        cursor: "pointer",
        backgroundColor: "#11111104"
      },
    },
  });

  const iconClass = mergeStyles({
    fontSize: 12,
  });

  const handleSubmit = (event: { preventDefault: () => void; }) => {
    event.preventDefault();
  };

  return (
    <form onSubmit={handleSubmit}>
      <Stack horizontal tokens={horizontalGapStackTokens}>
        <PostalCodeSearch handleChange={handleChange} val={value} />
        <div className={buttonS} onClick={clear}>
          <FontIcon iconName="Clear" className={iconClass} />
        </div>
      </Stack>
    </form>
  );
};

export default PostalCodeFilter;