import React from "react";
import { Formik, Form } from "formik";
import { Stack, IStackStyles } from "office-ui-fabric-react";
import {
  validationSchemaDepartmentShort,
} from "../../../actions/validationSchemas";
import { useDepartmentForm } from "../../../hooks/useDepartmentForm";
import FormBtns from "../../../../common/layout/form/NewFormBtns";
import NewEdditionField from "../../../../common/layout/form/NewEdditionField";

import { margin, btnContainer } from "../../../styles/panelForm.styles";
import ActivityIndicator from "../../../../common/layout/ActivityIndicator";
import ErrorMessage from "../../../../common/layout/form/ErrorMessage";
import { useCancelForm } from "../../../hooks/useCancelForm";
import FormHeader from "../FormHeader";

const containerS: IStackStyles = {
  root: {
    padding: "40px 10px 20px 10px",
    height: "100%",
  },
};

const DepartmentsForm: React.FC = () => {
  const { initValues, edit, isLoading, add, selectedIds } = useDepartmentForm();
  const cancel = useCancelForm();

  return (
    <Formik
      initialValues={initValues}
      onSubmit={selectedIds[0] ? edit : add}
      enableReinitialize={true}
      validationSchema={validationSchemaDepartmentShort}
      validateOnChange
    >
      {({ handleSubmit }) => (
        <Form translate="yes" onSubmit={handleSubmit}>
          <Stack styles={containerS} verticalAlign="space-between" verticalFill>
            <FormHeader text={selectedIds[0] ? "Edytuj oddział" : "Dodaj oddział"}/>
            <NewEdditionField name="city" label="Miejscowość" />
            <NewEdditionField name="postalCode" label="Kod pocztowy" />
            <NewEdditionField name="street" label="Ulica i numer" />
            <div className={btnContainer}>
              <FormBtns
                cancelForm={cancel}
                submitBtnText={"Zapisz"}
                disabled={false}
                positionAbsolute={true}
              />
            </div>
            <div className={margin}>
              {isLoading ? <ActivityIndicator /> : <ErrorMessage />}
            </div>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

export default DepartmentsForm;