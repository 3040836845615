import React, {useCallback} from "react";
import { Pivot, PivotItem, IPivotStyles } from "office-ui-fabric-react";
import { useHistory, useLocation } from "react-router-dom";

const pivotS: Partial<IPivotStyles> = {
  root: {
    zIndex: 1,
    // backgroundColor: theme.palette.white,
    width: '100%',
    paddingBottom: 10,
    paddingTop: 10,
    paddingLeft: 30,
  },
};

interface IClientsNavProps {
  items: Array<{id: string, name: string}>
}

const ClientsNav: React.SFC<IClientsNavProps> = ({items}) => {
  const history = useHistory();
  const location = useLocation();

  const handleClick = useCallback((e: any) => {
    history.push(e.props.id);
  },[]);

  return (
    <Pivot
      onLinkClick={handleClick}
      styles={pivotS}
      selectedKey={location.pathname}
    >
      {items.map(({id, name}: {id: string, name: string}) => {
        return <PivotItem headerText={name} itemKey={id} key={id} id={id}/>
      })}
    </Pivot>
  );
};

export default ClientsNav;