import React from 'react'
import { mergeStyles } from 'office-ui-fabric-react';
import { wrapper } from '../../auth/styles/activityIndicatorContainer.styles';
import ListSuccessMessage from '../../common/layout/ListSuccessMessage';
import NotConfirmedUsersList from './NotConfirmedUsersList';
import NotConfirmedUserPanel from '../panel/NotConfirmedUserPanel';

const padding = mergeStyles({
    paddingTop: 15
})

const NotConfirmedUsers: React.FC = () => {
    return (
        <div className={wrapper}>
            <div className={padding}>
                <ListSuccessMessage />
            </div>
            <NotConfirmedUsersList />
            <NotConfirmedUserPanel />
        </div>
    )
}

export default NotConfirmedUsers