import React from "react";
import { wrapper } from "../../common/styles/formDropDown.styles";
import ReceptionPlacesList from "../components/ReceptionPlacesList";
import ReceptionPlacesPanel from "../panel/ReceptionPlacesPanel";

const ReceptionPlaces: React.FC = () => {
    return (
        <div className={wrapper}>
            <ReceptionPlacesList />
            <ReceptionPlacesPanel />
        </div>
    );
};

export default ReceptionPlaces;