import React from "react";
import { RootStateOrAny, useSelector, useDispatch } from "react-redux";
import { Panel } from "office-ui-fabric-react";
import { panelS } from "../../../features/common/styles/panelStyles";
import {
  setSelectedListItem,
  toggleOrderPanel,
} from "../../../config/redux/client/clientActions";
import OrderNewClientForm from "../forms/OrderNewClientForm";
import OrderDisplay from "../display/OrderDisplay";
import OrderEditClientForm from "../forms/OrderEditClientForm";
import { useClientData } from "../../../features/orders/hooks/useClientData";

const OrderPanel: React.FC = () => {
  const dispatch = useDispatch();
  const { orderPanelOpen, formActive, editOrder, selectedItem } = useSelector(
    (state: RootStateOrAny) => state.client
  );

  useClientData();

  const dismissPanel = () => {
    dispatch(
      toggleOrderPanel({ isOpen: false, formActive: formActive, editOrder: false })
    );
    dispatch(setSelectedListItem(""));
    localStorage.removeItem("receptionPlace");
    localStorage.removeItem("orderTypeItemId");
  };
  return (
    <Panel
      isOpen={orderPanelOpen}
      closeButtonAriaLabel="Close"
      //headerText="Zamówienie"
      onDismiss={dismissPanel}
      type={3}
      onOuterClick={() => { }}
      isLightDismiss={false}
      styles={panelS}
      isHiddenOnDismiss={false}
      isFooterAtBottom={true}
    >
      {formActive && editOrder === false && <OrderNewClientForm />}
      {formActive && editOrder === true && <OrderEditClientForm selectedItem={selectedItem} />}
      {!formActive && selectedItem && (
        <OrderDisplay selectedItem={selectedItem} />
      )}
    </Panel>
  );
};

export default OrderPanel;