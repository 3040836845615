import React, { useEffect } from "react";
import { SelectionMode } from "office-ui-fabric-react/lib/Selection";
import {
    ConstrainMode,
    DetailsListLayoutMode,
    DetailsRow,
    getTheme,
    IColumn,
    Icon,
    IDetailsListProps,
} from "office-ui-fabric-react";
import { listS } from "../styles/list.styles";
import { useDetailGroupedList } from "../hooks/useDetailGroupedList";
import { useDispatch } from "react-redux";
import { ShimmeredDetailsList } from 'office-ui-fabric-react/lib/ShimmeredDetailsList';
import { setSelectedUnactiveUsers } from "../../../config/redux/unactiveusers/unactiveusersActions";
const UnactiveUsersList: React.FC = () => {
    const theme = getTheme()
    const dispatch = useDispatch();
    const onInvoke = () => {

    };

    const onSelect = (data: any) => {
        dispatch(setSelectedUnactiveUsers(data));
    };

    const onRenderItemColumn = (item?: any, index?: number, column?: IColumn): JSX.Element | string | number => {
        if (column === null) {
            return "";
        }

        if (!!column && column.key === 'thumbnail') {
            if (activatedUsers[item.id] === true) {
                return <Icon iconName="ReminderPerson" />;
            } else {
                return <Icon iconName="Contact" />;
            }
        }

        if (!!column) {
            return item[column.key as keyof any];
        } else {
            return "";
        }
    };

    const onRenderRow: IDetailsListProps["onRenderRow"] = (props: any) => {
        const customStyles: Partial<any> = {};
        if (props) {
            if (activatedUsers[props.item.id] === true) {
                customStyles.root = { color: theme.palette.green };
            }
            return <DetailsRow {...props} styles={customStyles} />;
        }
        return null;
    };

    const {
        items,
        groups,
        columns,
        selection,
        handleInvoke,
        selectedUnactiveUsers,
        status,
        activatedUsers
    } = useDetailGroupedList(onSelect, onInvoke);

    useEffect(() => {
        return () => {
            dispatch(setSelectedUnactiveUsers(""));
        };
    }, []);

    useEffect(() => {
        if (!selectedUnactiveUsers) {
            selection.selectToKey("", true);
        }
    }, [selectedUnactiveUsers]);
    return (
        <ShimmeredDetailsList
            items={items}
            selectionMode={SelectionMode.single}
            selection={selection}
            groupProps={{
                headerProps: {
                    onGroupHeaderClick: () => { },
                },
            }}
            onRenderItemColumn={onRenderItemColumn}
            onRenderRow={onRenderRow}
            layoutMode={DetailsListLayoutMode.justified}
            onItemInvoked={handleInvoke}
            groups={groups}
            columns={columns}
            styles={listS}
            constrainMode={ConstrainMode.unconstrained}
            onColumnHeaderClick={() => { }}
            enableShimmer={status === 'loading'}
        />
    );
};

export default UnactiveUsersList;