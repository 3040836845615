export const DetailedPriceListColumns = [
    {
      key: "companyName",
      name: "Nazwa firmy",
      fieldName: "companyName",
      minWidth: 160,
      maxWidth: 300,
      isResizable: true,
    },
    {
      key: "receptionPlaceAddress",
      name: "Adres punktu odbioru",
      fieldName: "receptionPlaceAddress",
      minWidth: 160,
      maxWidth: 300,
      isResizable: true,
    },
    {
      key: "orderTypeItem",
      name: "Podtyp",
      fieldName: "orderTypeItem",
      minWidth: 80,
      maxWidth: 120,
      isResizable: true,
    },
    {
      key: "currentPrice",
      name: "Aktualna cena",
      fieldName: "currentPrice",
      minWidth: 80,
      maxWidth: 150,
      isResizable: true,
    },
    {
      key: "companySpecialPrice",
      name: "Cena firmy",
      fieldName: "companySpecialPrice",
      minWidth: 80,
      maxWidth: 150,
      isResizable: true,
    },
    {
      key: "companySpecialPriceTo",
      name: "Data do",
      fieldName: "companySpecialPriceTo",
      minWidth: 70,
      maxWidth: 100,
      isResizable: true,
    },
    {
      key: "receptionPlaceSpecialPrice",
      name: "Cena punktu",
      fieldName: "receptionPlaceSpecialPrice",
      minWidth: 80,
      maxWidth: 120,
      isResizable: true,
    },
    {
      key: "receptionPlaceSpecialPriceTo",
      name: "Data do",
      fieldName: "receptionPlaceSpecialPriceTo",
      minWidth: 70,
      maxWidth: 100,
      isResizable: true,
    },
    {
      key: "unitPrice",
      name: "Cena jednostkowa",
      fieldName: "unitPrice",
      minWidth: 120,
      maxWidth: 200,
      isResizable: true,
    },
    {
      key: "postalCodePrice",
      name: "Cena dla kodu",
      fieldName: "postalCodePrice",
      minWidth: 100,
      maxWidth: 120,
      isResizable: true,
    },
  ];