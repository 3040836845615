import jwtDecode from "jwt-decode";
import { useState } from "react";
import authentication from "react-azure-b2c";
import i18n from "../../../localization/i18n";

type IDecoded = {
  role: string;
  exp: number
};

export enum Roles {
  ADMIN = "ADMIN",
  CLIENT = "CLIENT",
  CLIENT_ADMIN = "CLIENT_ADMIN",
  DRIVER = "DRIVER"
}

export const authorize = () => {
  const token = authentication.getAccessToken().accessToken;
  const isActivated = sessionStorage.getItem("isActivated") === "true";
  const hasAllRequiredData = sessionStorage.getItem("hasAllRequiredData") === "true";
  let role: string | null = "";
  let expired: boolean = false


  if (token) {
    const decoded: IDecoded = jwtDecode(token);
    if (sessionStorage.getItem('role') !== "") {
      role = sessionStorage.getItem('role');
      if(role == Roles.CLIENT_ADMIN){
        //i18n.language = 'pl'
        //console.log("pl")
        //i18n.changeLanguage('pl')
      }
    }
    const dateNow = new Date()
    if (decoded.exp * 1000 < dateNow.getTime()) {
      expired = true
      authentication.signOut();
    }
  }

  return {
    token,
    role,
    expired,
    isActivated,
    hasAllRequiredData
  };
};