import * as React from "react";
import { Stack, Image, FontIcon } from "office-ui-fabric-react";
import UserIcon from "./UserIcon";
import { NavLink } from "react-router-dom";
import { NavigationPath } from "../../../config/routing/NavigationPath";
import { burgerS, container, containerS, link, textS } from "../../styles/layout/header.styles";
import { useDispatch } from "react-redux";
import { toggleClientNav } from "../../../config/redux/client/clientActions";
import { LanguageSwitcher } from "../../../features/common/layout/header/LanguageSwitcher";
import { useTranslation } from "react-i18next";

export interface HeaderProps { }

const Header: React.FC<HeaderProps> = () => {
  const dispatch = useDispatch()

  const openNav = () => {
    dispatch(toggleClientNav(true))
  }
  const { t } = useTranslation("common");
  return (
    <Stack
      styles={containerS}
      verticalAlign="center"
      horizontal
      horizontalAlign="space-between"
    >
      <div className={container}>
        <FontIcon iconName='AlignJustify' className={burgerS} onClick={openNav} />
        <Image src="/recycle-white.png" />
        <NavLink to={NavigationPath.CLIENT_DASHBOARD} className={link}>
          <p className={textS}>{t("desktop")}</p>
        </NavLink>
      </div>
      <div className={container}>
        <LanguageSwitcher />
        <UserIcon />
      </div>
    </Stack>
  );
};

export default Header;