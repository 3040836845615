export const generateInitValues = (fieldNames: Array<{ name: string }>, value: string | boolean) => {
    const valuesArr = fieldNames.map(({ name }) => {
      return { [name]: value };
    });
  
    const initValues = valuesArr.reduce(function (result, item) {
      var key = Object.keys(item)[0];
      result[key] = item[key];
      return result;
    }, {});
  
    return initValues;
  };