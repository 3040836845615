import React from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import CommentForm from "../CommentForm";

import OrderComments from "./OrderComments";

const CommentsContainer: React.FC = () => {
  const { addComment } = useSelector((state: RootStateOrAny) => state.orders);

  return <>{addComment ? <CommentForm /> : <OrderComments />}</>;
};

export default CommentsContainer;
