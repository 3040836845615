import React from "react";
import { useField } from "formik";
import { TextField } from "office-ui-fabric-react";

import { textfieldS } from "../../../../common/styles/formField.styles";

export interface PriceProps {
  name: string;
  label: string;
  value: number;
  disabled?: boolean;
}

const Price: React.FC<PriceProps> = ({ name, label, disabled, value }) => {
  const [field] = useField(name)
  return (
    <TextField
      type="number"
      label={label}
      required
      disabled={disabled}
      value={value.toFixed(2).toString()}
      styles={textfieldS}
    />
  );
};

export default Price;
