import React, { useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { getSendNotifications } from "../../../connectors/others/connectors";
import { generateSMSHistory } from "../../clients/components/colums/smshistory-columns";
import { useList } from "../../common/hooks/useList";
import SMSHistoryList from "./SMSHistoryList";

const SMSHistoryListContainer: React.FC = () => {
    const [data, setData] = useState<any[]>([]);
    const { selectedDrivers } = useSelector(
        (state: RootStateOrAny) => state.drivers
    );
    const getData = async () => {
        const id = selectedDrivers[0].id;
        var pcks = await getSendNotifications(id);
        setData(generateSMSHistory(pcks.data.data));
    }

    const disptach = useDispatch();

    const { handleInvoke, selection, items } = useList(
        data,
    );

    useEffect(() => {
        getData();
    }, []);

    return (
        <SMSHistoryList
            items={items}
        />
    );
};

export default SMSHistoryListContainer;
