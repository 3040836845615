import React from "react";
import { mergeStyles } from "office-ui-fabric-react";
import Column from "./Column";

const containerS = mergeStyles({
  margin: "0",
  padding: "30px",
});

const header = mergeStyles({
  fontWeight: "700",
});

const orderNubmer = mergeStyles({
  paddingRight: 15,
  fontWeight: "700",
});

const numberHeader = mergeStyles({
  display: "flex",
});

const logo = mergeStyles({
  height: 100,
});

const signContainer = mergeStyles({
   
})

class OrderPrint extends React.Component<any> {
  render() {
    return (
      <>
        {this.props.data && (
          <div className={containerS}>
            <img src="/logo2.jpg" alt="" className={logo} />
            <h2 className={numberHeader}>
              <div className={orderNubmer}>
                <p>Numer zamówienia:</p>
              </div>
              <div>
                <p>{this.props.data.orderInfo.number}</p>
              </div>
            </h2>
            <h3 className={header}>Historia zamówienia</h3>
            <Column
              name="Data przyjęcia zamówienia"
              value={this.props.data.orderInfo.acceptedDate
                .split("T")
                .splice(0, 1)}
            />
            <Column
              name="Data planowanej realizacji zamówienia"
              value={this.props.data.orderInfo.acceptedDate
                .split("T")
                .splice(0, 1)}
            />

            <h3 className={header}>Dane zamówienia</h3>
            <Column
              name="Nazwa klienta"
              value={this.props.data.orderInfo.company}
            />
            <Column
              name="Telefon"
              value={
                this.props.data.orderInfo.receptionPlacePhone
                  ? this.props.data.orderInfo.receptionPlacePhone
                  : ""
              }
            />
            <Column
              name="Adres odbioru"
              value={this.props.data.orderInfo.receptionPlace}
            />
            <Column
              name="pojazd"
              value={this.props.data.orderInfo.vehicle}
            />
            <Column
              name="Typ zamówienia"
              value={this.props.data.orderInfo.type}
            />
            <Column name="ilość" value={this.props.data.orderInfo.quantity} />
            <Column
              name="Forma płatności"
              value={this.props.data.paymentType === 0 ? "Przelew" : "Gotówka"}
            />
            <Column name="Kwota brutto" value={this.props.data.totalValue} />
            <div className={signContainer}>
              <div>
                <p>................................</p>
              </div>
              <div>
                <p>podpis</p>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default OrderPrint;
