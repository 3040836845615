import { IActiveActions} from "../../../models/action.types";
import { Quantity, IQuantity } from "../../../../common/models/quantity.model";

export const generateActivecommands = (
    quantity: IQuantity,
    actions: IActiveActions
  ) => {

    const items = [{
      key: "deactivate",
      text: "Deaktywuj",
      iconProps: { iconName: "peopleAlert" },
      name: "deactivate",
      onClick: actions.deactivate,
    },
    {
      key: "delete",
      text: "Usuń",
      iconProps: { iconName: "delete" },
      name: "delete",
      onClick: actions.removeCompany,
    }
  ]

  const items2 = [
    {
      key: "downloadReport",
      text: "Pobierz", 
      iconProps: {iconName: "ReportDocument"},
      onClick: actions.downloadActiveClientsReport,
    }
  ]
    if (quantity === Quantity.NONE) {
      return [
        {
          key: "add",
          text: "Dodaj",
          iconProps: { iconName: "add" },
          name: "add",
          onClick: actions.addCompany,
        },
        ...items2
      ];
    }else if(quantity === Quantity.ONE){
      return [
        {
        key: "see",
        text: "Zobacz",
        iconProps: { iconName: "textDocument" },
        name: "see",
        onClick: actions.see,
        },
        {
          key: "add",
          text: "Dodaj",
          iconProps: { iconName: "add" },
          name: "add",
          onClick: actions.addCompany,
        },
        {
          key: "edit",
          text: "Edytuj",
          iconProps: { iconName: "edit" },
          name: "edit",
          onClick: actions.edit,
        },
        ...items,
        ...items2
      ];
  
    } else {
      return [
        {
          key: "add",
          text: "Dodaj",
          iconProps: { iconName: "add" },
          name: "add",
          onClick: actions.addCompany,
        },
        ...items,
        ...items2
      ];
    }
  };