export const reportsColumns = [
    {
        key: "name",
        name: "Nazwa",
        fieldName: "name",
        minWidth: 250,
        maxWidth: 300,
        isResizable: true,
    },
    {
        key: "description",
        name: "Opis",
        fieldName: "description",
        minWidth: 350,
        maxWidth: 500,
        isResizable: true,
    },
];