import { IUserActions } from '../../../../../clients/models/commandBar-actions-model';
import { IQuantity } from '../../../../../common/models/quantity.model';
import { Quantity } from "../../../../../common/models/quantity.model"

export const generateUsersComands = (quantity: IQuantity, actions: IUserActions, item: any, commandBarNames: any) => {
  
  if (quantity === Quantity.NONE) {
    return [
      {
        key: "add",
        text: commandBarNames.inviteNewUser,
        iconProps: { iconName: "mail" },
        name: "add",
        onClick: actions.toggleInvite,
      },
    ];
  } else {
    const commandItem = item.isActive ? {
      key: "deactivate",
      text: commandBarNames.deactivate,
      iconProps: { iconName: "PeopleBlock" },
      name: "deactivate",
      onClick: actions.deactivateUserById
    } :
      {
        key: "activate",
        text: commandBarNames.activate,
        iconProps: { iconName: "ReminderPerson" },
        name: "activate",
        onClick: actions.activateUserById
      }

    if (!item.isInvitationInProgress) {
      return [
        {
          key: "add",
          text: commandBarNames.inviteNewUser,
          iconProps: { iconName: "mail" },
          name: "add",
          onClick: actions.toggleInvite,
        },
        {
          key: "edit",
          text: commandBarNames.edit,
          iconProps: { iconName: "edit" },
          name: "edit",
          onClick: actions.toggle,
        },
        {
          key: "delete",
          text: commandBarNames.delete,
          iconProps: { iconName: "delete" },
          name: "delete",
          onClick: actions.deleteUserById,
        },
        commandItem,
      ];
    } else {
      return [
        {
          key: "add",
          text: commandBarNames.inviteNewUser,
          iconProps: { iconName: "mail" },
          name: "add",
          onClick: actions.toggleInvite,
        },
        {
          key: "mail",
          text: commandBarNames.inviteAgain,
          iconProps: { iconName: "mail" },
          name: "mail",
          onClick: actions.inviteUserAgainByMail,
        },
        {
          key: "delete",
          text: commandBarNames.delete,
          iconProps: { iconName: "delete" },
          name: "delete",
          onClick: actions.deleteUserById,
        },
      ];
    }
  }
};