import { FormikValues } from "formik";
import { useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { clearAlerts, setErrorAlert, setListMessage } from "../../../config/redux/alerts/alertsActions";
import { clearData, refetchClientPanelData } from "../../../config/redux/clients/panel/clientPanelActions";
import { addCompaniesAttachments, getAuthorizationAzureStorageSasToken } from "../../../connectors/clients/connectors";
import { BlobServiceClient, BlobUploadCommonResponse } from '@azure/storage-blob';

export const useFilesUploadForm = () => {

    const {  basicInfo } = useSelector(
      (state: RootStateOrAny) => state.clientPanel
    );
    const dispatch = useDispatch();
  
    const [file, setFile] = useState<File>();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
  
    const arrangementFileHandler = (event: any) => {
        setFile(event.target.files[0]);
        setIsFilePicked(true);
    };

  const handleSubmit = async (values: FormikValues) => {
    setIsLoading(true)
    try {
      var companyId = basicInfo.basicInformations.id
      var taxNumber = basicInfo.basicInformations.taxNumber
      if(file !== undefined) {
        await uploadFile(file, companyId, taxNumber, values);
        setFile(undefined);
      }

      setIsFilePicked(false)
      setIsLoading(false)
      dispatch(clearData())
      dispatch(clearAlerts())
      dispatch(refetchClientPanelData(true));
      dispatch(setListMessage("Operacja przebiegła pomyślnie"))
    } catch (err) {
      dispatch(clearData())
      dispatch(clearAlerts())
      dispatch(setErrorAlert("Błąd"))
    }
  }
  
  const uploadFile = async (file: File, companyId: number, taxNumber: string, values: FormikValues) => {
    let result = await uploadFileToAzure(file, taxNumber);
  
    var obj = {
      originalFileName: file.name,
      blobFileName: result.name,
      fileUrl: result.url,
      documentDate: values.documentDate,
      documentType: values.documentType,
      containersNumber: values.containersNumber,
      CompanyId: companyId,
      description: values.description
    }
  
    var resp = await addCompaniesAttachments(obj);
  }
  
  const uploadFileToAzure = async (file: File, taxNumber: string): Promise<UploadedFile> => {
    const sa = await getAuthorizationAzureStorageSasToken(file.name, taxNumber);
    if(sa.data === undefined)   
      return {
        name: "",
        url:""
      }
    
    const sasResult: SasResult = {
      blobUri: sa.data.blobUri,
      blobName: sa.data.blobName,
      container: sa.data.container,
      sasKey: sa.data.sasKey
    };
    
    const blobServiceClient = new BlobServiceClient(
      `${sasResult.blobUri}/?${sasResult.sasKey}`
    );

    const containerClient = blobServiceClient.getContainerClient(sasResult.container);
    const blockblobClient = containerClient.getBlockBlobClient(sasResult.blobName);
    
    let blobUploadCommonResponse: BlobUploadCommonResponse = await blockblobClient.uploadData(file);

    if(blobUploadCommonResponse._response.status === 201) {
      //let url: string = await blobStorageService.getLinkToFile(sasResult.blobName);
      return {
        name: sasResult.blobName,
        url: sasResult.blobUri,
      }
    }

    return {
      name: "",
      url:""
    }
  }
  
    return {
      arrangementFileHandler,
      handleSubmit,
      isLoading
    }
  }
  export interface SasResult {
    blobUri: string;
    sasKey: string;
    container: string;
    blobName: string;
  }
  
  export interface UploadedFile {
    name: string;
    url: string;
  }