import { useEffect, useMemo } from "react";
import { useQuery } from "react-query";
import { userInfo } from "../../../connectors/auth/connectors";

export const useUserInfo = () => {

  const {data, status, error} = useQuery("userinfo", userInfo,
    {
      retry: false, // Will retry failed requests 10 times before displaying an error
      staleTime: 5*1000
    }
  );

  const fetchedData = useMemo(() => {
    if(data) {
      return data;
    } else if(error) {
      return "";
    } else {
      return "";
    }
  }, [data]);

  useEffect(() => {
    // if(data) { }
  }, [JSON.stringify(fetchedData)]);

  return {
    data: data,
    status: status,
    error: error
  };
};