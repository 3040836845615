import {  CommandButton,  mergeStyles } from "office-ui-fabric-react";
import React, { useRef, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import  { useReactToPrint } from "react-to-print";
import { togglePrintingLoading } from "../../../config/redux/orders/ordersActions";
import { getOrderDetails } from "../../../connectors/orders/connectors";
import OrderPrint from "./OrderPrint";

const container = mergeStyles({
  display: "none",
});

const wrapper = mergeStyles({
  display: "flex",
  justifyContent: "center"
})
export interface PrintContainerProps {}

const PrintContainer: React.FC<PrintContainerProps> = () => {
  const [data, setData] = useState();
  const dispatch = useDispatch()
  const { selectedOrders } = useSelector(
    (state: RootStateOrAny) => state.orders
  );
  const componentRef = useRef<any>();
  const handlePrint = useReactToPrint({
    onBeforeGetContent: async () => {
      dispatch(togglePrintingLoading(true))
      const res = await getOrderDetails(selectedOrders[0].id);
      setData(res.data.data);

      if(res){
        dispatch(togglePrintingLoading(false))
      }
    },
    content: () => {
      return componentRef.current;
    },
  });

  return (
    <div className={wrapper}>
      <div className={container}>
        <OrderPrint ref={componentRef} data={data} />
      </div>
      <CommandButton onClick={handlePrint} iconProps={{ iconName: "print" }}>
        Drukuj
      </CommandButton>
    </div>
  );
};

export default PrintContainer;
