import * as React from "react";
import { IContainerPoint } from "../interfaces/IContainerPoint";
import { wrapperStyles } from "../styles/style";
import { ContainerBatteryStatusEnumToString } from "../../common/converters/containerBatteryStatus";

export interface ContainerPopupHtmlProps {
    containnerData: IContainerPoint;
}

export const ContainerPopupHtml: React.FC<ContainerPopupHtmlProps> = ({ containnerData }) => {
    return (
        <div style={wrapperStyles.popupStyles}>
            Numer kontenera: <b>{containnerData.containerNumber}</b>< br />
            {containnerData.serNo !== undefined ? <>Numer seryjny: <b>{containnerData.serNo}</b> < br /> </> : <></>}
            Ostatni odczyt: {containnerData.lastActiveDate}< br />
            {(containnerData.imei !== undefined && containnerData.imei !== 0) ? <>IMEI: {containnerData.imei} < br /> </> : <></>}
            {containnerData.iccid !== undefined ? <>Iccid: {containnerData.iccid} < br /> </> : <></>}
            {containnerData.currentBatteryLevel !== undefined ? <>Poziom baterii: {containnerData.currentBatteryLevel} [V]<br /> </> : <></>}
            {containnerData.currentBatteryStatus !== undefined ? <>Status bateii: {ContainerBatteryStatusEnumToString(containnerData.currentBatteryStatus)}<br /> </> : <></>}
        </div>
    )
}