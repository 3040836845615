import * as React from "react";
import { useTranslation } from "react-i18next";
import DisplayText from "./DisplayTxt";
import { IReceptionPlacesPanelLang } from "../../../localization/abstract/client/receptionPlaces";

export interface ReceptionDataDisplayProps {
  selectedItem: any;
}

const ReceptionDataDisplay: React.FC<ReceptionDataDisplayProps> = ({
  selectedItem,
}) => {
  const { t } = useTranslation("receptionPlaces");
  const receptionPlaceDetailNames: IReceptionPlacesPanelLang = t("panel", {
    returnObjects: true,
  });

  return (
    <div>
      <DisplayText title={receptionPlaceDetailNames.city} value={selectedItem.city} />
      <DisplayText title={receptionPlaceDetailNames.streetAndNumber} value={selectedItem.street} />
      <DisplayText title={receptionPlaceDetailNames.postalCode} value={selectedItem.postalCode} />
      <DisplayText title={receptionPlaceDetailNames.phoneNumber} value={selectedItem.phone} />
      <DisplayText title={receptionPlaceDetailNames.bdoNumber} value={selectedItem.bdoNumber} />
      <DisplayText title={receptionPlaceDetailNames.clientType} value={selectedItem.orderType} />
      <DisplayText
        title={receptionPlaceDetailNames.defaultReceptionPlace}
        value={selectedItem.isDefault ? receptionPlaceDetailNames.yes : receptionPlaceDetailNames.no}
      />
    </div>
  );
};

export default ReceptionDataDisplay;