import React from "react";
import { useSelector, RootStateOrAny, useDispatch } from "react-redux";
import { Panel, IPanelStyles } from "office-ui-fabric-react";
import { RenderNavigation } from "../../../orders/components/CustomNavigation";
import { clearAlerts } from "../../../../config/redux/alerts/alertsActions";
import { toggleValidatorPanelApproved } from "../../../../config/redux/validators/validatorsPanel/validatorPanelActions";
import ConfirmValidatorForm from "../forms/ConfirmValidatorForm";

const panelS: Partial<IPanelStyles> = {
  content: {
    padding: 20,
  },
  main: {
    height: "100%",
  },
};

const ValidatorPanelApproved: React.FC = () => {
  const dispatch = useDispatch();
  const { validatorPanelApprovedOpen } = useSelector(
    (state: RootStateOrAny) => state.validatorPanel
  );

  const dissmisPanel = (ev: any) => {
    dispatch(clearAlerts());
    dispatch(toggleValidatorPanelApproved(false));
  };

  const cancelForm = () => {
    dispatch(toggleValidatorPanelApproved(false));
  };

  return (
    <Panel
      isOpen={validatorPanelApprovedOpen}
      closeButtonAriaLabel="Close"
      type={3}
      onDismiss={dissmisPanel}
      onOuterClick={() => { }}
      isLightDismiss={false}
      isHiddenOnDismiss={false}
      onRenderNavigation={RenderNavigation}
      styles={panelS}
      isFooterAtBottom={true}
    >
      <>
        <ConfirmValidatorForm
          cancelForm={cancelForm}
          reception={0}
          orderTypeItemId={0}
        />
      </>
    </Panel>
  );
};

export default ValidatorPanelApproved;