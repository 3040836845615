import * as React from "react";

import VehiclesList from "../components/VehiclesList";
import VehiclePanel from "../components/VehiclePanel";
import ListSuccessMessage from "../../common/layout/ListSuccessMessage";
import { mergeStyles } from "office-ui-fabric-react";

const margin = mergeStyles({
  paddingTop: 15
});

const wrapper = mergeStyles({
  width: "95%",
  margin: "0px auto",
});


const Vehicles: React.FC = () => {
  return (
    <div className={wrapper}>
      <div className={margin}>
        <ListSuccessMessage />
      </div>
      <VehiclesList />
      <VehiclePanel />
    </div>
  );
};

export default Vehicles;
