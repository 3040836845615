import React, { useEffect, useState, useCallback } from "react";
import {
    ComboBox,
    IComboBox,
    IComboBoxOption,
} from "office-ui-fabric-react/lib/ComboBox";
import _ from "lodash";
import { getCompanies, getCompany } from "../../../../../connectors/clients/connectors/index";
import { useEffectAfterMount } from "../../../../common/hooks/useEffectAfterMount";
import { Spinner, SpinnerSize } from "@fluentui/react";
import { IMessageBarStyles, MessageBar, MessageBarType } from "office-ui-fabric-react";
import { useField } from "formik";

const errorMessageS: IMessageBarStyles = {
    root: {
        marginTop: "15px",
        width: "100%",
    },
};

interface ClientsDropDownProps {
    value: string;
    label: string,
    name: string,
    setFieldValue?: any;
    resetForm?: any;
    onChange?: any;
    required?: boolean,
    setDepartments?: any,
    company?: any;
}

const ClientsDropDown: React.FC<ClientsDropDownProps> = ({
    setFieldValue,
    resetForm,
    value,
    onChange,
    label,
    name,
    required,
    company
}) => {
    const [clients, setClients] = useState<any>([]);
    const [inputValue, setInputValue] = useState("");
    const [asyncActionInProgress, setAsyncActionInProgress] = useState<boolean>(false);
    const [field, { error }] = useField(name);
    const comboBoxRef = React.useRef<IComboBox>(null);

    const openMenu = React.useCallback(() => {
        if (comboBoxRef.current != undefined) {
            comboBoxRef.current.focus(true)
        }
    }, []);

    const debouncedFetchClients = useCallback(_.debounce((inputValue: string) => {
        fetchClients(inputValue);
    }, 350),
        []
    );

    useEffect(() => {
        fetchClients();
    }, []);

    useEffect(() => {
        fetchClient()
    },[company])

    useEffectAfterMount(() => {
        debouncedFetchClients(inputValue);
    }, [inputValue]);

    const handleChange = useCallback(
        (option?: IComboBoxOption, index?: number, value?: string): void => {
            if (value !== undefined) {
                if (value.length > 5) {
                    openMenu();
                }
                setInputValue(value);
            }
        },
        []
    );


    const fetchClient = async () =>{
        if(company.key != ""){
            const found = clients.find((x :any)=> x.key == company.key);
            if(found == undefined){
                const res = await getCompany(company.key);
                const company1 = res.data.data;
                var data = [
                    {
                    key: company1.basicInformations.id,
                    text: (company1.basicInformations.shortName && company1.basicInformations.shortName !== "" ? company1.basicInformations.shortName : company1.basicInformations.companyName) + ' (' + company1.basicInformations.taxNumber + ')',
                    data: company1.basicInformations
                }]
                setClients(data)
                onChange(data[0])
                setFieldValue(name, data[0]);
            }
            else
            {
                setClients([found])
                onChange(found)
                setFieldValue(name, found);
            }
        }
    }

    const fetchClients = async (currentInputValue?: string) => {
        setAsyncActionInProgress(true);

        const params = { page: 1, limit: 20, Verified: true, Active: true, AllFields: !!currentInputValue ? currentInputValue : inputValue };
        const res = await getCompanies(params);
        const data = res.data.data.map((client: any) => {
            return {
                key: client.id,
                text: (client.shortName && client.shortName !== "" ? client.shortName : client.companyName) + ' (' + client.taxNumber + ')',
                data: client,
            };
        });

        setClients(data);
        setAsyncActionInProgress(false);
    }

    const setOption = (
        event: React.FormEvent<IComboBox>,
        option?: IComboBoxOption,
        index?: number,
        value?: string
    ): void => {
        if (option) {
            resetForm();
            setFieldValue(name, option);
            if (onChange) {
                onChange(option)
            }
        }
    };


    return <div style={{ position: 'relative' }}>
        {asyncActionInProgress &&
            <Spinner size={SpinnerSize.small} style={{ position: 'absolute', top: 7, left: 60 }} />
        }
        <ComboBox
            componentRef={comboBoxRef}
            required={required ? required : false}
            label={label}
            selectedKey={value}
            autoComplete={"on"}
            allowFreeform
            onPendingValueChanged={handleChange}
            onChange={setOption}
            options={clients}
            dropdownWidth={550}
        //styles={optionsS}
        />
        {error && (
            <MessageBar
                messageBarType={MessageBarType.error}
                isMultiline={false}
                dismissButtonAriaLabel="Close"
                styles={errorMessageS}
            >
                {error}
            </MessageBar>
        )}
    </div>
};

export default ClientsDropDown;