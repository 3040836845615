import {IStoreDocs, IActiveStoreDocs, IReload, IOpenPanel} from "./storeDocsActions.types"
import {StoreDocsActions} from "./storeDocsActions.enum"

export const setStoreDocs = (data: IStoreDocs) => {
  return {
    type: StoreDocsActions.SET_STOREDOCS,
    payload: data,
  };
};

export const clearListData = () => {
  return {
    type: StoreDocsActions.CLEAR_LIST_DATA,
  };
};

export const setActiveStoreDoc = (data: IActiveStoreDocs) => {
  return {
    type: StoreDocsActions.SET_ACTIVE_STOREDOC,
    payload: data
  }
}

export const reloadData = (data: IReload) => {
  return {
    type: StoreDocsActions.RELOAD_DATA,
    payload: data
  }
}

export const toggleAddStoreDocPanel = (data: IOpenPanel) => {
  return {
    type: StoreDocsActions.TOGGLE_PANEL,
    payload: data
  }
}

export const addNewStoreDoc = (add :boolean) => {
  return {
    type: StoreDocsActions.ADD_NEW_STOREDOC,
    payload: add
  }
}

export const setApprove = (approve: boolean) => {
  return {
    type: StoreDocsActions.SET_APPROVE,
    payload: approve
  }
}

export const setCancel = (cancel: boolean) => {
  return {
    type: StoreDocsActions.SET_CANCEL,
    payload: cancel
  }
}