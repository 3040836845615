import { RootStateOrAny } from 'react-redux';
import { useSelector } from 'react-redux';
import { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { getOrderTypes } from "../../../connectors/orderTypes/connectors";
import {
  Selection,
} from "office-ui-fabric-react/lib/DetailsList";

export const useDetailGroupedList = (onSelection?: any, onInvoke?: any) => {
  const { data, status } = useQuery("orderTypes", getOrderTypes);
  const [groups, setGroups] = useState<any>(undefined);
  const [items, setItems] = useState<any>([]);
  const {selectedOrderTypes} = useSelector((state: RootStateOrAny) => state.orderTypes)
  const fetchedData = useMemo(() => {
    if(data){
      return data.data.data
    }else return ""
  },[data])
 
  useEffect(() => {
    if (data) {
      generateGroups(data.data.data);
      generateItems(data.data.data);
    }
  }, [JSON.stringify(fetchedData)]);

  const generateGroups = (items: Array<any>) => {
    let index = 0;
    const gr = items.map((item: any) => {
      const grItem = {
        name: `Rodzaj: ${item.name}`,
        count: item.items.length,
        key: item.name,
        startIndex: index,
      };
      index += item.items.length;
      return grItem;
    });

    setGroups(gr);
  };
  const columns = useMemo(
    () => [
      {
        key: "thumbnail",
        minWidth: 16,
        maxWidth: 16, 
        fieldName: "thumbnail",
        name: "thumbnail",
        isIconOnly: true,
        //iconName:"Page",
        isResizable: false,
      },
      {
        key: "name",
        name: "Nazwa",
        fieldName: "name",
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
      },
      {
        key: "value",
        name: "Ładowność (t)",
        fieldName: "value",
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
      },
    ],
    []
  );

  const generateItems = (items: Array<any>) => {
    const arr: Array<any> = [];
    items.forEach((item: any) => {
      const parsedItems = item.items.map((item: any) => {
        const convertedString = item.value.toString().replace(/[,.]/g, (m: any) => (m === ',' ? '.' : ','))
        return{
          ...item,
          value: convertedString
        }
      })
      
      arr.push(...parsedItems);
    });
    setItems(arr);
  };

  const selection = useMemo(
    () =>
      new Selection({
        onSelectionChanged: () => {
            if(onSelection){
              onSelection(selection.getSelection())
            }
        },
      }),
    []
  );

  const handleInvoke = (item: any) => {
    if(onInvoke){
      onInvoke()
    }
  };

  return {
    groups,
    items,
    columns,
    selection,
    handleInvoke,
    selectedOrderTypes,
   status
  };
};