import {FieldNames} from "../../models/clients-filedNames.enum"

export const inActiveClientsColumns = [
  {
    key: "resultName",
    name: "Nazwa",
    fieldName: FieldNames.NAME,
    minWidth: 300,
    maxWidth: 500,
    isResizable: true,
  },
  {
    key: "Street",
    name: "Adres",
    fieldName: FieldNames.ADDRESS,
    minWidth: 300,
    maxWidth: 500,
    isResizable: true,
  },
  {
    key: "DeactivationReason",
    name: "Powód deaktywacji",
    fieldName: 'deactivationReason',
    minWidth: 300,
    maxWidth: 500,
    isResizable: true,
  },
];