import * as yup from "yup";

export const vehicleValidation = yup.object().shape({
  
  brand: yup.string().required("podaj markę samochodu").max(50),
  model: yup.string().required("Podaj model samochodu"),
  registerNumber: yup.string()
    .required("Podaj numer rejestracyjny")
    .matches(/^([A-Z]{1,3}\s[0-9A-Z]{4,5})+$/, "Prawidłowa postać numeru rejestracyjnego: XX XXXXX, XXX XXXXX lub XXX XXXX")
    .matches(/^[A-Z]{1,3}.*/, "Pierwszą częścią muszę być litery")
    .max(9, "Maksymalna długość numeru rejestracyjnego wynosi 8 znaków"),
  driver: yup.string().required("podaj kierowcę"),
});