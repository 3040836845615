import React, { useState } from "react";
import { Formik, Form } from "formik";
import { containerS } from "../../../styles/containerStyles";
import FormBtns from "../../../../common/layout/form/NewFormBtns";
import FormDropDown from "../../../../common/layout/form/FormDropDown";
import ActivityIndicator from "../../../../common/layout/ActivityIndicator";
import MessageContainer from "../../../../auth/components/register/forms/MessageContainer";
import { useEndOrder } from "../../../hooks/useCompleteOrder";
import ReadOnlyDetails from "./ReadOnlyDetails";
import NewFormField from "../../../../common/layout/form/NewFormField";
import { useMatchedVehicles } from "../../../hooks/useMatchedVehicles";
import { completeOrderValidation } from "../../../actions/validationSchemas";
import { useOperators } from "../../../hooks/useOperators";
import { useOrderTypes } from "../../../hooks/useOrderTypes";
import Quantities from "./Qunatities";
import DateOfCompletion from "./DateOfCompletion";
import Prices from "./Prices";
import { divider, filedS, fieldContainer } from "../../../styles/realizationOrderForm.styles";
import { mergeStyles } from "@uifabric/merge-styles";
import { useOrderInternalStores } from "../../../hooks/useOrderInternalStores";

export interface RealizationOrderFormProps { }

const RealizationOrderForm: React.FC<RealizationOrderFormProps> = () => {
    const {
        initValues,
        handleSubmit,
        isLoading,
        cancelForm,
        orderDetails,
    } = useEndOrder();
    const { vehicles } = useMatchedVehicles([orderDetails.orderInfo == null ? null : orderDetails.orderInfo.id]);
    const [price, setPrice] = useState<any>("");
    const { operators } = useOperators();

    const orderTypeId = orderDetails ? orderDetails.orderTypeId : "";
    const orderId = orderDetails ? (orderDetails.orderInfo ? orderDetails.orderInfo.id : "") : "";
    const { orderTypes } = useOrderTypes(orderTypeId);
    const { internalStores, averageDistance } = useOrderInternalStores(orderId)

    var averageDistanceStr = internalStores.length > 0 ? `średnia odłegłość od magazynu: ${averageDistance}km` : "Nie możemy zidentyfikować adresu. Popraw adres punktu odbioru.";

    const container = mergeStyles({
        display: "flex",
        justifyContent: "space-between",
        marginTop: 15
    })

    return (
        <div className={containerS}>
            <ReadOnlyDetails />
            <Formik
                enableReinitialize={true}
                initialValues={initValues}
                onSubmit={handleSubmit}
                validateOnBlur={false}
                validateOnChange={false}
                validationSchema={completeOrderValidation}
            >
                {({ values }) => (
                    <Form>
                        <div className={divider}>
                            <FormDropDown
                                name="driver"
                                headerText="Kierowca/pojazd"
                                options={vehicles}
                            />
                        </div>
                        <div className={divider}>
                            <FormDropDown
                                name="operator"
                                headerText="Operator obsługujący"
                                options={operators}
                            />
                        </div>
                        <div className={container}>
                            <div className={filedS}>
                                <NewFormField name="tonnage" label="Tonaż" />
                            </div>
                            <div className={filedS}>
                                <NewFormField name="kpo" label="KPO" />
                            </div>
                        </div>
                        <DateOfCompletion name="receivingDate" values={values} />
                        <div className={divider}>
                            <FormDropDown
                                name="orderTypeItemId"
                                headerText="Typ"
                                options={orderTypes}
                                onChangeEffect={setPrice}
                            />
                        </div>
                        <div>
                            <FormDropDown
                                name="internalStoreId"
                                headerText="Magazyn Recykl"
                                options={internalStores}
                                additionalInfo={averageDistanceStr}
                            />
                        </div>
                        <Quantities />
                        {<div className={divider}>
                            <NewFormField
                                name="updatedPrice"
                                label="Nowa cena"
                                type="number"
                                placeholder="Wpisz nową cenę"
                                notRequired
                            />
                        </div>}
                        <Prices
                            price={values.updatedPrice !== undefined ? values.updatedPrice : (orderDetails.netPrice ? orderDetails.netPrice : "")}
                            quantity={values.quantityTaken}
                            orderDetails={orderDetails}
                            tonnage={values.tonnage}
                        />
                        <FormBtns
                            submitBtnText="Zakończ"
                            cancelForm={cancelForm}
                            disabled={isLoading}
                        />                       
                        {isLoading ? <ActivityIndicator /> : <MessageContainer />}
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default RealizationOrderForm;