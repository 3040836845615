import { useEffect, useState } from "react";
import { IContainerPoint } from "../interfaces/IContainerPoint";
import { IReceptionPlacePoint } from "../interfaces/IReceptionPlacePoint";
import { getReceptionPlaceCoordaintes, getContainerCoordinates, getInternalStoreItems, getReceptionPlaceLocalizations, getInternalStoreBorderPoints } from "../../../connectors/store/connectors";
import { PointTypeEnum } from "../enums/PointTypeEnum";
import moment from 'moment';
import { IInteralStorePoint } from "../interfaces/IInternalStorePoint";
import { MapTypeEnum } from "../enums/MapTypeEnum";
import { RootStateOrAny, useSelector } from "react-redux";
import { ContainerBatteryStatusEnum } from "../enums/ContainerBatteryStatusEnum";
import { IReceptionPlacePointLocalization } from "../interfaces/IReceptionPlacePointLocalization";
import { IInternalStoreBorderPoint } from "../interfaces/IInternalStoreBorderPoint";

export const useStoreMap = () => {
    const [receptionPlacePoints, setReceptionPlacePoints] = useState<IReceptionPlacePoint[]>([]);
    const [receptionPlacePointLocalizations, setReceptionPlaceLocalizationPoints] = useState<IReceptionPlacePointLocalization[]>([]);
    const [containerPoints, setContainerPoints] = useState<IContainerPoint[]>([]);
    const [containerPointsFromApi, setContainerPointsFromApi] = useState<IContainerPoint[]>([]);
    const [internalStorePoints, setInternalStorePoints] = useState<IInteralStorePoint[]>([]);
    const [receptionPlacePointsLoading, setReceptionPlacePointsLoading] = useState<boolean>(true);
    const [containerPointsLoading, setContainerPointsLoading] = useState<boolean>(true);
    const [internalStorePointsPointsLoading, setInternalStorePointsPointsLoading] = useState<boolean>(true);
    const [mapType, setMapType] = useState<string>(MapTypeEnum.road);
    const [internalStoreBorderPoints, setInternalStoreBorderPoints] = useState<IInternalStoreBorderPoint[]>([])

    const {
        showContainerPoints,
        showContainerCriticalBatteryPoints,
        showContainerLowBatteryPoints,
        selectedReceptionPlace,
        selectedInternalStore
    } = useSelector((state: RootStateOrAny) => state.storeMap);

    useEffect(() => {
        setReceptionPlaces();
    }, [])

    useEffect(() => {
        setMapContainers();
    }, [])

    useEffect(() => {
        setInternalStore();
    }, [])

    useEffect(() => {
        filterMapContainers()
    }, [showContainerCriticalBatteryPoints, showContainerLowBatteryPoints, showContainerPoints, containerPointsFromApi])

    useEffect(() => {
        if(selectedReceptionPlace){
            setReceptionPlacesLocalizations(selectedReceptionPlace)
        }
        else{
            setReceptionPlaceLocalizationPoints([]);
        }

    }, [selectedReceptionPlace])

    useEffect(() => {
        if(selectedInternalStore){
            setInternalStoreBorders(selectedInternalStore)
        }
        else{
            setInternalStoreBorderPoints([])
        }
    }, [selectedInternalStore])

    const setReceptionPlacesLocalizations = async (receptionPlace: IReceptionPlacePoint) => {
        var receptionPlaceId = Number(receptionPlace.id)
        const res = await getReceptionPlaceLocalizations(receptionPlaceId)
        var tempReceptionPlacePointLocalizations: IReceptionPlacePointLocalization[] =[];
        res.data.data.map((receptionPlacePointLocalization: IReceptionPlacePointLocalization) =>{
            tempReceptionPlacePointLocalizations.push({
                key: receptionPlacePointLocalization.id,
                id: receptionPlacePointLocalization.id,
                latitude: receptionPlacePointLocalization.latitude,
                longitude: receptionPlacePointLocalization.longitude,
                elevation: undefined,
                groupId: PointTypeEnum.RECEPTION_PLACE_LOCALIZATION
            });
        })
        setReceptionPlaceLocalizationPoints(tempReceptionPlacePointLocalizations)
    }

    const setReceptionPlaces = async () => {
        //Do testowania
        //setReceptionPlacePoints([]);
        //setReceptionPlacePointsLoading(false)
        //return; 
        const res = await getReceptionPlaceCoordaintes();
        var tempClientPoints: IReceptionPlacePoint[] = [];
        res.data.map((receptionPlacePoint: IReceptionPlacePoint) => {
            if (receptionPlacePoint.latitude != undefined && receptionPlacePoint.longitude != undefined) {
                receptionPlacePoint.distances.map((x: any) =>
                    x.distance = Math.round(x.distance / 1000))
                tempClientPoints.push(
                    {
                        key: "receptionPlacePoint_" + receptionPlacePoint.id,
                        id: receptionPlacePoint.id,
                        longitude: receptionPlacePoint.longitude,
                        latitude: receptionPlacePoint.latitude,
                        elevation: receptionPlacePoint.elevation,
                        bdoNumber: receptionPlacePoint.bdoNumber,
                        city: receptionPlacePoint.city,
                        postalCode: receptionPlacePoint.postalCode,
                        street: receptionPlacePoint.street,
                        companyName: receptionPlacePoint.companyName,
                        taxNumber: receptionPlacePoint.taxNumber,
                        groupId: PointTypeEnum.RECEPTION_PLACE,
                        distances: receptionPlacePoint.distances
                    }
                );
            }
        });
        setReceptionPlacePoints(tempClientPoints);
        setReceptionPlacePointsLoading(false)
    };

    const setMapContainers = async () => {
        const res = await getContainerCoordinates();
        var tempContainerPoints: IContainerPoint[] = [];
        res.data.list.map((containerPoint: IContainerPoint) => {
            if (containerPoint.latitude != undefined && containerPoint.latitude != undefined) {
                tempContainerPoints.push(
                    {
                        key: "containerPoint_" + containerPoint.id,
                        id: containerPoint.id,
                        longitude: containerPoint.longitude,
                        latitude: containerPoint.latitude,
                        elevation: containerPoint.elevation,
                        lastActiveDate: moment(containerPoint.lastActiveDate).format('DD-MM-YYYY HH:mm:ss'),
                        imei: containerPoint.imei,
                        iccid: containerPoint.iccid,
                        serNo: containerPoint.serNo,
                        containerNumber: containerPoint.containerNumber,
                        groupId: PointTypeEnum.CONTAINER,
                        currentBatteryLevel: containerPoint.currentBatteryLevel,
                        currentBatteryStatus: containerPoint.currentBatteryStatus,
                        //identifier: containerPoint.identifier
                    });
            }
        });     
        setContainerPointsFromApi(tempContainerPoints);
        setContainerPointsLoading(false);
    };

    const filterMapContainers = async () => {
        var tempContainerCriticalBatteryPoints: IContainerPoint[] = []
        var tempContainerLowBatteryPoints: IContainerPoint[] = []
        var tempContainerRestBatteryPoints: IContainerPoint[] = []

        if (showContainerPoints) {
            tempContainerRestBatteryPoints = containerPointsFromApi.filter((item: IContainerPoint) =>
                (item.currentBatteryStatus == ContainerBatteryStatusEnum.NoInfo || item.currentBatteryStatus === ContainerBatteryStatusEnum.Normal))
        }

        if (showContainerCriticalBatteryPoints) {
            tempContainerCriticalBatteryPoints = containerPointsFromApi.filter((item: IContainerPoint) => item.currentBatteryStatus === ContainerBatteryStatusEnum.Critical)
        }

        if (showContainerLowBatteryPoints) {
            tempContainerLowBatteryPoints = containerPointsFromApi.filter((item: IContainerPoint) => item.currentBatteryStatus === ContainerBatteryStatusEnum.Low)
        }

        var tempFilteredContainerPoints = [
            ...tempContainerRestBatteryPoints,
            ...tempContainerCriticalBatteryPoints,
            ...tempContainerLowBatteryPoints];

        setContainerPoints(tempFilteredContainerPoints);
    }

    const setInternalStore = async () => {
        var res = await getInternalStoreItems();
        var temp: IInteralStorePoint[] = [];
        res.data.data.map((x: IInteralStorePoint) => {
            temp.push({
                key: "internalPoint_" + x.id,
                id: x.id,
                name: x.name,
                longitude: x.longitude,
                latitude: x.latitude,
                groupId: PointTypeEnum.INTERNAL_STORE
            })
        });
        setInternalStorePoints(temp);
        setInternalStorePointsPointsLoading(false);
    }

    const setInternalStoreBorders = async (internalStore: IInteralStorePoint) => {
        var res = await getInternalStoreBorderPoints(internalStore.id.toString());
        var temp: IInternalStoreBorderPoint[] = []
        res.data.data.map((x:IInternalStoreBorderPoint) => {
            temp.push(
                {
                    key: "internalStoreBorderPoint_" + x.id,
                    id: x.id,
                    latitude: x.latitude, 
                    longitude: x.longitude,
                    groupId: PointTypeEnum.INTERNAL_STORE_BORDER_POINT
                }
            )
        });
        setInternalStoreBorderPoints(temp);
    }

    return {
        receptionPlacePoints,
        receptionPlacePointLocalizations,
        containerPoints,
        internalStorePoints,
        internalStoreBorderPoints,
        containerPointsLoading,
        receptionPlacePointsLoading,
        internalStorePointsPointsLoading,
        mapType,
        setMapType
    }
}