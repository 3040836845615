import React, { useEffect, useState, useCallback } from "react";
import {
    ComboBox,
    IComboBox,
    IComboBoxOption,
} from "office-ui-fabric-react/lib/ComboBox";
import _ from "lodash"

import { Spinner, SpinnerSize } from "@fluentui/react";
import { useEffectAfterMount } from "../../common/hooks/useEffectAfterMount";
import { getVehicles, getVehiclesListbox } from "../../../connectors/vehicles/connectors";
import { optionsS } from "../../orders/styles/clientsDropDown.styles";

interface VehiclesDropDownProps {
    value: string;
    label: string,
    name: string,
    setFieldValue?: any;
    resetForm?: any;
    onChange?: any
    required?: boolean
}

const VehiclesDropDown: React.FC<VehiclesDropDownProps> = ({
    setFieldValue,
    onChange,
    label,
    name,
    required
}) => {
    const [vehicles, setVehicles] = useState<any[]>([]);
    const [inputValue, setInputValue] = useState("");
    const [asyncActionInProgress, setAsyncActionInProgress] = useState<boolean>(false);

    const debouncedVehiclesFetch = useCallback(_.debounce((inputValue: string) => {
        fetchVehicles(inputValue);
    }, 350),
        []
    );

    useEffect(() => {
        fetchVehicles();
    }, []);

    useEffectAfterMount(() => {
        debouncedVehiclesFetch(inputValue);
    }, [inputValue]);

    const handleChange = useCallback(
        (option?: IComboBoxOption, index?: number, value?: string): void => {
            if (value !== undefined) {
                setInputValue(value);
            }
        },
        []
    );

    const fetchVehicles = async (currentInputValue?: string) => {
        setAsyncActionInProgress(true);

        const params = { Search: !!currentInputValue ? currentInputValue : inputValue };

        const res = await getVehiclesListbox(params);
        const data: any[] = []
        for (var key in res.data.data) {
            data.push(
                {
                    key: key,
                    text: res.data.data[key]
                }
            )
        }

        setVehicles(data);
        setAsyncActionInProgress(false);
    }

    const setOption = (
        event: React.FormEvent<IComboBox>,
        option?: IComboBoxOption,
        index?: number,
        value?: string
    ): void => {
        if (option) {
            setFieldValue(name, option.key);
            if (onChange) {
                onChange(option)
            }
        }
    };

    return <div style={{ position: 'relative' }}>
        {asyncActionInProgress &&
            <Spinner size={SpinnerSize.small} style={{ position: 'absolute', top: 7, left: 60 }} />
        }
        <ComboBox
            required={required ? required : false}
            label={label}
            //selectedKey={value}
            autoComplete={"on"}
            allowFreeform
            onPendingValueChanged={handleChange}
            onChange={setOption}
            options={vehicles}
            dropdownWidth={550}
        //styles={optionsS}
        />
    </div>
};

export default VehiclesDropDown;