import * as React from "react";
import { AzureMapFeature } from "react-azure-maps";

export interface PointListProps {
    points: any[];
    receptionPlace: any
}

export const ReceptionPlaceBoundary: React.FC<PointListProps> = ({ receptionPlace, points }) => {

    if (receptionPlace == undefined || points == undefined || points.length == 0)
        return <></>

    let maxLatitude = receptionPlace.latitude
    let minLatitude = receptionPlace.latitude
    let maxLongitude = receptionPlace.longitude
    let minLongitude = receptionPlace.longitude

    points.forEach(location => {
        // Check and update maximum and minimum latitude values
        if (location.latitude > maxLatitude) {
            maxLatitude = location.latitude;
        }
        if (location.latitude < minLatitude) {
            minLatitude = location.latitude;
        }

        // Check and update maximum and minimum longitude values
        if (location.longitude > maxLongitude) {
            maxLongitude = location.longitude;
        }
        if (location.longitude < minLongitude) {
            minLongitude = location.longitude;
        }
    });

    maxLongitude += 0.0001
    minLongitude -= 0.0001
    maxLatitude += 0.0004
    minLatitude -= 0.0004

    const polygonCoordinates = [
        [maxLongitude, minLatitude],
        [maxLongitude, maxLatitude],
        [minLongitude, maxLatitude],
        [minLongitude, minLatitude],
        [maxLongitude, minLatitude], //taki jak pierwszy zeby zamknąc prostokąt
    ];

    return (
        <AzureMapFeature
            key={"ReceptionPlaceBoundaryKey"}
            id={"ReceptionPlaceBoundaryId"}
            type="Polygon"
            variant={'shape'}
            coordinates={polygonCoordinates}
        />
    )
}