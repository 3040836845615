export const acceptedOrdersColumns = [
  {
    key: "AcceptedDate",
    name: "Numer zamówienia",
    fieldName: "number",
    minWidth: 100,
    maxWidth: 150,
    isResizable: true,
  },
  {
    key: "CreatedAt",
    name: "Data zamówienia",
    fieldName: "createdAt",
    minWidth: 100,
    maxWidth: 120,
    isResizable: true,
  },
  {
    key: "ReceivingDate",
    name: "Data przyjęcia",
    fieldName: "acceptedDate",
    minWidth: 100,
    maxWidth: 120,
    isResizable: true,
  },
  {
    key: "Company",
    name: "Klient",
    fieldName: "company",
    minWidth: 150,
    maxWidth: 250,
    isResizable: true,
  },
  {
    key: "ReceptionPlace",
    name: "Punkt Odbioru",
    fieldName: "receptionPlace",
    minWidth: 100,
    maxWidth: 350,
    isResizable: true,
  },
  {
    key: "Quantity",
    name: "Ilość",
    fieldName: "quantity",
    minWidth: 20,
    maxWidth: 30,
    isResizable: true,
  },
  {
    key: "Type",
    name: "Typ",
    fieldName: "type",
    minWidth: 50,
    maxWidth: 100,
    isResizable: true,
  },
  {
    key: "OrderTypeItemName",
    name: "Rodzaj",
    fieldName: "orderTypeItem",
    minWidth: 50,
    maxWidth: 100,
    isResizable: true,
  },
  {
    key: "ContactPhone",
    name: "Numer kontaktowy",
    fieldName: "contactPhone",
    minWidth: 50,
    maxWidth: 100,
    isResizable: true,
  },
];