export const usersColumns = [
    {
      key: "column1",
      name: "Email",
      fieldName: "email",
      minWidth: 50,
      maxWidth: 100,
      isResizable: true,
    },
    {
      key: "column2",
      name: "Imię",
      fieldName: "firstName",
      minWidth: 50,
      maxWidth: 100,
      isResizable: true,
    },
    {
      key: "column3",
      name: "Nazwisko",
      fieldName: "lastName",
      minWidth: 50,
      maxWidth: 100,
      isResizable: true,
    },
    {
      key: "column4",
      name: "Telefon",
      fieldName: "phone",
      minWidth: 50,
      maxWidth: 100,
      isResizable: true,
    },
    {
      key: "column5",
      name: "Oddział",
      fieldName: "departmentName",
      minWidth: 50,
      maxWidth: 100,
      isResizable: true,
    },
  ];

  export const usersColumnsShort = [
    {
      key: "column1",
      name: "Email",
      fieldName: "email",
      minWidth: 50,
      maxWidth: 100,
      isResizable: true,
    },
    {
      key: "column2",
      name: "Imię",
      fieldName: "firstName",
      minWidth: 50,
      maxWidth: 100,
      isResizable: true,
    },
    {
      key: "column3",
      name: "Nazwisko",
      fieldName: "lastName",
      minWidth: 50,
      maxWidth: 100,
      isResizable: true,
    },
    {
      key: "column4",
      name: "Telefon",
      fieldName: "phone",
      minWidth: 50,
      maxWidth: 100,
      isResizable: true,
    },
  ];
  
  export const generateUsers = (users: any) => {
    const items: Array<any> = [];
  
    users.forEach((user: any) => {
      items.push({
        key: user.id,
        id: user.id,
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        phone: user.phone,
        isActive: user.isActivated,
        department: user.department,
        departmentName: user.departmentName,
        isClientAdmin: user.isClientAdmin,
        isInvitationInProgress: user.isInvitationInProgress
      });
    });
  
    return [...items];
  };