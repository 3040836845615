import React from "react";
import {useSelector, RootStateOrAny, useDispatch} from "react-redux"
import {
  ChoiceGroup,
  IChoiceGroupOption,
  IChoiceGroupStyles,
} from "office-ui-fabric-react";
import { clearAlerts } from "../../../../../config/redux/alerts/alertsActions";

const choiceGroupS: IChoiceGroupStyles = {
  root: {
    marginBottom: 25,
  },
};

const options: IChoiceGroupOption[] = [
  { key: "USER", text: "Zarejestruj użytkownika do istniejącego oddziału" },
  { key: "DEPARTMENT", text: "Zarejestruj oddział oraz użytkownika" },
];

interface RegistrationChoiceGroupProps {
  setActiveForm: (option?: string) => void;
}

const RegistrationChoiceGroup: React.FC<RegistrationChoiceGroupProps> = ({
  setActiveForm,
}) => {
  const dispatch = useDispatch()
  const { error } = useSelector(
    (state: RootStateOrAny) => state.register
  );

  const setOption = (
    ev?: React.FormEvent<any>,
    option?: IChoiceGroupOption
  ): void => {
    if(error){
      dispatch(clearAlerts())
    }

    //elvis dosent work for some reason
    if(option){
      setActiveForm(option.key);
    }
  };

  return (
    <ChoiceGroup
      options={options}
      styles={choiceGroupS}
      onChange={setOption}
      label={""}
      required={true}
    />
  );
};

export default RegistrationChoiceGroup;