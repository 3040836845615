import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { checkQuantity } from "../../commandBar/actions/checkQuantity";
import { Quantity } from "./../../../features/common/models/quantity.model";
import { setFormType, toggleContainersPanel, toggleContainersModal } from "../../../config/redux/container/containersActions";

export const useContainerCommandBar = () => {
    const dispatch = useDispatch();
    const { selectedContainers } = useSelector((state: RootStateOrAny) => state.containers);
    const quantity = checkQuantity(selectedContainers.length);

    const seeContainer = () => {
        dispatch(setFormType({ 
            isEdit: false,
            isShow: true,
            isRfidHistory: false
        }))
        dispatch(toggleContainersPanel({ isOpen: true }));
    };

    const editContainer = () => {
        dispatch(setFormType({ 
            isEdit: true,
            isShow: false,
            isRfidHistory: false
         }))
        dispatch(toggleContainersPanel({ isOpen: true }))
    }

    const seeContainerRfidHistory = () => {
        dispatch(setFormType({ 
            isEdit: false,
            isShow: false,
            isRfidHistory: true
         }))
        dispatch(toggleContainersPanel({ isOpen: true }))
    }

    const seeMap = () => {
        dispatch(toggleContainersModal({
            isOpen: true,
            isMap: true,
            isFotos: false
        }))
    }

    const seePhotos = () => {
        dispatch(toggleContainersModal({
            isOpen: true,
            isMap: false,
            isFotos: true
        }))
    }

    const generateItems = () => {
        switch (quantity) {
            case Quantity.ONE:
                return [
                    {
                        key: "See",
                        text: "Zobacz",
                        iconProps: { iconName: "reportdocument" },
                        name: "prepare",
                        onClick: seeContainer,
                    },
                    {
                        key: "Edit",
                        text: "Edytuj",
                        iconProps: { iconName: "edit" },
                        name: "edit",
                        onClick: editContainer,
                    },
                    {
                        key: "SeeMap",
                        text: "Zobacz na mapie",
                        iconProps: { iconName: "MapPin" },
                        name: "seeMap",
                        onClick: seeMap,
                    },
                    {
                        key: "SeePhotos",
                        text: "Zobacz zdjęcia",
                        iconProps: { iconName: "PhotoCollection" },
                        name: "seePhotos",
                        onClick: seePhotos,
                        disabled: selectedContainers[0].hasPhotos == false                    
                    },
                    {
                        key: "RfidHistory",
                        text: "Magazyny wewnętrzne",
                        iconProps: { iconName: "Home" },
                        name: "RfidHistory",
                        onClick: seeContainerRfidHistory
                    }

                ];
            default:
                return []
        }
    };

    const items = generateItems()

    return {
        items
    }
};