import React from 'react'
import { useCorrectOrderForm } from '../../../hooks/useCorrectOrderForm'
import OrderDetailTxt from '../edditOrder/OrderDetailTxt';
import { Label, Spinner, SpinnerSize, Stack, TextField } from 'office-ui-fabric-react';
import { Form, Formik } from 'formik';
import NewFormField from '../../../../common/layout/form/NewFormField';
import NewFormBtns from '../../../../common/layout/form/NewFormBtns';
import { completeCorrectOrderValidation } from "../../../actions/validationSchemas";
import FormDatePicker from '../../../../common/layout/form/FormDatePicker';

const CorrectOrderForm: React.FC = () => {

    const { orderDetails, initValues, handleSubmit, cancelForm, isLoading } = useCorrectOrderForm();

    return (
        <>
            {initValues.orderId != null ?
                <Formik
                    initialValues={initValues}
                    onSubmit={handleSubmit}
                    validateOnChange={true}
                    validateOnBlur={false}
                    validationSchema={completeCorrectOrderValidation}
                //validationSchema={newOrderValidationShort}
                >
                    {({ handleSubmit, setFieldValue, resetForm, values }) => (
                        <Form translate="yes" onSubmit={handleSubmit}>
                            <Stack>
                                {orderDetails ?
                                    <div>
                                        <OrderDetailTxt
                                            title="Numer zamówienia"
                                            value={orderDetails.orderInfo.number}
                                        />
                                    </div> :
                                    <></>}
                                <NewFormField name="tonnage" label="Tonaż" required={false} />
                                <Label required={true}>
                                    Data zakończenia
                                </Label>
                                <FormDatePicker placeholder={initValues.receivingDate!} name="receivingDate" values={values} required={false} />
                            </Stack>
                            <NewFormBtns
                                positionAbsolute={true}
                                submitBtnText="Popraw"
                                cancelForm={cancelForm}
                                disabled={isLoading}
                            />
                        </Form>
                    )}
                </Formik>
                : <Spinner size={SpinnerSize.large} />
            }
        </>
    )
}

export default CorrectOrderForm