import React, { useEffect, useState } from "react";
import { getOrderTypeItems } from "../../../../connectors/orderTypes/connectors";
import MessageContainer from "../../../auth/components/register/forms/MessageContainer";
import ActivityIndicator from "../../../common/layout/ActivityIndicator";
import { useAddPrice } from "../../hooks/useAddPrice";

import PriceFormShort from "./PriceFormShort";
import PriceForm from "./PriceFrom";

const AddPrice: React.FC = () => {
  const [items, setItems] = useState([]);
  const { cancelForm, addPrice, activePriceType, loading, selectedPrice, prcingPanelOpen } = useAddPrice();

  useEffect(() => {
    if(activePriceType && prcingPanelOpen){
      fetch();
    }
  }, []);

  const fetch = async () => {
    const res = await getOrderTypeItems(activePriceType.id);
    setItems(
      res.data.data.map((item: any) => ({
        key: item.id,
        text: item.name,
        data: item,
      }))
    );
  };

  return (
    <>
      {activePriceType.name === "Pojazd" ? (
        <PriceForm
          handleSubmit={addPrice}
          orderTypesItems={items}
          initValues={{}}
          cancelForm={cancelForm}
        />
      ) : (
        <PriceFormShort
          handleSubmit={addPrice}
          orderTypesItems={items}
          initValues={{}}
          cancelForm={cancelForm}
        />
      )}
      {loading ? <ActivityIndicator /> : <MessageContainer />}
    </>
  );
};

export default AddPrice;
