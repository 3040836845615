import * as React from 'react';
import { IPanelStyles, Panel } from 'office-ui-fabric-react/lib/Panel';
import { PanelS } from '../../../auth/styles/panel.styles';
import { concatStyleSets } from 'office-ui-fabric-react';
import NotificationsList from '../../NotificationsList';
import NotificationItem from '../NotificationItem';

interface INotificationPanelProps {
    isOpen: boolean;
    dismissPanel: () => void;
    type: boolean;
}

const paneMarignS: Partial<IPanelStyles> = {
    root: {
        marginTop: "50px",
    }
};

const styles = concatStyleSets(paneMarignS, PanelS);

export const NotificationPanel: React.FunctionComponent<INotificationPanelProps> = ({
    isOpen,
    dismissPanel,
    type
}) => {
    return (
        <Panel
            headerText={type ? "Powiadomienia" : "Ustawienia"}
            isBlocking={false}
            isOpen={isOpen}
            onDismiss={dismissPanel}
            closeButtonAriaLabel="Zamknij"
            styles={styles}
        >
            {type ?<> <NotificationsList dismissPanel={dismissPanel}/></> : <p></p>}
            {/* {type ? <NotificationItem /> : null} */}
        </Panel>
    );
};
