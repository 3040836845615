import { FormikValues } from 'formik';

export const transformData = (values: FormikValues) => {
    const department = {
      city: values.city,
      street: values.street,
      postalCode: values.postalCode,
    };
  
    const user = {
      email: values.email,
      firstName: values.firstName,
      lastName: values.lastName,
      phone: values.phoneNumber,
      // password: values.pass,
      // passwordConfirm: values.pass2
    };
  
    return {
      department,
      user,
    };
  };

