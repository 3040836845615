import React, { useState } from "react";
import { Formik, Form, FormikValues, FormikHelpers } from "formik";
import { Stack } from "office-ui-fabric-react";
import { useCancelForm } from "../../../hooks/useCancelForm";
import FormBtns from "../../../../common/layout/form/NewFormBtns";
import { usePickUpPointForm } from "../../../hooks/usePickUpPointForm";
import {
  textFieldsContainer,
  container,
  containerS,
} from "../../../styles/pickUpPointsForm.styles";
import { margin, btnContainer } from "../../../styles/panelForm.styles";
import ActivityIndicator from "../../../../common/layout/ActivityIndicator";
import NewFormCheckbox from "../../../../common/layout/form/NewFormCheckbox";
import ErrorMessage from "../../../../common/layout/form/ErrorMessage";
import FormDropDown from "../../../../common/layout/form/FormDropDown";
import { validationSchemaReceptionPlace, validationSchemaNonPolishReceptionPlace } from "../../../actions/validationSchemas";
import FormHeader from "../FormHeader";
import NewEdditionField from "../../../../common/layout/form/NewEdditionField";
import EditableListForm from "./EditableListForm";

export const fieldNames = [
  { name: "city", label: "Miejscowość" },
  { name: "street", label: "Ulica i numer" },
  { name: "postalCode", label: "Kod pocztowy" },
  { name: "phone", label: "Telefon" },
  { name: "bdoNumber", label: "BDO" },
];

const ReceptionPlacesForm: React.FC = () => {
  const {
    add,
    edit,
    initValues,
    selectedIds,
    isLoading,
    activeClient,
    departments,
    orderTypes,
    addSpecialPrices,
    editSpecialPrices
  } = usePickUpPointForm();
  const cancel = useCancelForm();
  const [specialPrices, setSpecialPrices] = useState<any[]>([])

  const formatSpecialPrice = (price: any) => {
    const formattedDateFrom = new Date(price.dateFrom).toISOString().slice(0, 19);
    const formattedDateTo = new Date(price.dateTo).toISOString().slice(0, 19);

    return {
      id: price.id,
      value: price.value,
      dateFrom: formattedDateFrom,
      dateTo: formattedDateTo,
      orderTypeItemId: price.orderTypeItemId
    };
  };

  const handleSubmitEdit = async (values: FormikValues, formikHelpers: FormikHelpers<FormikValues>) => {
    const { resetForm } = formikHelpers;
    const formattedSpecialPrices = specialPrices.map(formatSpecialPrice);
    const serializedSpecialPrices = JSON.stringify({
      specialPrices: formattedSpecialPrices
    });
    await editSpecialPrices(values, serializedSpecialPrices, { resetForm });
  };

  const handleSubmitAdd = async (values: FormikValues, formikHelpers: FormikHelpers<FormikValues>) => {
    const { resetForm } = formikHelpers;
    const formattedSpecialPrices = specialPrices.map(formatSpecialPrice);
    const serializedSpecialPrices = JSON.stringify({
      specialPrices: formattedSpecialPrices
    });
    await addSpecialPrices(values, serializedSpecialPrices, { resetForm });
  };


  return (
    <Formik
      initialValues={initValues}
      enableReinitialize={true}
      onSubmit={selectedIds[0] ? handleSubmitEdit : handleSubmitAdd}
      //validationSchema={sessionStorage.getItem("role") !== "ADMIN" ? validationSchemaReceptionPlace : PickUpPointSchemaAdmin}
      validationSchema={validationSchemaNonPolishReceptionPlace} //jak narazie tak bo nie znam kraju tutaj - do zmiany kiedys
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({ handleSubmit, values }) => (
        <>
          <Form translate="yes" onSubmit={handleSubmit}>
            <Stack styles={containerS} verticalAlign="space-between" verticalFill>
              <FormHeader text={selectedIds[0] ? "Edytuj punkt odbioru" : "Dodaj punkt odbioru"} />
              {activeClient.basicInformations.multiDepartment && !selectedIds[0] && (
                <FormDropDown
                  options={departments}
                  name="department"
                  headerText="Wybierz oddział"
                />
              )}
              <div className={textFieldsContainer}>
                {fieldNames.map(({ name, label }) => (
                  <NewEdditionField key={name} name={name} label={label} />
                ))}
                <FormDropDown
                  headerText="Typ klienta"
                  name="orderTypeId"
                  options={orderTypes}
                />
              </div>
              <Stack.Item>
                <div className={container}>
                  <NewFormCheckbox name="default" label="Domyślny punkt odbioru" />
                </div>
              </Stack.Item>
            </Stack>
            <Stack styles={containerS} verticalAlign="space-between" verticalFill>
              <FormHeader text={"Dodawanie ceny"} />
              <EditableListForm
                setSpecialPrices={setSpecialPrices}
                specialPrices={specialPrices}
                receptionPlaceId={selectedIds && selectedIds[0] ? selectedIds[0].id : null}
                orderTypeId={selectedIds && selectedIds[0] ? selectedIds[0].orderTypeId : null}
              />
            </Stack>
            <div>
              <div className={btnContainer} style={{ zIndex: 999 }}>
                <FormBtns
                  cancelForm={cancel}
                  submitBtnText={selectedIds[0] ? "Zapisz" : "Dodaj"}
                  disabled={isLoading}
                  positionAbsolute={true}
                />
              </div>
            </div>
          </Form>
          <div className={margin}>
            {isLoading ? <ActivityIndicator /> : <ErrorMessage />}
          </div>
        </>
      )}
    </Formik>
  );
};

export default ReceptionPlacesForm;