import { IDriversAction } from "./driversActions.types";
import { DriversActions } from "./driversActions.enum";

const initState = {
  selectedDrivers: [],
  activeDriver: {},
  driverPanelOpen: false,
  SMSHistoryPanelOpen: false,
  reloadDriversList: false,
  addDriver: false,
};

export const driversReducer = (state = initState, action: IDriversAction) => {
  const { type, payload } = action;

  switch (type) {
    case DriversActions.SET_SELECTED_DRIVERS:
      return { ...state, selectedDrivers: payload };
    case DriversActions.TOGGLE_DRIVERS_PANEL:
      return { ...state, driverPanelOpen: payload.open };
    case DriversActions.TOGGLE_SMS_HISTORY_PANEL:
      return { ...state, SMSHistoryPanelOpen: payload.open };
    case DriversActions.RELOAD_DRIVERS:
      return { ...state, reloadDriversList: payload.reload };
    case DriversActions.SET_DRIVER:
      return { ...state, activeDriver: payload };
    case DriversActions.ADD_DRIVER:
      return { ...state, addDriver: payload.add };
    case DriversActions.CLEAR:
      return { ...initState };
    default:
      return state;
  }
};
