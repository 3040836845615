import { Form, Formik } from "formik";
import { IDropdownOption, Label, mergeStyles, Stack } from "office-ui-fabric-react";
import * as React from "react";
import { useTranslation } from "react-i18next";
import MessageContainer from "../../../features/auth/components/register/forms/MessageContainer";
import ActivityIndicator from "../../../features/common/layout/ActivityIndicator";
import FormDatePicker from "../../../features/common/layout/form/FormDatePicker";
import FormDropDown from "../../../features/common/layout/form/FormDropDown";
import NewFormBtns from "../../../features/common/layout/form/NewFormBtns";
import { orderReportClientValidationSchema } from "../../actions/orderReportClientValidationSchema";
import { useOrdersReportForm } from "../../hooks/orders/useOrdersReportForm";
import { IOrdersOrderReportOrderByOptionsLang, IOrdersOrderReportSortOptionsLang, IOrdersOrderStatusLang, IOrdersReportPanel as IOrdersReportPanelLang, IOrdersReportValidationSchemaLang } from "../../../localization/abstract/client/orders";

const margin = mergeStyles({
  marginTop: 15,
});

export interface OrderReportFormProps { }

const OrderReportClientForm: React.FC<OrderReportFormProps> = () => {
  const {
    handleSubmit,
    cancelForm,
    isLoading,
  } = useOrdersReportForm();

  const { t } = useTranslation("orders");
  const orderStatusNames: IOrdersOrderStatusLang = t("orderStatus", {
    returnObjects: true,
  });

  const orderReportSortOptionsNames: IOrdersOrderReportSortOptionsLang = t("orderReportSortOptions", {
    returnObjects: true,
  });

  const orderReportOrderByOptionsNames: IOrdersOrderReportOrderByOptionsLang = t("orderReportOrderByOptions", {
    returnObjects: true,
  });

  const reportPanelNames: IOrdersReportPanelLang = t("reportPanel", {
    returnObjects: true,
  });

  const validationSchemaNames: IOrdersReportValidationSchemaLang = t("reportValidationSchema", {
    returnObjects: true,
  });

  const options: IDropdownOption[] = [
    { key: 0, text: orderStatusNames.placed },
    { key: 1, text: orderStatusNames.accepted },
    { key: 2, text: orderStatusNames.inRealization },
    { key: 3, text: orderStatusNames.realized },
    { key: 4, text: orderStatusNames.canceled },
    { key: 5, text: orderStatusNames.invoiced },
  ];
  const optionsSort: IDropdownOption[] = [
    { key: 'true', text: orderReportSortOptionsNames.increase },
    { key: 'false', text: orderReportSortOptionsNames.decrease },
  ];
  const optionsOrderBy: IDropdownOption[] = [
    { key: 'Number', text: orderReportOrderByOptionsNames.orderNumber },
    { key: 'Created', text: orderReportOrderByOptionsNames.orderDate },
    { key: 'ReceptionPlace', text: orderReportOrderByOptionsNames.receptionPlace },
    { key: 'CurrentStatus', text: orderReportOrderByOptionsNames.orderStatus },
  ];

  return (
    <Formik
      initialValues={{}}
      enableReinitialize={true}
      onSubmit={handleSubmit}
      validationSchema={orderReportClientValidationSchema(validationSchemaNames)}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({ handleSubmit, values }) => (
        <Form translate="yes" onSubmit={handleSubmit}>
          <Stack verticalAlign="space-between" verticalFill>
            <Label required>{reportPanelNames.orderDate}</Label>
            <Stack horizontal>
              <FormDatePicker placeholder={reportPanelNames.createdFrom} name="createdFrom" values={values} required />
              <div style={{ padding: '2px', lineHeight: 2 }}>&#8211;</div>
              <FormDatePicker placeholder={reportPanelNames.createdTo} name="createdTo" values={values} required />
            </Stack>
            <div >
              <FormDropDown required options={options} name="currentStatus" headerText={reportPanelNames.orderStatus} placeholder={reportPanelNames.orderStatusPlaceholder} />
            </div>
            <div >
              <FormDropDown required options={optionsOrderBy} name="orderBy" headerText={reportPanelNames.sortBy} placeholder={reportPanelNames.sortByPlaceholder} />
            </div>
            <div >
              <FormDropDown required options={optionsSort} name="desc" headerText={reportPanelNames.sort} placeholder={reportPanelNames.sortPlaceholder} />
            </div>
            <div className={margin}>
              <NewFormBtns
                submitBtnText={reportPanelNames.download}
                cancelBtnText={reportPanelNames.cancel}
                cancelForm={cancelForm}
                disabled={isLoading}
                positionAbsolute={true}
              />
            </div>
            <div className={margin}>
              {isLoading ? <ActivityIndicator /> : <MessageContainer />}
            </div>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

export default OrderReportClientForm;