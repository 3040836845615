import React from 'react';
import ReceptionPlacesListContainer from '../components/lists/receptionPlaces/ReceptionPlacesListContainer';
import ReceptionPlacePanel from '../components/panels/ReceptionPlacesPanel';
import ListSuccessMessage from "../../features/common/layout/ListSuccessMessage";
import { mergeStyles } from "office-ui-fabric-react";

export interface ReceptionPlacesProps {

}
const padding = mergeStyles({
    paddingTop: 15
})

const wrapper = mergeStyles({
    width: "95%",
    margin: "0px auto",
});

const ClientReceptionPlaces: React.FC<ReceptionPlacesProps> = () => {
    return (
        <div className={wrapper}>
            <div className={padding}>
                <ListSuccessMessage />
            </div>
            <ReceptionPlacesListContainer />
            <ReceptionPlacePanel />
        </div>
    );
}

export default ClientReceptionPlaces;