import React from "react";
import ReactDOM from "react-dom";
import { App } from "./App";
import { FluentCustomizations } from "@uifabric/fluent-theme";
import {
  Customizer,
  mergeStyles,
  getTheme,
} from "office-ui-fabric-react";
import { initializeIcons } from 'office-ui-fabric-react/lib/Icons';
import authentication from 'react-azure-b2c';
import "./localization/i18n.tsx";

const theme = getTheme();

// global styles
mergeStyles({
  selectors: {
    ":global(body), :global(html), :global(#root)": {
      margin: 0,
      padding: 0,
      fontFamily: "Segoe UI, sans-serif",
    },
    ":global(*) :global(*::after) :global(*::before)": {
      boxSizing: "border-box",
    },
    ":global(body)": {
      color: theme.palette.neutralDark,
    },
  },
});

const appTheme =  {
  themePrimary: '#10893e',
  themeLighterAlt: '#f1faf5',
  themeLighter: '#cbecd8',
  themeLight: '#a2dcb8',
  themeTertiary: '#57b97d',
  themeSecondary: '#22984f',
  themeDarkAlt: '#0f7c39',
  themeDark: '#0d6930',
  themeDarker: '#094d23',
  neutralLighterAlt: '#faf9f8',
  neutralLighter: '#f3f2f1',
  neutralLight: '#edebe9',
  neutralQuaternaryAlt: '#e1dfdd',
  neutralQuaternary: '#d0d0d0',
  neutralTertiaryAlt: '#c8c6c4',
  neutralTertiary: '#a19f9d',
  neutralSecondary: '#605e5c',
  neutralPrimaryAlt: '#3b3a39',
  neutralPrimary: '#323130',
  neutralDark: '#201f1e',
  black: '#000000',
  white: '#ffffff',
};

// if(false) { TODO
//   let merged = {...FluentCustomizations.settings.theme , ...appTheme};
//   FluentCustomizations.settings.theme.palette = merged;
// } 

const { REACT_APP_INSTANCE, REACT_APP_TENANT, REACT_APP_SIGN_IN_POLICY_INTERNAL, REACT_APP_SIGN_IN_POLICY_CLIENTS, REACT_APP_CLIENT_ID, REACT_APP_CACHE_LOCATION, REACT_APP_SCOPES, REACT_APP_REDIRECT_URL_ADMIN, REACT_APP_REDIRECT_URL_CLIENT } = process.env;

let isInternal = false;
if (process.env.NODE_ENV === 'development') {
  isInternal = window.location.port === '3000';
} else {
  isInternal = window.location.hostname.includes('admin');
}

authentication.initialize({
  instance: REACT_APP_INSTANCE, 
  tenant: REACT_APP_TENANT,
  signInPolicy: isInternal ?  REACT_APP_SIGN_IN_POLICY_INTERNAL : REACT_APP_SIGN_IN_POLICY_CLIENTS,
  clientId: REACT_APP_CLIENT_ID,
  cacheLocation: REACT_APP_CACHE_LOCATION,
  scopes: [REACT_APP_SCOPES],
  redirectUri: isInternal ? REACT_APP_REDIRECT_URL_ADMIN : REACT_APP_REDIRECT_URL_CLIENT,
  postLogoutRedirectUri: isInternal ? 'https://login.microsoftonline.com/common/oauth2/logout' : window.location.origin,
});

sessionStorage.removeItem('role');
sessionStorage.removeItem('isActivated');
sessionStorage.removeItem('hasAllRequiredData');

initializeIcons('https://static2.sharepointonline.com/files/fabric/assets/icons/');
authentication.run(() => {
  ReactDOM.render(
    <Customizer {...FluentCustomizations}>
      <App />    
    </Customizer>,
    document.getElementById("root")
  );
});