import { useEffect, useState } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { getContainerHistory } from "../../../connectors/containers/connectors";
import moment from "moment";
import { IContainerHistoryPoint } from "../interfaces/IContainerHistoryPoint";

export const useContainerHistoryMap = () => {

    const { selectedContainers,  } = useSelector(
        (state: RootStateOrAny) => state.containers
    );
    const [loading, setIsLoading] = useState(false)
    const [containerHistoryPoints, setContainerHistoryPoints] = useState<IContainerHistoryPoint[]>([])
    const [dateOptions, setDateOptions] = useState<any[]>([]);

    useEffect(() => {
        setContainerHistoryFromApi();
    }, [])

    const setContainerHistoryFromApi = async () => {
        setIsLoading(true)
        var containerHistories = await getContainerHistory(selectedContainers[0] ? selectedContainers[0].id : "")

        var historyPositions: IContainerHistoryPoint[] = []
        containerHistories.data.data.map((x: any) => {
            var containerHistoryLine: IContainerHistoryPoint = {
                key: x.id,
                id: x.id,
                latitude: x.latitude,
                longitude: x.longitude,
                lastActiveDate: new Date(x.lastActiveDate),
                lastActiveDateStr: moment(x.lastActiveDate).format('DD.MM.YYYY HH:mm:ss'),
                firstActiveDateStr: moment(x.firstActiveDate).format('DD.MM.YYYY HH:mm:ss')
            }
            historyPositions.push(containerHistoryLine)
        })
        historyPositions.sort(
            (objA, objB) => objB.lastActiveDate.getTime() - objA.lastActiveDate.getTime(),
        );
        var oldestDate = new Date(Math.min(...historyPositions.map(obj => new Date(obj.lastActiveDate).getTime())));
        var today = new Date();

        // Calculate the time difference in days
        var timeDiff = Math.floor((today.getTime() - oldestDate.getTime()) / (1000 * 60 * 60 * 24));
        var options = [];
        switch (true) {
            case timeDiff > 180:
                options.push({ key: 180, days: 180, text: "ostatnie 180 dni" })
            case timeDiff > 90:
                options.push({ key: 90, days: 90, text: "ostatnie 90 dni" })
            case timeDiff > 60:
                options.push({ key: 60, days: 60, text: "ostatnie 60 dni" })
            case timeDiff > 30:
                options.push({ key: 30, days: 30, text: "ostatnie 30 dni" })
            case timeDiff > 21:
                options.push({ key: 21, days: 21, text: "ostatnie 21 dni" })
            case timeDiff > 14:
                options.push({ key: 14, days: 14, text: "ostatnie 14 dni" })
            case timeDiff > 7:
                options.push({ key: 7, days: 7, text: "ostatnie 7 dni" })
            case timeDiff > 1:
                options.push({ key: 3, days: 3, text: "ostatnie 3 dni" })
            case timeDiff > 1:
                options.push({ key: 1, days: 1, text: "ostatni dzień" })
            default:
                options.push({ key: 0, days: 0, text: "wszystkie" });
        }
        setDateOptions(options)
        setContainerHistoryPoints(historyPositions)
        setIsLoading(false)
    }

    return {
        containerHistoryPoints,
        loading,
        dateOptions
    }
}