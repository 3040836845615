import { ContainersActions } from "./containersActions.enum"
import { IContainer, IForm, IOpenModal, IOpenPanel, IReload } from "./containersActions.types"

export const setContainers = (data: Array<IContainer>) => {
  return {
    type: ContainersActions.SET_CONTAINERS,
    payload: data
  }
}

export const reloadContainers = (data: IReload) => {
  return {
    type: ContainersActions.RELOAD_DATA,
    payload: data
  }
}

export const toggleContainersPanel = (data: IOpenPanel) => {
  return {
    type: ContainersActions.TOGGLE_PANEL,
    payload: data
  }
}

export const setFormType = (data: IForm) => {
  return {
    type: ContainersActions.SET_FORM_TYPE,
    payload: data
  }
}

export const toggleContainersModal = (data: IOpenModal) => {
  return {
    type: ContainersActions.TOGGLE_MODAL,
    payload: data
  }
}