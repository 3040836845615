import React, { useMemo } from "react";
import { Dropdown, IDropdownOption } from "office-ui-fabric-react/lib/Dropdown";
import { useQuery } from "react-query";
import { getReasonsById } from "../../../connectors/cancellationReasons/connectors";
import { Reason } from "../../common/enums/reason.enum"

export interface ReasonsDropDownProps {
  selectedReason: IDropdownOption | undefined;
  setSelectedReason: any;
}

const ReasonsDropDown: React.FC<ReasonsDropDownProps> = ({
  selectedReason,
  setSelectedReason,
}) => {
  const {data} = useQuery(["orderCancleReasons", Reason.OrderCancel], getReasonsById)
  const parsedData = useMemo(() => {
    if(data){
      return data.data.data.map((item: any) => ({
        key: item.id,
        text: item.value
      }))
    }else return []
  },[data])
  const onChange = (
    event: React.FormEvent<HTMLDivElement>,
    item?: IDropdownOption
  ): void => {
    setSelectedReason(item);
  };


  return (
    <Dropdown
      label="Wybierz powód anulowania"
      selectedKey={selectedReason ? selectedReason.key : undefined}
      onChange={onChange}
      options={parsedData}
    />
  );
};

export default ReasonsDropDown;
