import { setListMessage } from './../../../../config/redux/alerts/alertsActions';
import { reloadAdmins } from './../../../../config/redux/admins/adminsActions';
import { useSelector, RootStateOrAny, useDispatch } from "react-redux";

import {toggleAdminsPanel} from "../../../../config/redux/admins/adminsActions"
import { checkQuantity } from "../../actions/checkQuantity";
import { generateItems } from "../../actions/generateCommands/generateAdminsCommands";
import { deleteUser } from "../../../../connectors/clients/connectors";

export const useAdminsCommandBar = () => {
  const dispatch = useDispatch();
  const { selectedAdmins } = useSelector(
    (state: RootStateOrAny) => state.admins
  );
  
  const openPanelToAdd = () => {
    selectedAdmins.splice(0, selectedAdmins.length)
    dispatch(toggleAdminsPanel({open: true}))
  }

  const openPanelToEdit = () => {
    dispatch(toggleAdminsPanel({open: true}))
  }

  const deleteAdmin = async () => {
    try {
      await deleteUser(selectedAdmins[0].id);
      dispatch(setListMessage("Operacja przebiegła pomyślnie"));
      dispatch(reloadAdmins({reload: true}));
    } catch(err) {
      console.error(err);
    }
  }

  const actions = {
      deleteAdmin,
      openPanelToAdd,
      openPanelToEdit
  };

  const quantity = checkQuantity(selectedAdmins.length);

  const items = generateItems(quantity, actions);

  return {
    items,
  };
};