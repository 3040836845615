import * as React from "react";
import { AzureMapFeature } from "react-azure-maps";
import { data } from 'azure-maps-control'

export interface PointListProps {
    points: any[];
}

export const PointList: React.FC<PointListProps> = ({ points }) => {
    return (
        <>
            {points.map(x => {
                const popupOptions = {
                    position: new data.Position(x.longitude, x.latitude)
                };
                return <>
                    <AzureMapFeature
                        key={x.key}
                        id={x.id}
                        variant={'shape'}
                        type="Point"
                        coordinate={popupOptions.position}
                        setCoords={x}
                        properties={{
                            x: x.id,
                            y: x.groupId
                        }}
                    />
                </>
            })}
        </>
    )
}