import * as React from "react";
import {
  CommandBar,
} from "office-ui-fabric-react";

import {useUnactiveUsersCommandBar} from "../hooks/settings/useUnactiveUsersCommandBar"
import { comandBarS} from "../styles/commandBar.styles"
 
const UnactiveUsersCommandBar: React.FC = () => {
    const {items} = useUnactiveUsersCommandBar()
  
    return <CommandBar items={items} styles={comandBarS} />;
}
 
export default UnactiveUsersCommandBar;