import * as React from "react";
import {
  CommandBar,
} from "office-ui-fabric-react";

import { comandBarS} from "../../styles/commandBar.styles"
import { usePricingCommandBar } from "../../hooks/settings/usePricingCommandBar";

const PricingCommandBard: React.FC = () => {
    const {items} = usePricingCommandBar()
  
    return <CommandBar items={items} styles={comandBarS} />;
}
 
export default PricingCommandBard;