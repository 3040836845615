import { CancellationReasonsAcions } from "./cancellationReasonsActions.enum";
import { CancellationReasonsAction } from "./cancellationReasonsActions.types";

const initState = {
  reasonPanelOpen: false,
  selectedReason: "",
  activeReasonType: ""
};

export const cancellationReasonsReducer = (
  state = initState,
  action: CancellationReasonsAction
) => {
  const { type, payload } = action;

  switch (type) {
    case CancellationReasonsAcions.TOGGLE_REASONS_PANEL:
      return { ...state, reasonPanelOpen: payload };
    case CancellationReasonsAcions.SET_SELECTED_REASON:
      return { ...state, selectedReason: payload };
    case CancellationReasonsAcions.SET_ACTIVE_REASON_TYPE:
      return {...state, activeReasonType: payload}  
    default:
      return state;
  }
};
