import moment from "moment";

export const filesColumns = [
    {
      key: "column1",
      name: "Nazwa pliku",
      fieldName: "originalFileName",
      minWidth: 50,
      maxWidth: 100,
      isResizable: true,
    },
    {
      key: "column1_2",
      name: "Opis",
      fieldName: "description",
      minWidth: 50,
      maxWidth: 100,
      isResizable: true,
    },
    {
      key: "column2",
      name: "Typ dokumentu",
      fieldName: "documentTypeStr",
      minWidth: 50,
      maxWidth: 100,
      isResizable: true,
    },
    {
      key: "column3",
      name: "Liczba kontenerów",
      fieldName: "containersNumber",
      minWidth: 50,
      maxWidth: 100,
      isResizable: true,
    },
    {
        key: "column4",
        name: "Data dokumentu",
        fieldName: "documentDate",
        minWidth: 50,
        maxWidth: 100,
        isResizable: true,
      },
  ];

  export const generateFilesList = (filesDetails: Array<any>) => {
    const items: Array<any> = [];
    filesDetails.forEach((filesDetail: any) => {
      items.push({
        key: filesDetail.id, 
        id: filesDetail.id,
        blobFileName: filesDetail.blobFileName,
        documentType: filesDetail.documentType,
        documentTypeStr: ParseDocumentType(filesDetail.documentType),
        containersNumber: filesDetail.containersNumber,
        documentDate: moment(filesDetail.documentDate).format('MM.DD.YYYY'),
        fileUrl: filesDetail.fileUrl,
        originalFileName: filesDetail.originalFileName,
        description: filesDetail.description
      })
    });
  
    return [...items];
  };

  export const ParseDocumentType = (documentType: number) =>{
    switch(documentType){
      case 1:
        return "umowa";
      case 2:
        return "protokół odbioru";
      case 3: 
        return "protokół przekazania";
    }
  }