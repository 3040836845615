import React from "react";

import ClientsList from "../components/ClientsList";
import ClientsPanel from "../components/edditionPanel/ClientPanel";
import AddCompanyPanel from "../components/AddCompanyPanel";
import ListSuccessMessage from "../../common/layout/ListSuccessMessage";
import DeactivationReason from "./DeactivationReason";
import { useLocation } from "react-router-dom";
import { connect, RootStateOrAny } from "react-redux";

export interface IStateProps {
  isPanelOpen: boolean,
}

const ClientsLayout: React.FC<IStateProps> = ({isPanelOpen}) => {
  const location = useLocation()

  return (
    <>
      <ClientsPanel />
      <ListSuccessMessage/>
      <ClientsList pathname={location.pathname} isPanelOpen={isPanelOpen}/>
      <AddCompanyPanel />
      <DeactivationReason/>
    </>
  );
};

const mapStateToProps = (state: RootStateOrAny) => {
  return {
    isPanelOpen: state.clientPanel.clientPanelOpen,
  };
};

export default connect(mapStateToProps)(ClientsLayout);
