import * as React from "react";
import { useSelector, RootStateOrAny } from "react-redux";
import BasicList from "../../common/layout/BasicList";
import { reportsColumns } from "../columns/reportsColumns";
import { prepareNewReport, reloadReports, setReport, setSelectedReports, toggleReportsPanel } from "../../../config/redux/reports/reportsAction";
import { getReports } from "../../../connectors/reports/connectors";

const ReportsList: React.FC = () => {
    const {
        selectedItem,
        activeReport,
        reportsPanelOpen,
        reloadReportsList,
        prepareReport,
    } = useSelector((state: RootStateOrAny) => state.reports);

    const parseReport = (items: Array<any>) => {
        return items;
    };

    return (
        <>
            <BasicList
                setSelectedItems={setSelectedReports}
                setActiveItem={setReport}
                togglePanel={toggleReportsPanel}
                reload={reloadReports}
                editList={prepareNewReport}
                getData={getReports}
                activeItem={activeReport}
                panelOpen={reportsPanelOpen}
                selectedItems={selectedItem}
                removeItems={reloadReportsList}
                listEdited={prepareReport}
                columns={reportsColumns}
                parseItems={parseReport}
            />
        </>
    );
};

export default ReportsList;