import { ITextStyles } from "office-ui-fabric-react";
import { FontSizes } from "@uifabric/fluent-theme";
import { FontWeights, mergeStyles } from "office-ui-fabric-react";

export const headerS: ITextStyles = {
  root: {
    marginTop: 5,
    fontSize: FontSizes.size16,
    fontWeight: FontWeights.semibold,
  },
};

export const wrapper = mergeStyles({
  marginTop: 20
})