import moment from "moment";

export const parseValidators = (validators: Array<any>) => {
  return validators.map((validator: any) => {
    const data = {
      validatorName: validator.validatorName,
      message: validator.message,
      approvedDate: validator.approvedDate,
      approvedDateStr: validator.approvedDate ? moment(validator.approvedDate).format('DD-MM-YYYY HH:mm:ss') : "",
      comment: validator.comment,
      SerNo: validator.detailedColumns.SerNo,
      ContainerName: validator.detailedColumns.ContainerName,
      Address: validator.detailedColumns.Address,
      CompanyName: validator.detailedColumns.CompanyName,
      Number: validator.detailedColumns.Number,
      id: validator.id,
      createdDate: validator.createdDate,
      createdDateStr: validator.createdDate ? moment(validator.createdDate).format('DD-MM-YYYY HH:mm:ss') : "",
    };

    return data;
  });
};