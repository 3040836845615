import { useBoolean, useId } from "@uifabric/react-hooks";
import { Callout } from "office-ui-fabric-react";
import * as React from "react";
import i18n from "../../../../localization/i18n";

export interface LanguageIconProps { }

export const LanguageSwitcher: React.FC<LanguageIconProps> = () => {
  const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
  const buttonId = useId('callout-button');

  const pl = "/flags/pl.svg";
  const gb = "/flags/gb.svg";
  const de = "/flags/de.svg";
  const sk = "/flags/sk.svg";
  const et = "/flags/et.svg";
  const cs = "/flags/cs.svg";

  const getFlag = () => {
    var lang: string = i18n.language;
    if (lang.startsWith("pl"))
      return pl;
    if (lang.startsWith("en"))
      return gb;
    if (lang.startsWith("et"))
      return et;
    if (lang.startsWith("sk"))
      return sk;
    if (lang.startsWith("de"))
      return de;
    if (lang.startsWith("cs"))
      return cs;
    return gb;
  }

  var curnLang = getFlag();

  return (
    <>
      <img
        id={buttonId}
        src={curnLang}
        height='24px'
        width='24px'
        onClick={toggleIsCalloutVisible}
      />
      {isCalloutVisible && (
        <Callout
          role="dialog"
          gapSpace={0}
          target={`#${buttonId}`}
          onDismiss={toggleIsCalloutVisible}
          setInitialFocus
          style={{ marginLeft: "5px", marginRight: "5px", marginTop: "5px" }}
        >
          <img
            style={{ margin: "5px", boxShadow: "0px 0px 2px 1px rgba(66, 68, 90, 1)" }}
            src={pl}
            height='24px'
            width='32px'
            onClick={() => {
              i18n.changeLanguage("pl");
              toggleIsCalloutVisible();
            }} />
          <img
            style={{ margin: "5px", boxShadow: "0px 0px 2px 1px rgba(66, 68, 90, 1)" }}
            src={gb}
            height='24px'
            width='32px'
            onClick={() => {
              i18n.changeLanguage("en");
              toggleIsCalloutVisible();
            }} />
          <img
            style={{ margin: "5px", boxShadow: "0px 0px 2px 1px rgba(66, 68, 90, 1)" }}
            src={de}
            height='24'
            width='32px'
            onClick={() => {
              i18n.changeLanguage("de");
              toggleIsCalloutVisible();
            }} />
          <img
            style={{ margin: "5px", boxShadow: "0px 0px 2px 1px rgba(66, 68, 90, 1)" }}
            src={sk}
            height='24'
            width='32px'
            onClick={() => {
              i18n.changeLanguage("sk");
              toggleIsCalloutVisible();
            }} />
          <img
            style={{ margin: "5px", boxShadow: "0px 0px 2px 1px rgba(66, 68, 90, 1)" }}
            src={et}
            height='24'
            width='32px'
            onClick={() => {
              i18n.changeLanguage("et");
              toggleIsCalloutVisible();
            }} />
          <img
            style={{ margin: "5px", boxShadow: "0px 0px 2px 1px rgba(66, 68, 90, 1)" }}
            src={cs}
            height='24'
            width='32px'
            onClick={() => {
              i18n.changeLanguage("cs");
              toggleIsCalloutVisible();
            }} />

        </Callout>
      )}
    </>
  );
};