import { getOrderTypeItems } from './../../../connectors/orderTypes/connectors/index';

import { useState, useEffect } from 'react';

export const useOrderTypes = (orderTypeId: string) => {
    const [orderTypes, setOrderTypes] = useState([])

    useEffect(() => {
        if(orderTypeId){
            fetch()
        }  
    },[orderTypeId])

    const fetch = async () => {
        const res = await getOrderTypeItems(orderTypeId)
        const parsedRed = res.data.data.map((item: any) => ({
            key: item.id,
            text: item.name,
            data: item.value
        }))

        setOrderTypes(parsedRed)
    } 


    return{
        orderTypes
    }
}