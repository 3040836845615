import {  ITextFieldStyles, ILabelStyles, } from "office-ui-fabric-react";
import { FontSizes } from "@uifabric/fluent-theme";
import { FontWeights } from "office-ui-fabric-react";

export const labelS: ILabelStyles = {
    root: {
      width: 200,
      marginRight: 30,
      fontSize: FontSizes.size16,
      fontWeight: FontWeights.semibold,
      selectors: {
        "&::after": {
          display: "none",
        },
      },
    },
  };
  
export const textFieldS: Partial<ITextFieldStyles> = {
    root: {
      paddingTop: 30,
    },
  
    wrapper: {
      display: "flex",
      flexDirection: "row",
    },
  
    fieldGroup: {
      width: 350,
    },
  
    subComponentStyles: {
      label: labelS,
    },
  };