import * as React from "react";
import {
  Stack,
  Text,
  ITextStyles,
  IStackStyles,
} from "office-ui-fabric-react";
import { FontSizes } from "@uifabric/fluent-theme";
import { FontWeights } from "office-ui-fabric-react";

const textS: ITextStyles = {
  root: {
    fontSize: FontSizes.size14,
    width: 350,
  },
};

const filedS: ITextStyles = {
  root: {
    fontSize: FontSizes.size16,
    fontWeight: FontWeights.semibold,
    width: "200px",
    marginRight: 30,
  },
};

const textContainerS: IStackStyles = {
  root: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: "30px",
  },
};

export interface DisplayTextProps {
  name: string;
  value: string;
}

const DisplayText: React.FC<DisplayTextProps> = ({ name, value }) => {
  return (
    <Stack horizontal styles={textContainerS} key={name}>
      <Text styles={filedS}>{name}</Text>
      <Text styles={textS}>{value}</Text>
    </Stack>
  );
};

export default DisplayText;
