import React from 'react'
import ListSuccessMessage from '../../common/layout/ListSuccessMessage'
import { padding } from '../../reports/styles/style'
import ContainersList from '../components/ContainersList'
import ContainerMapModal from '../modal/ContainerMapModal'
import ContainersPanel from '../panel/ContainersPanel'

export interface ContainersProps { }

const Containers: React.FC<ContainersProps> = () => {
    return (
        <>
            <ContainersPanel />
            <div className={padding}>
                <ListSuccessMessage />
            </div>
            <ContainersList />
            <ContainerMapModal />
        </>
    )
}

export default Containers