import * as React from "react";
import {
  CommandBar,
} from "office-ui-fabric-react";

import {useDriversCommandBar} from "../../hooks/settings/useDriversCommandBar"
import { comandBarS} from "../../styles/commandBar.styles"
 
const DriversCommandBar: React.FC = () => {
    const {items} = useDriversCommandBar()
  
    return <CommandBar items={items} styles={comandBarS} />;
}
 
export default DriversCommandBar;