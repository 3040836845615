import React from "react";
import { Text } from "office-ui-fabric-react/lib/Text";
import { textContainerS } from "../../styles/layout/detailTxt.styles";
import { titleS } from "../../../features/orders/styles/title";
import { subTitleS } from "../../../features/orders/styles/detailTxt.style";

export interface DisplayTxtProps {
  title: string;
  value: string;
}

const DisplayTxt: React.FC<DisplayTxtProps> = ({ title, value }) => {
  return (
    <div className={textContainerS}>   
        <Text styles={titleS}>{title}</Text>
        <Text styles={subTitleS}>{value}</Text>
    </div>
  );
};

export default DisplayTxt;