import React, { useEffect, useState, useCallback } from "react";
import {
    ComboBox,
    IComboBox,
    IComboBoxOption,
} from "office-ui-fabric-react/lib/ComboBox";
import _ from "lodash";
import { getAllReceptionPlaces, getDepartmentReceptionPlaces } from "../../../../../connectors/clients/connectors/index";
import { useEffectAfterMount } from "../../../../common/hooks/useEffectAfterMount";
import { Spinner, SpinnerSize } from "@fluentui/react";
import { IMessageBarStyles, MessageBar, MessageBarType } from "office-ui-fabric-react";
import { useField } from "formik";

const errorMessageS: IMessageBarStyles = {
    root: {
        marginTop: "15px",
        width: "100%",
    },
};

interface ReceptionPlacesDropDownProps {
    value: string;
    label: string,
    name: string,
    setFieldValue?: any;
    resetForm?: any;
    onChange?: any;
    required?: boolean,
    setDepartments?: any,
    departmentId: any,
    setCompany: any,
    companyKey: any,
    setReception:any,
    setDepartment: any
}

const ReceptionPlacesDropDown: React.FC<ReceptionPlacesDropDownProps> = ({
    setFieldValue,
    resetForm,
    value,
    onChange,
    label,
    name,
    required,
    departmentId,
    setCompany, 
    companyKey,
    setReception,
    setDepartment
}) => {
    const [receptionPlaces, setReceptionPlaces] = useState([]);
    const [inputValue, setInputValue] = useState("");
    const [asyncActionInProgress, setAsyncActionInProgress] = useState<boolean>(false);
    const [field, { error }] = useField(name);
    const comboBoxRef = React.useRef<IComboBox>(null);

    const openMenu = React.useCallback(() => {
        if (comboBoxRef.current != undefined) {
            comboBoxRef.current.focus(true)
        }
    }, []);

    const debouncedFetchClients = useCallback(_.debounce((inputValue: string) => {
        fetchReceptionPlaces(inputValue);
    }, 350),
        []
    );

    useEffect(() => {
        fetchReceptionPlaces();
    }, []);

    useEffectAfterMount(() => {
        debouncedFetchClients(inputValue);
    }, [inputValue]);

    const handleChange = useCallback(
        (option?: IComboBoxOption, index?: number, value?: string): void => {

            if (value !== undefined) {
                if (value.length > 2) {
                    openMenu();
                }
                setInputValue(value);
            }
        },
        []
    );

    const fetchReceptionPlaces = async (currentInputValue?: string) => {
        if(companyKey != "") return;
        setAsyncActionInProgress(true);

        const params = { page: 1, limit: 20, AllFields: !!currentInputValue ? currentInputValue : inputValue };
        var res :any= {}
        if(departmentId.key == undefined || departmentId.key==""){
            res = await getAllReceptionPlaces(params);
        }
        else {
            res = await getDepartmentReceptionPlaces(departmentId.key);
        }

        const data = res.data.data.map((receptionPlaces: any) => {
            return {
                key: receptionPlaces.id,
                text: `${receptionPlaces.city} ${receptionPlaces.street} ${receptionPlaces.postalCode} ${!receptionPlaces.isActive ? '(Nieaktywny)' : ''}`,
                clientid: receptionPlaces.id,
                data: receptionPlaces,
            };
        });

        setReceptionPlaces(data);
        setAsyncActionInProgress(false);
    }

    const setOption = (
        event: React.FormEvent<IComboBox>,
        option?: IComboBoxOption,
        index?: number,
        value?: string
    ): void => {
        if (option) {
            setCompany({key: option.data.companyId})
            setDepartment({key: option.data.departmentId, text: "ASd" })
            resetForm();
            setFieldValue(name, option.key);
            setReception(option)
            
            if (onChange) {
                onChange(option)
            }
        }
    };

    return <div style={{ position: 'relative' }}>
        {asyncActionInProgress &&
            <Spinner size={SpinnerSize.small} style={{ position: 'absolute', top: 7, left: 60 }} />
        }
        <ComboBox
            componentRef={comboBoxRef}
            required={required ? required : false}
            label={label}
            selectedKey={value}
            autoComplete={"on"}
            allowFreeform
            onPendingValueChanged={handleChange}
            onChange={setOption}
            options={receptionPlaces}
            dropdownWidth={550}
        />
        {error && (
            <MessageBar
                messageBarType={MessageBarType.error}
                isMultiline={false}
                dismissButtonAriaLabel="Close"
                styles={errorMessageS}
            >
                {error}
            </MessageBar>
        )}
    </div>
};

export default ReceptionPlacesDropDown;