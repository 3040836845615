import React from "react";
import { RootStateOrAny, useSelector, useDispatch } from "react-redux";
import { Panel } from "office-ui-fabric-react";
import { panelS } from "../../../features/common/styles/panelStyles";
import { toggleOrdersReportPanel } from "../../../config/redux/client/clientActions";
import OrderReportClientForm from "../forms/OrderReportClientForm";
import { clearAlerts } from "../../../config/redux/alerts/alertsActions";
import { useTranslation } from "react-i18next";

const OrdersReportPanel: React.FC = () => {
  const dispatch = useDispatch();
  const { ordersReportPanelOpen } = useSelector(
    (state: RootStateOrAny) => state.client
  );

  const dismissPanel = () => {
    dispatch(clearAlerts());
    dispatch(toggleOrdersReportPanel({ isOpen: false }));
  };

  const { t } = useTranslation("orders");
  const panelName: string = t("reportPanelName");

  return (
    <Panel
      isOpen={ordersReportPanelOpen}
      closeButtonAriaLabel="Close"
      headerText={panelName}
      onDismiss={dismissPanel}
      type={1}
      onOuterClick={() => { }}
      isLightDismiss={false}
      styles={panelS}
      isHiddenOnDismiss={false}
      isFooterAtBottom={true}
    >
      <OrderReportClientForm />
    </Panel>
  );
};

export default OrdersReportPanel;