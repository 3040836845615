import * as React from "react";
import { AzureMapFeature } from "react-azure-maps";
import { IInternalStoreBorderPoint } from "../interfaces/IInternalStoreBorderPoint";
import atlas from "azure-maps-control";
import { IInteralStorePoint } from "../interfaces/IInternalStorePoint";

export interface InternalStoreBoundaryProps {
    points: IInternalStoreBorderPoint[];
    internalStorePoint: IInteralStorePoint
}

export const InternalStoreBoundary: React.FC<InternalStoreBoundaryProps> = ({ points, internalStorePoint }) => {
    
    if (internalStorePoint == undefined || points == undefined || points.length < 3)
        return <></>

    var polygonCoordinates: atlas.data.Position[] = []
    points.forEach(location => {
        // Check and update maximum and minimum latitude values
        polygonCoordinates.push([location.longitude, location.latitude])
    });

    polygonCoordinates.push([points[0].longitude, points[0].latitude]);

    return (
        <AzureMapFeature
            key={"InternalStoreBoundaryKey"}
            id={"InternalStoreBoundaryKeyId"}
            type="Polygon"
            variant={'shape'}
            coordinates={polygonCoordinates}
        />
    )
}