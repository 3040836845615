export const generateItems = (data: any) => {
    const items: any[] = [];

    data.forEach((receptionPlace: any) => {
      items.push({
        id: receptionPlace.id,
        key: receptionPlace.id,
        receptionPlaceAddress: receptionPlace.receptionPlaceAddress ? receptionPlace.receptionPlaceAddress : "",
        bdoNumber: receptionPlace.bdoNumber ? receptionPlace.bdoNumber : "",
        orderType: receptionPlace.orderType ? receptionPlace.orderType : "",
        phone: receptionPlace.phone ? receptionPlace.phone : "",
        companyName: receptionPlace.companyName ? receptionPlace.companyName : "",
        containersAmount: receptionPlace.containersAmount ? receptionPlace.containersAmount : "",
        taxNumber : receptionPlace.taxNumber ? receptionPlace.taxNumber : ""
      });
    });
    return [...items];
  };