import React from "react";
import { Formik, Form } from "formik";
import { Stack } from "office-ui-fabric-react";
import NewFormField from "../../common/layout/form/NewFormField";
import ActivityIndicator from "../../common/layout/ActivityIndicator";
import MessageContainer from "../../auth/components/register/forms/MessageContainer";
import NewFormBtns from "../../common/layout/form/NewFormBtns";

export interface TypeFormProps {
  handleSubmit: any;
  initValues: any;
  cancelForm: any;
  selectedOrderTypes: any;
  loading: any
}

const TypeForm: React.FC<TypeFormProps> = ({ handleSubmit, initValues, cancelForm, selectedOrderTypes, loading}) => {

  return (
    <Formik
      initialValues={initValues}
      onSubmit={handleSubmit}
      enableReinitialize={true}
    >
      {({ handleSubmit }) => (
        <Form id="form" translate="yes" onSubmit={handleSubmit}>
          <Stack>
            <NewFormField name="typeName" label="Nazwa typu zamówienia" />
            <NewFormField name="capacity" label="Ładowność (t)" type="number"/>
            {loading ? <ActivityIndicator /> : <MessageContainer />}
          </Stack>

          <NewFormBtns
              cancelForm={cancelForm}
              submitBtnText={selectedOrderTypes[0] ? "Zapisz" : "Dodaj"}
              disabled={loading}
              positionAbsolute={true}             
            />
        </Form>
      )}
    </Formik>
  );
};

export default TypeForm;
