import { OrderTypes } from "../../orders/models/OrderTypes.enum";

export const generateItems = (data: any) => {
    const items: any[] = [];

    data.forEach((client: any) => {
      items.push({
        id: client.id,
        key: client.id,
        // Nazwa: client.shortName ? client.shortName : client.companyName,
        Nazwa: client.resultName,
        Adres: `${client.street} ${client.postalCode} ${client.city}`,
        data: client,
        deactivationReason: client.deactivationReason ? client.deactivationReason : "",
        receptionPlacesOrderTypes: client.receptionPlacesOrderTypes ? generateReceptionPlacesOrderTypes(client.receptionPlacesOrderTypes) : ""
      });
    });
    return [...items];
  };

  const generateReceptionPlacesOrderTypes = (data: any[]) =>{
    var stringData :any[] = [] ;
    data.forEach((type: any) => {
      stringData.push(getTextFromOrderType(type));
    });
    return stringData.join(', ');
  } 

  export const getTextFromOrderType = (orderType: number) => {
    switch (orderType) {
      case 1:
        return "Pojazd";
      case 2:
        return "Tonaż";
      case 3:
        return "Kontener";
    }
  };