import React, { useCallback } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { Pivot, PivotItem } from "office-ui-fabric-react/lib/Pivot";
import { useLocation } from "react-router-dom";
import { OrderTabsNames } from "../models/OrderTabs.enum";
import OrderHistory from "./forms/edditOrder/OrderHistory";
import CommentsContainer from "./forms/edditOrder/CommentsContainer";
import OrderDetailsForm from "./forms/edditOrder/OrderDetailsForm";
import {addOrderComment} from "../../../config/redux/orders/ordersActions";
import { NavigationPath } from "../../../config/routing/NavigationPath";
import RealizationOrderForm from "./forms/completeOrder/RealizationOrderForm";
import OrderDetails from "./OrderDetails";
import { setActiveOrderTab, setOrderEditing } from "../../../config/redux/orders/ordersPanel/orderPanelActions";

const OrderTabs: React.FC = () => {
  const { addComment } = useSelector((state: RootStateOrAny) => state.orders);
  const { editing } = useSelector((state: RootStateOrAny) => state.orderPanel);
  const location = useLocation();
  const dispatch = useDispatch();

  const handleClick = useCallback(
    (e: any) => {
      if (addComment) {
        dispatch(addOrderComment(false));
      }

      if (editing) {
        dispatch(setOrderEditing(false))
      }
      dispatch(setActiveOrderTab(e.props.id));
    },
    [addComment, editing]
  );

  const item = () => {
    if (location.pathname === NavigationPath.IN_PROGRESS_ORDERS) {
      return <RealizationOrderForm />
    } else if (
      location.pathname === NavigationPath.NEW_ORDERS ||
      location.pathname === NavigationPath.ACCEPTED_ORDERS
    ) {
      return <OrderDetailsForm />
    } else {
      return <OrderDetails />
    }
  };

  return (
    <Pivot onLinkClick={handleClick}>
      <PivotItem
        headerText={OrderTabsNames.DETAILS}
        id={OrderTabsNames.DETAILS}
      >
        {editing ? item() : (<OrderDetails />)}
      </PivotItem>
      <PivotItem
        headerText={OrderTabsNames.COMMENTS}
        id={OrderTabsNames.COMMENTS}
      >
        <CommentsContainer />
      </PivotItem>
      <PivotItem
        headerText={OrderTabsNames.HISTORY}
        id={OrderTabsNames.HISTORY}
      >
        <OrderHistory />
      </PivotItem>
    </Pivot>
  );
};

export default OrderTabs;