import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  toggleValidatorPanelApproved,
    toggleValidatorPanel,
} from "../../../config/redux/validators/validatorsPanel/validatorPanelActions";
import { generateItems } from "../../../features/commandBar/actions/generateCommands/generateValidatorsCommands";
import { checkQuantity } from '../../../features/commandBar/actions/checkQuantity';
import { Quantity } from '../../../features/common/models/quantity.model';

export const useValidatorsCommandBar = () => {
  const dispatch = useDispatch();
  const { selectedValidators } = useSelector(
    (state: RootStateOrAny) => state.validators
  );
  const location = useLocation();
  const quantity = checkQuantity(selectedValidators.length);

  const openPanel = () => {
    dispatch(toggleValidatorPanel(true));
  };

  const openPanelSyncValidator = () => {
    dispatch(toggleValidatorPanelApproved(true));
  };

  const actions = {
    openPanel,
    openPanelSyncValidator
  };

  const items = quantity != Quantity.NONE ? generateItems(quantity, location.pathname, actions, selectedValidators[0], dispatch) : generateItems(quantity, location.pathname, actions, undefined, dispatch);

  return {
    items,
  };
};