import React, { useEffect, useState } from "react";
import { Text, Stack } from "office-ui-fabric-react";
import { FormikValues } from "formik";
import { getOrderTypes } from "../../../connectors/orderTypes/connectors/index";
import {
  headerS,
  container,
  orderTypeContainer,
  textS,
} from "./vehicleOrderTypesCheckboxes.styles";
import NewFormCheckbox from "../../common/layout/form/NewFormCheckbox";

const VehicleOrderTypesCheckboxes: React.SFC<{ values: FormikValues }> = ({
  values,
}) => {
  const [OrderTypes, setOrderTypes] = useState([]);

  useEffect(() => {
    get();
  }, []);

  const get = async () => {
    const res = await getOrderTypes();
    setOrderTypes(res.data.data);
  };

  return (
    <Stack verticalAlign="center">
      <Text styles={headerS}>Obsługiwane typy zamówień:</Text>
      <Stack horizontal wrap horizontalAlign="space-between">
        {OrderTypes.map((orderType: any) => {
          return (
            <div key={orderType.id} className={container}>
              <Text styles={textS}>{orderType.name}</Text>
              {orderType.items.map((item: any) => {
                return (
                  <div className={orderTypeContainer} key={item.id}>
                    <NewFormCheckbox name={item.id} label={item.name} />
                    {/* <Field
                          name={item.id}
                          label={item.name}
                          component={FormCheckbox}
                          checked={values[item.id]}
                        /> */}
                  </div>
                );
              })}
            </div>
          );
        })}
      </Stack>
    </Stack>
  );
};

export default VehicleOrderTypesCheckboxes;