import { NotConfirmedUsersActions } from "./notConfirmedUsersActions.enum"
import { INotConfirmedUser, INotConfirmedUsers, INotConfirmedUsersPanel, IReload } from "./notConfirmedUsersActions.types"

export const setSelectedNotConfirmedUser = (data: INotConfirmedUsers) => {
    return {
      type: NotConfirmedUsersActions.SET_SELECTED_NOT_CONFIRMED_USERS,
      payload: data
    }
  }
  
  export const toggleNotConfirmedUserPanel = (data: INotConfirmedUsersPanel) => {
    return {
      type: NotConfirmedUsersActions.TOGGLE_NOT_CONFIRMED_USER_PANEL,
      payload: data
    }
  }

  export const reloadNotConfirmedUser = (data: IReload) => {
    return {
      type: NotConfirmedUsersActions.RELOAD_NOT_CONFIRMED_USER,
      payload: data
    }
  }

  export const setNotConfirmedUser = (data: INotConfirmedUser) => {
    return {
      type: NotConfirmedUsersActions.SET_NOT_CONFIRMED_USER,
      payload: data
    }
  }
  