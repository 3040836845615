import { Form, Formik } from "formik";
import { mergeStyles, Stack } from "office-ui-fabric-react";
import React from "react";
import MessageContainer from "../../../auth/components/register/forms/MessageContainer";
import ActivityIndicator from "../../../common/layout/ActivityIndicator";
import NewFormBtns from "../../../common/layout/form/NewFormBtns";
import NewFormField from "../../../common/layout/form/NewFormField";
import { useReasonForm } from "../../hooks/useReasonForm";

const margin = mergeStyles({
  marginTop: 15,
});

const ReasonForm: React.FC = () => {
  const {
    cancelForm,
    add,
    loading,
    selectedReason,
    initValue,
    edit,
  } = useReasonForm();

  return (
    <Formik
      initialValues={initValue}
      onSubmit={selectedReason[0] ? edit : add}
      enableReinitialize={true}
    >
      {({ handleSubmit }) => (
        <Form translate="yes" onSubmit={handleSubmit}>
          <Stack>
            <div >
              <NewFormField name="reason" label="Treść" />
            </div>
            <NewFormBtns
              cancelForm={cancelForm}
              submitBtnText={selectedReason ? "Zapisz" : "Dodaj"}
              disabled={loading}
              positionAbsolute={true}
            />
            <div className={margin}>
              {loading ? <ActivityIndicator /> : <MessageContainer />}
            </div>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

export default ReasonForm;
