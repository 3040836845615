import api from "../../../config/axios/configAxios";

export const getReasons = async () => api.get(`/api/Dictionaries/list`);

export const getReasonsById = async (key: string, id: string) => api.get(`/api/Dictionaries/${id}/values`);

export const addReason = async({id, value}: any) => api.post(`/api/Dictionaries/${id}/add-value`, {value});

export const deleteReasonById = async (id: string) => api.delete(`/api/Dictionaries/${id}/remove-value`);

export const editReason = async({id, value}: any) => api.put(`/api/Dictionaries/${id}/update-value`, {value});