import { RootStateOrAny } from 'react-redux';
import { useSelector } from 'react-redux';
import { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { getInactivePickUpPoints } from "../../../connectors/clients/connectors";
import {
  Selection,
} from "office-ui-fabric-react/lib/DetailsList";
import { setActivatedUsers } from '../../../config/redux/unactivepickuppoints/unactivepickuppointsActions';

export const useDetailGroupedList = (onSelection?: any, onInvoke?: any) => {
  const { data, status, refetch } = useQuery("unactivepickuppoints", getInactivePickUpPoints);
  const [groups, setGroups] = useState<any>(undefined);
  const [items, setItems] = useState<any>([]);
  const { selectedUnactiveUsers, activatedUsers } = useSelector((state: RootStateOrAny) => state.unactivepickuppoints)
  // const {selectedItem, formActive} = useSelector((state: RootStateOrAny) => state.client)
  const fetchedData = useMemo(() => {
    if (data) {
      return data.data.data
    } else return ""
  }, [data])

  useEffect(() => {
    if (data) {
      generateGroups(data.data.data);
      generateItems(data.data.data);
    }
  }, [JSON.stringify(fetchedData)]);

  useEffect(() => {
    if (JSON.stringify(activatedUsers) !== JSON.stringify({})) {
      refetch()
      setActivatedUsers({})
    }
  }, [activatedUsers]);

  const generateGroups = (items: Array<any>) => {
    let index = 0;
    const gr = items.map((item: any) => {
      const grItem = {
        name: `${item.companyName}`,
        count: item.receptionPlaces.length,
        key: item.companyName,
        startIndex: index,
      };
      index += item.receptionPlaces.length;
      return grItem;
    });

    setGroups(gr);
  };
  const columns = useMemo(
    () => [
      {
        key: "thumbnail",
        minWidth: 16,
        maxWidth: 16,
        fieldName: "thumbnail",
        name: "thumbnail",
        isIconOnly: true,
        isResizable: false,
      },
      {
        key: "street",
        name: "Ulica i numer",
        fieldName: "street",
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
      },
      {
        key: "postalCode",
        name: "Kod pocztowy",
        fieldName: "postalCode",
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
      },
      {
        key: "city",
        name: "Miejscowość",
        fieldName: "city",
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
      },
      {
        key: "bdoNumber",
        name: "BDO",
        fieldName: "bdoNumber",
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
      },
      {
        key: "phone",
        name: "Telefon",
        fieldName: "phone",
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
      },
    ],
    []
  );

  const generateItems = (items: Array<any>) => {
    const arr: Array<any> = [];
    items.forEach((item: any) => {
      const parsedItems = item.receptionPlaces.map((item: any) => {
        return {
          ...item,
        }
      })

      arr.push(...parsedItems);
    });
    setItems(arr);
  };

  const selection = useMemo(
    () =>
      new Selection({
        onSelectionChanged: () => {
          if (onSelection) {
            onSelection(selection.getSelection())
          }
        },
      }),
    []
  );

  const handleInvoke = (item: any) => {
    if (onInvoke) {
      onInvoke()
    }
  };

  return {
    groups,
    items,
    columns,
    selection,
    handleInvoke,
    selectedUnactiveUsers,
    status,
    activatedUsers
  };
};