export const receptionPlacesValidatorsColumns = [
  {
    key: "createdDate",
    name: "Data utworzenia",
    fieldName: "createdDateStr",
    minWidth: 100,
    maxWidth: 150,
    isResizable: true,
  },
  {
    key: "message",
    name: "Opis",
    fieldName: "message",
    minWidth: 200,
    maxWidth: 250,
    isResizable: true,
  },
  {
    key: "Address",
    name: "Adres",
    fieldName: "Address",
    minWidth: 100,
    maxWidth: 120,
    isResizable: true,
  },
  {
    key: "CompanyName",
    name: "Nazwa firmy",
    fieldName: "CompanyName",
    minWidth: 150,
    maxWidth: 180,
    isResizable: true,
  },
  {
    key: "approvedDate",
    name: "Data akceptacji",
    fieldName: "approvedDateStr",
    minWidth: 100,
    maxWidth: 120,
    isResizable: true,
  },
  {
    key: "comment",
    name: "Komentarz",
    fieldName: "comment",
    minWidth: 100,
    maxWidth: 120,
    isResizable: true,
  },
];