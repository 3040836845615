import { clearAlerts, setErrorAlert, setListMessage } from './../../../../config/redux/alerts/alertsActions';
import { reloadUsers, toggleUsersPanel, addNewUser, setSelectedUsers } from './../../../../config/redux/users/usersActions';
import { useSelector, RootStateOrAny, useDispatch } from "react-redux";
import { activateUser, deactivateUser, deleteInvitedUser, deleteUser, inviteUserAgain } from "../../../../connectors/clients/connectors";
import { checkQuantity } from "../../actions/checkQuantity";
import { generateUsersComands } from "../../../commandBar/actions/generateCommands/clients/panel/generateUserCommands";
import { useTranslation } from 'react-i18next';
import { IUsersCommandBarLang } from '../../../../localization/abstract/client/users';

export const useUsersCommandBar = () => {
  const dispatch = useDispatch();
  const { selectedUsers } = useSelector(
    (state: RootStateOrAny) => state.users
  );
  const { t: t_common } = useTranslation("common");
  const openPanel = () => {
    dispatch(toggleUsersPanel({ open: true }))
  }

  const handleDeleteUser = async () => {
    try {
      await deleteUser(selectedUsers[0].id)
      dispatch(setListMessage("Operacja przebiegła pomyślnie"))
      dispatch(reloadUsers({ reload: true }))
    } catch (err) {
      console.error(err);
    }
  }

  const activateUserById = async () => {
    try {
      const res = await activateUser(selectedUsers[0].id);
      dispatch(addNewUser({ add: true }));
      dispatch(setListMessage("Operacja przebiegła pomyślnie"))
    } catch (err) {
      console.error(err);
    }
  };

  const deactivateUserById = async () => {
    try {
      const res = await deactivateUser(selectedUsers[0].id);
      dispatch(addNewUser({ add: true }));
      dispatch(setListMessage("Operacja przebiegła pomyślnie"))
    } catch (err) {
      console.error(err);
    }
  }

  const inviteUserAgainByMail = async () => {
    try {
      const res = await inviteUserAgain(selectedUsers[0].email);
      var response = t_common("invitationSent");
      dispatch(setListMessage(response));
    } catch (err) {
      console.error(err);
      dispatch(setErrorAlert("Wystąpił błąd"))
    }
  }

  const deleteUserById = async () => {
    dispatch(clearAlerts());
    try {
      const res = !selectedUsers[0].isInvitationInProgress ? await deleteUser(selectedUsers[0].id) : await deleteInvitedUser(selectedUsers[0].id);
      // dispatch(reload({ reload: true }));
      dispatch(setListMessage("Operacja przebiegła pomyślnie"));
    } catch (err) {
      console.error(err);
    }
  };

  const toggle = () => {
    dispatch(toggleUsersPanel({ open: true }));
  };

  const toggleInvite = () => {
    dispatch(setSelectedUsers([]));
    dispatch(toggleUsersPanel({ open: true }));
  };

  const actions = {
    handleDeleteUser,
    openPanel,
    activateUserById,
    deactivateUserById,
    inviteUserAgainByMail,
    deleteUserById,
    toggle,
    toggleInvite
  }

  const quantity = checkQuantity(selectedUsers.length);
  const { t } = useTranslation("users");
  const commandBarNames: IUsersCommandBarLang = t("commandBar", {
    returnObjects: true,
  });
  const items = sessionStorage.getItem("role") === "CLIENT_ADMIN" ? generateUsersComands(quantity, actions, selectedUsers[0], commandBarNames) : [];

  return {
    items,
  };
};