export enum LinksNames {
   UNVERIFIED_CLIENTS = "Bez weryfikacji",
   NEW_CLIENTS = "Nowo dodani",
   ACTIVE_CLIENTS = "Aktywni",
   INACTIVE_CLIENTS = "Nieaktywni",
   RECEPTION_PLACES = "Punkty odbioru",

   VALIDATORS = "Walidatory",
   CONTAINERS_VALIDATORS = "Kontenery",
   RECEPTION_PLACES_VALIDATORS = "Punkty odbioru",
   ORDERS_VALIDATORS = "Zamówienia",

   GENERAL_PRICE_LIST = "Cennik ogólny",
   DETAILED_PRICE_LIST = "Cennik szczegółowy",

   ORDERS = "Zamówienia",
   ALL_ORDERS = "Wszystkie",
   NEW_ORDERS = "Nowe",
   ACCEPTED_ORDERS = "Przyjęte",
   IN_PROGRESS_ORDERS = "W trakcie realizacji",
   COMPLETED_ORDERS = "Zrealizowane",
   INVOICED_ORDERS = "Zafakturowane",
   CANCELED_ORDERS = "Anulowane",

   ORDER_TYPES = "Typy zamówień",
   VEHICLES = "Flota",
   DRIVERS = "Kierowcy",
   PRICING = "Cennik",
   OPERATORS = "Operatorzy",
   CANCELLATION_REASONS = "Powody anulowania",
   VALIDATOR_TYPES = "Typy walidatorów",
   NOT_CONFIRMED_USERS = "Niezatwierdzeni użytkownicy",
   ADMINS = "Administratorzy",

   REPORT_LIST = "Lista raportów",
   STORE_LIST = "Magazyny zewnętrzne",
   INTERALSTORE_LIST = "Magazyny wewnętrzne",
   STORE_DOCS_LIST = "Dokumenty",
   CONTAINERS_LIST = "Kontenery",
   STORE_MAP = "Mapa",
   TEMP = "Temp",

   CLIENT_USERS = "Użytkownicy",
   ACTIVATE_USERS = "Akytwuj użytkowników",
}