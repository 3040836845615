export const ordersValidatorsColumns = [
  {
    key: "createdDate",
    name: "Data utworzenia",
    fieldName: "createdDateStr",
    minWidth: 100,
    maxWidth: 150,
    isResizable: true,
  },
  {
    key: "message",
    name: "Opis",
    fieldName: "message",
    minWidth: 300,
    maxWidth: 350,
    isResizable: true,
  },
  {
    key: "Number",
    name: "Numer zamówienia",
    fieldName: "Number",
    minWidth: 100,
    maxWidth: 180,
    isResizable: true,
  },
  {
    key: "CompanyName",
    name: "Klient",
    fieldName: "CompanyName",
    minWidth: 100,
    maxWidth: 120,
    isResizable: true,
  },
  {
    key: "approvedDate",
    name: "Data akceptacji",
    fieldName: "approvedDateStr",
    minWidth: 100,
    maxWidth: 120,
    isResizable: true,
  },
  {
    key: "comment",
    name: "Komentarz",
    fieldName: "comment",
    minWidth: 100,
    maxWidth: 120,
    isResizable: true,
  },
];