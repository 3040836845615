import React from "react";
import { useSelector, RootStateOrAny, useDispatch } from "react-redux";
import { Panel } from "office-ui-fabric-react";
import { toggleUsersPanel } from "../../../config/redux/users/usersActions";
import { panelS } from "../../common/styles/panelStyles"
import UserForm from "./UserForm";
import { useTranslation } from "react-i18next";
import { IUsersPanelHeaderLang } from "../../../localization/abstract/client/users";

const UserPanel: React.SFC = () => {
  const dispatch = useDispatch();
  const { userPanelOpen, selectedUsers } = useSelector(
    (state: RootStateOrAny) => state.users
  );

  const dissmisPanel = () => {
    dispatch(toggleUsersPanel({ open: false }));
  };

  const { t } = useTranslation("users");
  const userNames: IUsersPanelHeaderLang = t("panelHeader", {
    returnObjects: true,
  });

  return (
    <Panel
      headerText={selectedUsers[0] ? userNames.editUser : userNames.addUser}
      isOpen={userPanelOpen}
      closeButtonAriaLabel="Close"
      type={1}
      onDismiss={dissmisPanel}
      isLightDismiss={false}
      isHiddenOnDismiss={false}
      styles={panelS}
    >
      <UserForm />
    </Panel>
  );
};

export default UserPanel;
