import {
  toggleReceptionPlacePanel,
  setSelectedListItem,
} from "../../../config/redux/client/clientActions";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";

export const useReceptionPlacesList = () => {
  const { selectedItem, receptionPlacePanelOpen } = useSelector((state: RootStateOrAny) => state.client);
  const dispatch = useDispatch();

  const setPanelOpen = () => {
    dispatch(toggleReceptionPlacePanel({isOpen: true, formActive: true}));
  };

  const setItemOnSelection = (arr: Array<string>) => {
    dispatch(setSelectedListItem(arr[0]));
  };

  return {
    setPanelOpen,
    setItemOnSelection,
    selectedItem,
    receptionPlacePanelOpen
  };
};
