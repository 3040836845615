import React from "react";
import { useSelector, RootStateOrAny, useDispatch } from "react-redux";
import { Panel } from "office-ui-fabric-react";

import { panelS } from "../../../common/styles/panelStyles";
import { toggleReasonsPanel } from "../../../../config/redux/cancellationReasons/cancellationReasonsActions";
import ReasonForm from "../form/ReasonForm";

const ReasonPanel: React.FC = () => {
  const dispatch = useDispatch();
  const { reasonPanelOpen } = useSelector(
    (state: RootStateOrAny) => state.cancellationReasons
  );

  const dissmisPanel = () => {
    dispatch(toggleReasonsPanel(false));
  };

  return (
    <Panel
      headerText="Powód"
      isOpen={reasonPanelOpen}
      closeButtonAriaLabel="Close"
      type={1}
      onDismiss={dissmisPanel}
      isLightDismiss={false}
      isHiddenOnDismiss={false}
      styles={panelS}
    >
      <ReasonForm />
    </Panel>
  );
};

export default ReasonPanel;
