import React from "react";
import { Stack } from "office-ui-fabric-react/lib/Stack";
import {
  DetailsList,
  DetailsListLayoutMode,
  SelectionMode,
  Selection,
} from "office-ui-fabric-react/lib/DetailsList";

import { listS } from "../../../common/styles/list.styles";
import { operatorsColumns } from "../../data/operators-columns";

export interface OperatorsListProps {
  selection: Selection;
  items: Array<any>;
  handleInvoke: any;
}

const OperatorsList: React.FC<OperatorsListProps> = ({
  items,
  selection,
  handleInvoke,
}) => {
  return (
    <Stack>
      <DetailsList
        items={items}
        columns={operatorsColumns}
        styles={listS}
        setKey="items"
        selection={selection}
        layoutMode={DetailsListLayoutMode.justified}
        selectionPreservedOnEmptyClick={true}
        selectionMode={SelectionMode.single}
        onItemInvoked={handleInvoke}
        usePageCache={true}
      />
    </Stack>
  );
};
export default OperatorsList;
