import React from 'react';
import ListSuccessMessage from '../../common/layout/ListSuccessMessage';
import { padding } from '../../reports/styles/style';
import StoreDocsPanel from '../panel/StoreDocsPanel';
import StoreDocsList from './StoreDocsList';

export interface StoreDocsProps { }

const StoreDocs: React.FC<StoreDocsProps> = () => {
    return (
        <>
            <StoreDocsPanel />
            <div className={padding}>
                <ListSuccessMessage />
            </div>
            <StoreDocsList isPanelOpen={false} />
        </>
    );
}

export default StoreDocs;