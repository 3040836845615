import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./en";
import pl from "./pl";
import de from "./de"
import sk from "./sk"
import et from "./et"
import cs from "./cs";
import LanguageDetector from "i18next-browser-languagedetector";
import XHR from "i18next-http-backend"
import { Roles } from "../config/routing/actions/authService";

const resources = {
  pl,
  en,
  de,
  sk,
  et,
  cs
} as const;

i18n
  //.use(Backend)
  .use(XHR)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    //lng: "pl",
    resources: resources
  });
//i18n.changeLanguage('en-US');

if (sessionStorage.getItem('role') !== "") {
  let role = sessionStorage.getItem('role');
  if (role == Roles.ADMIN) {
    i18n.changeLanguage("pl")
  }
}
export default i18n;