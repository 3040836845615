import React from "react";
import { useDispatch } from "react-redux";
import { SearchBox } from "office-ui-fabric-react";
import { useDelay } from "../../hooks/useDelay";
import { filter, toggleFiltration,
} from "../../../../config/redux/filters/filterActions";
import { searchBoxStyle } from "../../styles/searchBox.style";

interface ISearchBoxProps {
    reload: (data: any) => any;
    placeholder: string
}


const SearchBoxCommon: React.FC<ISearchBoxProps> = ({ reload, placeholder }) => {
    const dispatch = useDispatch();
    const { setSearchTerm, searchTerm } = useDelay(
        setfiltration
    );

    function setfiltration() {
        if(searchTerm != undefined){
            dispatch(filter({ searchPhrase: searchTerm }));
            dispatch(toggleFiltration({ filter: true }));
            dispatch(reload({ reload: true }));
        }
    };

    const handleSubmit = (event: { preventDefault: () => void; }) => {
        event.preventDefault();
      };

    return (
        <form onSubmit={handleSubmit} >
            <SearchBox
                styles={searchBoxStyle}
                placeholder={placeholder}
                name="searchPhrase"
                onChange={(newValue) => { setSearchTerm(newValue) }}
                type="text"
            />
        </form>
    );
};

export default SearchBoxCommon;