import * as React from "react";

import ClientsLayout from "../components/ClientsLayout"


const InactiveClients: React.SFC = () => {
  return (
    <ClientsLayout/>
  );
};

export default InactiveClients;
