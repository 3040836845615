import React from "react";
import { mergeStyles } from "office-ui-fabric-react";
import NewFormField from "../../../../common/layout/form/NewFormField";

export interface QuantitiesProps {}

const container = mergeStyles({
  display: "flex",
  justifyContent: "space-between",
  marginTop: 15
});

const field = mergeStyles({
  width: 250,
});

const Quantities: React.FC<QuantitiesProps> = () => {
  return (
    <div className={container}>
      <div className={field}>
        <NewFormField name="quantity" label="Ilość (szt.)" />
      </div>
      <div className={field}>
        <NewFormField name="quantityTaken" label="Ilość odebrana (szt.)" />
      </div>
    </div>
  );
};

export default Quantities;
