import { mergeStyles, MessageBar, MessageBarType } from "office-ui-fabric-react";
import * as React from "react";

const wrapper = mergeStyles({
    width: "100%"
})

export interface ResetMessageProps {
  message: string;
}

const ResetMessage: React.FC<ResetMessageProps> = ({ message }) => {
  return (
    <div className={wrapper}>
      {message && (
        <MessageBar
          messageBarType={
            message === "Zresetowano hasło"
              ? MessageBarType.success
              : MessageBarType.error
          }
        >
          <p>{message}</p>
        </MessageBar>
      )}
    </div>
  );
};

export default ResetMessage;
