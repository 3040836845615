import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setSelectedListItem } from "../../../../config/redux/client/clientActions";
import { useList } from "../../../../features/common/hooks/useList";
import { useReceptionPlacesList } from "../../../hooks/receptionPlaces/useReceptionPlacesList";
import ReceptionPlacesList from "./ReceptionPlacesList";
import { getReceptionPlaces } from "../../../../connectors/clients/connectors";
import { generatePickUpPoints } from "../../../../features/clients/components/colums/pickUpPoints-columns";

const ReceptionPlacesListContainer: React.FC = () => {
  const [data, setData] = useState<any[]>([]);
  var companyId = sessionStorage.getItem("companyId");
  const asd = async () => {
    var pcks = await getReceptionPlaces(companyId ? companyId : "");
    setData(generatePickUpPoints(pcks.data.data));
  }

  const disptach = useDispatch();
  const {
    selectedItem,
    setItemOnSelection,
    setPanelOpen,
    receptionPlacePanelOpen
  } = useReceptionPlacesList();

  const { handleInvoke, selection, items } = useList(
    data,
    setPanelOpen,
    setItemOnSelection
  );

  useEffect(() => {
    if (!selectedItem) {
      selection.selectToKey("", true);
    }

  }, [selectedItem]);

  useEffect(() => {
    asd();
    return () => {
      disptach(setSelectedListItem(""));
    };
  }, []);

  useEffect(() => {
    if (receptionPlacePanelOpen === false) {
      asd();
    }
  }, [receptionPlacePanelOpen]);

  return (
    <ReceptionPlacesList
      handleInvoke={handleInvoke}
      selection={selection}
      items={items}
    />
  );
};

export default ReceptionPlacesListContainer;