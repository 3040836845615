import {
  setErrorAlert,
  clearAlerts,
} from "./../../../config/redux/alerts/alertsActions";
import {
  addReason,
  editReason,
} from "./../../../connectors/cancellationReasons/connectors/index";
import { setSelectedReason, toggleReasonsPanel } from "./../../../config/redux/cancellationReasons/cancellationReasonsActions";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { useMemo, useState } from "react";
import { IResetForm } from "../../common/models/resetForm.type";
import { FormikValues } from "formik";
import { setListMessage } from "../../../config/redux/alerts/alertsActions";
import { useMutation, useQueryCache } from "react-query";

export const useReasonForm = () => {
  const { selectedReason, activeReasonType } = useSelector(
    (state: RootStateOrAny) => state.cancellationReasons
  );
  const [loading, setIsLoading] = useState(false);
  const cache = useQueryCache();
  const dispatch = useDispatch();

  const cancelForm = () => {
    dispatch(toggleReasonsPanel(false));
    dispatch(setSelectedReason(""))
    dispatch(clearAlerts());
  };

  const [addMutate] = useMutation(addReason, {
    onSuccess: () => {
      cache.invalidateQueries("reasons");
    },
    onError: (err) => {
      throw new Error();
    },
  });

  const [editMutate] = useMutation(editReason, {
    onSuccess: () => {
      cache.invalidateQueries("reasons");
    },
    onError: (err) => {
      throw new Error();
    },
  });

  const add = async (values: FormikValues, { resetForm }: IResetForm) => {
    setIsLoading(true);

    try {
      dispatch(clearAlerts());
      setIsLoading(false);
      await addMutate({ id: activeReasonType, value: values.reason });
      dispatch(toggleReasonsPanel(false));
      dispatch(setListMessage("operacja przebiegła pomyślnie"));
    } catch(err: any) {
      setIsLoading(false);
      dispatch(setErrorAlert(err.response.data.Errors[0]));
    }
  };

  const edit = async (values: FormikValues, { resetForm }: IResetForm) => {
    setIsLoading(true);

    try {
      dispatch(clearAlerts());
      setIsLoading(false);
      await editMutate({ id: selectedReason[0].id, value: values.reason });
      dispatch(toggleReasonsPanel(false));
      dispatch(setListMessage("operacja przebiegła pomyślnie"));
    } catch(err: any) {
      setIsLoading(false);
      dispatch(setErrorAlert(err.response.data.Errors[0]));
    }
  };

  const initValue = useMemo(
    () => ({ reason: selectedReason[0] ? selectedReason[0].reason : "" }),
    []
  );

  return {
    cancelForm,
    add,
    loading,
    selectedReason,
    initValue,
    edit,
  };
};