import * as React from 'react';

import SideNav from "./SideNav"
import {priceListPaths} from "../models/PivotNavPaths"
 
const PriceListNav: React.SFC = () => {
    return (  
        <SideNav items={priceListPaths}/>
    );
}
 
export default PriceListNav;