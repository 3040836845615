import { IQuantity } from "../../../common/models/quantity.model";
import { NavigationPath } from "../../../../config/routing/NavigationPath";
import { generateAll } from "./orders/generateDefaultCommands"
import { generateAccepted } from "./orders/generateAcceptedCommands"
import { generateInProgress } from "./orders/generateInProgressCommands"
import { generateNew } from "./orders/generateNewCommands"
import { generateCompleted } from "./orders/generateCompleted"

export const generateItems = (
  quantity: IQuantity,
  pathname: string,
  actions: any,
  selectedOrder?: any,
  dispatch?: any,
) => {
  switch (pathname) {
    case NavigationPath.ACCEPTED_ORDERS:
      return generateAccepted(quantity, actions)
    case NavigationPath.NEW_ORDERS:
      return generateNew(quantity, actions)
    case NavigationPath.IN_PROGRESS_ORDERS:
      return generateInProgress(quantity, actions)
    case NavigationPath.ALL_ORDERS:
      return []
    case NavigationPath.COMPLETED_ORDERS:
      return generateCompleted(quantity, actions, selectedOrder, dispatch);
    default:
      return generateAll(quantity, actions);
  }
};






