import api from "../../../config/axios/configAxios";
import queryString from "query-string";
import { IVerifyCompany, IPickUpPoint } from "../models/index";
//company
export const getMyCompany = async () =>
  api.get(`/api/Companies/MyCompany`);

export const getCompanies = async (params: any) =>
  api.get(`/api/Admin/Companies?${queryString.stringify(params)}`);

export const getCompany = async (id: string) =>
  api.get(`/api/Admin/Companies/${id}`);

export const deleteCompany = async (companyId: string) =>
  api.delete(`/api/Admin/Companies/${companyId}`);

export const verifyCompany = async (data: IVerifyCompany) =>
  api.put(`/api/Admin/Companies/verify`, data);

export const activateCompany = async (companiesIds: Array<string>) =>
  api.put(`/api/Admin/Companies/activate`, { companiesIds });

export const deactivateCompany = async (companiesIds: Array<string>, reason: string) =>
  api.put(`/api/Admin/Companies/deactivate`, { companiesIds, deactivateReason: reason });

export const editCompanyInfo = (companyId: string, data: any) =>
  api.patch(`/api/Admin/Companies/${companyId}/update`, data);

export const addSpecialPriceToCompany = async (id: string, data: any) => 
  api.put(`api/Admin/Companies/${id}/special-prices`,
  data
);

export const getOrderTypes = async (id: string) =>
  api.get(`/api/Admin/OrderTypes?companyId=${id}`);


//departments

export const getDepartments = async (companyId: string) =>
  api.get(`/api/Admin/Companies/${companyId}/departments`);

export const editDepartment = async (departmentId: string, data: any) =>
  api.put(`/api/Admin/Departments/${departmentId}`, data);

export const addDepartment = (companyId: string, data: any) =>
  api.post(`/api/Admin/Companies/${companyId}/add-department`, data);

export const deleteDepartment = (departmentId: string) =>
  api.delete(`/api/Admin/Departments/${departmentId}`);

//users
export const getUsers = async (companyId: string) =>
  api.get(`/api/Admin/Companies/${companyId}/users?IncludeInvitedUsers=true`);

export const deleteUser = async (userId: string) =>
  api.delete(`/api/Admin/Users/${userId}`);

export const deleteInvitedUser = async (userId: string) =>
  api.delete(`/api/InvitedUsers/${userId}`);

export const editUser = (userId: string, data: any) =>
  api.put(`/api/Admin/Users/${userId}`, data);

export const activateUser = (userId: string) =>
  api.put(`/api/Admin/Users/${userId}/activate`);

export const deactivateUser = (userId: string) =>
  api.put(`/api/Admin/Users/${userId}/deactivate`);

export const getUnactiveUsers = (userId: string) =>
  api.get(`api/Admin/users/unactivated`);

export const inviteUserAgain = (email: string) =>
  api.post(`/api/InvitedUsers/resending`, { email: email });

export const getReceptionPlaces = async (companyId: string) =>
  api.get(`/api/Admin/Companies/${companyId}/reception-places`);

export const getAllReceptionPlaces = async (params: any) =>
    api.get(`/api/Admin/reception-places?${queryString.stringify(params)}`);

export const getInactivePickUpPoints = async () =>
  api.get(`/api/Admin/reception-places/unactivated`);

export const activatePickUpPoint = async (Id: string, orderTypeId: string) =>
  api.put(`/api/Admin/reception-places/${Id}/activate`, { ReceptionPlaceId: Id, orderTypeId: parseInt(orderTypeId) });

export const deactivatePickUpPoint = async (Id: string) =>
  api.put(`/api/Admin/reception-places/${Id}/unactivate`);

export const getDepartmentReceptionPlaces = async (departmentId: string) =>
  api.get(`/api/Admin/Departments/${departmentId}/reception-places`);

export const addPickUpPoint = async (
  data: IPickUpPoint,
  departmentId?: string | null,
) =>
  api.post(`/api/Admin/Departments/${departmentId}/add-reception-place`, data);

export const editPickUpPoint = async (
  receptionPlaceId: string,
  data: IPickUpPoint,
  departmentId?: string | null,
) =>
  api.put(
    `/api/Admin/Departments/${departmentId}/update-reception-place/${receptionPlaceId}`,
    data
  );

export const deletePickUpPoint = async (id: string) =>
  api.delete(`/api/Admin/Departments/remove-reception-place/${id}`);

export const addSpecialPricePickUpPoint = async (id: string, data: any) => 
    api.put(`api/Admin/reception-places/${id}/special-prices`,
    data
);

export const getSpecialPrices = async (params: any) =>
    api.get(`/api/Admin/Prices/special-prices?${queryString.stringify(params)}`);

//client-info
export const getActiveCompaniesReport = async () => {
  api.get("/api/Admin/Companies/active/report", { responseType: 'blob', }
  ).then(response => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'Aktywni.xlsx');
    document.body.appendChild(link);
    link.click();
  });
};

export const getInactiveCompaniesReport = async () => {
  api.get("/api/Admin/Companies/unactive/report", { responseType: 'blob', }
  ).then(response => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'Nieaktywni.xlsx');
    document.body.appendChild(link);
    link.click();
  });
};

//upload files 
export const getAuthorizationAzureStorageSasToken = async (filename: string, taxNumber: string) =>
  api.get(`/api/AzureStorage/sastoken?filename=${filename}&folder=${taxNumber}`);

export const getLinkToFile = async (fileName: string) =>
  api.get(`/api/AzureStorage/file-url?fileName=${fileName}`);

// to są wspólne endpointy dla dokumentów !!!! - można ujednolicić
export const getCompaniesAttachments = async (companyId: string) =>
  api.get(`/api/Admin/Documents?CompanyId=${companyId}&Page=1&Limit=1000&Desc=true`);

export const addCompaniesAttachments = async (data: any) =>
  api.post(`/api/Admin/Documents`, data);

export const deleteCompaniesAttachments = async (documentId: string) =>
  api.delete(`/api/Admin/Documents?documentId=${documentId}`);