import { NotConfirmedUsersActions } from "./notConfirmedUsersActions.enum";
import { INotConfirmedUsersActions } from "./notConfirmedUsersActions.types";

const initState = {
    selectedNotConfirmedUsers: [],
    activeNotConfirmedUser: {},
    notConfirmedUserPanelOpen: false,
    reloadNotConfirmedUsers: false
};

export const notConfirmedUsersReducer = (state = initState, action: INotConfirmedUsersActions) => {
    const { type, payload } = action;

    switch (type) {
        case NotConfirmedUsersActions.SET_SELECTED_NOT_CONFIRMED_USERS:
            return { ...state, selectedNotConfirmedUsers: payload };
        case NotConfirmedUsersActions.SET_NOT_CONFIRMED_USER:
            return { ...state, selectedNotConfirmedUser: payload };
        case NotConfirmedUsersActions.TOGGLE_NOT_CONFIRMED_USER_PANEL:
            return { ...state, notConfirmedUserPanelOpen: payload.open };
        case NotConfirmedUsersActions.RELOAD_NOT_CONFIRMED_USER:
            return { ...state, reloadNotConfirmedUsers: payload.reload };
        case NotConfirmedUsersActions.CLEAR:
            return { ...initState };
        default:
            return state;
    }

}