import { Stack } from "@fluentui/react";
import { Form, Formik } from "formik";
import NewFormField from "../../common/layout/form/NewFormField";
import NewFormBtns from "../../common/layout/form/NewFormBtns";
import MessageContainer from "../../auth/components/register/forms/MessageContainer";
import ActivityIndicator from "../../common/layout/ActivityIndicator";
import { useNotConfirmedUsers } from "../hooks/useNotConfirmedUsers";
import React from "react";
import DisplayText from "../../clients/components/edditionPanel/displayData/DisplayText";

const NotConfirmedUserForm: React.FC = () => {
    const { cancelForm, confirm, initValues, loading } = useNotConfirmedUsers();

    return (
        <Formik initialValues={initValues} onSubmit={confirm}>
            {({ handleSubmit }) => (
                <Form translate="yes" onSubmit={handleSubmit}>
                    <Stack>
                        <NewFormField name="lastName" label="Nazwisko" />
                        <NewFormField name="firstName" label="Imię" />
                        <NewFormField name="phone" label="Telefon" />
                        <DisplayText name={"Email"} value={initValues.email} />
                        <NewFormBtns
                            positionAbsolute={true}
                            cancelForm={cancelForm}
                            submitBtnText={"Zatwierdź"}
                            disabled={loading}
                        />
                    </Stack>
                    {loading ? <ActivityIndicator /> : <MessageContainer />}
                </Form>
            )}
        </Formik>
    );
};

export default NotConfirmedUserForm;