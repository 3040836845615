import { mergeStyles} from "office-ui-fabric-react";

export const divider = mergeStyles({
    marginTop: 15
})


export const filedS = mergeStyles({
    width: 250
})

export const fieldContainer = mergeStyles({
    display: "flex",
    justifyContent: "space-between",
    marginTop: 15,
    marginBottom: 15,
})