import { ValidatorPanelActions } from "./validatorPanel.enum";

export const setValidatorDetails = (data: any) => {
  return {
    type: ValidatorPanelActions.SET_VALIDATOR_DETAILS,
    payload: data,
  };
};

export const toggleValidatorPanel = (isOpen: boolean) => {
  return {
    type: ValidatorPanelActions.TOGGLE_VALIDATOR_PANEL,
    payload: isOpen,
  };
};

export const toggleValidatorPanelApproved = (isOpen: boolean) => {
  return {
    type: ValidatorPanelActions.TOGGLE_VALIDATOR_PANEL_APPROVED,
    payload: isOpen,
  };
};

export const setValidatorComments = (data: any) => {
  return {
    type: ValidatorPanelActions.SET_VALIDATOR_COMMENTS,
    payload: data,
  };
};

export const setValidatorHistory = (data: any) => {
  return {
    type: ValidatorPanelActions.SET_VALIDATOR_HISTORY,
    payload: data,
  };
};

export const addNewComment = (newComment: boolean) => {
  return {
    type: ValidatorPanelActions.ADD_NEW_COMMENT,
    payload: newComment,
  };
};