import { IStackStyles, mergeStyles } from "office-ui-fabric-react";

export const textFieldsContainer = mergeStyles({
  marginBottom: 30,
});

export const containerS: IStackStyles = {
  root: {
    padding: "40px 10px 20px 10px",
    height: "100%",
  },
};

export const container = mergeStyles({
  display: "flex",
  flexDirection: "column",
});

export const dropDownContainer = mergeStyles({
  marginBottom: 20,
});


