import { IAdmin, IAdminsPanel, IReload, IAdd } from "./adminsActions.types";
import {AdminsActions} from './adminsActions.enum'

export const setAdmin = (data: IAdmin) => {
    return{
        type: AdminsActions.SET_ADMIN,
        payload: data
    }
}

export const setSelectedAdmins = (data: Array<IAdmin>) => {
  return {
    type: AdminsActions.SET_SELECTED_ADMIN,
    payload: data
  }
}

export const toggleAdminsPanel = (data: IAdminsPanel) => {
  return {
    type: AdminsActions.TOGGLE_ADMINS_PANEL,
    payload: data
  }
}

export const reloadAdmins = (data: IReload) => {
  return {
    type: AdminsActions.RELOAD_ADMINS,
    payload: data
  }
}

export const clearAdmins = () => {
  return {
    type: AdminsActions.CLEAR,
  }
}

export const addNewAdmin = (data: IAdd) => {
  return {
    type: AdminsActions.ADD_ADMIN,
    payload: data
  }
}


//do wywlaenia
export const toggleSelfEditPanel = (isOpen: boolean) => {
  return {
      type: AdminsActions.CLEAR,
      payload: isOpen
  }
}