import React from "react";
import { Formik, Form } from "formik";
import { Stack } from "office-ui-fabric-react";
import PanelHeader from "../../../features/common/layout/PanelHeader";
import NewFormField from "../../../features/common/layout/form/NewFormField";
import NewFormBtns from "../../../features/common/layout/form/NewFormBtns";
import ActivityIndicator from "../../../features/common/layout/ActivityIndicator";
import MessageContainer from "../../../features/auth/components/register/forms/MessageContainer";
import { useDispatch } from "react-redux";
import { toggleSelfEditPanel } from "../../../config/redux/client/clientActions";
import { useTranslation } from "react-i18next";
import { IProfileUserPanelLang } from "../../../localization/abstract/client/profile";

const SelfEditForm: React.FC = () => {
  const { t } = useTranslation("profile");
  const userPanelNames: IProfileUserPanelLang = t("userPanel", {
    returnObjects: true,
  });

  const dispatch = useDispatch();
  const cancelForm = () => {
    dispatch(toggleSelfEditPanel(false));
  };

  return (
    <Formik initialValues={{}} onSubmit={() => {}}>
      {({ handleSubmit }) => (
        <Form translate="yes" onSubmit={handleSubmit}>
          <Stack>
            <PanelHeader text={userPanelNames.editProfile} />
            <NewFormField name="lastName" label={userPanelNames.lastName} />
            <NewFormField name="firstName" label={userPanelNames.firstName} />
            <NewFormField name="email" label={userPanelNames.email} />
            <NewFormBtns
              cancelForm={cancelForm}
              submitBtnText={userPanelNames.save}
              cancelBtnText={userPanelNames.cancel}
              disabled={false}
            />
          </Stack>
          {false ? <ActivityIndicator /> : <MessageContainer />}
        </Form>
      )}
    </Formik>
  );
};

export default SelfEditForm;