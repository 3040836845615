import { IQuantity, Quantity } from "../../../../common/models/quantity.model";

export const generateDetailedPrice = (quantity: IQuantity, actions: any, isLoading: boolean, selectedValidator?: any) => {

  const item = [
    {
      key: "see",
      text: "Pobierz",
      iconProps: { iconName: "textDocument" },
      onClick: actions.exportFile,
      disabled: isLoading,
    },
  ];

  switch (quantity) {
    case Quantity.NONE:
      return [...item];
    case Quantity.ONE:
      return [...item];
    case Quantity.MANY:
      return [...item];
  }
};