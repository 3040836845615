import { useMemo, useState } from "react";
import {
    setErrorAlert,
    clearAlerts,
    setListMessage
} from "./../../../config/redux/alerts/alertsActions";
import { FormikValues } from "formik";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";

import {
    setRegistrationComplete,
    clearCompanyData,
} from "../../../config/redux/register/registerActions";
import {registerCompany, registerCompanyAdmin, registerPersonAdmin} from "../../../connectors/auth/connectors/index";
import { transformData } from "../actions/transformRegisterData";
import { Errors } from "../enums/errors.enum";
import { IResetForm } from "../../common/models/resetForm.type";
import { useUserData } from "../../common/hooks/useUserData";
import { LangEnumToCountryName } from "../../common/converters/language";

export const useRegistration = () => {
    const [isLoading, setIsLoading] = useState(false)
    const { user } = useUserData();

    const {
        data: { alreadyRegistered, requestId, name, taxNumber, companyAddress, regon, country }, success,
    } = useSelector((state: RootStateOrAny) => state.register.company);
    const dispatch = useDispatch();

    const initValues = useMemo(
        () => ({
            name: name ? name : "",
            taxNumber: taxNumber ? taxNumber : "",
            regon: regon ? regon : "",
            city: companyAddress ? companyAddress.city : "",
            street: companyAddress ? companyAddress.street : "",
            postalCode: companyAddress ? companyAddress.postalCode : '',
            email: !user ? "" : user.emails[0],
            phoneNumber: "",
            bdo: "",
            multiDepartment: false,
            accept: false,
            country: country, 
            countryText: LangEnumToCountryName(country)
        }),
        [name, success]
    );


    const register = async (values: FormikValues, { resetForm }: IResetForm) => {
        setIsLoading(true)
        try {
            dispatch(clearAlerts())
            const { user, department } = transformData(values);
            const body = {
                name: values.name,
                taxNumber: values.taxNumber,
                requestId: requestId,
                multiDepartment: values.multiDepartment,
                bdoNumber: values.bdo,
                contactPerson: user,
                regon: values.regon,
                department,
                country: values.country
            };
            const url = window.location.href.split('/');
            const route = window.location.href.split('/')[url.length - 1];
            const inactive = false;
            const res = inactive ? await registerCompany(body) : (!!body.taxNumber ? await registerCompanyAdmin(body) : await registerPersonAdmin(body));
            resetForm();
            dispatch(setRegistrationComplete());
            dispatch(setListMessage("Rejestracja przebiegła pomyślnie. Wysłano zaproszenie."))
        } catch (err) {
            if (err.response) {
                if (err.response.status === 401) {
                    dispatch(setErrorAlert(Errors.UNAUTHORIZED))
                } else {
                    if (err.response.data.Errors[0]) {
                        dispatch(setErrorAlert(err.response.data.Errors.join(', ')));
                    } else {
                        dispatch(setErrorAlert(Errors.UNKNOWN))
                    }
                }
            } else if (err.request) {
                dispatch(setErrorAlert(Errors.NO_RESPONSE))
            } else {
                dispatch(setErrorAlert(Errors.ERROR_SETTING_UP_REQUEST))
            }
        }
        setIsLoading(false)
    };

    const cancelForm = () => {
        dispatch(clearCompanyData());
    };

    return {
        register,
        alreadyRegistered,
        cancelForm,
        initValues,
        isLoading
    };
};