import { IDropdownOption } from "office-ui-fabric-react/lib/components/Dropdown";
import { useEffect, useState } from "react";
import { getDrivers } from "../../../connectors/drivers/connectors";

export const useDrivers = () => {
  const [options, setOptions] = useState<Array<IDropdownOption>>([]);


  useEffect(() => {
    setDrivers();
  }, []);

  const setDrivers = async () => {
    const res = await getDrivers();
    const drivers = res.data.data.map((driver: any) => {
      return {
        key: driver.userData.id,
        text: driver.userData.lastName,
      };
    });
    setOptions(drivers);
  };

  return options
};
