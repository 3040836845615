import { useState } from 'react';
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { useLocation } from "react-router-dom";
import { generateItems } from "../../../features/commandBar/actions/generateCommands/generateDetailedPriceCommandBar";
import { checkQuantity } from '../../../features/commandBar/actions/checkQuantity';
import { Quantity } from '../../../features/common/models/quantity.model';
import { getDatailedReport } from "../../../connectors/detailedPrice/connectors";
import { setErrorAlert } from "../../../config/redux/alerts/alertsActions";

export const useDetailedPriceCommandBar = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { selectedDetailedPrice } = useSelector(
    (state: RootStateOrAny) => state.detailedPrice
  );
  const location = useLocation();
  const quantity = checkQuantity(selectedDetailedPrice.length);

  const exportFile = async () => {
    try {
      setIsLoading(true);
      await getDatailedReport({ OrderBy: 'CompanyName', Desc: false, SpecialDate: new Date()});
      setIsLoading(false);
    } catch {
      setIsLoading(false);
      dispatch(setErrorAlert("Pobieranie nie powiodło się"));
    }
  };

  const actions = {
    exportFile
  };

  const items = quantity !== Quantity.NONE ? generateItems(quantity, location.pathname, actions, isLoading, selectedDetailedPrice[0]) : generateItems(quantity, location.pathname, actions, isLoading, undefined);

  return {
    items,
    isLoading,
    setIsLoading
  };
};