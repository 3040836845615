import moment from "moment";
import { ContainerBatteryStatusEnumToStringFirstCapitalLetter } from "../../common/converters/containerBatteryStatus";
import { ContainerStatusToString } from "../../common/converters/containerStatus";

export const generateItems = (data: any) => {
    const items: any[] = [];

    data.forEach((container: any) => {
        items.push({
            containerNumber: container.containerNumber,
            id: container.id,
            key: container.id,
            closestCompanyName: container.closestCompanyName ? container.closestCompanyName : "",
            closestCompanyTaxNumber: container.closestCompanyTaxNumber ? container.closestCompanyTaxNumber : "",
            closestReceptionPlaceAddress: container.closestReceptionPlaceAddress ? container.closestReceptionPlaceAddress : "",
            closestReceptionPlaceType: container.closestReceptionPlaceType ? container.closestReceptionPlaceType : "",
            closestReceptionPlaceDistance: container.closestReceptionPlaceDistance ? Math.round(container.closestReceptionPlaceDistance) : "",
            iccid: container.iccid ? container.iccid : "",
            imei: container.imei ? container.imei : "",
            lastActiveDateStr: container.lastActiveDate ? moment(container.lastActiveDate).format('DD.MM.YYYY HH:mm:ss') : "",
            lastActiveDate: container.lastActiveDate ? container.lastActiveDate : new Date(),
            latitude: container.latitude ? container.latitude : "",
            longitude: container.longitude ? container.longitude : "",
            serNo: container.serNo ? container.serNo : "",
            status: container.status,
            statusString: ContainerStatusToString(container.status),
            internalStoreName: container.internalStoreName,
            currentBatteryLevel: container.currentBatteryLevel,
            description: container.description,
            hasPhotos: container.hasPhotos,
            currentBatteryStatus: container.currentBatteryStatus,
            currentBatteryStatusString: ContainerBatteryStatusEnumToStringFirstCapitalLetter(container.currentBatteryStatus),
            assignedInternalStoreName: container.assignedInternalStoreName
        });
    });
    return [...items];
};