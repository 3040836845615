import * as React from "react";
import { TextField, ITextFieldStyles } from "office-ui-fabric-react/lib/TextField";
import { useField } from "formik";

const textFieldS: Partial<ITextFieldStyles> = {
  subComponentStyles:{
    label:{
      root:{
        fontSize: "16px",
        marginTop: 15
      } 
    }
  }
}

interface CommentInputProps {
  name: string,
  label: string,
  value?: string,
  onChange?: any,
  rows?: number
}

const CommentInput: React.FC<CommentInputProps> = ({ name, value, onChange, label, rows }) => {
  const [field] = useField(name)

  return (
    <TextField
      label={label}
      multiline
      rows={rows ? rows: 6}
      resizable={false}
      type="text"
      styles={textFieldS}
      {...field}
    />
  );
};

export default CommentInput;
