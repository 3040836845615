import * as React from "react";

import Navigation from "../../nav/mainNav/Navigation";
import Header from "./header/Header";

const Layout: React.FC = () => {
  return (
    <>
      <Header />
      <Navigation />  
    </>
  );
};

export default Layout;
