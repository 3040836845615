import * as React from "react";
import { Route, Redirect } from "react-router-dom";
import { mergeStyles } from "office-ui-fabric-react";
import { NavigationPath } from "./NavigationPath";
import { authorize, Roles } from "./actions/authService";
import SelfEditPanel from "../../features/Admins/components/panel/SelfEditPanel";

const main = mergeStyles({
    marginTop: "96px",
    marginLeft: "230px",
    width: "calc(100% - 230px)",
});

export const AdminLayoutRoute = ({
    component: Component,
    layout: Layout,
    commandBar: CommandBar,
    sideNav: SideNav,
    ...rest
}: any) => {
    const { token, role, expired, isActivated, hasAllRequiredData } = authorize();

    return (
        <Route
            {...rest}
            render={(props) => {
                if (token && (role === Roles.ADMIN || role === Roles.DRIVER) && !expired) {
                    if (!isActivated || !hasAllRequiredData) {
                        return (
                            <Redirect to={{ pathname: NavigationPath.INACTIVE_ACCOUNT }} />
                        );
                    } else if (role == Roles.ADMIN) {
                        return (
                            <>
                                <Layout />
                                {CommandBar && <CommandBar />}
                                <div className={main}>
                                    {SideNav && <SideNav />}
                                    <Component {...props} />
                                    <SelfEditPanel />
                                </div>
                            </>
                        );
                    }
                    else if (role === Roles.DRIVER) {
                        return <Redirect to={{ pathname: NavigationPath.DRIVER_APP_MESSAGE }} />;
                    }
                } else if (token && !expired) {
                    return <Redirect to={{ pathname: NavigationPath.CLIENT_ORDERS }} />;
                } else {
                    return <Redirect to={{ pathname: NavigationPath.UNAVAILABLE }} />;
                }
            }}
        />
    );
};