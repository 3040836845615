import api from "../../../config/axios/configAxios";

export const getReports = async () =>
    api.get(`/api/Admin/Reports`);

export const getReportInfo = async(id: number) =>
    api.get(`/api/Admin/Reports/`+id+`/info`);

    export const GetReportFile = async (name: string, reportId: number, query: string) => {
        var res = await api.get("/api/Admin/Reports/"+reportId+"?"+query, {responseType: 'blob', }
        ).then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', name+'.xlsx');
          document.body.appendChild(link);
          link.click();
        }).catch(err => {
          throw err;
        })
      };