import { getOperators } from './../../../connectors/operators/connectors/index';
import { useState, useEffect } from 'react';

export const useOperators = () => {
    const [operators, setOperators] = useState([])

    useEffect(() => {
        fetch()
    },[])

    const fetch = async () => {
        const res = await getOperators()
        const parsedRes = res.data.data.map((item: any) => ({
            key: item.id,
            text: item.name
        }))

        setOperators(parsedRes)
    } 


    return{
        operators
    }
}