import React, { useMemo } from "react";
import {
  IBreadcrumbItem,
  Breadcrumb,
} from "office-ui-fabric-react";
import { useHistory, useLocation } from "react-router-dom";

import {
  NavigationPath,
  PathMap,
} from "../../../../config/routing/NavigationPath";
import { breadcrumbS } from "../../styles/breadCrumb.styles";


const BCrumb: React.FC = () => {
  const location = useLocation();
  const history = useHistory()
  const path = PathMap.get(location.pathname);
  let items

  const handleClick = (ev?: React.MouseEvent<HTMLElement>,  item?: IBreadcrumbItem) => {
    if(item && ev){
      const {key} = item
      ev.preventDefault()
      history.push(`${key}`)
    }
  }

  let BreadCrumbitems: IBreadcrumbItem[] = useMemo(() => ([
    { text: "PULPIT", key: NavigationPath.HOME, url:"", onClick: handleClick},
  ]),[]);


  if(typeof(path) === "string"){
    const paths: IBreadcrumbItem[] = path.split("/").map((path: string) => {
      return {
        text: path,
        key: path
      }
     
    })
    items = [...BreadCrumbitems, ...paths]
  }else{
    items=[...BreadCrumbitems]
  }


  return <Breadcrumb items={items} styles={breadcrumbS} maxDisplayedItems={4}/>;
};

export default BCrumb;
