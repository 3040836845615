export const allReceptionPlacesColumns = [
  {
    key: "receptionPlaceAddress",
    name: "Adres",
    fieldName: 'receptionPlaceAddress',
    minWidth: 200,
    maxWidth: 250,
    isResizable: true,
  },
  {
    key: "bdoNumber",
    name: "Numer BDO",
    fieldName: 'bdoNumber',
    minWidth: 100,
    maxWidth: 250,
    isResizable: true,
  },
  {
    key: "phone",
    name: "Numer telefonu",
    fieldName: 'phone',
    minWidth: 100,
    maxWidth: 250,
    isResizable: true,
  },
  {
    key: "companyName",
    name: "Nazwa firmy",
    fieldName: 'companyName',
    minWidth: 150,
    maxWidth: 250,
    isResizable: true,
  },
  {
    key: "orderType",
    name: "Typ zamówień",
    fieldName: 'orderType',
    minWidth: 150,
    maxWidth: 250,
    isResizable: true,
  },  
  {
    key: "containersAmount",
    name: "Ilość kontenerów",
    fieldName: 'containersAmount',
    minWidth: 100,
    maxWidth: 250,
    isResizable: true,
  },
];