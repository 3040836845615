import moment from "moment";

export const parseDetailedPrice = (detailedPrice: Array<any>) => {
  return detailedPrice.map((detailedPrice: any) => {
    const data = {
      companyName: detailedPrice.companyName,
      companySpecialPrice: detailedPrice.companySpecialPrice,
      companySpecialPriceFrom: detailedPrice.companySpecialPriceFrom ? moment(detailedPrice.companySpecialPriceFrom).format('DD-MM-YYYY') : "",
      companySpecialPriceTo: detailedPrice.companySpecialPriceTo ? moment(detailedPrice.companySpecialPriceTo).format('DD-MM-YYYY') : "",
      currentPrice: detailedPrice.currentPrice,
      orderTypeItem: detailedPrice.orderTypeItem,
      postalCodePrice: detailedPrice.postalCodePrice,
      receptionPlaceAddress: detailedPrice.receptionPlaceAddress,
      receptionPlaceSpecialPrice: detailedPrice.receptionPlaceSpecialPrice,
      receptionPlaceSpecialPriceFrom: detailedPrice.receptionPlaceSpecialPriceFrom ? moment(detailedPrice.receptionPlaceSpecialPriceFrom).format('DD-MM-YYYY') : "",
      receptionPlaceSpecialPriceTo: detailedPrice.receptionPlaceSpecialPriceTo ? moment(detailedPrice.receptionPlaceSpecialPriceTo).format('DD-MM-YYYY') : "",
      taxNumber: detailedPrice.taxNumber,
      unitPrice: detailedPrice.unitPrice,
    };

    return data;
  });
};