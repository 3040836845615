export const pickUpPointsColumns = [
    {
      key: "column1",
      name: "Ulica i numer",
      fieldName: "street",
      minWidth: 50,
      maxWidth: 100,
      isResizable: true,
    },
    {
      key: "column2",
      name: "Kod pocztowy",
      fieldName: "postalCode",
      minWidth: 30,
      maxWidth: 100,
      isResizable: true,
    },
    {
      key: "column3",
      name: "Miejscowość",
      fieldName: "city",
      minWidth: 50,
      maxWidth: 100,
      isResizable: true,
    },
    {
      key: "column4",
      name: "Oddział",
      fieldName: "departmentCity",
      minWidth: 50,
      maxWidth: 100,
      isResizable: true,
    },
    {
      key: "column7",
      name: "Telefon",
      fieldName: "phone",
      minWidth: 50,
      maxWidth: 100,
      isResizable: true,
    },
    {
      key: "column5",
      name: "BDO",
      fieldName: "bdoNumber",
      minWidth: 50,
      maxWidth: 100,
      isResizable: true,
    },    
    { 
      key: "column6", 
      name: "Typ zamówień",
      fieldName: "orderType",
      minWidth: 50,
      maxWidth: 100,
      isResizable: true,
  }
  ];

  export const pickUpPointsColumnsShort = [
    {
      key: "column1",
      name: "Ulica i numer",
      fieldName: "street",
      minWidth: 50,
      maxWidth: 100,
      isResizable: true,
    },
    {
      key: "column2",
      name: "Kod pocztowy",
      fieldName: "postalCode",
      minWidth: 30,
      maxWidth: 100,
      isResizable: true,
    },
    {
      key: "column3",
      name: "Miejscowość",
      fieldName: "city",
      minWidth: 50,
      maxWidth: 100,
      isResizable: true,
    },
    {
      key: "column7",
      name: "Telefon",
      fieldName: "phone",
      minWidth: 50,
      maxWidth: 100,
      isResizable: true,
    },
    {
      key: "column4",
      name: "BDO",
      fieldName: "bdoNumber",
      minWidth: 50,
      maxWidth: 100,
      isResizable: true,
    },
    { key: "column5", 
      name: "Typ zamówień",
      fieldName: "orderType",
      minWidth: 50,
      maxWidth: 100,
      isResizable: true,
  }
  ];
  
  export const generatePickUpPoints = (pickUpPoints: Array<any>) => {
    const items: Array<any> = [];
  
    pickUpPoints.forEach((point: any) => {
      items.push({
        key: point.id,
        id: point.id,
        street: point.street,
        postalCode: point.postalCode,
        city: point.city,
        isDefault: point.isDefault,
        departmentId: point.departmentId,
        departmentCity: point.departmentCity,
        phone: point.phone,
        bdoNumber: point.bdoNumber,
        orderTypeId: point.orderTypeId,
        orderType: point.orderType,
        isActive: point.isActive
      })
    });
  
    return [...items];
  };