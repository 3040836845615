import { mergeStyles } from "office-ui-fabric-react";

export const wrapper = mergeStyles({
    display: "flex",
    justifyContent: "space-between",
});

export const mainWrapper = mergeStyles({
    paddingTop: 20,
    paddingBottom: 15
})
export const divider = mergeStyles({
    width: 250,
});

export const littleWrapper = mergeStyles({
    paddingTop: 5,
    paddingBottom: 5
})

export const paddingTopStyle = mergeStyles({
    paddingTop: 10,
})

export const padding = mergeStyles({
    paddingTop: 15
})