import { IButtonStyles } from 'office-ui-fabric-react';
import { ITextStyles } from 'office-ui-fabric-react';
import { mergeStyles } from 'office-ui-fabric-react';

export const textContainer = mergeStyles({
    marginTop: 15,
    paddingTop: 10,
    paddingBottom: 10,
})

export const cos = mergeStyles({
    marginTop: 10
})

export const textS: ITextStyles = {
    root:{
        marginBottom: 5
    }
}

export const closeBtnS: IButtonStyles= {
    root:{
        marginTop: 15
    }
}