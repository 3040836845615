import { VehiclesActions } from "./vehiclesActions.enum";
import { IVehiclesAction } from "./vehiclesActions.types";

const initState = {
  selectedVehicles: [],
  activeVehicle: {},
  reload: false,
  open: false,
  addVehicle: false,
};

export const vehiclesReducer = (state = initState, action: IVehiclesAction) => {
  const { type, payload } = action;

  switch (type) {
    case VehiclesActions.SET_SELECTED_VEHICLES:
      return { ...state, selectedVehicles: payload };
    case VehiclesActions.SET_ACTIVE_VEHICLE:
      return { ...state, activeVehicle: payload };
    case VehiclesActions.RELOAD_VEHICLES:
      return { ...state, reload: payload.reload };
    case VehiclesActions.TOGGLE_VEHICLE_PANEL:
      return { ...state, open: payload };
    case VehiclesActions.ADD_VEHICLE:
      return { ...state, addVehicle: payload.add };
    default:
      return state;
  }
};
