import * as React from "react";
import { CommandBar } from "office-ui-fabric-react";
import { comandBarS } from "./../../../features/commandBar/styles/commandBar.styles";
import { useStoreMapCommandBar } from "./../hooks/useStoreMapCommandBar";

const StoreMapCommandBar: React.FC = () => {

    var { items } = useStoreMapCommandBar()

    return <CommandBar items={items} styles={comandBarS} />;
}

export default StoreMapCommandBar;