export const containerInternalStoreHistoryColumns = [
    // {
    //     key: "gatewayIdentifier",
    //     name: "Identyfikator bramki",
    //     fieldName: 'gatewayIdentifier',
    //     minWidth: 50,
    //     maxWidth: 100,
    //     isResizable: true,
    // },
    // {
    //     key: "rfidTag",
    //     name: "Tag bramki",
    //     fieldName: 'rfidTag',
    //     minWidth: 50,
    //     maxWidth: 100,
    //     isResizable: true,
    // },
    {
        key: "lastGpsUTCDateStr",
        name: "Data przejazdu",
        fieldName: 'lastGpsUTCDateStr',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
    },
    {
        key: "internalStoreName",
        name: "Magazyn",
        fieldName: 'internalStoreName',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
    }
]