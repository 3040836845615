import { reloadOrders } from './../../../config/redux/orders/ordersActions';
import * as React from "react";
import { connect, RootStateOrAny } from "react-redux";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import { getOrders } from "../../../connectors/orders/connectors/index";
import { toggleOrderAccept, toggleOrderCancel, removeData } from "../../../config/redux/orders/ordersActions";
import { IPaginationState, IPaginationProps } from "./Pagination.types";
import { filter, setFinished, sort, toggleFiltration } from "../../../config/redux/filters/filterActions";
import { getParams } from "../actions/getParams";
import { parseOrders } from "../actions/parseOrders"
import { AxiosResponse } from 'axios';

class Pagination extends React.Component<IPaginationProps, IPaginationState> {

    footerRef = React.createRef<HTMLDivElement>();

    state = {
        data: [],
        limit: 35,
        total: 0,
        page: 1,
        loading: false,
    };

    async componentDidMount() {

        await this.props.dispatch(sort({ column: undefined }));
        await this.props.dispatch(toggleFiltration({ filter: false }))
        await this.props.dispatch(filter({ searchPhrase: undefined }));

        if (this.props.footerRef.current !== null) {
            this._observer.observe(this.props.footerRef.current);
        }

        this._setOrders();
    }

    componentDidUpdate(prevProps: IPaginationProps) {
        const { remove, filter, orderAccepted, orderCanceled, reload, dispatch, searchPhrase, column } = this.props;

        if (remove) {
            this._remove();
            this._removeItems();
        }

        if (orderAccepted || orderCanceled) {
            this._remove()
            this._removeItems()
            orderAccepted && dispatch(toggleOrderAccept(false))
            orderCanceled && dispatch(toggleOrderCancel(false))
        }

        if (reload) {
            this._reload();
            this._removeItems();
        }

        if (filter == true) {

            this._reload();

            if (column) {
                this._filterOrders(filter == true ? searchPhrase : "", column.key, column.isSortedDescending)
            }
            else {
                this._filterOrders(filter == true ? searchPhrase : "")
            }

            this.props.dispatch(toggleFiltration({ filter: false }))
        }
    }

    componentWillUnmount() {
        if (this.props.footerRef.current !== null) {
            this._observer.unobserve(this.props.footerRef.current);
        }
    }

    private _handleObserver = (entry: any, observer: any) => {
        if (this.state.data.length < this.state.total) {
            this._setOrders();
        }
    };

    private _observer = new IntersectionObserver(this._handleObserver, {
        root: null,
        rootMargin: "200px",
        threshold: 1,
    });

    private _remove = async () => {
        this.props.dispatch(removeData({
            remove: false,
            reload: undefined
        }));
    };

    private _reload = async () => {
        this.props.dispatch(reloadOrders(false));
    };

    private _removeItems = () => {
        const ids = this.props.selectedOrders.map((item: any) => item.id)
        const Orders = _.remove(this.state.data, (client: any) => {
            if (!ids.includes(client.id)) {
                return client;
            }
        });

        this.setState({ data: [...Orders] });
    };

    private _filterOrders = async (searchPhrase: string, orderBy: undefined | string = undefined, desc: undefined | boolean = false) => {
        const { limit } = this.state;
        const params = getParams(this.props.location.pathname);
        var res: AxiosResponse<any>;
        if (orderBy !== undefined) {
            res = await getOrders({ page: 1, limit, ...params, OrderBy: orderBy, Desc: desc, allFields: searchPhrase, PostalCode: this.props.postalCode });
        }
        else {
            res = await getOrders({ page: 1, limit, ...params, allFields: searchPhrase, PostalCode: this.props.postalCode });
        }

        const orders = parseOrders(res.data.data);
        this.setState((prevState: IPaginationState) => {
            return {
                data: [...orders],
                page: 2,
                total: res.data.total,
            };
        });
        this.props.dispatch(setFinished({ finished: true }));
    }

    private _setOrders = async (newPage?: number) => {
        // const res = await this.fetchOrders(page ? page : this.state.page);
        const { searchPhrase, column} = this.props;
        const { limit, page } = this.state;
        const params = getParams(this.props.location.pathname);
        const p = newPage ? newPage : page;
        let res: any;
        // this.setState({loading: true})
        if (column) {
            res = await getOrders({ page: p, limit, ...params, OrderBy: column.key, Desc: column.isSortedDescending, allFields: searchPhrase, PostalCode: this.props.postalCode });
        } else {
            res = await getOrders({ page: p, limit, ...params, allFields: searchPhrase, PostalCode: this.props.postalCode });
        }
        const orders = parseOrders(res.data.data);
        this.setState((prevState: IPaginationState) => {
            return {
                data: newPage ? [...orders] : [...prevState.data, ...orders],
                page: newPage ? newPage : prevState.page + 1,
                total: 600,
            };
        });
        this.props.dispatch(setFinished({ finished: true }));
    };


    public render() {
        const renderProps = {
            data: this.state.data,
            loading: this.state.loading
        };

        return this.props.render(renderProps);
    }
}

const mapStateToProps = (state: RootStateOrAny) => {
    return {
        remove: state.orders.remove,
        reload: state.orders.reloadOrders,
        postalCode: state.orders.postalCode,
        selectedOrders: state.orders.selectedOrders,
        orderAccepted: state.orders.orderAccepted,
        orderCanceled: state.orders.orderCanceled,
        filter: state.filtration.filter,
        searchPhrase: state.filtration.searchPhrase,
        column: state.filtration.column,
        finished: state.filtration.finished
    };
};

export default connect(mapStateToProps)(withRouter(Pagination));