import * as React from 'react';

import SideNav from "./SideNav"
import {ordersPaths} from "../models/PivotNavPaths"
 
const OrdersNav: React.SFC = () => {
    return (  
        <SideNav items={ordersPaths}/>
    );
}
 
export default OrdersNav;