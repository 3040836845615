import React from 'react'
import { containerInternalStoreHistoryColumns } from '../columns/containerInternalStoreHistoryColumns'
import { DetailsList, DetailsListLayoutMode, SelectionMode, Stack } from 'office-ui-fabric-react';
import { useContainerInternalStoreHistoryList } from '../hooks/useContainerInternalStoreHistoryList';

const ContainerInternalStoreHistoryList: React.FC = () => {

    const { containerInInternalStoreHistory } = useContainerInternalStoreHistoryList();

    return (
        <Stack>
            <DetailsList
                items={containerInInternalStoreHistory}
                columns={containerInternalStoreHistoryColumns}
                // styles={listS}
                setKey="items"
                // selection={selection}
                layoutMode={DetailsListLayoutMode.justified}
                selectionPreservedOnEmptyClick={true}
                selectionMode={SelectionMode.none}
                //onItemInvoked={handleInvoke}
                usePageCache={true}
            />
        </Stack>
    )
}

export default ContainerInternalStoreHistoryList