import React from "react";
import { Form, Formik, FormikValues } from "formik";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { DefaultButton, IButtonStyles, mergeStyles, PrimaryButton } from "office-ui-fabric-react";
import CommentInput from "./CommentInput";
import { addOrderComment } from "../../../../config/redux/orders/ordersActions";
import { IResetForm } from "../../../common/models/resetForm.type";
import { addComment } from "../../../../connectors/orders/connectors";
import _ from "lodash";
import { addNewComment } from "../../../../config/redux/orders/ordersPanel/orderPanelActions";

const wrapper = mergeStyles({
  padding: "30px 15px",
});

const CommentForm: React.FC = () => {
  const dispatch = useDispatch();
  const { orderDetails } = useSelector((state: RootStateOrAny) => state.orderPanel);

  const handleSubmit = async (
    values: FormikValues,
    { resetForm }: IResetForm
  ) => {
    if (values.comment) {
      try {
        await addComment(orderDetails.orderInfo.id, {
          content: values.comment,
        });
        resetForm();

        dispatch(addNewComment(true))
        _.delay(() => {
          dispatch(addOrderComment(false));
        }, 200);
      } catch(err) {
        console.error(err);
      }
    }
  };

  const cancelForm = () => {
    dispatch(addOrderComment(false));
  };

  const buttonS: Partial<IButtonStyles> = {
    root: {
      marginRight: "20px",
    },
  };

  const btns = mergeStyles({
    position: "absolute",
    bottom: 30,
    left: 40,
  });

  return (
    <Formik initialValues={{ comment: "" }} onSubmit={handleSubmit}>
      {({ handleSubmit, values, handleChange }) => (
        <Form onSubmit={handleSubmit}>
          <div className={wrapper}>
            <CommentInput
              name="comment"
              label="Dodaj komentarz"
              value={values.comment}
              onChange={handleChange}
              rows={12}
            />
          </div>
          <div className={btns}>
            <PrimaryButton text={"Dodaj komentarz"} type="submit" styles={buttonS} />
            <DefaultButton text={"Anuluj"} type="reset" onClick={cancelForm} />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default CommentForm;