import {
 setClients,
 setActiveClient,
} from "../../../config/redux/clients/clientsActions";

import {IClients, IActiveClient} from "../../../config/redux/clients/client-actions.types"


export const setSelectedClients = (data: IClients) => (disaptch: React.Dispatch<any>) =>{
    disaptch(setClients(data))
}

export const setInvokedClient = (data: IActiveClient) => (dispatch: React.Dispatch<any>) => {
    dispatch(setActiveClient(data))
}