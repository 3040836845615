import { mergeStyles } from "office-ui-fabric-react";
import React from "react";
import ListSuccessMessage from "../../common/layout/ListSuccessMessage";

import OrderTypesPanel from "../components/OrderTypesPanel";
import OrderTypesList from "../components/OrderTypesList";

const padding = mergeStyles({
  paddingTop: 15,
});

const OrderTypes: React.FC = () => {
  return (
    <div >
      <div className={padding}>
        <ListSuccessMessage />
      </div>
      <OrderTypesList />
      <OrderTypesPanel />
    </div>
  );
};

export default OrderTypes;
