import { CancellationReasonsAcions } from './cancellationReasonsActions.enum';



export const setSelectedReason = (data: any) => {
  return {
    type: CancellationReasonsAcions.SET_SELECTED_REASON,
    payload: data
  };
};


export const toggleReasonsPanel = (isOpen:boolean) => {
    return {
        type: CancellationReasonsAcions.TOGGLE_REASONS_PANEL,
        payload: isOpen
    }
}

export const setActiveReasonType = (reasonType: string) => {
  return{
    type: CancellationReasonsAcions.SET_ACTIVE_REASON_TYPE,
    payload: reasonType
  }
}

