import common from "./client/common.json"
import receptionPlaces from "./client/receptionPlaces.json"
import orders from "./client/orders.json"
import users from "./client/users.json"
import profile from "./client/profile.json"
import pricelist from "./client/pricelist.json"
import { LanguageTranslations } from "../abstract"

const pl: LanguageTranslations = {
    common,
    receptionPlaces,
    orders,
    users,
    profile,
    pricelist
};

export default pl;