export const ContainersValidatorsColumns = [
  {
    key: "createdDate",
    name: "Data utworzenia",
    fieldName: "createdDateStr",
    minWidth: 100,
    maxWidth: 150,
    isResizable: true,
  },
  {
    key: "message",
    name: "Opis",
    fieldName: "message",
    minWidth: 300,
    maxWidth: 350,
    isResizable: true,
  },
  {
    key: "ContainerName",
    name: "Nazwa kontenera",
    fieldName: "ContainerName",
    minWidth: 130,
    maxWidth: 160,
    isResizable: true,
  },
  {
    key: "SerNo",
    name: "Numer seryjny",
    fieldName: "SerNo",
    minWidth: 100,
    maxWidth: 120,
    isResizable: true,
  },
  {
    key: "approvedDate",
    name: "Data akceptacji",
    fieldName: "approvedDateStr",
    minWidth: 100,
    maxWidth: 120,
    isResizable: true,
  },
  {
    key: "comment",
    name: "Komentarz",
    fieldName: "comment",
    minWidth: 100,
    maxWidth: 120,
    isResizable: true,
  },
];