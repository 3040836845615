export const canceledOrdersColumns = [
  {
    key: "Number",
    name: "Numer",
    fieldName: "number",
    minWidth: 50,
    maxWidth: 100,
    isResizable: true,
  },
  {
    key: "CreatedAt",
    name: "Data zamówienia",
    fieldName: "createdAt",
    minWidth: 100,
    maxWidth: 120,
    isResizable: true,
  },
  // {
  //   key: "column3",
  //   name: "Data anulowania",
  //   fieldName: "createdAt",
  //   minWidth: 150,
  //   maxWidth: 200,
  //   isResizable: true,
  // },
  {
    key: "Company",
    name: "Klient",
    fieldName: "company",
    minWidth: 400,
    maxWidth: 450,
    isResizable: true,
  },
  {
    key: "ReceptionPlace",
    name: "Punkt Odbioru",
    fieldName: "receptionPlace",
    minWidth: 100,
    maxWidth: 350,
    isResizable: true,
  },
  {
    key: "Operator",
    name: "Operator",
    fieldName: "operator",
    minWidth: 50,
    maxWidth: 100,
    isResizable: true,
  },
  {
    key: "OrderTypeItemName",
    name: "Typ",
    fieldName: "orderTypeItem",
    minWidth: 50,
    maxWidth: 100,
    isResizable: true,
  },
  {
    key: "Vehicle",
    name: "Pojazd",
    fieldName: "vehicle",
    minWidth: 50,
    maxWidth: 75,
    isResizable: true,
  },
  {
    key: "ContactPhone",
    name: "Numer kontaktowy",
    fieldName: "contactPhone",
    minWidth: 50,
    maxWidth: 100,
    isResizable: true,
  },
];