export const operatorsColumns = [
    {
        key: "Nazwa",
        name: "Nazwa",
        fieldName: "name",
        minWidth: 300,
        maxWidth: 600,
        isResizable: true,
      },
      {
        key: "Aktywny",
        name: "Aktywny",
        fieldName: "isActive",
        minWidth: 300,
        maxWidth: 600,
        isResizable: true,
      },
];