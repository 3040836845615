import { clearCompanyData } from './../../../config/redux/register/registerActions';
import { clearAlerts } from './../../../config/redux/alerts/alertsActions';
import { useState } from "react";
import {useDispatch} from "react-redux"

export const usePanel = () => {
  const dispatch = useDispatch()
  const [isOpen, setIsOpen] = useState(false);

  const openPanel = () => setIsOpen(true);
  const dismissPanel = () => {
    setIsOpen(false)
    dispatch(clearAlerts())
    dispatch(clearCompanyData())
  };

  return {
    isOpen,
    openPanel,
    dismissPanel,
  };
};
