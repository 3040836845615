import * as React from "react";
import {
  Stack,
  IStackStyles,
  mergeStyles,
  getTheme,
  IStackTokens,
  ITextStyles,
  FontSizes,
} from "office-ui-fabric-react";
import { Depths } from "@uifabric/fluent-theme";
import { Breakpoints } from "../features/common/styles/utils/Breakpoints";
import { usePanel } from "../features/auth/hooks/usePanel";
import PricingPanel from "../features/auth/components/pricing/PricingPanel";
import RegisterDesc from "../features/auth/components/register/RegisterDesc";
import { useUserData } from "../features/common/hooks/useUserData";
import Title from "../features/auth/components/login/Title";
import { Redirect } from "react-router-dom";
import { NavigationPath } from "../config/routing/NavigationPath";
import { authorize, Roles } from "../config/routing/actions/authService";
import { QueryStatus } from "react-query";
import ReceptionPlacePanel from "../client/components/panels/ReceptionPlacesPanel";
import { useUserInfo } from "../features/common/hooks/useUserInfo";
import { useMyCompanyInfo } from "../features/clients/hooks/useMyCompanyInfo";
import { RootStateOrAny, useSelector } from "react-redux";

const theme = getTheme();
const wrapperS: IStackStyles = {
  root: {
    width: "100%",
    height: "100vh",
    background: "radial-gradient(circle, rgba(204,204,204,1) 6%, rgba(0,103,184,0.15730042016806722) 94%)",
    backgroundSize: 'cover',
    selectors: {
      [`@media ${Breakpoints.LARGE_MAX}`]: {
        backgroundColor: theme.palette.white,
        backgroundSize: 'cover',
      },
    },
  },
};

const borderStackS: IStackStyles = {
  root: {
    border: '2px solid' + theme.palette.neutralLight,
    padding: '15px'
  },
};

const containerS: IStackStyles = {
  root: {
    display: "flex",
    flexDirection: "column",
    margin: "auto auto",
    backgroundColor: theme.palette.white,

    selectors: {
      [`@media ${Breakpoints.LARGE}`]: {
        minWidth: "320px",
        minHeight: "338px",
        width: "440px",
        padding: "44px",

        position: "relative",
        boxShadow: Depths.depth16,
      },
      [`@media ${Breakpoints.LARGE_MAX}`]: {
        width: "100%",
        height: "100%"
      },
    },
  },
};

const stackStyles: IStackStyles = {
  root: {
  },
};

const stackItemStyles = mergeStyles({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  padding: '5px',
  textAlign: 'center',
  selectors: {
    [`@media ${Breakpoints.LARGE_MAX}`]: {
      marginTop: "20px",

    },
  },
});

const customSpacingStackTokens: IStackTokens = {
  childrenGap: '10%',
};

const customSpacingStackTokens2: IStackTokens = {
  childrenGap: '2%',
};

interface UnavailableProps {
  queryStatus: QueryStatus
}
const textS: ITextStyles = {
  root: {
    fontSize: FontSizes.large,
  },
};

const Unavailable: React.FC<UnavailableProps> = ({ queryStatus }) => {
  const { openPanel } = usePanel();
  const { user } = useUserData();
  const { data } = useUserInfo();
  const { myCompanyData } = useMyCompanyInfo();
  const { isActivated, hasAllRequiredData } = authorize();
  const { listMessage } = useSelector((state: RootStateOrAny) => state.alerts);

  const userInfoData: any = !!data ? (!!data.data ? data.data : null) : null;
  const myCompanyInfo: any = !!myCompanyData ? (!!myCompanyData.data ? (!!myCompanyData.data.data ? myCompanyData.data.data : null) : null) : null;

  let showAddReceptionPlaceButtonAndPanel: boolean = !!userInfoData && userInfoData.role === Roles.CLIENT_ADMIN && (!!myCompanyInfo && myCompanyInfo.receptionPlaceExists === false);

  if (!!listMessage && listMessage.length > 0) {
    showAddReceptionPlaceButtonAndPanel = false;
  }

  return (
    <> {!isActivated || !hasAllRequiredData ?
      <Stack styles={wrapperS}>
        <Stack styles={containerS}>
          <Stack styles={stackStyles} tokens={customSpacingStackTokens}>
            <Stack.Item className={stackItemStyles}><Title /></Stack.Item >
            <Stack.Item className={stackItemStyles}>
              <RegisterDesc openPanel={openPanel} user={user} showAddReceptionPlaceButton={showAddReceptionPlaceButtonAndPanel} isActivated={hasAllRequiredData} />
              {/* <RegisterPanel dismissPanel={dismissPanel} isOpen={isOpen} /> */}

              <PricingPanel />

              {showAddReceptionPlaceButtonAndPanel &&
                <ReceptionPlacePanel predefinedItemData={!!myCompanyInfo ?
                  {
                    city: myCompanyInfo.city,
                    postalCode: myCompanyInfo.postalCode,
                    street: myCompanyInfo.street,
                    default: true,
                    bdoNumber: "",
                    phone: "",
                    orderTypeId: undefined,
                    department: "",
                  }
                  : undefined}
                />
              }
            </Stack.Item>
            {/* <Stack.Item styles={borderStackS} className={stackItemStyles}>
                  <RegisterDesc openPanel={openPanel} user={user} isActivated={hasAllRequiredData} />
                  <RegisterPanel dismissPanel={dismissPanel} isOpen={isOpen} />
                  <PricingPanel />
                 </Stack.Item> */}
          </Stack>
        </Stack>
      </Stack>
      :
      <Redirect to={NavigationPath.HOME} />}
    </>
  );
};

export default Unavailable;