export enum StoreMapActions {
    SHOW_CONTAINER_POINTS = "showContainerPoints",
    SHOW_CONTAINER_CRITICAL_BATTERY_POINTS = "showContainerCriticalBatteryPoints",
    SHOW_CONTAINER_LOW_BATTERY_POINTS = "showContainerLowBatteryPoints",
    SHOW_RECEPTIONPLACE_POINTS = "showReceptionPlacePoints",
    SHOW_INTERNALSTORE_POINTS = "showInternalStorePoints",
    SET_CONTAINER = "setContainer",
    SET_RECEPTION_PLACE = "setReceptionPlace",
    SET_INTERNAL_STORE = "setInternalStore",
    IS_EDIT_CONTAINER_FORM = "isEditContainerForm",
    TOGGLE_PANEL = "togglePanel",
    TOGGLE_SETTINGS_PANEL = "toggleSettingsPanel"
}