import { ITextStyles } from "office-ui-fabric-react/lib/Text";
import { FontSizes } from "@uifabric/fluent-theme";
import { FontWeights, mergeStyles } from "office-ui-fabric-react";

export const titleS: ITextStyles = {
    root: {
        fontSize: FontSizes.size16,
        marginBottom: 10,
        marginTop: 10,
        fontWeight: FontWeights.semibold
    }
}

export const btnS = mergeStyles({
    marginTop: 15
})