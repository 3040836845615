export const containersColumns = [
    {
        key: "containerNumber",
        name: "Numer kontenera",
        fieldName: 'containerNumber',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
    },
    {
        key: "closestReceptionPlaceAddress",
        name: "Przybliżona lokalizacja",
        fieldName: 'closestReceptionPlaceAddress',
        minWidth: 100,
        maxWidth: 250,
        isResizable: true,
    },
    {
        key: "serNo",
        name: "Numer seryjny",
        fieldName: 'serNo',
        minWidth: 75,
        maxWidth: 150,
        isResizable: true,
    },
    {
        key: "lastActiveDate",
        name: "Ostatnia aktywność",
        fieldName: 'lastActiveDateStr',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
    },
    {
        key: "internalStoreName",
        name: "Magazyn",
        fieldName: 'internalStoreName',
        minWidth: 50,
        maxWidth: 100,
        isResizable: true,
    },
    {
        key: "status",
        name: "Status",
        fieldName: 'statusString',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
    },
    {
        key: "currentBatteryStatusString",
        name: "Konserwacja baterii",
        fieldName: 'currentBatteryStatusString',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
    },
    {
        key: "assignedInternalStoreName",
        name: "Magazyn serwisowy",
        fieldName: 'assignedInternalStoreName',
        minWidth: 100,
        maxWidth: 250,
        isResizable: true,
    },
]