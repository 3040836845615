import { useEffect, useState } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { getContainerRfidHistory } from "../../../connectors/containers/connectors";
import { IContainerInternalStoreHistory } from "../interfaces/IContainerInternalStoreHistory";
import moment from "moment";

export const useContainerInternalStoreHistoryList = () => {
    const { selectedContainers, isRfidHistory, isOpen } = useSelector(
        (state: RootStateOrAny) => state.containers
    );
    
    const [isLoading, setIsLoading] = useState(false)
    const [containerInInternalStoreHistory, setContainerInInternalStoreHistory] = useState<IContainerInternalStoreHistory[]>([])
    
    // useEffect(() => {
    //     console.log("sdf")
    //     setContainerInInternalStoreHistoryEffect()
    // }, [])

    useEffect(() => {
        console.log("sdf")
        if(selectedContainers[0] && isRfidHistory == true){
            setContainerInInternalStoreHistoryEffect()
        }
    }, [selectedContainers, isRfidHistory, isOpen])


    const setContainerInInternalStoreHistoryEffect = async () => {
        setIsLoading(true);
        var containerInternalStoreHistoryFromApi = await getContainerRfidHistory(selectedContainers[0] ? selectedContainers[0].id : "")

        if (containerInternalStoreHistoryFromApi && containerInternalStoreHistoryFromApi.data && containerInternalStoreHistoryFromApi.data.data) {
            var containerInternalStoreHistoryTemp: IContainerInternalStoreHistory[] = []
            containerInternalStoreHistoryFromApi.data.data.map((containerRfidHistory: IContainerInternalStoreHistory) => {
                let containerInternalStoreHistoryToAdd: IContainerInternalStoreHistory = {
                        id: containerRfidHistory.id,
                        //gatewayIdentifier: containerRfidHistory.gatewayIdentifier,
                        //rfidTag: containerRfidHistory.rfidTag,
                        lastGpsUTCDate: containerRfidHistory.lastGpsUTCDate,
                        lastGpsUTCDateStr: moment(containerRfidHistory.lastGpsUTCDate).format('DD-MM-YYYY HH:mm:ss'),
                        internalStoreName: containerRfidHistory.internalStoreName
                }
                containerInternalStoreHistoryTemp.push(containerInternalStoreHistoryToAdd)
            })

            setContainerInInternalStoreHistory(containerInternalStoreHistoryTemp)
        }
        setIsLoading(false)
    }

    return {
        containerInInternalStoreHistory,
        isLoading
    }
}