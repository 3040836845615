import { mergeStyles } from "office-ui-fabric-react";
import * as React from "react";

const column = mergeStyles({
  display: "flex",
});

const columnName = mergeStyles({
  paddingRight: 15,
  fontWeight: "500"
});

export interface ColumnProps {
  name: string;
  value: string;
}

const Column: React.FC<ColumnProps> = ({ name, value }) => {
  return (
    <div className={column}>
      <div className={columnName}>
        <p>{name}:</p>
      </div>
      <div>
        <p>{value}</p>
      </div>
    </div>
  );
};

export default Column;
