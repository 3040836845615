import { IClientsAction } from "./client-actions.types";
import { ClientsActions } from "./clients-actions.enum";

const initState = {
  selectedClients: [],
  activeClient: "",
  reload: false,
  toggleClientsPanel: false,
  addNewClient: false,
  clientOrderType: "",
  deactivationReasonOpen: false
};

export const clientsReducer = (state = initState, action: IClientsAction) => {
  const { type, payload } = action;
  switch (type) {
    case ClientsActions.SET_CLIENTS:
      return { ...state, selectedClients: payload.selectedClients };
    case ClientsActions.SET_ACTIVE_CLIENT:
      return { ...state, activeClient: payload.activeClient };
    case ClientsActions.RELOAD_DATA:
      return { ...state, reload: payload.reload };
    case ClientsActions.TOGGLE_PANEL:
      return { ...state, toggleClientsPanel: payload.isOpen };
    case ClientsActions.SET_CLIENT_ORDER_TYPE:
      return { ...state, clientOrderType: payload };
    case ClientsActions.ADD_NEW_CLIENT:
      return { ...state, addNewClient: payload };
      case ClientsActions.TOGGLE_DEACTIVATION_REASON:
        return { ...state, deactivationReasonOpen: payload };  
    case ClientsActions.CLEAR_LIST_DATA:
      return { ...initState };
    default:
      return state;
  }
};
