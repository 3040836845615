import * as React from "react";
import { useTranslation } from "react-i18next";
import DisplayText from "./DisplayTxt";
import { IOrdersOrderPanelLang } from "../../../localization/abstract/client/orders";

export interface OrderDisplayProps {
  selectedItem: any;
}

const OrderDisplay: React.FC<OrderDisplayProps> = ({
  selectedItem,
}) => {
  const { t } = useTranslation("orders");
  const orderDetailNames: IOrdersOrderPanelLang = t("orderPanel", {
    returnObjects: true,
  });
  return (
    <div>
      <DisplayText title={orderDetailNames.orderNumber} value={selectedItem.number} />
      <DisplayText title={orderDetailNames.orderDate} value={selectedItem.date} />
      <DisplayText title={orderDetailNames.receptionPlace} value={selectedItem.receptionPlace} />
      <DisplayText title={orderDetailNames.orderType} value={selectedItem.type} />
      <DisplayText title={orderDetailNames.orderStatus} value={selectedItem.status} />
      <DisplayText title={orderDetailNames.additionalInformation} value={selectedItem.additionalInfo ? selectedItem.additionalInfo : orderDetailNames.none} />
    </div>
  );
};

export default OrderDisplay;