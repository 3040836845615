import React, { useMemo, useState } from "react";
import { RootStateOrAny, useSelector, useDispatch } from "react-redux";
import { PrimaryButton } from "office-ui-fabric-react/lib/Button";
import { IDropdownOption } from "office-ui-fabric-react/lib/Dropdown";
import { Dialog } from "office-ui-fabric-react/lib/Dialog";
import { mergeStyles } from "office-ui-fabric-react";

import { errorButtonS } from "../../common/styles/errorBtn.styles";
import DialogErr from "../../common/layout/DialogErr";
import { useEffectAfterMount } from "../../common/hooks/useEffectAfterMount";
import { setListMessage } from "../../../config/redux/alerts/alertsActions";
import { deactivateCompany } from "../../../connectors/clients/connectors";
import {
  reloadData,
  toggleDeactivationReason,
} from "../../../config/redux/clients/clientsActions";
import { useQuery } from "react-query";
import { getReasonsById } from "../../../connectors/cancellationReasons/connectors";
import DeactivationReasonDropDown from "./DeactivationReasonDropDown";
import { Reason } from "../../common/enums/reason.enum";

const messageContainer = mergeStyles({
  width: "100%",
  height: 15,
  marginTop: 10,
  marginBottom: 20,
});

export interface DeactivationReasonProps {};

const DeactivationReason: React.FC<DeactivationReasonProps> = () => {
  const [errVisible, changeErrVisibility] = useState(false);
  const [selectedReason, setSelectedReason] = React.useState<IDropdownOption>();
  const { data } = useQuery(
    ["deactivationReasons", Reason.ClientDeactivation],
    getReasonsById
  );
  const reasons = useMemo(() => {
    if (data) {
      return data.data.data.map((reason: any) => ({
        key: reason.id,
        text: reason.value,
      }));
    } else return [];
  }, [data]);

  const dispatch = useDispatch();
  const { deactivationReasonOpen, selectedClients } = useSelector(
    (state: RootStateOrAny) => state.clients
  );

  const dismiss = () => {
    dispatch(toggleDeactivationReason(false));
    changeErrVisibility(false);
    setSelectedReason(undefined);
  };

  const setOrderCanceled = async () => {
    if (selectedReason) {
      try {
        await deactivateCompany(selectedClients, selectedReason.text.toString());
        dispatch(reloadData({ reload: true }));
        dispatch(toggleDeactivationReason(false))
        setSelectedReason(undefined)
        dispatch(setListMessage("Operacja przebiegła pomyślnie"));
      } catch (err) {}
    } else {
      changeErrVisibility(true);
    }
  };

  useEffectAfterMount(() => {
    return () => {
      setSelectedReason(undefined);
    };
  }, []);

  return (
    <Dialog hidden={!deactivationReasonOpen} onDismiss={dismiss}>
      <DeactivationReasonDropDown
        selectedReason={selectedReason}
        setSelectedReason={setSelectedReason}
        options={reasons}
      />
      <PrimaryButton
        styles={errorButtonS}
        text="Deaktywuj"
        onClick={setOrderCanceled}
      />
      <div className={messageContainer}>
        {errVisible && <DialogErr message="Wybierz powód deaktywacji" />}
      </div>
    </Dialog>
  );
};

export default DeactivationReason;
