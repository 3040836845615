import { useState } from "react";
import jwtDecode from "jwt-decode";
import authentication from "react-azure-b2c"

type IDecoded = {
  role: string;
  exp: number;
};

export const useUserData = () => {
  const [data, setData] = useState<any>();
  const token = authentication.getAccessToken().accessToken;

  if(token) {
    const decoded: IDecoded = jwtDecode(token);
    if(!data) {
      setData(decoded);
    }
  }

  return {
    user: data,
  };
};
