import * as React from "react";
import {
  CommandBar,
} from "office-ui-fabric-react";

import { comandBarS} from "../../styles/commandBar.styles"
import { useOperatorsCommandBar } from "../../hooks/settings/useOperatorsCommandBar";

const OperatorsCommandBard: React.FC = () => {
    const {items} = useOperatorsCommandBar()
  
    return <CommandBar items={items} styles={comandBarS} />;
}
 
export default OperatorsCommandBard;