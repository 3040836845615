import React from "react";
import { Stack } from "office-ui-fabric-react/lib/Stack";
import { Text } from 'office-ui-fabric-react/lib/Text';
import {
    DetailsListLayoutMode,
    SelectionMode,
} from "office-ui-fabric-react/lib/DetailsList";
import { ShimmeredDetailsList } from 'office-ui-fabric-react/lib/ShimmeredDetailsList';
import { useQuery } from "react-query";
import { FontIcon, mergeStyles, mergeStyleSets, getTheme } from "office-ui-fabric-react";
import { getNotifications } from "../../connectors/others/connectors";
import { NavLink } from "react-router-dom";
import { NavigationPath } from "../../config/routing/NavigationPath";

export interface Notification {
    notificationType: number;
    count: number;
    name: string | undefined;
    dismissPanel: () => void;
}

export interface notificationDataType {
    link: string;
    Title: string;
}

export interface NotificationListProps {
    dismissPanel: () => void;
}

const theme = getTheme();
const boldText = mergeStyles({
    fontWeight: 600
});
const fontColor = mergeStyles({
    color: "rgb(50, 49, 48)"
});

const marginLeft = mergeStyles({
    marginLeft: 31
});
const textS = mergeStyles({
    textDecoration: "none",
});
const iconClass = mergeStyles({
    fontSize: 16,
    height: 21,
    width: 21,
    marginRight: 10,
});
const classNames = mergeStyleSets({
    iconS: [{ color: theme.palette.themeSecondary }, iconClass],
    textBoldS: [boldText, fontColor],
    textS: [fontColor, marginLeft],
    textNoNew: [boldText, fontColor, {textAlign: "center"}]
});
const spaceS = {
    margin: "80px",
    marginBottom: "40px"
};
const notificationData: { [key: number]: notificationDataType } = {
    0: { link: NavigationPath.NEW_ORDERS, Title: "Nowe zamówienia" },
    1: { link: NavigationPath.IN_PROGRESS_ORDERS, Title: "Zamówienia w realizacji po terminie" },
    2: { link: NavigationPath.UNVERIFIED_CLIENTS, Title: "Klienci do weryfikacji" },
    3: { link: NavigationPath.UNACTIVE_USERS, Title: "Użytkownicy do weryfkiacji" },
    4: { link: NavigationPath.UNACTIVE_PICKUP_POINTS, Title: "Punkty odbioru do weryfikacji" },
}

let columns = [
    {
        key: "Powiadomienie",
        name: "Powiadomienie",
        fieldName: 'Powiadomienie',
        minWidth: 160,
        isResizable: true,
        onRender: (item: Notification) => (
            <NavLink onFocus={(e) => { e.target.blur(); item.dismissPanel() }} to={notificationData[item.notificationType].link} className={textS}>
                <Stack>
                    <Text className={classNames.textBoldS}>
                        <FontIcon iconName="AlertSolid" className={classNames.iconS} />
                        {item.name}
                    </Text>
                </Stack>
                <Stack>
                    <Text className={classNames.textS}>
                        Liczba: {item.count}
                    </Text>
                </Stack>
            </NavLink>
        ),
    },
];

const NotificationsList: React.FC<NotificationListProps> = ({ dismissPanel }) => {
    const { data, status } = useQuery("notifications", getNotifications)
    const fetchedData = data ? data.data.data as Notification[] : undefined
    
    if(fetchedData) {
        fetchedData.forEach((element) => {
            element.name = notificationData[element.notificationType].Title;
            element.dismissPanel = dismissPanel;
        });
    }

    return (
        <Stack>
            {fetchedData === [] ?
                <>
                    <svg style={spaceS} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33.393 38.958">
                        <path d="M33.393 30.61v2.783H22.262a5.413 5.413 0 01-.435 2.163 5.607 5.607 0 01-2.967 2.967 5.6 5.6 0 01-4.327 0 5.607 5.607 0 01-2.967-2.967 5.413 5.413 0 01-.435-2.163H0V30.61h2.783v-16.7a13.619 13.619 0 01.5-3.7 14.128 14.128 0 011.4-3.326A13.944 13.944 0 019.674 1.9 14.131 14.131 0 0113 .5a13.911 13.911 0 017.392 0 14.149 14.149 0 013.326 1.4 13.944 13.944 0 014.989 4.989 14.149 14.149 0 011.4 3.326 13.639 13.639 0 01.5 3.7v16.7zm-5.565 0v-16.7a10.72 10.72 0 00-.88-4.326 11.278 11.278 0 00-5.924-5.924 11.071 11.071 0 00-8.653 0 11.278 11.278 0 00-5.925 5.927 10.72 10.72 0 00-.88 4.326v16.7zM16.7 36.176a2.7 2.7 0 001.087-.217 2.835 2.835 0 001.478-1.479 2.694 2.694 0 00.218-1.087h-5.569a2.694 2.694 0 00.218 1.087 2.835 2.835 0 001.478 1.479 2.7 2.7 0 001.09.217z" fill="#ddd" />
                    </svg>
                    <Text className={classNames.textNoNew}>
                        Brak powiadomień
                    </Text>
                </>
                :
                <ShimmeredDetailsList
                    items={fetchedData as any}
                    columns={columns}
                    onRenderDetailsHeader={() => <></>}
                    setKey="items"
                    layoutMode={DetailsListLayoutMode.justified}
                    selectionMode={SelectionMode.none}
                    enableShimmer={status === 'loading'}
                />}
        </Stack>
    );
};

export default NotificationsList;