import { RootStateOrAny, useDispatch, useSelector } from "react-redux";

export const useReceptionPlacesShowForm = () => {

    const { selectedReceptionPlaces } = useSelector(
        (state: RootStateOrAny) => state.receptionPlaces
    );

    const initValues = selectedReceptionPlaces[0]
        ? selectedReceptionPlaces[0] :
        {
            receptionPlaceAddress: "",
            bdoNumber: "",
            orderType: "",
            phone: "",
            companyName: "",
            containersAmount: ""
        }

    return { initValues }
}