import { IDriver, IDriversPanel, IReload, IAdd } from "./driversActions.types";
import {DriversActions} from './driversActions.enum'

export const setDriver = (data: IDriver) => {
    return{
        type: DriversActions.SET_DRIVER,
        payload: data
    }
}

export const setSelectedDrivers = (data: Array<IDriver>) => {
  return {
    type: DriversActions.SET_SELECTED_DRIVERS,
    payload: data
  }
}

export const toggleDriversPanel = (data: IDriversPanel) => {
  return {
    type: DriversActions.TOGGLE_DRIVERS_PANEL,
    payload: data
  }
}

export const toggleSMSHistoryPanel = (data: IDriversPanel) => {
  return {
    type: DriversActions.TOGGLE_SMS_HISTORY_PANEL,
    payload: data
  }
}

export const reloadDrivers = (data: IReload) => {
  return {
    type: DriversActions.RELOAD_DRIVERS,
    payload: data
  }
}

export const clearDrivers = () => {
  return {
    type: DriversActions.CLEAR,
  }
}

export const addNewDriver = (data: IAdd) => {
  return {
    type: DriversActions.ADD_DRIVER,
    payload: data
  }
}