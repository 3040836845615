import { mergeStyles } from 'office-ui-fabric-react';
import { NeutralColors } from '@uifabric/fluent-theme';
import { ITextStyles } from "office-ui-fabric-react";
import { FontSizes } from "@uifabric/fluent-theme";


export const textContainerS = mergeStyles({
    display: "flex",
    flexDirection: "column",
    paddingTop: 10,
    paddingBottom: 10
})

export const titleS: ITextStyles = {
    root: {
        fontSize: FontSizes.size16,
        fontWeight: "600",
        marginBottom: 10,
    }
}

export const subTitleS: ITextStyles = {
    root: {
        fontSize: FontSizes.size16,
        color: NeutralColors.gray130
    }
}
