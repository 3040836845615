import { useEffect, useState } from "react"
import { IContainerHistoryPoint } from "../interfaces/IContainerHistoryPoint"

export const useContainerHistoryPoints = (containerHistoryPoints: IContainerHistoryPoint[], daysBack: number) => {
    const [filteredContainerHistoryPoints, setFilteredContainerHistoryPoints] = useState<IContainerHistoryPoint[]>([])

    useEffect(() => {
        generateFilteredDates()
    }, [])

    useEffect(() => {
        generateFilteredDates()
    }, [containerHistoryPoints, daysBack])

    const generateFilteredDates = () => {
        if (daysBack === 0) {
            setFilteredContainerHistoryPoints(containerHistoryPoints);
        }
        else {
            const today: Date = new Date();
            const pastDate: Date = new Date(today);
            pastDate.setDate(today.getDate() - daysBack);

            const filteredDates: IContainerHistoryPoint[] = containerHistoryPoints.filter((obj: IContainerHistoryPoint) => {
                const date: Date = new Date(obj.lastActiveDate);
                return date > pastDate;
            });
            setFilteredContainerHistoryPoints(filteredDates);
        }
    }

    return {
        filteredContainerHistoryPoints
    }
}