import { mergeStyles } from "office-ui-fabric-react";
import { DefaultButton } from "office-ui-fabric-react/lib/Button";
import {
  Dialog,
  DialogFooter,
} from "office-ui-fabric-react/lib/Dialog";
import React from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { clearAlerts } from "../../../config/redux/alerts/alertsActions";

const errS = mergeStyles({
  color: 'red'
})

const RealizationError: React.FC = () => {
  const { dialogError } = useSelector((state: RootStateOrAny) => state.alerts);
  const dispatch = useDispatch();

  const dismiss = async () => {
    dispatch(clearAlerts());
  };

  return (
    <Dialog
      hidden={!dialogError}
      onDismiss={dismiss}
      title="Brakuje danych"
      closeButtonAriaLabel="Close"
    >
      <>
        <p className={errS}>{dialogError ? dialogError : "."}</p>
        <DialogFooter>
          <DefaultButton onClick={dismiss} text="Zamknij" />
        </DialogFooter>
      </>
    </Dialog>
  );
};

export default RealizationError;