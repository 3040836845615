import { NavigationPath } from "./../../../config/routing/NavigationPath";
import { DetailedPriceType } from "../models/DetailedPriceType.enum";

export const getParams = (pathname: string) => {
  const currentDate = new Date();
  let params = {};
  switch (pathname) {
    case NavigationPath.DETAILED_PRICE_LIST:
      return (params = { SpecialDate: currentDate });
  }

  return params;
};