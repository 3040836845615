import * as React from "react";
import {
  Stack,
  Text,
  IStackStyles,
  concatStyleSets,
  ITextStyles,
  DefaultButton,
  IStackTokens,
  FontIcon,
} from "office-ui-fabric-react";
import { pDark, hDark } from "../../../common/styles/text";
import authentication from "react-azure-b2c";
import { useDispatch } from "react-redux";
import { togglePricing } from "../../../../config/redux/register/registerActions";
import { toggleReceptionPlacePanel } from "../../../../config/redux/client/clientActions";
import ListSuccessMessage from "../../../common/layout/ListSuccessMessage";
import { useTranslation } from "react-i18next";
import { ICommonUnactivatedUserLang } from "../../../../localization/abstract/client/common";

const marginP: ITextStyles = {
  root: {
    marginBottom: "23px",
  },
};

const marginH: ITextStyles = {
  root: {
    marginBottom: "15px",
  },
};

const container: IStackStyles = {
  root: {
    textAlign: "center",
    width: '100%'
  },
};

const stackS: IStackStyles = {
  root: {
    width: '100%'
  },
};

const stackSMargin: IStackStyles = {
  root: {
    width: '80%',
    marginTop: '20px !important',
  },
};

const customSpacingStackTokens: IStackTokens = {
  childrenGap: '-20%',
};

const logout = () => {
  sessionStorage.removeItem('role');
  sessionStorage.removeItem('isActivated');
  sessionStorage.removeItem('hasAllRequiredData');
  authentication.signOut();
}

const lastText = concatStyleSets(pDark, marginP);
const header = concatStyleSets(hDark, marginH);

const desc1 = "Aby korzystać z systemu i składać";
const desc2 = "zamówienia, należy uzupełnić dane";

interface IRegisterDesc {
  openPanel: () => void;
  user: any;
  isActivated: boolean;
  showAddReceptionPlaceButton?: boolean;
}

const RegisterDesc: React.FC<IRegisterDesc> = ({ openPanel, user, showAddReceptionPlaceButton, isActivated }) => {
  const dispatch = useDispatch();
  const openPanelPricing = () => {
    dispatch(togglePricing(true))
  };
  const openPanelReceptionPlaces = () => {
    dispatch(toggleReceptionPlacePanel({ isOpen: true, formActive: true }))
  };

  const { t } = useTranslation("common");
  const unactivatedNames: ICommonUnactivatedUserLang = t("unactivatedUser", {
    returnObjects: true,
  });

  return (
    <Stack verticalAlign="center" horizontalAlign="center" styles={container}>
      <Text styles={header}>{user ? user.emails[0] : ""}</Text>

      {isActivated ? <>
        <Text styles={lastText} ><FontIcon iconName="Warning" />
          {unactivatedNames.accountWatingForActivation}
        </Text></>
        :
        <>
          <Text styles={pDark}>{desc1}</Text>
          <Text styles={lastText}>{desc2}</Text>
        </>
      }

      <Stack horizontal tokens={customSpacingStackTokens} styles={stackS}>
        <Stack.Item grow={1}>
          <DefaultButton text={unactivatedNames.seePriceList} onClick={openPanelPricing} />
        </Stack.Item>

        <Stack.Item grow={1}>
          <DefaultButton text={unactivatedNames.logout} onClick={logout} />
        </Stack.Item>

        {/* {isActivated ? null : 
        <Stack.Item grow={1}>
          <PrimaryButton
            text="Uzupełnij dane"
            onClick={openPanel}
          />
        </Stack.Item>} */}
      </Stack>

      {showAddReceptionPlaceButton === true &&
        <Stack horizontal tokens={customSpacingStackTokens} styles={stackS}>
          <Stack.Item grow={1}>
            <DefaultButton text={unactivatedNames.addReceptionPlace} onClick={openPanelReceptionPlaces} style={{ width: '76%', marginTop: 20 }} />
          </Stack.Item>
        </Stack>
      }

      <Stack horizontal tokens={customSpacingStackTokens} styles={stackSMargin}>
        <Stack.Item grow={1}>
          <ListSuccessMessage clearListMessageTimeout={1000 * 60 * 60 * 24} />
        </Stack.Item>
      </Stack>
    </Stack>
  );
};

export default RegisterDesc;