import React from "react";
import { Text } from "office-ui-fabric-react/lib/Text";
import { titleS, subTitleS, textContainerS } from "../../../styles/detailTxt.style";

export interface OrderDetailProps {
  title: string;
  value: string;
}

const OrderDetail: React.FC<OrderDetailProps> = ({ title, value }) => {
  return (
    value == undefined || value == "" ?
      <></> :
      <div className={textContainerS}>
        <Text styles={titleS}>{title}</Text>
        <Text styles={subTitleS}>{value}</Text>
      </div>
  );
};

export default OrderDetail;