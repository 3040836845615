import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toggleValidatorPanelApproved } from '../../../config/redux/validators/validatorsPanel/validatorPanelActions';
import { setListMessage,setErrorAlert } from '../../../config/redux/alerts/alertsActions';
import { OrdersAlerts } from '../../orders/models/Alerts.enum';
import { approveValidator } from '../../../connectors/validators/connectors';
import { useValidatorData } from '../../orders/hooks/useValidatorData';
import moment from 'moment';
import { reloadValidators } from '../../../config/redux/validators/validatorsActions';

export const useConfirmValidator = (cancelForm: () => void) => {
    const dispatch = useDispatch();
    const { selectedValidators } = useValidatorData();
    const [isLoading, setIsLoading] = useState(false);
    const [comment, setComment] = useState("");

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        try {
            setIsLoading(true);
            if (selectedValidators) {
                const res = await approveValidator(selectedValidators[0].id, comment);
                if (res) {
                    selectedValidators[0].comment = res.data.data.comment;
                    selectedValidators[0].approvedDate = res.data.data.approvedDate;
                    selectedValidators[0].approvedDateStr = moment(res.data.data.approvedDate).format('DD-MM-YYYY HH:mm:ss');
                }
            };
            dispatch(toggleValidatorPanelApproved(false));
            dispatch(reloadValidators(true));
            dispatch(setListMessage("Operacja przebiegła pomyślnie"));
        } catch (err) {
            dispatch(setErrorAlert(OrdersAlerts.FILL_ALL_FILEDS));
        } finally {
            setIsLoading(false);
        }
    };

    return {
        comment,
        setComment,
        handleSubmit,
        isLoading,
    };
};
