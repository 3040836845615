import api from "../../../config/axios/configAxios";
import queryString from "query-string";

export const getAllContainers = async (params: any) =>
    api.get(`/IoT/containers-list?${queryString.stringify(params)}`);

export const getContainer = async (containerId: string) =>
    api.get(`/IoT/container/${containerId}`);

export const putContainer = async (containerId: any, params: any) =>
    api.put(`/IoT/container/${containerId}`, params);

export const getContainerHistory = async (containerId: string) =>
    api.get(`IoT/container/${containerId}/history`)

export const getContainerInfo = async (containerId: string) =>
    api.get(`Iot/container/${containerId}`)

// export const getContainerRfidHistory = async (containerId: string) =>
//     api.get(`IoT/container/${containerId}/rfid`)

export const getContainerRfidHistory = async (containerId: string) =>
    api.get(`api/Admin/GpsDevice/${containerId}/internal-store/history`)

export const getGpsDeviceStatuses = async () =>
    api.get(`api/Admin/GpsDevice/statuses`)