import React from "react";
import {
  Dropdown,
  IDropdownOption,
} from "office-ui-fabric-react/lib/Dropdown";
import { mergeStyles } from "office-ui-fabric-react"
import { Text, ITextStyles } from "office-ui-fabric-react";
import { FontSizes } from "@uifabric/fluent-theme";
import { FontWeights } from "office-ui-fabric-react";
import { MethodsOfPayment } from "../../../models/MethodsOfPayment.enum"
import { useTranslation } from "react-i18next";
import { IOrdersPaymentMethodLang } from "../../../../../localization/abstract/client/orders";

const wrapper = mergeStyles({
  paddingTop: 20,
  paddingBottom: 15
})


const headerS: ITextStyles = {
  root: {
    display: "block",
    marginBottom: 15,
    fontSize: FontSizes.size16,
    fontWeight: FontWeights.semibold,
  },
};

const options = (paymentMethodNames: any) => [
  {
    key: MethodsOfPayment.CASH,
    text: paymentMethodNames.cash,
  },
  {
    key: MethodsOfPayment.TRANSFER,
    text: paymentMethodNames.transfer,
  },
];


export interface PaymentMethodProps {
  setFieldValue: any,
  paymentType: any
}

const PaymentMethod: React.FC<PaymentMethodProps> = ({
  setFieldValue, paymentType
}) => {
  const [selectedItem, setSelectedItem] = React.useState<IDropdownOption>();
  const { t } = useTranslation("orders");
  const paymentMethodNames: IOrdersPaymentMethodLang = t("paymentMethod", {
    returnObjects: true,
  });
  const handleChange = (
    event: React.FormEvent<HTMLDivElement>,
    option: IDropdownOption | undefined
  ): void => {
    if (option) {
      setSelectedItem(option);
      setFieldValue('paymentType', option.key)
    }
  };

  return (
    <div className={wrapper}>
      <Text styles={headerS}>{paymentMethodNames.paymentMethod}</Text>
      <Dropdown
        selectedKey={selectedItem ? selectedItem.key : paymentType}
        options={options(paymentMethodNames)}
        onChange={handleChange}
      />
    </div>
  );
};

export default PaymentMethod;