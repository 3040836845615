import React, { useCallback, useEffect, useState, } from "react";
import { Dropdown, IDropdownOption } from "office-ui-fabric-react/lib/Dropdown";
import { IMessageBarStyles, MessageBar, MessageBarType } from "office-ui-fabric-react";
import { useField } from "formik";
import { wrapper } from "../../styles/formDropDown.styles";
import { useEffectAfterMount } from "../../hooks/useEffectAfterMount";

const errorMessageS: IMessageBarStyles = {
  root: {
    marginTop: "15px",
    width: "100%",
  },
};

export interface FormDropDownProps {
  name: string;
  headerText: string;
  options: Array<any>;
  placeholder?: string;
  onChange?: any
  onChangeEffect?: any,
  required?: boolean
  setOrderTypeItemId?: any;
  reception: any;
  setPrice?: any;
  setTempOrderType?: any;
  price?: any;
}

const FormDropDownOrderItemId: React.FC<FormDropDownProps> = ({
  options,
  headerText,
  placeholder,
  name,
  onChange,
  onChangeEffect,
  required = true,
  setTempOrderType,
  reception,
  setPrice,
  price
}) => {
  const [field, { value, error }, { setValue }] = useField(name);

  const handleChange = useCallback(
    async (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
      if (option) {
        setValue(option.key);
        setTempOrderType(option);
        if (onChange) {
          var priceFromApi = await onChange(reception.key, option.key)
          setPrice(priceFromApi);
        }
      }
    },
    [options]
  );

  useEffect(() => {
    if (!!reception && !!reception.key && options.length == 1 && options[0] && !!options[0].key) {
      const setPriceFunc = async () => {
        var priceFromApi = await onChange(reception.key, options[0].key);
        setPrice(priceFromApi);
      }
      setValue(options[0].key);
      setTempOrderType(options[0]);
      setPriceFunc()
    }
  }, [options])

  useEffectAfterMount(() => {
    (async () => {
      if (!!value && !price) {
        if (onChange) {
          var priceFromApi = await onChange(reception.key, value.key);
          setPrice(priceFromApi);
        }
      }
    })();
    if (onChangeEffect) {
      if (options) {
        const item = options.find((option: any) => {

          if (option.key === value) {
            //setOrderTypeItemId(option.key)
            return option
          }
        })

        onChangeEffect(item)
      }
    }
  }, [value, options[0]]);

  return (
    <div className={wrapper}>
      <Dropdown
        selectedKey={value ? value : ""}
        placeholder={placeholder}
        options={options}
        onChange={handleChange}
        label={headerText}
        required={required}
      //defaultSelectedKey={options.length == 1 && options[0] && !! options[0].key ? options[0].key : null}
      />
      {error && (
        <MessageBar
          messageBarType={MessageBarType.error}
          isMultiline={false}
          dismissButtonAriaLabel="Close"
          styles={errorMessageS}
        >
          {error}
        </MessageBar>
      )}
    </div>
  );
};

export default FormDropDownOrderItemId;