import { StoreMapActions } from "./storeMapActions.enum";
import { IStoreMapActions } from "./storeMapActions.types";

const initState = {
  showReceptionPlacePoints: true,
  showContainerPoints: true,
  showContainerCriticalBatteryPoints: true,
  showContainerLowBatteryPoints: true,
  showInternalStorePoints: true,
  containerPanelOpen: false,
  settingsPanelOpen: false
};

export const storeMapReducer = (state = initState, action: IStoreMapActions) => {
  const { type, payload } = action;
  switch (type) {
    case StoreMapActions.SHOW_CONTAINER_POINTS:
      return { ...state, showContainerPoints: payload };
    case StoreMapActions.SHOW_CONTAINER_CRITICAL_BATTERY_POINTS:
      return { ...state, showContainerCriticalBatteryPoints: payload };
    case StoreMapActions.SHOW_CONTAINER_LOW_BATTERY_POINTS:
      return { ...state, showContainerLowBatteryPoints: payload };
    case StoreMapActions.SHOW_RECEPTIONPLACE_POINTS:
      return { ...state, showReceptionPlacePoints: payload };
    case StoreMapActions.SHOW_INTERNALSTORE_POINTS:
      return { ...state, showInternalStorePoints: payload };
    case StoreMapActions.SET_CONTAINER:
      return { ...state, selectedContainer: payload };
    case StoreMapActions.SET_RECEPTION_PLACE:
      return { ...state, selectedReceptionPlace: payload };
    case StoreMapActions.SET_INTERNAL_STORE:
      return { ...state, selectedInternalStore: payload };
    case StoreMapActions.TOGGLE_PANEL:
      return { ...state, containerPanelOpen: payload.isOpen };
    case StoreMapActions.IS_EDIT_CONTAINER_FORM:
      return { ...state, isEditContainerForm: payload}
    case StoreMapActions.TOGGLE_SETTINGS_PANEL:
      return { ...state, settingsPanelOpen: payload}
    default:
      return state;
  }
};