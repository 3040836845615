import { IQuantity, Quantity } from "../../../common/models/quantity.model";
import { OrderTypes } from "../../../orders/models/OrderTypes.enum";

interface IActions {
  editPrice: () => void;
  deletePrice: () => void;
  addPrice: (e: any) => void
}

export const generateItems = (quantity: IQuantity, actions: IActions, orderTypes: Array<any>) => {
  switch (quantity) {
    case Quantity.NONE:
      const itemsArr = orderTypes.map(({ id, name }) => {
        let icon = { iconProps: {} };

        if (name === OrderTypes.VEHICLE) {
          icon = { iconProps: { iconName: "Car" } };
        } else if (name === OrderTypes.CONTAINER) {
          icon = { iconProps: { iconName: "Inbox" } };
        } else if (name === OrderTypes.WEIGHT) {
          icon = { iconProps: { iconName: "Weights" } };
        }

        return {
          key: id,
          id: id,
          text: name,
          iconProps: icon.iconProps,
          onClick: actions.addPrice,
          name: name,
          data: name
        };
      });


      return [
        {
          key: "add",
          text: "Dodaj cenę",
          iconProps: { iconName: "add" },
          subMenuProps: {
            items: [...itemsArr],
          },
        },
      ];
    case Quantity.ONE:
      return [
        {
          key: "edit",
          text: "Edytuj",
          iconProps: { iconName: "edit" },
          name: "edit",
          onClick: actions.editPrice,
        },
        {
          key: "delete",
          text: "Usuń",
          iconProps: { iconName: "delete" },
          name: "delete",
          onClick: actions.deletePrice,
        },
      ];
    default:
      return [];
  }
};
