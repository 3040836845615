import * as React from "react";
import {
    CommandBar,
} from "office-ui-fabric-react";
import { comandBarS } from "./../../../features/commandBar/styles/commandBar.styles";
import { useReceptionPlacesCommandBar } from "../hooks/useReceptionPlacesCommandBar";
import { getTheme, IStackStyles, Stack } from "@fluentui/react";
import PostalCodeSFilter from "./PostalCodeFilter";
import { useLocation } from "react-router-dom";

const theme = getTheme();

const containerS: IStackStyles = {
  root: {
    position: "fixed",
    top: 55,
    right: 0,
    width: "auto",
    zIndex: 10,
    backgroundColor: "white",
    borderBottom: `2px solid ${theme.palette.neutralLighter}`,
  },
};

const ReceptionPlaceCommandBar: React.FC = () => {
    const { items } = useReceptionPlacesCommandBar();
    const location = useLocation();
    
    // return <CommandBar items={items} styles={comandBarS} />;
    return <Stack
    //   styles={containerS}
      horizontal
      horizontalAlign="space-between"
      verticalAlign="center"
    >
        <CommandBar items={items} styles={comandBarS} />
            <Stack styles={containerS} horizontal verticalAlign="center">
                <PostalCodeSFilter />
            </Stack>
    </Stack>;
}

export default ReceptionPlaceCommandBar;