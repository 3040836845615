import { LoginAction } from "./login-actions.types";
import { LoginActions } from "./login-actions.enum";

const initState = {
  loginErr: "",
  activationMessage: "",
  passResetOpen: false,
};

export const login = (state = initState, action: LoginAction) => {
  const { type, payload } = action;
  switch (type) {
    case LoginActions.SET_LOGIN_ERROR:
      return { ...state, loginErr: payload.message };
    case LoginActions.SET_ACTIVATION_MESSAGE:
      return { ...state, activationMessage: payload };
    case LoginActions.TOGGLE_PASS_RESET:
      return { ...state, passResetOpen: payload };
    case LoginActions.CLEAR_ERR:
      return { ...initState };
    default:
      return state;
  }
};
