import React from "react";
import { RootStateOrAny, useSelector, useDispatch } from "react-redux";
import { Panel } from "office-ui-fabric-react";
import { panelS } from "../../common/styles/panelStyles";
import ReceptionPlacesShowForm from "../components/ReceptionPlacesShowForm";
import { toggleReceptionPlacesPanel } from "../../../config/redux/receptionPlaces/receptionPlacesActions";

const ReceptionPlacesPanel: React.FC = () => {
    const dispatch = useDispatch();
    const { receptionPlacesPanelOpen } = useSelector(
        (state: RootStateOrAny) => state.receptionPlaces
    );

    const dismissPanel = () => {
        dispatch(toggleReceptionPlacesPanel({ isOpen: false }));
    };

    return (
        <Panel
            isOpen={receptionPlacesPanelOpen}
            closeButtonAriaLabel="Close"
            headerText={"Szczegóły punktu odbioru"}
            onDismiss={dismissPanel}
            type={3}
            styles={panelS}
            isHiddenOnDismiss={true}
        >
            <ReceptionPlacesShowForm />
        </Panel>
    );
};

export default ReceptionPlacesPanel;