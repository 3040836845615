import { createNewOrder, setOrderCorrecting, setOrderEditing, toggleSyncOrderPanel } from './../../../../config/redux/orders/ordersPanel/orderPanelActions';
import { setVehicle } from '../../../../config/redux/orders/ordersActions';
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  toggleCancelReason,
  reloadOrders,
  setRealizationDate,
  toggleOrderAccept
} from "../../../../config/redux/orders/ordersActions";
import { setDialogError, setListMessage } from './../../../../config/redux/alerts/alertsActions';
import { generateItems } from "../../actions/generateCommands/generateOrdersCommands";
import { checkQuantity } from "../../actions/checkQuantity";
import { setToRealization, acceptOrder, sendSMSToDrivers } from "../../../../connectors/orders/connectors/index";
import { toggleOrderPanel, toggleSMSPanel } from '../../../../config/redux/orders/ordersPanel/orderPanelActions';
import { Quantity } from '../../../common/models/quantity.model';

const message = "Operacja przebiegła pomyślnie"

export const useOrdersCommandBar = () => {
  const dispatch = useDispatch();
  const { selectedOrders, realizationDate, vehicle } = useSelector(
    (state: RootStateOrAny) => state.orders
  );
  const location = useLocation();
  const quantity = checkQuantity(selectedOrders.length);

  const pushToRealization = async () => {
    try{
      const mapedIds = selectedOrders.map((item: any) => item.id)
      const res = await setToRealization({
        orderRealizationDate: realizationDate,
        orderIds: mapedIds,
        vehicleId: vehicle,
      });
  
      dispatch(setRealizationDate({date: ""}))
      dispatch(setVehicle({vehicle: ""}))
      dispatch(reloadOrders(true))
      dispatch(setListMessage(message))
    } catch(err) {
      dispatch(setDialogError("Wybierz datę i pojazd"));
    } 
  };

  const setOrderAccepted = async () => {
    try {
      let orders = await Promise.all(
        selectedOrders.map(async ({id}: any) => {
          acceptOrder(id)
        })
      ) 
      dispatch(toggleOrderAccept(true));
      dispatch(setListMessage(message))
    } catch(err) {
      console.error(err);
    }
  };

  const setOrderCanceled = async () => {
    dispatch(toggleCancelReason(true));
  };

  const openPanel = () => {
    dispatch(setOrderCorrecting(false));
    dispatch(createNewOrder(false));
    dispatch(toggleOrderPanel(true));
    dispatch(setOrderEditing(true))
  };

  const openPanelToSee = () => {
    dispatch(toggleOrderPanel(true));
  };

  const openPanelSMS = () => {
    dispatch(toggleSMSPanel(true));
  };

  const openPanelSyncOrder = () => {
    dispatch(toggleSyncOrderPanel(true));
  };

  const addNewOrder = () => {
    dispatch(setOrderCorrecting(false));
    dispatch(createNewOrder(true));
    dispatch(toggleOrderPanel(true));
  }

  const openPanelToCorrect = () => {
    dispatch(createNewOrder(false));
    dispatch(setOrderCorrecting(true));
    dispatch(toggleOrderPanel(true));
  }

  const sendSMS = async () => {
    try {
      const mapedIds = selectedOrders.map((item: any) => item.id)
      const res = await sendSMSToDrivers(mapedIds);
      dispatch(setListMessage(message))
    } catch (err) {
      dispatch(setListMessage('Wystąpił błąd'))
      console.error(err);
    }
  };

  const actions = {
    pushToRealization,
    openPanel,
    setOrderCanceled,
    setOrderAccepted,
    addNewOrder,
    openPanelToSee,
    openPanelSMS,
    sendSMS,
    openPanelSyncOrder,
    openPanelToCorrect
  };

  const items = quantity != Quantity.NONE ? generateItems(quantity, location.pathname, actions, selectedOrders[0], dispatch) : generateItems(quantity, location.pathname, actions, undefined, dispatch);

  return {
    items,
  };
};