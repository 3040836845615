import * as React from "react";
import {
    CommandBar,
  } from "office-ui-fabric-react";

import {useUnactivePickupPointsCommandBar} from "../hooks/settings/useUnactivePickupPointsCommandBar"
import { comandBarS} from "../styles/commandBar.styles"
 
const UnactivePickupPointsCommandBar: React.FC = () => {
    const {items} = useUnactivePickupPointsCommandBar()
  
    return <CommandBar items={items} styles={comandBarS} />;
}
 
export default UnactivePickupPointsCommandBar;