import * as React from "react";
import {
  Stack,
  IStackStyles,
  getTheme,
} from "office-ui-fabric-react";
import Logo from "./Logo";
import BCrumb from "./BCrumb";
import Interface from "./Interface";
import { reloadData } from "../../../../config/redux/clients/clientsActions"
import { reloadOrders } from "../../../../config/redux/orders/ordersActions"
import { reloadData as reloadStoreDocs } from "../../../../config/redux/storeDocs/storeDocsActions"
import { useLocation } from "react-router-dom";
import { reloadStoreItems } from "../../../../config/redux/store/storeAction";
import SearchBoxCommon from "./SearchBoxCommon";
import { reloadReceptionPlaces } from "../../../../config/redux/receptionPlaces/receptionPlacesActions";
import { reloadContainers } from "../../../../config/redux/container/containersActions";
import { reloadDetailedPrice } from "../../../../config/redux/detailedPrice/detailedPriceActions";

const theme = getTheme();

const containerS: IStackStyles = {
  root: {
    backgroundColor: theme.palette.themePrimary,
    width: "100%",
    height: "50px",
    padding: "10px 25px",
    zIndex: 2000,
    position: "fixed",
    top: 0,
    left: 0,
    boxShadow: '0 5px 5px -5px #aaa',
  },
};

const sectionS: IStackStyles = {
  root: {
    width: "100%",
    marginRight: "70px",
  },
};

const Header: React.FC = () => {
  const location = useLocation()

  return (
    <>
    <Stack
      styles={containerS}
      verticalAlign="center"
      horizontal
      horizontalAlign="space-between"
    >
      <Logo />
      <Stack
        horizontal
        horizontalAlign="space-between"
        verticalAlign="center"
        styles={sectionS}
      >
        <BCrumb />
        {(location.pathname.split("/")[1] === "clients" && location.pathname.split("/")[2] !== "receptionPlaces") && <SearchBoxCommon reload={reloadData} placeholder={"Szukaj klienta"} />}
        {(location.pathname.split("/")[1] === "clients" && location.pathname.split("/")[2] === "receptionPlaces") && <SearchBoxCommon reload={reloadReceptionPlaces} placeholder={"Szukaj punktu odbioru"} />}
        {location.pathname.split("/")[1] === "orders" && <SearchBoxCommon reload={reloadOrders} placeholder={"Szukaj zamówienia"} />}
        {location.pathname.split("/")[1] === "validators" && <SearchBoxCommon reload={reloadOrders} placeholder={"Szukaj walidatorów"} />}
        {location.pathname.includes("priceList/detailedPriceList") && (<SearchBoxCommon reload={reloadDetailedPrice} placeholder={"Szukaj"} />)}
        {(location.pathname.split("/")[1] === "store" && location.pathname.split("/")[2] === "docs") && <SearchBoxCommon reload={reloadStoreDocs} placeholder={"Szukaj dokumentu"} />}
        {(location.pathname.split("/")[1] === "store" && location.pathname.split("/")[2] === "list") && <SearchBoxCommon reload={reloadStoreItems} placeholder={"Szukaj magazynu"} />}
        {(location.pathname.split("/")[1] === "store" && location.pathname.split("/")[2] === "containers") && <SearchBoxCommon reload={reloadContainers} placeholder={"Szukaj konteneru"} />}
      </Stack>
      <Interface />
    </Stack>
    </>
  );
};

export default Header;