import { mergeStyles } from "office-ui-fabric-react";
import * as React from "react";
import ListSuccessMessage from "../../common/layout/ListSuccessMessage";
import ReasonsList from "./list/ReasonsList";
import ReasonPanel from "./panel/ReasonPanel";

const padding = mergeStyles({
  paddingTop: 15
})

const CancellationResons: React.FC = () => {
  return (
    <>
      <div className={padding}>
        <ListSuccessMessage />
      </div>
      <ReasonsList />
      <ReasonPanel />
    </>
  );
};

export default CancellationResons;
