import * as React from 'react';
import {
  IRenderFunction,
  IPanelProps,
  Stack,
  IStackStyles,
  getTheme
} from "office-ui-fabric-react";
import OrderPanelCommandBar from "../../commandBar/components/orders/panel/OrderPanelCommandBar";

const theme = getTheme()

const navS: IStackStyles = {
  root: {
    width: "100%",
    borderBottom: `2px solid ${theme.palette.neutralLighter}`,
  },
};

export const RenderNavigation: IRenderFunction<IPanelProps> = ((props, defaultRender) => (
  <Stack horizontal styles={navS} horizontalAlign="space-between">
    <OrderPanelCommandBar />
    {defaultRender!(props)}
  </Stack>
)
);