enum NotificationChannel {
  Sms,
  Email
}

enum NotificationStatus {
  inProgress,
  Sent,
  Delivered,
  Error,
}

enum NotificationType {
  NewOrders,
  ExpiredOrders,
  NewCompaniesToVerify,
  UsersToVerify,
  ReceptionPlaceToVerify,
  OrderForDriver
}

export const generateSMSHistoryColumns = (isMultiline: boolean) => {
  return [
    {
      key: "sentDate",
      name: "Data wysłania",
      fieldName: "sentDate",
      minWidth: 80,
      maxWidth: 140,
      isResizable: true,
    },
    {
      key: "status",
      name: "Status",
      fieldName: "status",
      minWidth: 50,
      maxWidth: 100,
      isResizable: true,
    },
    {
      key: "channel",
      name: "Kanał",
      fieldName: "channel",
      minWidth: 40,
      maxWidth: 80,
    },
    {
      key: "type",
      name: "Rodzaj",
      fieldName: "type",
      minWidth: 70,
      maxWidth: 140,
      isResizable: true,
    },
    {
      key: "body",
      name: "Treść",
      fieldName: "body",
      minWidth: 50,
      maxWidth: 100,
      isResizable: true,
      isMultiline: isMultiline
    },

  ];
};

export const generateSMSHistory = (Item: any) => {
  const items: Array<any> = [];
  Item.forEach((Item: any) => {
    items.push({
      key: Item.id,
      id: Item.id,
      body: Item.body,
      channel: parseNotificationChannel(Item.channel),
      status: parseNotificationStatus(Item.status),
      type: parseNotificationType(Item.type),
      sentDate: new Date(Item.sentDate).toISOString().replace(/\.\d+/, "").replace(/T|Z/g, ' '),
    });
  });

  return [...items];
};

const parseNotificationChannel = (key: any) => {
  switch (key) {
    case NotificationChannel.Sms: {
      return "SMS";
    }
    case NotificationChannel.Email: {
      return "Email";
    }
    default: {
      return "Nieznany";
    }
  }
}

const parseNotificationStatus = (key: any) => {
  switch (key) {
    case NotificationStatus.inProgress: {
      return "W trakcie";
    }
    case NotificationStatus.Sent: {
      return "Wysłano";
    }
    case NotificationStatus.Delivered: {
      return "Dostarczono";
    }
    case NotificationStatus.Error: {
      return "Błąd";
    }
    default: {
      return "Nieznany";
    }
  }
}

const parseNotificationType = (key: any) => {
  switch (key) {
    case NotificationType.NewOrders: {
      return "Nowe zamówienia";
    }
    case NotificationType.ExpiredOrders: {
      return "Zamówienia po terminie";
    }
    case NotificationType.NewCompaniesToVerify: {
      return "Nowe firmy do weryfikacji";
    }
    case NotificationType.UsersToVerify: {
      return "Użytkownicy do weryfikacji";
    }
    case NotificationType.OrderForDriver: {
      return "Zamówienie dla kierowcy";
    }
    default: {
      return "Nieznany";
    }
  }
}