import { IQuantity, Quantity } from "../../../common/models/quantity.model";
import { OrderTypeString } from "../../../common/enums/orderTypes.enum";

export const generateItems = (
  quantity: IQuantity,
  actions: any,
  orderTypes: Array<any>,
  selectedOrderTypes: Array<any>
) => {
  switch (quantity) {
    case Quantity.NONE:
      const itemsArr :any[] = [];
      orderTypes.map(({ id, name }) => {
        let icon = { iconProps: {} };

        if (name === OrderTypeString.Vehicle) {
          icon = { iconProps: { iconName: "Car" } };
        } else if (name === OrderTypeString.Container) {
          icon = { iconProps: { iconName: "Inbox" } };
        } else if (name === OrderTypeString.Weight) {
          icon = { iconProps: { iconName: "Weights" } };
        }
        
        if(name !== OrderTypeString.Container){
          itemsArr.push({
            key: id,
            id: id,
            text: name,
            iconProps: icon.iconProps,
            onClick: actions.addOrderType,
          });
        }
      });
      return [
        {
          key: "add",
          text: "Dodaj typ",
          iconProps: { iconName: "add" },
          subMenuProps: {
            items: [...itemsArr],
          },
        },
      ];
    case Quantity.ONE:   
      var selectedOrderTypeItem = selectedOrderTypes[0];
      var orderType = orderTypes.filter(x => x.id === selectedOrderTypeItem.orderTypeId)[0]; 

      if (orderType.name === OrderTypeString.Container) {
        return [
          {
            key: "edit",
            text: "Edytuj",
            iconProps: { iconName: "edit" },
            onClick: actions.edit,
          }
        ];
      } else {        
        return [
          {
            key: "edit",
            text: "Edytuj",
            iconProps: { iconName: "edit" },
            onClick: actions.edit,
          },
          {
            key: "delete",
            text: "Usuń",
            iconProps: { iconName: "delete" },
            name: "delete",
            onClick: actions.deleteOrderTypes,
          },
        ];
      }
    default:
      return [];
  }
};
