export enum ClientPanelActions {
    TOGGLE_EDITING = "toggleEditing",
    SET_ACTIVE_TAB = "setActiveTab",
    SET_ACTIVE_USER = "setActiveUser",
    SET_ACTIVE_DEPARTMENT = "setActiveDepartment",
    SET_ACTIVE_PICKUPPOINT = "setActivePickUpPoint",
    SET_ACTIVE_FILES = "setActiveFiles",
    SET_SELECTED_IDS = "setSelectedIds",
    SET_SUCCESS_MESSAGE ="setPanelSuccesMessage",
    RELOAD = "reload",
    CLEAR = "clear",
    REFETCH = "refetchData",
    SET_BASIC_INFO = "setBasicInfo",
    TOGGLE_CLIENTS_PANEL="toggleClientsPanel"

  }