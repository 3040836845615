import * as React from "react";
import { IContainerHistoryPoint } from "../interfaces/IContainerHistoryPoint";
import { wrapperStyles } from "../../storeMap/styles/style";

export interface IContainerHistoryPopupHtml {
    containerHistoryPoint?: IContainerHistoryPoint;
}

export const ContainerHistoryPopupHtml: React.FC<IContainerHistoryPopupHtml> = ({ containerHistoryPoint }) => {
    return (
        <div style={wrapperStyles.popupStyles}>
            <div style={wrapperStyles.popupStyles}>
                {
                    containerHistoryPoint != null && containerHistoryPoint.firstActiveDateStr != containerHistoryPoint.lastActiveDateStr ?
                        <>
                            Pierwszy odczyt: {containerHistoryPoint != null ? containerHistoryPoint.firstActiveDateStr : ""}< br />
                            Ostatni odczyt: {containerHistoryPoint != null ? containerHistoryPoint.lastActiveDateStr : ""}< br />
                        </>
                        :
                        <>
                            Odczyt: {containerHistoryPoint != null ? containerHistoryPoint.lastActiveDateStr : ""}
                        </>
                }
            </div>
        </div>
    )
}