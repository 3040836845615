import * as React from "react";
import {
  CommandBar,
  ICommandBarStyles,
} from "office-ui-fabric-react";

import { usePanelCommandBar } from "../../hooks/clients/usePanelCommandBar"

const commandBarS: ICommandBarStyles = {
  root: {
    width: 500,
  },
};


const ClientPanelComandBar: React.FC = () => {
  const {items} = usePanelCommandBar();


  return <CommandBar items={items} styles={commandBarS} />;
};

export default ClientPanelComandBar;


