import * as React from "react";
import { connect, RootStateOrAny } from "react-redux";
import {
  DetailsListLayoutMode,
  Selection,
  IColumn,
  SelectionMode,
} from "office-ui-fabric-react/lib/DetailsList";
import { MarqueeSelection } from "office-ui-fabric-react/lib/MarqueeSelection";
import { ShimmeredDetailsList, Stack } from "office-ui-fabric-react";
import {
  IProps,
  IValidatorsListState,
  IStateProps,
  IDispatchProps,
  IValidatorsListProps,
} from "../models/ValidatorsList.types";
import { footer, listS, listContainerS } from "../styles/ValidatorsList.styles";
import Pagination from "../providers/Pagination";
import { IRenderProps } from "../providers/Pagination.types";
import {
  setActiveValidator,
  setSelectedValidators,
} from "../../../config/redux/validators/validatorsActions";
import { toggleValidatorPanel } from "../../../config/redux/validators/validatorsPanel/validatorPanelActions";
import { generateColumns } from "../actions/generateColums";
import { NavigationPath } from "../../../config/routing/NavigationPath";
import {
  sort,
  toggleFiltration,
} from "../../../config/redux/filters/filterActions";

class ValidatorsList extends React.Component<IProps, IValidatorsListState> {
  private _selection: Selection;
  private _columns: IColumn[];
  private _generatedColumns: IColumn[];
  private _onItemInvoked: (item?: any, index?: number, e?: Event) => void;
  private _onColumnHeaderClick: (
    ev?: React.MouseEvent<HTMLElement>,
    column?: IColumn
  ) => void;
  protected _footerRef: React.RefObject<HTMLDivElement>;

  constructor(props: IProps) {
    super(props);

    this._selection = new Selection({
      onSelectionChanged: () => {
        if (!this.props.validatorPanelOpen) {
          const items = this._getSelectedKeys();
          const data = { selectedValidators: [...items] };
          props.dispatch(setSelectedValidators(data));
        }
      },
    });

    this._footerRef = React.createRef();

    this._generatedColumns = generateColumns(this.props.pathname) || [];

    this._onItemInvoked = (item: any) => {
      if (this.props.pathname !== NavigationPath.RECEPTION_PLACES_VALIDATORS) {
        props.dispatch(setActiveValidator(item));
        props.dispatch(toggleValidatorPanel(true));
      }
    };

    this._columns = [...this._generatedColumns];

    this._onColumnHeaderClick = (item: any, column: any) => {
      const newColumns: IColumn[] = this._columns.slice();

      const currColumn: IColumn = newColumns.filter(
        (currCol) => column.key === currCol.key
      )[0];
      newColumns.forEach((newCol: IColumn) => {
        if (newCol === currColumn) {
          currColumn.isSortedDescending = !currColumn.isSortedDescending;
          currColumn.isSorted = true;
        } else {
          newCol.isSorted = false;
          newCol.isSortedDescending = true;
        }
      });

      props.dispatch(toggleFiltration({ filter: true }));
      props.dispatch(sort({ column: currColumn }));

      this.setState({
        _cols: newColumns,
      });
    };

    this.state = {
      items: [],
      loading: false,
      _cols: this._columns,
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    const finishedAtColumn = this._columns.find(column => column.key === "createdDate");
  
    if (finishedAtColumn) {
      finishedAtColumn.isSortedDescending = true;
      dispatch(sort({ column: finishedAtColumn }));
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;

    dispatch(setSelectedValidators({ selectedValidators: [] }));
  }

  componentDidUpdate(prevprops: any) {
    const {
      reloadValidators,
      remove,
      validatorAccepted,
      validatorCanceled,
      selectedValidators,
    } = this.props;
    if (reloadValidators || remove || validatorAccepted || validatorCanceled) {
      this._selection.selectToKey("", true);
    }

    if (!selectedValidators[0]) {
      this._selection.selectToKey("", true);
    }

  }

  public render(): JSX.Element {
    return (
      <>
        <Pagination
          footerRef={this._footerRef}
          render={({ data, loading }: IRenderProps) => (
            <>
              <h1>{loading}</h1>
              <Stack styles={listContainerS}>
                <MarqueeSelection
                  isEnabled={!this.props.isPanelOpen}
                  selection={this._selection}
                >
                  <ShimmeredDetailsList
                    items={data}
                    columns={this._columns}
                    setKey="items"
                    layoutMode={DetailsListLayoutMode.justified}
                    selection={this._selection}
                    selectionPreservedOnEmptyClick={true}
                    onItemInvoked={this._onItemInvoked}
                    usePageCache={true}
                    styles={listS}
                    onColumnHeaderClick={this._onColumnHeaderClick}
                    enableShimmer={loading}
                    selectionMode={SelectionMode.single}
                  />
                </MarqueeSelection>
              </Stack>
              <div className={footer} ref={this._footerRef} />
            </>
          )}
        />
      </>
    );
  }

  private _getSelectedKeys = () => {
    const keys = this._selection.getSelection();
    return keys;
  };
}
const mapStateToProps = (state: RootStateOrAny) => state.validators;

export default connect<IStateProps, IDispatchProps, IValidatorsListProps>(
  mapStateToProps
)(ValidatorsList);