export const allOrdersColumns = [
    {
      key: "Number",
      name: "Numer zamówienia",
      fieldName: "number",
      minWidth: 100,
      maxWidth: 150,
      isResizable: true,
    },
    {
      key: "CreatedAt",
      name: "Data zamówienia",
      fieldName: "createdAt",
      minWidth: 100,
      maxWidth: 120,
      isResizable: true,
    },
    {
      key: "Company",
      name: "Klient",
      fieldName: "company",
      minWidth: 150,
      maxWidth: 250,
      isResizable: true,
    },
    {
      key: "ReceptionPlace",
      name: "Punkt Odbioru",
      fieldName: "receptionPlace",
      minWidth: 150,
      maxWidth: 400,
      isResizable: true,
    },
    {
      key: "Type",
      name: "Typ",
      fieldName: "type",
      minWidth: 100,
      maxWidth: 150,
      isResizable: true,
    },
    {
      key: "CurrentStatus",
      name: "Status zamówienia",
      fieldName: "status",
      minWidth: 100,
      maxWidth: 150,
      isResizable: true,
    },
    {
      key: "ContactPhone",
      name: "Numer kontaktowy",
      fieldName: "contactPhone",
      minWidth: 50,
      maxWidth: 100,
      isResizable: true,
    },
  ];