import { useEffect, useState } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { getContainerInfo } from "../../../connectors/containers/connectors";
import { IContainerPhoto } from "../interfaces/IContainerPhoto";
import moment from "moment";

export const useContainerFotos = () => {

    const { selectedContainers } = useSelector(
        (state: RootStateOrAny) => state.containers
    );
    
    const [isLoading, setIsLoading] = useState(false)
    const [containerPhotos, setContainerPhotos] = useState<IContainerPhoto[]>([])
    const [photosAmount, setPhotosAmount] = useState<number>(0);
    
    useEffect(() => {
        setContainerPhotosFromApi()
    }, [])

    const setContainerPhotosFromApi = async () => {
        setIsLoading(true);
        var containerInfo = await getContainerInfo(selectedContainers[0] ? selectedContainers[0].id : "")

        if (containerInfo && containerInfo.data && containerInfo.data.data && containerInfo.data.data.photos && containerInfo.data.data.photos.length > 0) {
            var containerPhotos: IContainerPhoto[] = []
            containerInfo.data.data.photos.map((photoFromApi: IContainerPhoto) => {
                let photo: IContainerPhoto = {
                        fileUrl: photoFromApi.fileUrl,
                        originalFileName: photoFromApi.originalFileName,
                        createdDate: photoFromApi.createdDate,
                        createdDateString: moment(photoFromApi.createdDate).format('DD.MM.YYYY')
                }
                containerPhotos.push(photo)
            })

            setContainerPhotos(containerPhotos)
            setPhotosAmount(containerPhotos.length)

        }
        setIsLoading(false)
    }

    return {
        containerPhotos,
        photosAmount,
        isLoading
    }
}