import * as React from "react";
import {
  CommandBar,
} from "office-ui-fabric-react";

import {useUsersCommandBar} from "../../hooks/settings/useUsersCommandBar"
import { comandBarS} from "../../styles/commandBar.styles"
 
const UsersCommandBar: React.FC = () => {
    const {items} = useUsersCommandBar()
  
    return <CommandBar items={items} styles={comandBarS} />;
}
 
export default UsersCommandBar;