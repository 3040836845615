import * as React from "react";
import { IContainerHistoryPoint } from "../interfaces/IContainerHistoryPoint";
import { wrapperStyles } from "../../storeMap/styles/style";

export interface IContainerDetailPopupHtml {
    containerDetail?: any;
}

export const ContainerDetailPopupHtml: React.FC<IContainerDetailPopupHtml> = ({ containerDetail }) => {
    return (
        <div style={wrapperStyles.popupStyles}>
            <div style={wrapperStyles.popupStyles}>
                Ostatni odczyt: {containerDetail.lastActiveDateStr}< br />
                {(containerDetail.imei != undefined && containerDetail.imei != 0) ? <>IMEI: {containerDetail.imei} < br /> </> : <></>}
                {containerDetail.iccid != undefined ? <>Iccid: {containerDetail.iccid} < br /> </> : <></>}
            </div>
        </div>
    )
}