import { IQuantity, Quantity } from "../../../../common/models/quantity.model";

export const generateAll = (quantity: IQuantity, actions: any) => {
  switch (quantity) {
    case Quantity.NONE:
      return [];
    case Quantity.ONE:
      return [
        {
          key: "see",
          text: "Zobacz",
          iconProps: { iconName: "textDocument" },
          onClick: actions.openPanel,
        },
      ];
    case Quantity.MANY:
      return [];
  }
};
