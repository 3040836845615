import { Depths, FontSizes, getTheme, ITextStyles, mergeStyles, mergeStyleSets, Spinner, SpinnerSize, Stack, Text } from '@fluentui/react';
import { FontIcon } from 'office-ui-fabric-react';
import * as React from 'react';

interface StatisticTileProps {
    icon: string;
    value: string | number;
    backgroundColor: string;
    valueLabel: string;
}

interface StatisticTileState {
    // width: number;
    // height: number;
}

class StatisticTile extends React.Component<StatisticTileProps, StatisticTileState> {
    constructor(props: StatisticTileProps) {
        super(props);
        
        this.state = {

        };
    }

    public render(): JSX.Element {
        return <div>{this._basicExample()}</div>;
    }

    private _basicExample(): JSX.Element {
        const theme = getTheme();
        const iconClass = mergeStyles({
            fontSize: 32,
            margin: '10px auto 14px auto',
        });
        const classNames = mergeStyleSets({
            iconS: [{ color: theme.palette.neutralPrimary }, iconClass],
        });
        const tileS = mergeStyles({
            cursor: 'pointer',
            backgroundColor: '#fff',
            width: '200px',
            height: '100px',
            border: "1px solid rgba(204,204,204,.8)",
            boxShadow: Depths.depth4,
            selectors: {
                ":hover": {
                    border: "1px solid rgba(204,204,204,.8)",
                    boxShadow: Depths.depth8,
                },
            },
        });
        const statsTile = mergeStyles({
            cursor: 'default',
            backgroundColor: '#fff',
            width: '210px',
            height: '120px',
            padding: '10px',
            border: "1px solid rgba(204,204,204,.8)",
            boxShadow: Depths.depth4,
            justifyContent: 'space-between',
            selectors: {
                ":hover": {
                    boxShadow: Depths.depth4,
                },
            },
        });
        const descriptionTextStyles: ITextStyles = {
            root: {
                color: '#323130',
                whiteSpace: 'nowrap',
                fontSize: FontSizes.size14,
                textAlign: 'center',
                selectors: {
                    'strong': {
                        display: 'block',
                        fontSize: FontSizes.size16,
                    }
                }
            },
        };

        const { backgroundColor, value, valueLabel, icon } = this.props;

        return (
            <Stack className={`${tileS} ${statsTile}`} style={{backgroundColor: backgroundColor + '33'}}>
                <FontIcon iconName={icon} className={classNames.iconS} />
                <Text styles={descriptionTextStyles}>
                    {valueLabel}
                    { !!value || value === 0 ? <strong>{typeof value === 'number' ? value.toString() : value}</strong> :
                        <Spinner size={SpinnerSize.small} style={{padding: '10px 0'}} />
                    }
                </Text>
            </Stack>
        );
    }
}

export default StatisticTile;