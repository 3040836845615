import React from "react";
import InputHeader from "../../InputHeader"
import CompanyFields from "./Company"
import DepartmentsFields from "../department/DepartmentsFields"
import UserFields from "../user/UserFields"

interface IInputSectionProps {
    requireCompanyData?: boolean;
    isPolishCompany: boolean
}

const InputSection: React.FC<IInputSectionProps> = ({ requireCompanyData, isPolishCompany }) => {
    return (
        <>
            <InputHeader text={"Dane użytkownika"} />
            <UserFields />
            {requireCompanyData ? (
                <>
                    <InputHeader text={"Dane firmy"} />
                    <CompanyFields requireCompanyData={requireCompanyData} isPolishCompany={isPolishCompany} />
                    <DepartmentsFields />
                </>
            ) : <DepartmentsFields />}
        </>
    );
};

export default InputSection;