import { IQuantity, Quantity } from "../../../common/models/quantity.model";

export const generateItems = (
  quantity: IQuantity,
  actions: any,
  selectedUnactiveUser: any,
  activatedUsers: any
) => {
  switch (quantity) {
    case Quantity.NONE:
        return [
           
          ];
    case Quantity.ONE:
      if (selectedUnactiveUser && activatedUsers[selectedUnactiveUser.id]) {
        return [
           
        ];
      } else {
        
        return [
          {
            key: "activate",
            text: "Aktywuj",
            iconProps: { iconName: "ReminderPerson" },
            onClick: actions.activateUserById,
          }
        ];
      }
      
    default:
      return [];
  }
};
