import * as React from "react";
import {
    CommandBar,
} from "office-ui-fabric-react";
import { comandBarS } from "./../../../features/commandBar/styles/commandBar.styles";
import { useReportsCommandBar } from "./../hooks/useReportsCommandBar";

const ReportsCommandBar: React.FC = () => {
    const { items } = useReportsCommandBar()

    return <CommandBar items={items} styles={comandBarS} />;
}

export default ReportsCommandBar;