import React from "react";
import { Formik, Form } from "formik";
import { Stack } from "office-ui-fabric-react";
import { validationSchemaSyncOrder } from "../../actions/validationSchemas";
import NewFormField from "../../../common/layout/form/NewFormField";
import NewFormBtns from "../../../common/layout/form/NewFormBtns";
import ActivityIndicator from "../../../common/layout/ActivityIndicator";
import MessageContainer from "../../../auth/components/register/forms/MessageContainer";
import { useSyncOrderForm } from "../../hooks/useSyncOrderForm";

const SyncOptimaForm: React.FC = () => {
  const {
    handleSubmit,
    initValues,
    cancelForm,
    loading
  } = useSyncOrderForm();

  return (
    <Formik
      initialValues={initValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchemaSyncOrder}
    >
      {({ handleSubmit }) => (
        <Form translate="yes" onSubmit={handleSubmit}>
          <Stack>
            <NewFormField name="numberOptima" label="Numer z Optimy" placeholder="Numer" />
            <NewFormBtns
              cancelForm={cancelForm}
              submitBtnText={"Synchronizuj"}
              disabled={loading}
              positionAbsolute={true}
            />
          </Stack>
          {loading ? <ActivityIndicator/> : <MessageContainer/>}
        </Form>
      )}
    </Formik>
  );
};

export default SyncOptimaForm;


