import * as React from 'react';

import SideNav from "./SideNav"
import {validatorsPaths} from "../models/PivotNavPaths"
 
const ValidatorsNav: React.SFC = () => {
    return (  
        <SideNav items={validatorsPaths}/>
    );
}
 
export default ValidatorsNav;