import React from "react";
import { Form, Formik } from "formik";
import { mergeStyles } from "office-ui-fabric-react";

import NewFormBtns from "../../../common/layout/form/NewFormBtns";
import NewFormField from "../../../common/layout/form/NewFormField";
import { resetPassSchema } from "../../actions/resetPassSchema";

const wrapper = mergeStyles({
  marginTop: 20,
});

export interface ResetPassFormProps {
  handleReset: any;
  loading: boolean;
  cancelForm: () => void
}

const ResetPassForm: React.FC<ResetPassFormProps> = ({
  handleReset,
  loading,
  cancelForm
}) => {
  return (
    <div className={wrapper}>
      <Formik
        initialValues={{}}
        validationSchema={resetPassSchema}
        onSubmit={handleReset}
      >
        {({ handleSubmit }) => (
          <Form translate="yes" onSubmit={handleSubmit}>
            <NewFormField name="password" label="hasło" type="password" />
            <NewFormField
              name="passwordConfirm"
              label="Powtórz hasło"
              type="password"
            />
            <NewFormBtns
              submitBtnText={"Resetuj"}
              cancelBtnText={"Wróć"}
              disabled={loading}
              cancelForm={cancelForm}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ResetPassForm;
