import * as React from "react";
import { useSelector, RootStateOrAny } from "react-redux";
import BasicList from "../../common/layout/BasicList";
import {
  setActiveVehicle,
  setSelectedVehicles,
  toggleVehiclePanel,
  reloadVehicles,
  addNewVehicle,
} from "../../../config/redux/vehicles/vehiclesActions";
import { getVehicles } from "../../../connectors/vehicles/connectors/index";
import { columns } from "./vehicleColumns";

const Temp: React.SFC = () => {
  const {
    selectedVehicles,
    activeVehicle,
    reload,
    open,
    addVehicle,
  } = useSelector((state: RootStateOrAny) => state.vehicles);

  const parseVehicles = (items: Array<any>) => {
    const vehicles = items.map((item: any) => ({
      ...item.vehicleData,
      ...item.driverData,
    }));
    return vehicles;
  };

  return (
    <>
      <BasicList
        setSelectedItems={setSelectedVehicles}
        setActiveItem={setActiveVehicle}
        togglePanel={toggleVehiclePanel}
        reload={reloadVehicles}
        editList={addNewVehicle}
        getData={getVehicles}
        activeItem={activeVehicle}
        panelOpen={open}
        selectedItems={selectedVehicles}
        removeItems={reload}
        listEdited={addVehicle}
        columns={columns}
        parseItems={parseVehicles}
      />
    </>
  );
};

export default Temp;
