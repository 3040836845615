import { ReceptionPlacesActions } from './receptionPlacesActions.enum'
import { IOpenPanel, IPostalCode, IReceptionPlace, IReload } from './receptionPlacesActions.types'

export const setReceptionPlaces = (data: Array<IReceptionPlace>) => {
  return {
    type: ReceptionPlacesActions.SET_RECEPTIONPLACES,
    payload: data
  }
}

export const reloadReceptionPlaces = (data: IReload) => {
  return {
    type: ReceptionPlacesActions.RELOAD_DATA,
    payload: data
  }
}

export const setPostalCode = (data: IPostalCode) => {
  return {
    type: ReceptionPlacesActions.SET_POSTAL_CODE,
    payload: data,
  };
};

export const toggleReceptionPlacesPanel = (data: IOpenPanel) => {
  return {
    type: ReceptionPlacesActions.TOGGLE_PANEL,
    payload: data
  }
}