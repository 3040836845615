import React from "react";
import { useSelector, RootStateOrAny } from "react-redux";
import {
  Stack,
  Text,
  ITextStyles,
  IStackStyles,
  mergeStyles,
} from "office-ui-fabric-react";
import { FontSizes } from "@uifabric/fluent-theme";
import { FontWeights } from "office-ui-fabric-react";

import MessageContainer from "../../../../auth/components/register/forms/MessageContainer";
import FormHeader from "../FormHeader";
import { DetailsList, IColumn, SelectionMode } from "@fluentui/react";
import { LangEnumToCountryName } from "../../../../common/converters/language";

interface ListItem {
  id: number | null;
  value: number;
  dateFrom: Date | string;
  dateTo: Date | string;
  orderTypeItemId: number;
  orderTypeItemName?: string;
}

const filedS: ITextStyles = {
  root: {
    fontSize: FontSizes.size16,
    fontWeight: FontWeights.semibold,
    width: 200,
    marginRight: 25,
  },
};

const textS: ITextStyles = {
  root: {
    fontSize: FontSizes.size14,
    width: 400,
  },
};

const wrapperS: IStackStyles = {
  root: {
    marginTop: 45,
    marginLeft: 10,
  },
};

const textContainerS: IStackStyles = {
  root: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: "20px",
    paddingBottom: "20px",
  },
};

enum FieldNames {
  COMPANY_NAME = "Nazwa firmy",
  SHORT_NAME = "Skrócona nazwa",
  ADRESS = "Adres",
  NIP = "NIP",
  BDO = "BDO",
  EMAIL = "Email",
  PHONE = "Telefon",
  CONTACT = "Osoba kontaktowa",
  ORDERS_TYPES = "Typ obsługiwanych zamówień",
  PAYMENT_METHOD = "Typ płatności",
  // ORDER_TYPE = "Typ klienta"
  COUNTRY = "Kraj"
}

const margin = mergeStyles({
  marginTop: 20,
});

const ClientBasicInfo: React.FC = () => {
  const { basicInfo } = useSelector(
    (state: RootStateOrAny) => state.clientPanel
  );

  // function getOrderItem(orderItemId? : number){
  //   if(orderItemId === null)
  //     return "Brak";
  //   else if(orderItemId === 1)
  //   {
  //     return "Pojazd";
  //   }
  //   else if(orderItemId === 2){
  //     return "Tonaż";
  //   }
  //   else if(orderItemId === 3){
  //     return "Kontener";
  //   }      
  //   else{
  //     return ""
  //   }  
  // }

  const {
    basicInformations: { shortName, bdoNumber, companyName, taxNumber, paymentType, country },
    address,
    specialPrices
  } = basicInfo;


  const items = [
    {
      name: FieldNames.COMPANY_NAME,
      value: companyName,
    },
    {
      name: FieldNames.SHORT_NAME,
      value: shortName,
    },
    { name: FieldNames.BDO, value: bdoNumber },
    { name: FieldNames.NIP, value: taxNumber },
    { name: FieldNames.PAYMENT_METHOD, value: paymentType === 1 ? "Przelew" : "Gotówka" },
    // { name: FieldNames.ORDER_TYPE, value: getOrderItem(orderTypeId)}
    { name: FieldNames.COUNTRY, value: LangEnumToCountryName(country) }
  ];

  const columns: IColumn[] = [
    {
      key: "orderTypeItemName",
      name: "Podkategoria",
      fieldName: "orderTypeItemName",
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },
    {
      key: "dateFrom",
      name: "Data początkowa",
      fieldName: "dateFrom",
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      onRender: (item: ListItem) => (
        <span>{formatDate(new Date(item.dateFrom))}</span>
      ),
    },
    {
      key: "dateTo",
      name: "Data końcowa",
      fieldName: "dateTo",
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      onRender: (item: ListItem) => (
        <span>{formatDate(new Date(item.dateTo))}</span>
      ),
    },
    {
      key: "value",
      name: "Cena",
      fieldName: "value",
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },
  ];

  const formatDate = (date?: Date) => {
    if (!date) return "";
    const day = ("0" + date.getDate()).slice(-2);
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };

  return (
    <Stack styles={wrapperS}>
      <FormHeader text="Informacje podstawowe" />
      {items.map(({ name, value }) => {
        return (
          <Stack horizontal styles={textContainerS} key={name}>
            <Text styles={filedS}>{name}</Text>
            <Text styles={textS}>{value}</Text>
          </Stack>
        );
      })}
      {address && (
        <div className={margin}>
          <Stack horizontal styles={textContainerS}>
            <Text styles={filedS}>Miejscowość</Text>
            <Text styles={textS}>{address.city}</Text>
          </Stack>
          <Stack horizontal styles={textContainerS}>
            <Text styles={filedS}>Ulica i numer</Text>
            <Text styles={textS}>{address.street}</Text>
          </Stack>
          <Stack horizontal styles={textContainerS}>
            <Text styles={filedS}>Kod pocztowy</Text>
            <Text styles={textS}>{address.postalCode}</Text>
          </Stack>
        </div>
      )}
      {specialPrices.length > 0 &&
        <div style={{ marginTop: '30px' }}>
          <FormHeader text="Ceny specjalne" />
          <DetailsList
            items={specialPrices}
            columns={columns}
            selectionMode={SelectionMode.none}
          />
        </div>
      }
      {/* <CloseBtn/> */}
      <div className={margin}>
        <MessageContainer />
      </div>
    </Stack>
  );
};

export default ClientBasicInfo;