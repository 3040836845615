export const invoicedOrdersColumns = [  
    {
      key: "Number",
      name: "Numer zamówienia",
      fieldName: "number",
      minWidth: 50,
      maxWidth: 150,
      isResizable: true,
    },
    {
      key: "CreatedAt",
      name: "Data zamówienia",
      fieldName: "createdAt",
      minWidth: 50,
      maxWidth: 150,
      isResizable: true,
    },
    {
      key: "FinishedAt",
      name: "Data zakończenia",
      fieldName: "finishedAt",
      minWidth: 50,
      maxWidth: 150,
      isResizable: true,
    },
    {
      key: "Company",
      name: "Klient",
      fieldName: "company",
      minWidth: 150,
      maxWidth: 250,
      isResizable: true,
    },
    {
      key: "ReceptionPlace",
      name: "Punkt Odbioru",
      fieldName: "receptionPlace",
      minWidth: 100,
      maxWidth: 150,
      isResizable: true,
    },
    {
      key: "Operator",
      name: "Operator",
      fieldName: "operator",
      minWidth: 50,
      maxWidth: 100,
      isResizable: true,
    },
    {
      key: "Type",
      name: "Typ",
      fieldName: "type",
      minWidth: 50,
      maxWidth: 100,
      isResizable: true,
    },
    {
      key: "Vehicle",
      name: "Pojazd",
      fieldName: "vehicle",
      minWidth: 50,
      maxWidth: 100,
      isResizable: true,
    },
    {
      key: "ContactPhone",
      name: "Numer kontaktowy",
      fieldName: "contactPhone",
      minWidth: 50,
      maxWidth: 100,
      isResizable: true,
    },
    {
      key: "OptimaStatus",
      name: "Status Optima",
      fieldName: "optimaStatus",
      minWidth: 50,
      maxWidth: 100,
      isResizable: true,
    },
    {
      key: "OptimaNumber",
      name: "Numer Optima",
      fieldName: "optimaNumber",
      minWidth: 50,
      maxWidth: 100,
      isResizable: true,
    },
    {
      key: "InvoiceNumber",
      name: "Numer Faktury",
      fieldName: "invoiceNumber",
      minWidth: 50,
      maxWidth: 100,
      isResizable: true,
    }    
  ];