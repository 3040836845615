import { useEffect, useMemo } from "react";
import { useQuery } from "react-query";
import { getMyCompany } from "../../../connectors/clients/connectors";

export const useMyCompanyInfo = () => {

  const { data, status, error } = useQuery("mycompanyinfo", getMyCompany,
    {
      retry: false, // Will retry failed requests 10 times before displaying an error
    }
  );

  const fetchedData = useMemo(() => {
    if(data) {
      return data;
    } else if(error) {
      return "";
    } else {
      return "";
    }
  }, [data]);

  useEffect(() => {
  }, [JSON.stringify(fetchedData)]);

  return {
    myCompanyData: data,
    myCompanyStatus: status,
    myCompanyError: error
  };
};