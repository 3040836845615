import { mergeStyles, ISpinButtonStyles } from "office-ui-fabric-react";

export const container = mergeStyles({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginTop: 15,
  });

  export const inputContainer = mergeStyles({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  });

export const dropDown = mergeStyles({
    minWidth: 300
})
  
export const spinBtnS: Partial<ISpinButtonStyles> = {
    root:{
        marginTop: 25
    }
}