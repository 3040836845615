import React from "react";
import { useSelector, RootStateOrAny, useDispatch } from "react-redux";
import { Panel } from "office-ui-fabric-react";
import { toggleDriversPanel } from "../../../config/redux/drivers/driversActions";
import { panelS } from "../../common/styles/panelStyles"
import DriverForm from "./DriverForm";

const DriverPanel: React.SFC = () => {
  const dispatch = useDispatch();
  const { driverPanelOpen } = useSelector(
    (state: RootStateOrAny) => state.drivers
  );

  const dissmisPanel = () => {
    dispatch(toggleDriversPanel({ open: false }));
  };

  return (
    <Panel
      headerText="Kierowca"
      isOpen={driverPanelOpen}
      closeButtonAriaLabel="Close"
      type={1}
      onDismiss={dissmisPanel}
      isLightDismiss={false}
      isHiddenOnDismiss={false}
      styles={panelS}
    >
      <DriverForm />
    </Panel>
  );
};

export default DriverPanel;