import { IStyleFunctionOrObject } from "office-ui-fabric-react";
import { Spinner, SpinnerSize } from "office-ui-fabric-react/lib/components/Spinner";
import Dialog, { IDialogContentStyles, IDialogStyleProps } from "office-ui-fabric-react/lib/Dialog";
import * as React from "react";
import { RootStateOrAny, useSelector } from "react-redux";

const dialogS: IStyleFunctionOrObject<IDialogStyleProps, IDialogContentStyles> = {
  header: {
    height: 0
  },

  inner: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 20,
  }
}

const PrintingLoader: React.FC = () => {
  const { printingLoading } = useSelector(
    (state: RootStateOrAny) => state.orders
  );

  return (
    <Dialog hidden={!printingLoading} dialogContentProps={{ showCloseButton: false, styles: dialogS }} >
      <Spinner size={SpinnerSize.large} />
    </Dialog>
  );
};

export default PrintingLoader;
