import React, { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import NewFormBtns from "../../../common/layout/form/NewFormBtns";
import { Dropdown } from "office-ui-fabric-react";
import { Spinner, SpinnerSize } from "@fluentui/react";
import { translation } from "../../actions/translateValidator";
import { useCheckValidator } from "../../hooks/useCheckValidator";

export interface OrderFormProps {
  cancelForm: any;
  validatorTypes: any;
}

const CheckValidatorForm: React.FC<OrderFormProps> = ({
  cancelForm,
  validatorTypes,
}) => {
  const [selectedValidatorType, setSelectedValidatorType] = useState<string>();
  const { handleSubmit, cancelSubmit, isLoading } = useCheckValidator(cancelForm);

  useEffect(() => {
    if (validatorTypes.length > 0) {
      setSelectedValidatorType(validatorTypes[0].name);
    }
  }, [validatorTypes]);

  const dropdownOptions = validatorTypes.map((validatorType: any) => {
    return {
      key: validatorType.name,
      text: translation[validatorType.name as keyof typeof translation]
    };
  });

  return (
    <>
      <Formik
        initialValues={{
          validatorType: dropdownOptions[0].key,
        }}
        onSubmit={handleSubmit}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({ handleSubmit, setFieldValue, initialValues }) => (
          <Form onSubmit={handleSubmit}>
            <Field
              name="validatorType"
              render={({ field, form }: { field: any; form: any }) => (
                <Dropdown
                  label="Typ walidatora"
                  options={dropdownOptions}
                  onChange={(event, option, index) => {
                    if (option) {
                      setFieldValue("validatorType", option.key);
                      setSelectedValidatorType(option.text);
                    }
                  }}
                />
              )}
            />
            <NewFormBtns
              positionAbsolute={true}
              submitBtnText="Sprawdź"
              cancelForm={cancelSubmit}
              disabled={isLoading}
            />
          </Form>
        )}
      </Formik>
      {isLoading && <Spinner size={SpinnerSize.large} style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />}
    </>
  );
};

export default CheckValidatorForm;
