import React from "react";
import { RootStateOrAny, useSelector, useDispatch } from "react-redux";
import { ChoiceGroup, IChoiceGroupOption, Panel } from "office-ui-fabric-react";
import { panelS } from "../../common/styles/panelStyles";
import { toggleStoreMapSettingsPanel } from "../../../config/redux/storeMap/StoreMapActions";
import { MapTypeEnum } from "../enums/MapTypeEnum";

import road from '../mapTypes/road.svg';
import satellite_road_labels from '../mapTypes/satellite-road-labels.svg';
import satellite from "../mapTypes/satellite.svg"
import shaded_relief from "../mapTypes/shaded-relief.svg"

export interface StoreMapSettingsPanelProps {
    mapType: string;
    setMapType: React.Dispatch<React.SetStateAction<string>>
}

const StoreMapSettingsPanel: React.FC<StoreMapSettingsPanelProps> = ({ mapType, setMapType }) => {
    const dispatch = useDispatch();
    const { settingsPanelOpen } = useSelector(
        (state: RootStateOrAny) => state.storeMap
    );

    const dismissPanel = () => {
        dispatch(toggleStoreMapSettingsPanel({ isOpen: false }));
    };

    const imageSize = { width: 360, height: 120 }
    const style = {
        //root: { padding: 0, margin: 0 },
        //field: { padding: 0, margin: 0 },
        //input: { padding: 0, margin: 0 },
        //labelWrapper: {  margin: 0, padding: 0}, 
        //imageWrapper: { margin: 0, padding: 0 },
        //choiceFieldWrapper: { margin: 0, padding: 0 },
        //iconWrapper: { margin: 0, padding: 0 }
    }

    const options: IChoiceGroupOption[] = [
        {
            key: MapTypeEnum.road,
            imageSrc: road,
            imageAlt: 'Drogowa',
            selectedImageSrc: road,
            imageSize: imageSize,
            text: 'Drogowa',
            styles: style
        },
        {
            key: MapTypeEnum.satellite_road_labels,
            imageSrc: satellite_road_labels,
            imageAlt: 'Satelita z drogami',
            selectedImageSrc: satellite_road_labels,
            imageSize: imageSize,
            text: 'Satelita z drogami',
            styles: style
        },
        {
            key: MapTypeEnum.satellite,
            imageSrc: satellite,
            imageAlt: 'Satelita',
            selectedImageSrc: satellite,
            imageSize: imageSize,
            text: 'Satelita',
            styles: style
        },
        {
            key: MapTypeEnum.road_shaded_relief,
            imageSrc: shaded_relief,
            imageAlt: 'Konturowa z drogami',
            selectedImageSrc: shaded_relief,
            imageSize: imageSize,
            text: 'Konturowa z drogami',
            styles: style
        },
    ];

    return (
        <Panel
            isOpen={settingsPanelOpen.isOpen}
            closeButtonAriaLabel="Close"
            headerText={"Ustawienia"}
            onDismiss={dismissPanel}
            type={3}
            styles={panelS}
            isHiddenOnDismiss={true}
        >
            <ChoiceGroup
                label="Wybierz typ mapy"
                defaultSelectedKey={mapType}
                options={options}
                onChange={(ev: any, option: any) => {
                    setMapType(option.key)
                }}
            />
        </Panel>
    );
};

export default StoreMapSettingsPanel;