import React from "react";
import { Formik, Form } from "formik";
import { Stack, IStackStyles } from "office-ui-fabric-react";
import {
  validationSchemaUpdateUser,
  validationSchemaUserShort,
} from "../../../actions/validationSchemas";
import FormBtns from "../../../../common/layout/form/NewFormBtns";
import NewEdditionField from "../../../../common/layout/form/NewEdditionField";
import { margin, btnContainer } from "../../../styles/panelForm.styles";
import ActivityIndicator from "../../../../common/layout/ActivityIndicator";
import { useUserForm } from "../../../hooks/useUserForm";
import { useCancelForm } from "../../../hooks/useCancelForm";
import ErrorMessage from "../../../../common/layout/form/ErrorMessage";
import FormHeader from "../FormHeader";
import { textFieldsContainer } from "../../../styles/pickUpPointsForm.styles";
import DisplayText from "../displayData/DisplayText";

const containerS: IStackStyles = {
  root: {
    padding: "40px 10px 20px 10px",
    height: "100%",
  },
};

const UsersForm: React.FC = () => {
  const {
    add,
    isLoading,
    initValues,
    edit,
    selectedIds,
  } = useUserForm();
  const cancel = useCancelForm();

  const schema = () => {
    if (selectedIds[0]) {
      return validationSchemaUpdateUser;
    } else {
      return validationSchemaUserShort;
    }
  };

  return (
    <Formik
      initialValues={initValues}
      onSubmit={selectedIds[0] ? edit : add}
      enableReinitialize={true}
      validationSchema={schema()}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ handleSubmit }) => (
        <Form translate="yes" onSubmit={handleSubmit}>
          <Stack styles={containerS} verticalAlign="space-between" verticalFill>
            <FormHeader text={selectedIds[0] ? "Edytuj użytkownika" : "Zaproś użytkownika"} />
            {/* {activeClient.basicInformations.multiDepartment && (
              <FormDropDown
                options={departments}
                name="department"
                headerText="Wybierz oddział"
              />
            )} */}
            <div className={textFieldsContainer}>
              {!selectedIds[0] ?
                <NewEdditionField name="email" label="Email" type="email" /> :
                <DisplayText name="Email" value={initValues.email} />
              }
              <NewEdditionField name="firstName" label="Imię" />
              <NewEdditionField name="lastName" label="Nazwisko" />
            {selectedIds[0] && <NewEdditionField name="phoneNumber" label="Telefon" /> }
            </div>
            <Stack.Item>
              {/* <div className={container}>
                <NewFormCheckbox name="isClientAdmin" label="Administrator firmy" />
              </div> */}
              <div className={btnContainer}>
                <FormBtns
                  cancelForm={cancel}
                  submitBtnText={initValues.email ? "Zapisz" : "Zaproś"}
                  disabled={isLoading}
                  positionAbsolute={true}
                />
              </div>
            </Stack.Item>
            <div className={margin}>
              {isLoading ? <ActivityIndicator /> : <ErrorMessage />}
            </div>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

export default UsersForm;