import * as React from "react";
import UsersList from "../components/UsersList";
import UserPanel from "../components/UserPanel";
import ListSuccessMessage from "../../common/layout/ListSuccessMessage";
import { mergeStyles } from "office-ui-fabric-react";

const padding = mergeStyles({
  paddingTop: 15
})

const wrapper = mergeStyles({
  width: "95%",
  margin: "0px auto",
});

const Users: React.FC = () => {
  return (
    <div className={wrapper}>
      <div className={padding}>
        <ListSuccessMessage />
      </div>
      <UsersList />
      <UserPanel />
    </div>
  );
};

export default Users;