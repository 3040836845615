import {FieldNames} from "../../models/clients-filedNames.enum"

export const activeClientsColumns = [
  {
    key: "resultName",
    name: "Nazwa",
    fieldName: FieldNames.NAME,
    minWidth: 300,
    maxWidth: 500,
    isResizable: true,
  },
  {
    key: "Street",
    name: "Adres",
    fieldName: FieldNames.ADDRESS,
    minWidth: 300,
    maxWidth: 500,
    isResizable: true,
    }, 
  {
    key: "receptionPlacesOrderTypes",
    name: "Typ zamówień",
    fieldName: FieldNames.ORDERTYPE,
    minWidth: 300,
    maxWidth: 500,
    isResizable: true,
  },
];