import React, { useMemo, useState } from 'react';
import { wrapper } from '../../common/styles/formDropDown.styles';
import { Image, IImageProps, ImageFit } from '@fluentui/react/lib/Image';
import { useContainerFotos } from '../hooks/useContainerFotos';
import { IconButton, Spinner, SpinnerSize, Stack } from '@fluentui/react';
import { Text } from '@fluentui/react/lib/Text';

const ContainerDetailFotos: React.FC = () => {

    const { containerPhotos, photosAmount, isLoading } = useContainerFotos();
    const [photoNumber, setPhotoNumber] = useState<number>(0)

    const imageProps: IImageProps = {
        imageFit: ImageFit.centerContain,
        height: "90%",
        width: "90%",
        //styles: props => ({ root: { border: '1px solid ' + props.theme.palette.neutralSecondary } }),
    };

    const memomizedContainerPhotoInfo = useMemo(
        () => (
            <Stack horizontal horizontalAlign="center" >
                <Stack.Item align='center' >
                    <Text variant='mediumPlus'>{photosAmount > 0 ? containerPhotos[photoNumber].createdDateString + ": " + containerPhotos[photoNumber].originalFileName : ""}</Text>
                </Stack.Item>
            </Stack>

        ),
        [photosAmount, photoNumber]
    )

    const memoizedContainerPhoto = useMemo(
        () => (

            <Image
                {...imageProps}
                src={photosAmount > 0 ? containerPhotos[photoNumber].fileUrl : ""}
                alt='Wystąpił błąd podczas pobierania zdjęcia.'
                shouldStartVisible={true}
            />
        ),
        [isLoading, photoNumber]
    );

    const next = () => {
        if (photoNumber >= photosAmount - 1) {
            setPhotoNumber(0);
        }
        else {
            setPhotoNumber(photoNumber + 1)
        }
    }

    const previous = () => {
        if (photoNumber <= 0) {
            setPhotoNumber(photosAmount - 1);
        }
        else {
            setPhotoNumber(photoNumber - 1)
        }
    }

    return (
        <div style={{
            height: "70vh",
            width: "150vh",
            // paddingRight: '1vh',
            // paddingLeft: '1vh',
            // paddingTop: '1vh'
        }}> {isLoading == false ?
            <>
                {memomizedContainerPhotoInfo}
                {memoizedContainerPhoto}
                {photosAmount > 0 &&
                    <Stack horizontal horizontalAlign="center" >
                        <Stack.Item align='center' >
                            <IconButton iconProps={{ iconName: 'ChevronLeftSmall' }} onClick={previous}></IconButton>
                        </Stack.Item>
                        <Stack.Item align='center' >
                            <Text variant={"mediumPlus"}> {photoNumber + 1} / {photosAmount}</Text>
                        </Stack.Item>
                        <Stack.Item align='center'>
                            <IconButton iconProps={{ iconName: 'ChevronRightSmall' }} onClick={next}></IconButton>
                        </Stack.Item>
                    </Stack>
                }
            </>
            :
            <Spinner size={SpinnerSize.large} style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />}
        </div>
    )
}

export default ContainerDetailFotos;