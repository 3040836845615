import { clearAlerts, setListMessage, setErrorAlert } from './../../../config/redux/alerts/alertsActions';
import { useDispatch, RootStateOrAny, useSelector } from "react-redux";
import { FormikValues } from "formik";

import {
  setUser,
  addNewUser,
  toggleUsersPanel,
} from "../../../config/redux/users/usersActions";
import { editUser } from "../../../connectors/clients/connectors";
import { IResetForm } from "../../common/models/resetForm.type";
import { useState } from "react";
import { inviteUser } from '../../../connectors/auth/connectors';

type IUser = {
  email: string,
  firstName: string,
  lastName: string,
  phoneNumber: string
}

export const useUserForm = () => {
  const [loading, setIsLoading] = useState(false)
  const { selectedUsers } = useSelector(
    (state: RootStateOrAny) => state.users
  );
  const dispatch = useDispatch();
  let initValues = {
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
  };

  if (selectedUsers[0]) {
    initValues = {
      firstName: selectedUsers[0].firstName,
      lastName: selectedUsers[0].lastName,
      phone: selectedUsers[0].phone,
      email: selectedUsers[0].email,
    };
  }


  const handleSubmit = async (
    values: FormikValues,
    { resetForm }: IResetForm
  ) => {
    setIsLoading(true)
    const body = {
      email: values.email,
      firstName: values.firstName,
      lastName: values.lastName,
      phoneNumber: values.phone,
    };

    try {
      dispatch(clearAlerts())
      let a = sessionStorage.getItem("companyId") ? sessionStorage.getItem("companyId") : undefined
      selectedUsers.length >= 1 ? await editUser(selectedUsers[0].id, body) : await inviteUser({ ...body, companyId: a });

      setIsLoading(false)
      dispatch(setUser({}));
      dispatch(addNewUser({ add: true }));
      resetForm();
      dispatch(toggleUsersPanel({ open: false }));
      dispatch(setListMessage("Operacja przebiegła pomyślnie"))
    } catch (err) {
      setIsLoading(false)
      dispatch(setErrorAlert("Wystąpił błąd"))
    }
  };

  const cancelForm = () => {
    dispatch(toggleUsersPanel({ open: false }));
    dispatch(clearAlerts())
  };

  return {
    handleSubmit,
    initValues,
    cancelForm,
    selectedUsers,
    loading
  };
};