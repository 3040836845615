import { NavigationPath } from "./../../../config/routing/NavigationPath";
import { OrderStatus } from "../models/OrderStatus.enum";

export const getParams = (pathname: string) => {
  let params = {};
  switch (pathname) {
    case NavigationPath.NEW_ORDERS:
      return (params = { OrderStatus: OrderStatus.NEW });
    case NavigationPath.ACCEPTED_ORDERS:
      return (params = { OrderStatus: OrderStatus.ACCEPTED });
    case NavigationPath.IN_PROGRESS_ORDERS:
      return (params = { OrderStatus: OrderStatus.IN_PROGRESS });
    case NavigationPath.INVOICED_ORDERS:
      return (params = { OrderStatus: OrderStatus.INVOICED_ORDERS });
    case NavigationPath.COMPLETED_ORDERS:
      return (params = { OrderStatus: OrderStatus.COMPLETED });
    case NavigationPath.CANCELED_ORDERS:
      return (params = { OrderStatus: OrderStatus.CANCELED });
  }

  return params;
};
