import React, { useCallback } from "react";
import { Dropdown, IDropdownOption } from "office-ui-fabric-react/lib/Dropdown";
import { IMessageBarStyles, MessageBar, MessageBarType } from "office-ui-fabric-react";
import { useField } from "formik";
import { wrapper } from "../../styles/formDropDown.styles";
import { useEffectAfterMount } from "../../hooks/useEffectAfterMount";

const errorMessageS: IMessageBarStyles = {
  root: {
    marginTop: "15px",
    width: "100%",
  },
};

export interface FormDropDownProps {
  name: string;
  headerText: string;
  options: Array<any>;
  placeholder?: string;
  onChange?: any
  onChangeEffect?: any,
  required?: boolean
  setInternalStore?: any;
  orderTypeItemId: number;
}

const FormDropDownInternalStore: React.FC<FormDropDownProps> = ({
  options,
  headerText,
  placeholder,
  name,
  onChange,
  onChangeEffect,
  required = true,
  setInternalStore,
  orderTypeItemId,
}) => {
  const [field, { value, error }, { setValue }] = useField(name);

  const [internalStorePlace] = useField("internalStorePlace");
  //const [orderTypeItemId] = useField("orderTypeItemId")

  const handleChange = useCallback(
    async (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
      if (option) {
        setValue(option.key);
        setInternalStore(option.key);   
        if(onChange){
          await onChange(option.key, orderTypeItemId);
          //setPrice(asd);
        } 
      }
    },
    [options]
  );

  useEffectAfterMount(() => {
    if(onChangeEffect){
      if(options){
        const item = options.find((option: any) => {
          if(option.key === value){

            return option
          }
        })
  
        onChangeEffect(item)
      }
    }
  }, [value, options[0], orderTypeItemId]);

  return (
    <div className={wrapper}>
      <Dropdown
        selectedKey={value ? value : ""}
        placeholder={placeholder}
        options={options}
        onChange={handleChange}
        label={headerText}
        required={required}
      />
      {error && (
        <MessageBar
          messageBarType={MessageBarType.error}
          isMultiline={false}
          dismissButtonAriaLabel="Close"
          styles={errorMessageS}
        >
          {error}
        </MessageBar>
      )}
    </div>
  );
};

export default FormDropDownInternalStore;
