export enum DocumentTypeEnum {
    CONTRACT = 0,                   // Umowa
    ACCEPTANCE_REPORT = 1,          // PO - Protokół odbioru
    HANDOVER_PROTOCOL = 2,          // PP - Protokół przekazania
    EXTERNAL_RECEPTION = 3,         // PZ - przyjęcie zewnętrzne (zakup)
    DESTRUCTION_PROTOCOL = 4,       // Protokół zniszczenia
    RECIPIENT_RESERVATION = 5,
    INVOICE = 6,                    // Faktura
    INVENTORY = 7,                  // Inwentura
    MM_PLUS = 8, 
    MM_MINUS = 9
}