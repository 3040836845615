import { useDispatch } from "react-redux";
import { RootStateOrAny, useSelector } from "react-redux";

export const useValidatorData = () => {
  const dispatch = useDispatch();
  const {
    validatorPanelOpen,
  } = useSelector((state: RootStateOrAny) => state.validatorPanel);
  const { selectedValidators } = useSelector(
    (state: RootStateOrAny) => state.validators
  );

  return {
    validatorPanelOpen,
    selectedValidators,
  };
};