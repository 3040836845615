import { getTheme, mergeStyles } from "office-ui-fabric-react";

const theme = getTheme()

export const containerS = mergeStyles({
  padding: "20px 15px",
});

export const iconClass = mergeStyles({
  color: theme.palette.accent,
  fontSize: "15px",
  lineHeight: "0.7"
});