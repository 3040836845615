import { FormikValues } from "formik";
import { useMemo, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { setErrorAlert, setListMessage } from "../../../config/redux/alerts/alertsActions";
import { getContainer, getGpsDeviceStatuses, putContainer } from "../../../connectors/containers/connectors";
import { IResetForm } from "../../common/models/resetForm.type";
import { useEffectAfterMount } from "../../common/hooks/useEffectAfterMount";
import { setContainer, toggleStoreMapPanel } from "../../../config/redux/storeMap/StoreMapActions";
import moment from "moment";

export const useStoreMapContainerForm = () => {

    const {
        selectedContainer,
        containerPanelOpen
    } = useSelector(
        (state: RootStateOrAny) => state.storeMap
    );
    const [loading, setIsLoading] = useState(false)
    const [fetchingContainer, setFetchingContainer] = useState(false)
    const [gpsDeviceStatuses, setGpsDeviceStatuses] = useState({})
    const dispatch = useDispatch()

    const getBody = (values: any) => {
        let returnObj: any = {
            containerNumber: values.containerNumber,
            status: values.status,
            identifier: values.identifier,
            id: values.id,
        };
        return returnObj
    }

    useEffectAfterMount(() => {
        if (containerPanelOpen && selectedContainer) {
            setGpsDeviceStatusesState();
            setFetchingContainer(true);
            fetchContainerDetails();
        }

    }, [containerPanelOpen]);

    const setGpsDeviceStatusesState = async () => {
        let gpsDeviceStatuses = await getGpsDeviceStatuses();
        var data: any[] = []
        gpsDeviceStatuses.data.data.map((gpsDeviceStatus: any) => {
            data.push({
                key: gpsDeviceStatus.GpsDeviceStatus,
                text: gpsDeviceStatus.description
            })
        })
        setGpsDeviceStatuses(data);
    }

    const fetchContainerDetails = async () => {
        const res = await getContainer(selectedContainer.id);
        if (res.data && res.data.data) {
            dispatch(setContainer(res.data.data));
            setFetchingContainer(false);
        }
    };

    const initValues = useMemo(
        () => ({
            id: selectedContainer ? selectedContainer.id : "",
            closestCompanyName: selectedContainer ? selectedContainer.closestCompanyName : "",
            closestCompanyTaxNumber: selectedContainer ? selectedContainer.closestReceptionPlaceAddress : "",
            closestReceptionPlaceAddress: selectedContainer ? selectedContainer.closestReceptionPlaceAddress : "",
            closestReceptionPlaceDistance: selectedContainer ? selectedContainer.closestReceptionPlaceDistance : "",
            closestReceptionPlaceType: selectedContainer ? selectedContainer.closestReceptionPlaceType : "",
            iccid: selectedContainer ? selectedContainer.iccid : "",
            imei: selectedContainer ? selectedContainer.imei : "",
            lastActiveDate: selectedContainer ? moment(selectedContainer.lastActiveDate).format('DD-MM-YYYY HH:mm:ss') : "",
            latitude: selectedContainer ? selectedContainer.latitude : "",
            longitude: selectedContainer ? selectedContainer.longitude : "",
            serNo: selectedContainer ? selectedContainer.serNo : "",
            containerNumber: selectedContainer ? selectedContainer.containerNumber : "",
            status: selectedContainer ? selectedContainer.status : "",
            statusString: selectedContainer ? selectedContainer.statusString : "",
            currentBatteryLevel: selectedContainer ? selectedContainer.currentBatteryLevel : "",
            // identifier: selectedContainer ? selectedContainer.identifier : "",
            // uhfIdentifier: selectedContainer ? selectedContainer.uhfIdentifier : "",
            description: selectedContainer ? selectedContainer.description : ""
        }),
        [JSON.stringify(selectedContainer)]
    )

    const cancelForm = () => {
        dispatch(toggleStoreMapPanel({ isOpen: false }));
    }

    const handleSubmit = async (
        values: FormikValues,
        { resetForm }: IResetForm
    ) => {

        setIsLoading(true);

        const body = getBody(values);

        try {
            await putContainer(body.id, body);
            setIsLoading(false);
            //dispatch(setContainer(undefined));
            dispatch(toggleStoreMapPanel({ isOpen: false }));
            // dispatch(setListMessage("Operacja przebiegła pomyślnie."));
        } catch (err) {
            setIsLoading(false);
            dispatch(setErrorAlert("Wystąpił błąd podczas wykonywania akcji."));
        }
    };

    return {
        initValues,
        fetchingContainer,
        loading,
        handleSubmit,
        cancelForm,
        gpsDeviceStatuses
    }
}