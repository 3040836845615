export enum ClientActions {
    TOGGLE_ORDER_PANEL = "toggleClientOrderPanel",
    SET_SELECTED_ORDER = "setSelectedClientOrder",
    TOGGLE_SELF_EDIT_PANEL = "toggleCleintSelfEditPanel",
    TOGGLE_NAV = "toggleNav",
    TOGGLE_RECEPTIONPLACE_PANEL = "toggleReceptionPlacePanel",
    SET_SELECTED_ITEM = "setSelectedClientListItem",
    SET_ORDER_DETAILS = "setOrderDetails",
    TOGGLE_ORDERS_REPORT_PANEL = "toggleOrdersReportPanel",
    RELOAD_ORDERS = "reloadOrders"
}