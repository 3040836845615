import * as React from "react";
import { Image, Text, Stack, ITextStyles, getTheme, IStackStyles, IImageStyles } from "office-ui-fabric-react";

import { FontSizes } from "@uifabric/fluent-theme";
import { FontWeights } from "office-ui-fabric-react";

const theme = getTheme()

const containerS: IStackStyles = {
    root: {
        minWidth: "230px",
    }
}

const imageS: Partial<IImageStyles> = {
    root: {
        marginRight: "15px"
    }
}

const textS: ITextStyles = {
    root: {
        fontSize: FontSizes.size16,
        fontWeight: FontWeights.semibold,
        color: theme.palette.white,
      },
}

const Logo: React.FC = () => {
  return (
    <Stack horizontal styles={containerS}>
      <Image src="/recycle-white.png" styles={imageS}/>
      <Text styles={textS}>SODO 2.0</Text>
    </Stack>
  );
};

export default Logo;
