
export const departmentsColumns = [
    {
      key: "column1",
      name: "Miejscowość",
      fieldName: "city",
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },
    {
      key: "column2",
      name: "Kod pocztowy",
      fieldName: "postalCode",
      minWidth: 50,
      maxWidth: 100,
      isResizable: true,
    },
    {
      key: "column3",
      name: "Ulica i numer",
      fieldName: "street",
      minWidth: 50,
      maxWidth: 100,
      isResizable: true,
    },
  ];
  
  export const generateDepartments = (Departments: any) => {
    const items: Array<any> = [];
  
    Departments.forEach((department: any) => {
      items.push({
        key: department.id,
        id: department.id,
        city: department.city,
        postalCode: department.postalCode,
        street: department.street,
      });
    });
  
    return [...items];
  };