import { PricingActions } from "./pricingActions.enum";

export const togglePricesPanel = (isOpen: boolean) => {
  return {
    type: PricingActions.TOGGLE_PRICING_PANEL,
    payload: isOpen,
  };
};

export const setSelectedPrice = (priceId: string) => {
  return {
    type: PricingActions.SET_SELECTED_PRICE,
    payload: priceId,
  };
};

export const setActivePriceType = (priceType: any) => {
  return{
    type: PricingActions.SET_ACTIVE_PRICE_TYPE,
    payload: priceType
  }
}

export const togglePriceEdit = (edit: boolean) => {
  return {
    type: PricingActions.TOGGLE_PRICING_EDIT,
    payload: edit
  }
}