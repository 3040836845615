import * as React from 'react';
import { IChartProps, ILineChartDataPoint, ILineChartPoints, LineChart } from '@fluentui/react-charting';
import { DefaultPalette } from '@fluentui/react/lib/Styling';
import { Spinner, SpinnerSize } from '@fluentui/react';

interface ILineChartBasicProps { // extends ILineChartProps {
  lineChartData: any[];
  lineChartTitle: string;
}

interface ILineChartBasicState {
  width: number;
  height: number;
  yMaxValue: number;
  parsedLineChartData: ILineChartPoints[];
}

class LineChartBasicExample extends React.Component<ILineChartBasicProps, ILineChartBasicState> {
  constructor(props: ILineChartBasicProps) {
    super(props);

    this.state = {
      width: 560,
      height: 300,
      yMaxValue: 0,
      parsedLineChartData: [],
    };
  } 

  // private _onWidthChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   this.setState({ width: parseInt(e.target.value, 10) });
  // };

  // private _onHeightChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   this.setState({ height: parseInt(e.target.value, 10) });
  // };

  private parseLineChartData(data: any[]) {
    const colorsArray: string[] = [DefaultPalette.green, DefaultPalette.blue, DefaultPalette.red];
    let newYMaxValue: number = 0;

    data.forEach((element: any, index: number) =>  {
      let newElementDataArray: ILineChartDataPoint[] = [];

      element.color = colorsArray[index];
      // element.onLineClick = some func
      
      for(let key in element.data) {
        if(element.data.hasOwnProperty(key)) {
          if(typeof element.data[key] === 'number' && element.data[key] > newYMaxValue) {
            newYMaxValue = element.data[key];
          }

          let dateForXAxis: Date = new Date(key);

          newElementDataArray.push(
            {
              x: dateForXAxis,
              y: element.data[key],
              // onDataPointClick: () => alert(`click on ${element.data[key]}`),
            },
          );
        }
      }

      element.data = newElementDataArray;
    });

    this.setState((prevState: ILineChartBasicState) => {
      return {
        ...prevState,
        parsedLineChartData: data,
        yMaxValue: newYMaxValue,
      };
  });
  }

  public render(): JSX.Element {
    const { lineChartData, lineChartTitle } = this.props;
    const { parsedLineChartData, yMaxValue } = this.state;

    if((!parsedLineChartData || parsedLineChartData.length === 0) && !!lineChartData) {
      this.parseLineChartData(lineChartData);
    }

    const data: IChartProps = {
      chartTitle: lineChartTitle,
      lineChartData: parsedLineChartData,
    };

    const rootStyle = { width: `${this.state.width}px`, height: `${this.state.height}px`, marginTop: 20 };
    const margins = { left: 35, top: 20, bottom: 35, right: 20 };

    return (
      <div>
        <label>
          {lineChartTitle}
          { !lineChartData && <Spinner size={SpinnerSize.small} style={{marginLeft: 15, display: 'inline-block'}} /> }
        </label>

        <div style={rootStyle}>
          <LineChart
            data={data}
            legendsOverflowText={'Elementy poza widokiem'}
            yMinValue={0}
            yMaxValue={yMaxValue}
            height={this.state.height}
            width={this.state.width}
            margins={margins}
          />
        </div>
      </div>
    );
  }
}

export default LineChartBasicExample;