import * as React from "react";
import {
  CommandBar,
  ICommandBarStyles,
  getTheme,
  Stack,
  IStackStyles,
  mergeStyles,
} from "office-ui-fabric-react";
import { useLocation } from "react-router-dom";
import { useOrdersCommandBar } from "../../hooks/orders/useOrdersCommandBar";
import RealizationDatePicker from "./RealizationDatePicker";
import PostalCodeSFilter from "./PostalCodeFilter";
import VehicleDropDown from "./VehicleDropDown";
import { NavigationPath } from "../../../../config/routing/NavigationPath";
import PrintContainer from "../../../printing/components/PrintContainer";
import { RootStateOrAny, useSelector } from "react-redux";
import { checkQuantity } from "../../actions/checkQuantity";
import { Quantity } from "../../../common/models/quantity.model";

const theme = getTheme();

const containerS: IStackStyles = {
  root: {
    position: "fixed",
    top: 50,
    left: 230,
    width: "calc(100% - 230px)",
    zIndex: 10,
    backgroundColor: "white",
    borderBottom: `2px solid ${theme.palette.neutralLighter}`,
  },
};

const btnsContainer = mergeStyles({
  display: "flex",
  alignContent: "center"
})

const commandBarS: ICommandBarStyles = {
  root: {
    paddingLeft: 0,
    width: 658,
  },
};

const OrdersCommandBar: React.FC = () => {
  const { items } = useOrdersCommandBar();
  const { selectedOrders } = useSelector(
    (state: RootStateOrAny) => state.orders
  );
  const quantity = checkQuantity(selectedOrders.length);
  const location = useLocation();

  return (
    <Stack
      styles={containerS}
      horizontal
      horizontalAlign="space-between"
      verticalAlign="center"
    >
      <div className={btnsContainer}>
        <CommandBar items={items} styles={commandBarS} />
        {location.pathname === NavigationPath.IN_PROGRESS_ORDERS &&
          quantity === Quantity.ONE && <PrintContainer />}
      </div>
      {location.pathname === NavigationPath.ACCEPTED_ORDERS && (
        <Stack horizontal verticalAlign="center">
          <VehicleDropDown />
          <RealizationDatePicker />
          <PostalCodeSFilter />
        </Stack>
      )}
      {(location.pathname === NavigationPath.NEW_ORDERS ||
        location.pathname === NavigationPath.IN_PROGRESS_ORDERS ||
        location.pathname === NavigationPath.CANCELED_ORDERS ||
        location.pathname === NavigationPath.ALL_ORDERS ||
        location.pathname === NavigationPath.INVOICED_ORDERS ||
        location.pathname === NavigationPath.COMPLETED_ORDERS) &&
        (
          <Stack horizontal verticalAlign="center">
            <PostalCodeSFilter />
          </Stack>
        )}
    </Stack>
  );
};

export default OrdersCommandBar;