import { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { Selection } from "office-ui-fabric-react/lib/DetailsList";
import { getValidatorTypes } from "../../../connectors/validators/connectors";
import { translation } from "../../validators/actions/translateValidator";

export const useValidatorList = (onSelection?: any, onInvoke?: any) => {
  const { data } = useQuery("validators", getValidatorTypes)
  const [groups, setGroups] = useState<any>([{ name: 'Kontenery', validatorType: 0 }, { name: 'Zamówienia', validatorType: 1 }, { name: 'Punkty odbioru', validatorType: 2 }]);
  const [items, setItems] = useState<any>([]);
  const fetchedData = useMemo(() => {
    if (data) {
      return data
    } else return "";
  }, [data]);

  useEffect(() => {
    if (data) {
      generateGroups(data.data.data);
      generateItems(data.data.data);
    }
  }, [JSON.stringify(fetchedData)]);

  const generateGroups = (items: Array<any>) => {
    const result: any = {};
    const typeNames: any = {
      0: 'Kontenery',
      1: 'Zamówienia',
      2: 'Punkty odbioru'
    };

    items.forEach(obj => {
      if (!result[obj.type]) {
        result[obj.type] = [];
      }
      result[obj.type].push(obj);
    });

    let index = 0;
    const gr = Object.keys(result).map((key: string) => {
      const item = result[key];
      const grItem = {
        name: typeNames[key],
        count: item.length,
        key: key,
        startIndex: index,
      };
      index += item.length;
      return grItem;
    });

    setGroups(gr);
  };

  const generateItems = (items: Array<any>) => {
    const result: any[] = [];
    items.forEach(obj => {
      if (!result[obj.type]) {
        result[obj.type] = [];
      }
      result[obj.type].push(obj);
    });

    const arr: Array<any> = [];
    result.forEach((item: any) => {
      const parsedItems = item.map((item: any) => {
        return {
          ...item,
          validators: `${translation[item.name as keyof typeof translation]} - ${item.description}`
        }
      })

      arr.push(...parsedItems);
    });

    setItems(arr);
  };

  const columns = useMemo(
    () => [
      {
        key: "validators",
        name: "Walidatory",
        fieldName: "validators",
        minWidth: 300,
        maxWidth: 500,
        isResizable: true,
      },
    ],
    []
  );

  const selection = useMemo(
    () =>
      new Selection({
        onSelectionChanged: () => {
          if (onSelection) {
            onSelection(selection.getSelection());
          }
        },
      }),
    []
  );

  const handleInvoke = (item: any) => {
    if (onInvoke) {
      onInvoke();
    }
  };

  return {
    groups,
    items,
    columns,
    selection,
    handleInvoke,
  };
};