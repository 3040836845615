import React, { useEffect } from "react";
import { SelectionMode } from "office-ui-fabric-react/lib/Selection";
import {
  ConstrainMode,
  DetailsListLayoutMode,
  IColumn,
  Icon,
} from "office-ui-fabric-react";
import { listS } from "../styles/list.styles";
import { useDetailGroupedList } from "../hooks/useDetailGroupedList";
import { useDispatch } from "react-redux";
import {
  setEditing,
  setSelectedOrderTypes,
} from "../../../config/redux/orderTypes/orderTypesActions";
import { ShimmeredDetailsList } from 'office-ui-fabric-react/lib/ShimmeredDetailsList';

const OrderTypesList: React.FC = () => {
  const dispatch = useDispatch();
  const onInvoke = () => {
    dispatch(setEditing({ editing: true }));
  };

  const onSelect = (data: any) => {
    dispatch(setSelectedOrderTypes(data));
  };

  const onRenderItemColumn = (item?: any, index?: number, column?: IColumn): JSX.Element | string | number => {
    if (column === null) {
      return "";
    }
    if (!!column && column.key === 'thumbnail') {
      if (item.orderTypeId === 1) {
        return <Icon iconName="Car" />;
      }
      else if (item.orderTypeId === 2) {
        return <Icon iconName="Weights" />;
      }
      else if (item.orderTypeId === 3) {
        return <Icon iconName="Inbox" />;
      }
      else {
        return ""
      }
    }

    if (!!column) {
      return item[column.key as keyof any];
    } else {
      return "";
    }
  };

  const {
    items,
    groups,
    columns,
    selection,
    handleInvoke,
    selectedOrderTypes,
    status
  } = useDetailGroupedList(onSelect, onInvoke);

  useEffect(() => {
    return () => {
      dispatch(setSelectedOrderTypes(""));
    };
  }, []);

  useEffect(() => {
    if (!selectedOrderTypes) {
      selection.selectToKey("", true);
    }
  }, [selectedOrderTypes]);
  return (
    <ShimmeredDetailsList
      items={items}
      selectionMode={SelectionMode.single}
      selection={selection}
      groupProps={{
        headerProps: {
          onGroupHeaderClick: () => { },
        },
      }}
      onRenderItemColumn={onRenderItemColumn}
      layoutMode={DetailsListLayoutMode.justified}
      onItemInvoked={handleInvoke}
      groups={groups}
      columns={columns}
      styles={listS}
      constrainMode={ConstrainMode.unconstrained}
      onColumnHeaderClick={() => { }}
      enableShimmer={status === 'loading'}
    />
  );
};

export default OrderTypesList;