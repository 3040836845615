import { IQuantity, Quantity } from "../../../../common/models/quantity.model";

export const generateValidators = (quantity: IQuantity, actions: any, selectedValidator?: any) => {

  const notChecked = [{
    key: "startCheck",
    text: "Uruchom sprawdzanie",
    iconProps: { iconName: "textDocument" },
    name: "startCheck",
    onClick: actions.openPanel,
  }]

  const approveValidator = [{
    key: "confirm",
    text: "Zatwierdź",
    iconProps: { iconName: "textDocument" },
    name: "confirtm",
    onClick: actions.openPanelSyncValidator,
    disabled: selectedValidator && selectedValidator.approvedDate ? true : false
  }]

  switch (quantity) {
    case Quantity.NONE:
      return [...notChecked];
    case Quantity.ONE:
      return [...approveValidator];
    case Quantity.MANY:
      return [];
  }
};