export enum OrdersActions {
    SET_ORDERS = "setSelectedOrders",
    CLEAR_ORDERS_DATA = "clearOrdersData",
    REMOVE_DATA = "removeOrdersData",
    SET_REALIZATION_DATE = "setDateOfRealization",
    SET_POSTAL_CODE = "setPostalCode",
    SET_VEHICLE = "setVehicle",
    TOGGLE_ORDER_PANEL = "toggleOrderPanel",
    SET_ACTIVE_ORDER = "setActiveOrder",
    ADD_ORDER_COMMENT = "addOrderComment",
    ACCEPT_ORDER = "acceptOrder",
    CANCEL_ORDER = "cancelOrder",
    TOGGLE_CANCEL_REASON = "toggleCancelReason",
    RELOAD = "reloadOrders",
    TOGGLE_PRINITG_LOADING = "togglePrintingLoading"
  }