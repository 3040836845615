import { ISearchBoxStyles, getTheme } from "office-ui-fabric-react";

const theme = getTheme();

export const searchBoxStyle: ISearchBoxStyles = {
  root: {
    backgroundColor: theme.palette.white,
    opacity: 0.8,
    width: "320px",
  },

  field: {
    selectors: {
      "::placeholder": {
        color: theme.palette.themeDarker,
      },
    },
  },
};