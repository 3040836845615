import { FormikValues } from "formik";
import { useEffect, useMemo, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { setErrorAlert, setListMessage } from "../../../config/redux/alerts/alertsActions";
import { reloadContainers, setContainers, toggleContainersPanel } from "../../../config/redux/container/containersActions";
import { toggleFiltration } from "../../../config/redux/filters/filterActions";
import { getGpsDeviceStatuses, putContainer } from "../../../connectors/containers/connectors";
import { IResetForm } from "../../common/models/resetForm.type";

export const useContainerForm = () => {

    const { selectedContainers } = useSelector(
        (state: RootStateOrAny) => state.containers
    );
    const [loading, setIsLoading] = useState(false)
    const [gpsDeviceStatuses, setGpsDeviceStatuses] = useState({})
    const dispatch = useDispatch()
    
    useEffect(() => {
        setGpsDeviceStatusesState();
    }, [])

    const setGpsDeviceStatusesState = async () => {
        let gpsDeviceStatuses = await getGpsDeviceStatuses();
        var data: any[] = []
        gpsDeviceStatuses.data.data.map((gpsDeviceStatus: any) => {
            data.push({
                key: gpsDeviceStatus.GpsDeviceStatus,
                text: gpsDeviceStatus.description
            })
        })
        setGpsDeviceStatuses(data);
    }


    const getBody = (values: any) => {
        let returnObj: any = {
            containerNumber: values.containerNumber,
            status: values.status,
            // identifier: values.identifier,
            id: values.id,
            // uhfIdentifier: values.uhfIdentifier,
            description: values.description
        };
        return returnObj
    }

    const initValues = useMemo(
        () => ({
            id: selectedContainers[0] ? selectedContainers[0].id : "",
            closestCompanyName: selectedContainers[0] ? selectedContainers[0].closestCompanyName : "",
            closestCompanyTaxNumber: selectedContainers[0] ? selectedContainers[0].closestCompanyTaxNumber : "",
            closestReceptionPlaceAddress: selectedContainers[0] ? selectedContainers[0].closestReceptionPlaceAddress : "",
            closestReceptionPlaceDistance: selectedContainers[0] ? selectedContainers[0].closestReceptionPlaceDistance : "",
            closestReceptionPlaceType: selectedContainers[0] ? selectedContainers[0].closestReceptionPlaceType : "",
            iccid: selectedContainers[0] ? selectedContainers[0].iccid : "",
            imei: selectedContainers[0] ? selectedContainers[0].imei : "",
            lastActiveDateStr: selectedContainers[0] ? selectedContainers[0].lastActiveDateStr : "",
            lastActiveDate: selectedContainers[0] ? selectedContainers[0].lastActiveDate : "",
            latitude: selectedContainers[0] ? selectedContainers[0].latitude : "",
            longitude: selectedContainers[0] ? selectedContainers[0].longitude : "",
            serNo: selectedContainers[0] ? selectedContainers[0].serNo : "",
            containerNumber: selectedContainers[0] ? selectedContainers[0].containerNumber : "",
            status: selectedContainers[0] ? selectedContainers[0].status : "",
            statusString: selectedContainers[0] ? selectedContainers[0].statusString : "",
            currentBatteryLevel: selectedContainers[0] ? selectedContainers[0].currentBatteryLevel : "",
            // identifier: selectedContainers[0] ? selectedContainers[0].identifier : "",
            // uhfIdentifier: selectedContainers[0] ? selectedContainers[0].uhfIdentifier : "",
            description: selectedContainers[0] ? selectedContainers[0].description : ""
            
        }),
        [JSON.stringify(selectedContainers)]
    )

    const cancelForm = () => {
        dispatch(toggleContainersPanel({ isOpen: false }));
        //dispatch(setSelectedListItem(''));
    }

    const handleSubmit = async (
        values: FormikValues,
        { resetForm }: IResetForm
    ) => {

        const body = getBody(values);

        try {
            await putContainer(body.id, body);
            setIsLoading(false);
            dispatch(setContainers([]))
            dispatch(toggleContainersPanel({ isOpen: false }));
            dispatch(reloadContainers({ reload: true }));
            dispatch(toggleFiltration({ filter: true }));
            dispatch(setListMessage("Operacja przebiegła pomyślnie."));
        } catch (err) {
            setIsLoading(false);
            dispatch(setErrorAlert("Wystąpił błąd podczas wykonywania akcji."));
        }
    };

    return {
        initValues,
        loading,
        handleSubmit,
        cancelForm,
        gpsDeviceStatuses
    }
}