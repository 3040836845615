import { IOrdersColumnsLang } from "../../../../localization/abstract/client/orders";

export const OrdersColumns = (columnsNames: IOrdersColumnsLang) => [
  {
    key: "column1",
    name: columnsNames.orderNumber,
    fieldName: "number",
    minWidth: 70,
    maxWidth: 260,
    isResizable: true,
  },
  {
    key: "column2",
    name: columnsNames.orderDate,
    fieldName: "date",
    minWidth: 50,
    maxWidth: 250,
    isResizable: true,
  },
  {
    key: "column3",
    name: columnsNames.receptionPlace,
    fieldName: "receptionPlace",
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
  },
  {
    key: "column4",
    name: columnsNames.orderStatus,
    fieldName: "status",
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
  },
];