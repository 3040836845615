import React from "react";
import { useField } from "formik";
import { ChoiceGroup, IChoiceGroupOption } from "office-ui-fabric-react";


interface IRadioBtnProps {
  name: string;
  label: string;
  options: Array<any>;
}

const MultiDepartmentRadio: React.FC<IRadioBtnProps> = ({ label, name, options }) => {
  const [field, {value}, { setValue }] = useField(name);
  const setOption = (
    ev?: React.FormEvent<any>,
    option?: IChoiceGroupOption
  ): void => {
    if (option) {
      option.key === "yes" ? setValue(true) : setValue(false);
    }
  };


  return (
    <ChoiceGroup
      options={options}
      onChange={setOption}
      label={label}
      required={true}
      defaultSelectedKey={value ? "yes" : "no"}
    />
  );
};

export default MultiDepartmentRadio;