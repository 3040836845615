import { FormikValues } from "formik";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { setErrorAlert, setListMessage } from "../../../config/redux/alerts/alertsActions";
import { addCompaniesAttachments, getAuthorizationAzureStorageSasToken } from "../../../connectors/clients/connectors";
import { BlobServiceClient, BlobUploadCommonResponse } from '@azure/storage-blob';
import { addNewStoreDoc, reloadData, toggleAddStoreDocPanel } from "../../../config/redux/storeDocs/storeDocsActions";

export const useStoreDocsAddFile = () => {
    // const { activeClient } = useSelector(
    //   (state: RootStateOrAny) => state.clients
    // );
    // const { editing, reload, basicInfo } = useSelector(
    //   (state: RootStateOrAny) => state.clientPanel
    // );
    const dispatch = useDispatch();

    const [file, setFile] = useState<File>();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [isLoading, setIsLoading] = useState(false)

    const arrangementFileHandler = (event: any) => {
        setFile(event.target.files[0]);
        setIsFilePicked(true);
    };

    const handleSubmit = async (values: FormikValues) => {
        setIsLoading(true);
        try {
            if (file !== undefined) {
                var taxNumber = values.company ? values.company.data.taxNumber : "";
                var companyId = values.company ? values.company.data.id : undefined;
                setFile(undefined);
                await uploadFile(file, companyId, taxNumber, values);
            }
            else {
                let obj = {
                    documentDate: values.documentDate,
                    documentType: values.documentType,
                    containersNumber: values.containersNumber,
                    CompanyId: values.company ? values.company.data.id : undefined,
                    description: values.description,
                    internalStoreId: values.internalStorePlace,
                    receptionPlaceId: values.receptionPlace,
                }
                await addCompaniesAttachments(obj);
            }

            setIsFilePicked(false);
            
            dispatch(toggleAddStoreDocPanel({ isOpen: false, add: false }));
            dispatch(setListMessage("Operacja przebiegła pomyślnie"));
            dispatch(reloadData({ reload: true }));
            dispatch(addNewStoreDoc(true))
            setIsLoading(false);
        } catch (error) {
            console.log(error);
            dispatch(setErrorAlert("Błąd"));
            dispatch(reloadData({ reload: true }));
            dispatch(toggleAddStoreDocPanel({ isOpen: false, add: false }));
            setIsLoading(false);
        }
    }

    const uploadFile = async (file: File, companyId: number, taxNumber: string, values: FormikValues) => {
        let result = await uploadFileToAzure(file, taxNumber);

        let obj = {
            originalFileName: file.name,
            blobFileName: result.name,
            containersNumber: values.containersNumber,
            fileUrl: result.url,
            documentDate: values.documentDate,
            documentType: values.documentType,
            CompanyId: companyId,
            description: values.description,
            internalStoreId: values.internalStorePlace,
            receptionPlaceId: values.receptionPlace,
        };

        let resp = await addCompaniesAttachments(obj);
    }

    const uploadFileToAzure = async (file: File, taxNumber: string): Promise<UploadedFile> => {
        const sa = await getAuthorizationAzureStorageSasToken(file.name, taxNumber);

        if (sa == undefined || sa.data === undefined) {
            return {
                name: "",
                url: ""
            };
        }

        const sasResult: SasResult = {
            blobUri: sa.data.blobUri,
            blobName: sa.data.blobName,
            container: sa.data.container,
            sasKey: sa.data.sasKey
        };

        const blobServiceClient = new BlobServiceClient(
            `${sasResult.blobUri}/?${sasResult.sasKey}`
        );

        const containerClient = blobServiceClient.getContainerClient(sasResult.container);
        const blockblobClient = containerClient.getBlockBlobClient(sasResult.blobName);

        let blobUploadCommonResponse: BlobUploadCommonResponse = await blockblobClient.uploadData(file);

        if (blobUploadCommonResponse._response.status === 201) {
            //let url: string = await blobStorageService.getLinkToFile(sasResult.blobName);
            return {
                name: sasResult.blobName,
                url: sasResult.blobUri,
            }
        }

        return {
            name: "",
            url: ""
        }
    }

    return {
        arrangementFileHandler,
        handleSubmit,
        isLoading
    }
}

export interface SasResult {
    blobUri: string;
    sasKey: string;
    container: string;
    blobName: string;
}

export interface UploadedFile {
    name: string;
    url: string;
}