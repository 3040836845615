import api from "../../../config/axios/configAxios";
import { IDriver } from "../models/index";

export const getDrivers = async () =>
  api.get(`/api/Admin/Users/drivers?Page=1&Limit=100`);

export const addDriver = async (data: IDriver) =>
  api.post(`/api/Admin/Users/AddDriver`, data);

export const editDriver = async (data: IDriver, driverId: string) =>
  api.put(`/api/Admin/Users/drivers/${driverId}`, data);
