import React, { } from 'react';
import { useCancelForm } from '../../clients/hooks/useCancelForm';
import { RootStateOrAny, useSelector } from 'react-redux';
import { wrapper } from '../../reports/styles/style';
import OrderDetailTxt from '../../orders/components/forms/edditOrder/OrderDetailTxt';
import { useReceptionPlacesShowForm } from '../hooks/useReceptionPlacesShowForm';

const ReceptionPlacesShowForm = () => {
    const { initValues } = useReceptionPlacesShowForm()

    return (
        <div className={wrapper}>
            <div>
                <OrderDetailTxt
                    title="Adres"
                    value={initValues.receptionPlaceAddress}
                />
                    <OrderDetailTxt
                        title="Numer BDO"
                        value={initValues.bdoNumber}
                    />
                    <OrderDetailTxt
                        title="Typ zamówień"
                        value={initValues.orderType}
                    />
                    <OrderDetailTxt
                        title="Numer telefonu"
                        value={initValues.phone}
                    />
                    <OrderDetailTxt
                        title="Nazwa firmy"
                        value={initValues.companyName}
                    />
                    <OrderDetailTxt
                        title="Ilość kontenerów"
                        value={initValues.containersAmount}
                    />
            </div>
        </div>
    )
};

export default ReceptionPlacesShowForm;