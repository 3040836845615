import * as React from "react";
import { useDispatch } from "react-redux";
import { Formik, Form, Field } from "formik";
import { Stack } from "office-ui-fabric-react";
import { mergeStyles } from "office-ui-fabric-react";

import { toggleVehiclePanel } from "../../../config/redux/vehicles/vehiclesActions";
import { useVehicleForm } from "../hooks/useVehicleFrom";
import VehicleOrderTypesCheckboxes from "./VehicleOrderTypesCheckboxes";
import NewFormBtns from "../../common/layout/form/NewFormBtns";
import NewFormField from "../../common/layout/form/NewFormField";
import { vehicleValidation } from "../actions/validationSchems";
import FormDropDown from "../../common/layout/form/FormDropDown";
import { useDrivers } from "../hooks/useDrivers";
import ActivityIndicator from "../../common/layout/ActivityIndicator";
import MessageContainer from "../../auth/components/register/forms/MessageContainer";
import { clearAlerts } from "../../../config/redux/alerts/alertsActions";

const margin = mergeStyles({
  marginTop: 20,
});

const VehicleFrom: React.FC = () => {
  const dispatch = useDispatch();
  const { initValues, handleSubmit,selectedVehicles, loading } = useVehicleForm();
  const options = useDrivers()
  const cancelForm = () => {
    dispatch(toggleVehiclePanel({ open: false }));
    dispatch(clearAlerts())
  };

  return (
    <Formik
      initialValues={initValues}
      onSubmit={handleSubmit}
      validationSchema={vehicleValidation}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({ handleSubmit, values, setFieldValue }) => (
        <Form translate="yes" onSubmit={handleSubmit}>
          <Stack>
            <NewFormField name="brand" label="Marka" />
            <NewFormField name="model" label="Model" />
            <NewFormField name="registerNumber" label="Numer rejestracyjny" />
            <FormDropDown options={options} name="driver" headerText="Kierowca" placeholder="Przypisz kierowcę"/>
            <VehicleOrderTypesCheckboxes values={values} />
            <div className={margin}>
              <NewFormBtns
                cancelForm={cancelForm}
                submitBtnText={selectedVehicles[0] ? "Zapisz" : "Dodaj"}
                disabled={loading}
                positionAbsolute={true}
              />
            </div>
            <div className={margin}>
            {loading ? <ActivityIndicator/> : <MessageContainer/>}
            </div>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

export default VehicleFrom;
