import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setContainers, toggleContainersModal } from '../../../config/redux/container/containersActions';
import ContainerMapModal from '../modal/ContainerMapModal';
import { getContainer } from '../../../connectors/containers/connectors';
import { NavigationPath } from '../../../config/routing/NavigationPath';

const ContainterModalMapFromURL: React.FC = () => {

    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const containerIdFromRoute = new URLSearchParams(location.search).get('Id');

    useEffect(() => {
        if (containerIdFromRoute) {
            setContainerData(containerIdFromRoute)
        }
        else {
            history.push(`${NavigationPath.HOME}`);
        }
    }, [])

    const setContainerData = async (containerId: string) => {
        const res = await getContainer(containerId);
        if (res.data && res.data.data) {
            dispatch(setContainers([res.data.data]));
            dispatch(toggleContainersModal({
                isOpen: true,
                isMap: false,
                isFotos: false
            }))
        }
        else {
            history.push(`${NavigationPath.HOME}`);
        }
    };

    return (
        <ContainerMapModal />
    );
};

export default ContainterModalMapFromURL;