import React from "react";
import { useSelector, RootStateOrAny } from "react-redux";

import { containerS } from "../../../styles/containerStyles";
import Comment from "./Comment";
export interface OrderHistoryProps { }



const OrderHistory: React.FC<OrderHistoryProps> = () => {
  const { orderHistory } = useSelector((state: RootStateOrAny) => state.orderPanel)
  return (
    <div className={containerS}>
      {orderHistory && orderHistory.map((item: any) => {
        return (
          <Comment
            key={item.date}
            content={item.statusDescription}
            id={item.date}
            user={item.user ? item.user : { firstName: "Nieznany", lastName: "użytkwonik" }} // item.user - z api musi przyjść firstName lastName
            createdAt={item.date.split("").splice(0, 10).join("") + " " + item.date.split("T")
              .splice(1, 1)
              .join("")
              .split(".")
              .splice(0, 1)
              .join("")}
          />
        );
      })}
    </div>
  );
};

export default OrderHistory;
