import {
  ISelectedDetailedPrice,
  IRemove,
  IActiveDetailedPrice,
} from "./detailedPriceActions.types";
import { DetailedPriceActions } from "./detailedPriceActions.enum";

export const setActiveDetailedPrice = (data: IActiveDetailedPrice) => {
  return {
    type: DetailedPriceActions.SET_ACTIVE_DETAILED_PRICE,
    payload: data,
  };
};

export const setSelectedDetailedPrice = (data: ISelectedDetailedPrice) => {
  return {
    type: DetailedPriceActions.SET_DETAILED_PRICE,
    payload: data,
  };
};

export const removeData = (data: IRemove) => {
  return {
    type: DetailedPriceActions.REMOVE_DATA,
    payload: data,
  };
};

export const reloadDetailedPrice = (data: boolean) => {
  return {
    type: DetailedPriceActions.RELOAD,
    payload: data,
  };
};

export const toggleDetailedPriceAccept = (detailedPriceAccepted: boolean) => {
  return {
    type: DetailedPriceActions.ACCEPT_DETAILED_PRICE,
    payload: detailedPriceAccepted,
  };
};

export const toggleDetailedPriceCancel = (detailedPriceCanceled: boolean) => {
  return {
    type: DetailedPriceActions.CANCEL_DETAILED_PRICE,
    payload: detailedPriceCanceled,
  };
};