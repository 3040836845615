import * as React from "react";
import {
  CommandBar,
} from "office-ui-fabric-react";

import {useVehiclesCommandBar } from "../../hooks/settings/useVehiclesCommandBar";
import { comandBarS} from "../../styles/commandBar.styles"

const VehiclesCommandBar: React.FC = () => {
  const { items } = useVehiclesCommandBar();

  return <CommandBar items={items} styles={comandBarS} />;
};

export default VehiclesCommandBar;
