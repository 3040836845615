import { Dropdown, IDropdownOption } from "office-ui-fabric-react";
import * as React from "react";

export interface DeactivationReasonDropDownProps {
  selectedReason: any;
  setSelectedReason: any;
  options: Array<any>;
}

const DeactivationReasonDropDown: React.FC<DeactivationReasonDropDownProps> = ({
  selectedReason,
  setSelectedReason,
  options
}) => {
  const onChange = (
    event: React.FormEvent<HTMLDivElement>,
    item?: IDropdownOption
  ): void => {
    setSelectedReason(item);
  };

  return (
    <Dropdown
      label="Wybierz powód deaktywacji"
      selectedKey={selectedReason ? selectedReason.key : undefined}
      onChange={onChange}
      options={options}
    />
  );
};

export default DeactivationReasonDropDown;
