import React from "react";
import { Formik } from "formik";
import NewFormBtns from "../../../common/layout/form/NewFormBtns";
import { TextField, Stack, Spinner, SpinnerSize } from "@fluentui/react";
import OrderDetailTxt from "../../../orders/components/forms/edditOrder/OrderDetailTxt";
import { useConfirmValidator } from "../../hooks/useConfirmValidator";
import { useValidatorData } from "../../../orders/hooks/useValidatorData";

export interface OrderFormProps {
  cancelForm: any;
  reception: number;
  orderTypeItemId: number;
}

const ConfirmValidatorForm: React.FC<OrderFormProps> = ({ cancelForm, reception, orderTypeItemId }) => {
  const { selectedValidators } = useValidatorData();
  const { comment, setComment, handleSubmit, isLoading } = useConfirmValidator(cancelForm);

  return (
    <>
      <Formik
        initialValues={{
          quantity: "1",
          reception: { reception },
          orderTypeItemId: { orderTypeItemId },
        }}
        onSubmit={(e: any) => handleSubmit(e)}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {() => (
          <form onSubmit={(e) => handleSubmit(e)}>
            <Stack tokens={{ childrenGap: 15 }}>
              <OrderDetailTxt
                title="Data utworzenia"
                value={selectedValidators[0].createdDateStr}
              />
              <OrderDetailTxt
                title="Opis"
                value={selectedValidators[0].message}
              />
              <TextField
                name="additionalInfo"
                label="Komentarz"
                value={comment}
                onChange={(e, newValue) => setComment(newValue || "")}
                multiline
              />
              <NewFormBtns
                positionAbsolute={true}
                submitBtnText="Zatwierdź"
                cancelForm={cancelForm}
                disabled={isLoading}
              />
            </Stack>
          </form>
        )}
      </Formik>
      {isLoading && <Spinner size={SpinnerSize.large} style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />}
    </>
  );
};

export default ConfirmValidatorForm;
