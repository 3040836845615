import {
  Nav,
  Panel,
  PanelType,
  ScrollablePane,
} from "office-ui-fabric-react";
import React, { useCallback } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { toggleClientNav } from "../../../config/redux/client/clientActions";
import { containerS, navS, panelS } from "../../styles/layout/nav.styles";
import { navLinkGroups } from "./links";

export interface ClientNavProps { }

const ClientNavPanel: React.FC<ClientNavProps> = () => {
  const dispatch = useDispatch();
  const { navOpen } = useSelector((state: RootStateOrAny) => state.client);

  const dismissPanel = () => {
    dispatch(toggleClientNav(false));
  };

  const history = useHistory();
  const location = useLocation();

  const handleLinkClick = useCallback((e: any, element: any) => {
    const { key } = element;
    e.preventDefault();
    history.push(`${key}`);
  }, []);

  return (
    <Panel
      isOpen={navOpen}
      closeButtonAriaLabel="Close"
      onDismiss={dismissPanel}
      type={PanelType.customNear}
      customWidth={"230px"}
      styles={panelS}
      isHiddenOnDismiss={false}
    >
      <ScrollablePane styles={containerS}>
        <Nav
          groups={navLinkGroups({})}
          styles={navS}
          onLinkClick={handleLinkClick}
          selectedKey={location.pathname}
        />
      </ScrollablePane>
    </Panel>
  );
};

export default ClientNavPanel;