import { AxiosResponse } from "axios";
import { useRef, useState } from "react";
import { IOrdersReportParams } from "../../../connectors/orders/models";
import i18n from "../../../localization/i18n";

interface DownloadFileProps {
  readonly getOrdersReport: (response: any) => Promise<AxiosResponse>;
  readonly preDownloading: () => void;
  readonly postDownloading: () => void;
  readonly onError: () => void;
  readonly getFileName: () => string;
}

interface DownloadedFileInfo {
  readonly download: (params: IOrdersReportParams) => Promise<void>;
  readonly ref: React.MutableRefObject<HTMLAnchorElement | null>;
  readonly name: string | undefined;
  readonly url: string | undefined;
}

export const useDownloadFile = ({
  getOrdersReport,
  preDownloading,
  postDownloading,
  onError,
  getFileName,
}: DownloadFileProps): DownloadedFileInfo => {
  const ref = useRef<HTMLAnchorElement | null>(null);
  const [url, setFileUrl] = useState<string>();
  const [name, setFileName] = useState<string>();

  const download = async (params: any) => {
    try {
      preDownloading();
      
      if (i18n.language.startsWith("pl")) {
        params.lang = 1;
      }
      else if (i18n.language.startsWith("en")) {
        params.lang = 2;
      }
      else if (i18n.language.startsWith("de")) {
        params.lang = 3;
      }
      else {
        params.lang = 1; //pl
      }

      const { data } = await getOrdersReport(params);
      const url = URL.createObjectURL(new Blob([data]));
      setFileUrl(url);
      setFileName(getFileName());
      if (ref.current) {
        ref.current.click();
      } else {
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', getFileName());
        document.body.appendChild(link);
        link.click();
      }
      postDownloading();
      URL.revokeObjectURL(url);
    } catch (error) {
      onError();
    }
  };

  return { download, ref, url, name };
};