import { StoreMapActions } from "./storeMapActions.enum"
import { IContainer, IOpenPanel } from "./storeMapActions.types";

export const setShowContainerPoints = (data: boolean) => {
  return {
    type: StoreMapActions.SHOW_CONTAINER_POINTS,
    payload: data,
  };
};

export const setShowContainerCriticalBatteryPoints = (data: boolean) => {
  return {
    type: StoreMapActions.SHOW_CONTAINER_CRITICAL_BATTERY_POINTS,
    payload: data,
  };
};

export const setShowContainerLowBatteryPoints = (data: boolean) => {
  return {
    type: StoreMapActions.SHOW_CONTAINER_LOW_BATTERY_POINTS,
    payload: data,
  };
};

export const setShowReceptionPlacePoints = (data: boolean) => {
  return {
    type: StoreMapActions.SHOW_RECEPTIONPLACE_POINTS,
    payload: data
  }
}

export const setShowInternalStorePoints = (data: boolean) => {
  return {
    type: StoreMapActions.SHOW_INTERNALSTORE_POINTS,
    payload: data
  }
}

export const setContainer = (data: any) => {
  return {
    type: StoreMapActions.SET_CONTAINER,
    payload: data
  }
}

export const setReceptionPlace = (data: any) => {
  return {
    type: StoreMapActions.SET_RECEPTION_PLACE,
    payload: data
  }
}

export const setInternalStore = (data: any) => {
  return {
    type: StoreMapActions.SET_INTERNAL_STORE,
    payload: data
  }
}

export const toggleStoreMapPanel = (data: IOpenPanel) => {
  return {
    type: StoreMapActions.TOGGLE_PANEL,
    payload: data
  }
}

export const isEditContainerForm = (data: boolean) => {
  return {
    type: StoreMapActions.IS_EDIT_CONTAINER_FORM,
    payload: data
  }
}

export const toggleStoreMapSettingsPanel = (data: IOpenPanel) => {
  return {
    type: StoreMapActions.TOGGLE_SETTINGS_PANEL,
    payload: data
  }
}