import React  from "react";
import { DirectionalHint, FontIcon, mergeStyles, TooltipDelay, TooltipHost } from "office-ui-fabric-react";
import { iconClass } from "../../styles/containerStyles";

export const inProgressOrdersColumns = [
  {
    key: "Number",
    name: "Numer zamówienia",
    fieldName: "number",
    minWidth: 50,
    maxWidth: 100,
    isResizable: true,
  },
  {
    key: "CreatedAt",
    name: "Data zamówienia",
    fieldName: "createdAt",
    minWidth: 50,
    maxWidth: 100,
    isResizable: true,
  },
  {
    key: "ReceivingDate",
    name: "Data planowanego odbioru",
    fieldName: "receivingDate",
    minWidth: 50,
    maxWidth: 100,
    isResizable: true,
  },
  {
    key: "Company",
    name: "Klient",
    fieldName: "company",
    minWidth: 150,
    maxWidth: 250,
    isResizable: true,
  },
  {
    key: "ReceptionPlace",
    name: "Punkt Odbioru",
    fieldName: "receptionPlace",
    minWidth: 100,
    maxWidth: 300,
    isResizable: true,
  },
  {
    key: "Type",
    name: "Typ",
    fieldName: "type",
    minWidth: 50,
    maxWidth: 100,
    isResizable: true,
  },
  {
    key: "Vehicle",
    name: "Pojazd",
    fieldName: "vehicle",
    minWidth: 50,
    maxWidth: 100,
    isResizable: true,
    onRender: (item: any) => ( item.notificationSent ?
      <>{item.vehicle} &nbsp; <TooltipHost
      content="Wysłano SMS z powiadomieniem do kierowcy"
      delay={TooltipDelay.zero}
      directionalHint={DirectionalHint.bottomCenter}
    ><FontIcon iconName="message" className={iconClass}/> </TooltipHost></> : item.vehicle
    ),
  },
  {
    key: "ContactPhone",
    name: "Numer kontaktowy",
    fieldName: "contactPhone",
    minWidth: 50,
    maxWidth: 100,
    isResizable: true,
  },
  {
    key: "route",
    name: "Numer trasy",
    fieldName: "route",
    minWidth: 25,
    maxWidth: 50,
    isResizable: true,
  },
  {
    key: "sequenceInRoute",
    name: "Kolejność w trasie",
    fieldName: "sequenceInRoute",
    minWidth: 25,
    maxWidth: 50,
    isResizable: true,
  },
  {
    key: "receptionCount",
    name: "Ilość odebrana",
    fieldName: "receptionCount",
    minWidth: 25,
    maxWidth: 50,
    isResizable: true,
  },
];
