import React from "react";
import { Stack } from "office-ui-fabric-react/lib/Stack";
import {
  DetailsList,
  DetailsListLayoutMode,
  SelectionMode,
  Selection,
} from "office-ui-fabric-react/lib/DetailsList";
import { listS } from "../../../../features/orders/styles/OrdersList.styles";
import { OrdersColumns } from "../columns/ordersColumns";
import { useTranslation } from "react-i18next";
import { IOrdersColumnsLang } from "../../../../localization/abstract/client/orders";

export interface OrdersListProps {
  selection: Selection;
  items: Array<any>;
  handleInvoke: any;
}

const OrdersList: React.FC<OrdersListProps> = ({
  items,
  selection,
  handleInvoke,
}) => {

  const { t } = useTranslation("orders");
  const columnsNames: IOrdersColumnsLang = t("columns", {
    returnObjects: true,
  });

  return (
    <Stack>
      <DetailsList
        items={items}
        columns={OrdersColumns(columnsNames)}
        styles={listS}
        setKey="items"
        selection={selection}
        layoutMode={DetailsListLayoutMode.justified}
        selectionPreservedOnEmptyClick={true}
        selectionMode={SelectionMode.single}
        onItemInvoked={handleInvoke}
        usePageCache={true}
      />
    </Stack>
  );
};

export default OrdersList;