import * as React from "react";
import { AzureMap, AzureMapDataSourceProvider, AzureMapLayerProvider, AzureMapPopup, AzureMapsProvider, IAzureMapOptions, IAzureMapPopupEvent, useAzureMaps } from "react-azure-maps";
import { AuthenticationType, data } from 'azure-maps-control'
import { useEffect, useState } from "react";
import { PointList } from "./PointList";
import { wrapper } from "../../auth/styles/activityIndicatorContainer.styles";
import { IContainerPoint } from "../interfaces/IContainerPoint";
import { ContainerPopupHtml } from "./ContainerPopupHtml";
import { IReceptionPlacePoint } from "../interfaces/IReceptionPlacePoint";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { PointTypeEnum } from "../enums/PointTypeEnum";
import { ReceptionPlacesPopupHtml } from "./ReceptionPlacePopupHtml";
import { IInteralStorePoint } from "../interfaces/IInternalStorePoint";
import { InteralStorePopupHtml } from "./InternalStorePopupHtml";
import { setContainer, setInternalStore, setReceptionPlace } from "../../../config/redux/storeMap/StoreMapActions";
import { IReceptionPlacePointLocalization } from "../interfaces/IReceptionPlacePointLocalization";
import { ReceptionPlaceBoundary } from "./ReceptionPlaceBoundary";
import { IInternalStoreBorderPoint } from "../interfaces/IInternalStoreBorderPoint";
import { InternalStoreBoundary } from "./InternalStoreBoundary";

const { REACT_APP_AZURE_MAP_KEY } = process.env;

const option: IAzureMapOptions = {
    authOptions: {
        authType: AuthenticationType.subscriptionKey,
        subscriptionKey: REACT_APP_AZURE_MAP_KEY
    },
    center: [19.4803, 52.0693],
    zoom: 5.7,
    //view: 'Auto',
}

export interface StoreMapMainProps {
    receptionPlacePoints: IReceptionPlacePoint[];
    receptionPlacePointLocalizations: IReceptionPlacePointLocalization[];
    containerPoints: IContainerPoint[];
    internalStorePoints: IInteralStorePoint[];
    internalStoreBorderPoints: IInternalStoreBorderPoint[];
    mapType: string
}

export const StoreMapMain: React.FC<StoreMapMainProps> = ({ receptionPlacePoints, receptionPlacePointLocalizations, containerPoints, internalStorePoints, internalStoreBorderPoints, mapType }) => {
    const dispatch = useDispatch();
    const [popupVisible, setPopupVisible] = useState<boolean>(false);
    const [popupPosition, setPopupPosition] = useState<data.Position>(new data.Position(
        0,
        0));
    const [html, setHtml] = useState<any>(<></>);
    const [pointType, setPointType] = useState<Number>(0);

    const { showReceptionPlacePoints, showContainerPoints, showInternalStorePoints, selectedReceptionPlace, selectedInternalStore } = useSelector((state: RootStateOrAny) => state.storeMap);

    const createDataPosition = (longitude: number, latitude: number) => {
        return new data.Position(
            longitude,
            latitude)
    }

    useEffect(() => {
        if (showReceptionPlacePoints == false && pointType == PointTypeEnum.RECEPTION_PLACE)
            setPopupVisible(false);
        if (showContainerPoints == false && pointType == PointTypeEnum.CONTAINER)
            setPopupVisible(false);
        if (showInternalStorePoints == false && pointType == PointTypeEnum.INTERNAL_STORE)
            setPopupVisible(false);
    }, [showReceptionPlacePoints, showContainerPoints, showInternalStorePoints])

    function getCoordinates(e: any) {
        if (e.shapes && e.shapes.length > 0) {
            const prop = e.shapes[0];

            if (prop.properties.y == PointTypeEnum.RECEPTION_PLACE) {
                var receptionPlacePoint: IReceptionPlacePoint | undefined = receptionPlacePoints.find(x => x.id == prop.id);
                if (receptionPlacePoint == undefined)
                    return;
                setPointType(PointTypeEnum.RECEPTION_PLACE);
                setPopupVisible(true);
                setPopupPosition(createDataPosition(
                    receptionPlacePoint.longitude,
                    receptionPlacePoint.latitude)
                );
                setHtml(<ReceptionPlacesPopupHtml receptionPlacePoint={receptionPlacePoint} />)
                dispatch(setContainer(undefined));
                dispatch(setReceptionPlace(receptionPlacePoint))
                dispatch(setInternalStore(undefined));
            }
            else if (prop.properties.y == PointTypeEnum.CONTAINER) {
                var containnerData: IContainerPoint | undefined = containerPoints.find(x => x.id == prop.id);
                if (containnerData == undefined)
                    return;
                setPointType(PointTypeEnum.CONTAINER);
                setPopupVisible(true);
                setHtml(<ContainerPopupHtml containnerData={containnerData} />)
                setPopupPosition(createDataPosition(
                    containnerData.longitude,
                    containnerData.latitude)
                );
                dispatch(setContainer(containnerData));
                dispatch(setReceptionPlace(undefined));
                dispatch(setInternalStore(undefined));
            }
            else if (prop.properties.y == PointTypeEnum.INTERNAL_STORE) {
                var internalStoreData: IInteralStorePoint | undefined = internalStorePoints.find(x => x.id == prop.id);
                if (internalStoreData == undefined)
                    return;
                setPointType(PointTypeEnum.INTERNAL_STORE);
                setPopupVisible(true);
                setHtml(<InteralStorePopupHtml interalStorePopupHtmlProps={internalStoreData} />);
                setPopupPosition(createDataPosition(
                    internalStoreData.longitude,
                    internalStoreData.latitude)
                );
                dispatch(setContainer(undefined));
                dispatch(setReceptionPlace(undefined));
                dispatch(setInternalStore(internalStoreData));
            }
        }
    }

    const memoizedContainerPoints = React.useMemo(
        () => (
            <PointList points={containerPoints} />
        ),
        [containerPoints],
    );

    const memoizedReceptionPlacePoints = React.useMemo(
        () => (
            <PointList points={receptionPlacePoints} />
        ),
        [receptionPlacePoints],
    );

    const memomizedReceptionPlaceLocalizations = React.useMemo(
        () => (
            <PointList points={receptionPlacePointLocalizations} />
        ),
        [receptionPlacePointLocalizations]
    )

    const memomizedReceptionPlaceCircle = React.useMemo(
        () => (
            <ReceptionPlaceBoundary receptionPlace={selectedReceptionPlace} points={receptionPlacePointLocalizations} />
        ),
        [receptionPlacePointLocalizations]
    )

    const memomizedInternalStorePoints = React.useMemo(
        () => (
            <PointList points={internalStorePoints} />
        ),
        [internalStorePoints]
    )

    const memomizedInternalStoreBorderPoints = React.useMemo(
        () => (
            <InternalStoreBoundary points={internalStoreBorderPoints} internalStorePoint={selectedInternalStore} />
        ),
        [internalStoreBorderPoints]
    )

    const preparePopupOptions = (pointPosition: any) => {
        var options = {
            position: pointPosition,
            pixelOffset: [0, -5]
        }
        return options;
    }

    const handlePopupEvent: IAzureMapPopupEvent = {
        eventName: "close",
        callback: (e) => {
            dispatch(setContainer(undefined));
            dispatch(setInternalStore(undefined));
            dispatch(setReceptionPlace(undefined));
        },
    };

    const memoizedMapPopup = React.useMemo(
        () => (
            <AzureMapPopup
                isVisible={popupVisible}
                options={preparePopupOptions(popupPosition)}
                popupContent={html}
                events={[handlePopupEvent]}
            />
        ),
        [popupVisible, popupPosition, html],
    );

    return (
        <div className={wrapper}>
            <div style={{
                height: '84vh',
                paddingRight: '3vh',
                paddingLeft: '3vh',
                paddingTop: '3vh'
            }}>
                <AzureMapsProvider>
                    <AzureMap
                        options={option}
                        styleOptions={{ style: mapType }}
                    >
                        <AzureMapDataSourceProvider id={'AzureMapDataSourceProvider_ReceptionPlacePoints'}>
                            <AzureMapLayerProvider
                                options={{
                                    radius: 5,
                                    strokeColor: "#0066CC",
                                    strokeWidth: 6,
                                    color: "#0066CC",
                                    visible: showReceptionPlacePoints
                                }}
                                id={'AzureMapLayerProvider_ReceptionPlacePoints'}
                                type={'BubbleLayer'}
                                events={{
                                    click: getCoordinates,
                                }}
                            />
                            {memoizedReceptionPlacePoints}
                        </AzureMapDataSourceProvider>
                        <AzureMapDataSourceProvider id={'AzureMapDataSourceProvider_ReceptionPlaceCircle'}>
                            <AzureMapLayerProvider
                                options={{
                                    strokeColor: "#0066CC",
                                    strokeWidth: 2,
                                    visible: showReceptionPlacePoints,
                                }}
                                id={'AzureMapLayerProvider_ReceptionPlaceCircle'}
                                type={'LineLayer'}
                            />
                            {memomizedReceptionPlaceCircle}
                        </AzureMapDataSourceProvider>
                        <AzureMapDataSourceProvider id={'AzureMapDataSourceProvider_ReceptionPlaceLocalizations'}>
                            <AzureMapLayerProvider
                                options={{
                                    radius: 1,
                                    strokeColor: "#0066CC",
                                    strokeWidth: 6,
                                    color: "#0066CC",
                                    visible: showReceptionPlacePoints
                                }}
                                id={'AzureMapLayerProvider_ReceptionPlaceLocalizations'}
                                type={'BubbleLayer'}
                            />
                            {memomizedReceptionPlaceLocalizations}
                        </AzureMapDataSourceProvider>
                        <AzureMapDataSourceProvider id={'AzureMapDataSourceProvider_ContainerPoints'}>
                            <AzureMapLayerProvider
                                options={{
                                    radius: 5,
                                    strokeColor: "#257F09",
                                    strokeWidth: 6,
                                    color: "#257F09",
                                    //visible: showContainerPoints -- because it is filtered in hooks
                                }}
                                id={'AzureMapLayerProvider_ContainerPoints'}
                                type={'BubbleLayer'}
                                events={{
                                    click: getCoordinates,
                                }}
                            />
                            {memoizedContainerPoints}
                        </AzureMapDataSourceProvider>
                        <AzureMapDataSourceProvider id={'AzureMapDataSourceProvider_InternalStorePoints'}>
                            <AzureMapLayerProvider
                                options={{
                                    radius: 11,
                                    color: "#4f1509",
                                    strokeWidth: 0,
                                    visible: showInternalStorePoints
                                }}
                                id={'AzureMapLayerProvider_InternalStorePoints'}
                                type={'BubbleLayer'}
                                events={{
                                    click: getCoordinates,
                                }}
                            />
                            {memomizedInternalStorePoints}
                        </AzureMapDataSourceProvider>
                        <AzureMapDataSourceProvider id={'AzureMapDataSourceProvider_InternalStoreBorderPoints'}>
                            <AzureMapLayerProvider
                                options={{
                                    strokeColor: "#4f1509",
                                    strokeWidth: 2,
                                    visible: showInternalStorePoints,
                                    //radius: 123
                                }}
                                id={'AzureMapLayerProvider_InternalStoreBorderPoints'}
                                type={'LineLayer'}
                            />
                            {memomizedInternalStoreBorderPoints}
                        </AzureMapDataSourceProvider>
                        <AzureMapDataSourceProvider id={'AzureMapDataSourceProvider_MapPopup'}>
                            <AzureMapLayerProvider
                                id={'AzureMapLayerProvider_MapPopup'}
                                type={"SymbolLayer"} />
                            {memoizedMapPopup}
                        </AzureMapDataSourceProvider>
                    </AzureMap>
                </AzureMapsProvider>
            </div>
        </div>
    );
}

export default StoreMapMain

//https://azure.github.io/react-azure-maps/index.html
//https://docs.microsoft.com/en-us/azure/azure-maps/how-to-manage-authentication