import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { toggleReceptionPlacesPanel } from "../../../config/redux/receptionPlaces/receptionPlacesActions";
import { checkQuantity } from "../../commandBar/actions/checkQuantity";
import { Quantity } from "./../../../features/common/models/quantity.model";

export const useReceptionPlacesCommandBar = () => {
    const dispatch = useDispatch();
    const { selectedReceptionPlaces } = useSelector((state: RootStateOrAny) => state.receptionPlaces);
    const quantity = checkQuantity(selectedReceptionPlaces.length);

    const openForm = () => {
        dispatch(toggleReceptionPlacesPanel({ isOpen: true }));
    };

    const generateItems = () => {
        switch (quantity) {
            case Quantity.ONE:
                return [
                    {
                        key: "See",
                        text: "Zobacz",
                        iconProps: { iconName: "reportdocument" },
                        name: "prepare",
                        onClick: openForm,
                    },
                ];
            default:
                return []
        }
    };

    const items = generateItems()

    return {
        items
    }
};