import { IPanelStyles } from "office-ui-fabric-react";
import { Breakpoints } from "../../common/styles/utils/Breakpoints";

export const PanelS: Partial<IPanelStyles> = {
    header: {
      marginTop: "25px",
      marginLeft: "20px",
      selectors: {
        [`@media ${Breakpoints.LARGE}`]: {
          marginLeft: "0",
        },
      },
    },
  
    navigation: {
      position: "absolute",
      top: "20px",
      right: "10px",
      zIndex: 3,
      selectors: {
        [`@media ${Breakpoints.LARGE}`]: {
          top: "20px",
          right: "20px",
        },
      },
    },
  
    content: {
      selectors: {
        [`@media (min-width: 414px)`]: {
          padding: "30px",
        },
      },
    },
  };