import { IReceptionPlacesAction } from "./receptionPlacesActions.types";
import { ReceptionPlacesActions } from "./receptionPlacesActions.enum";

const initState = {
  reloadReceptionPlacesList: false,
  selectedReceptionPlaces: {},
  receptionPlacesPanelOpen: false,
};

export const receptionPlacesReducer = (state = initState, action: IReceptionPlacesAction) => {
  const { type, payload } = action;
  switch (type) {
    case ReceptionPlacesActions.RELOAD_DATA:
      return { ...state, reloadReceptionPlacesList: payload.reload };
    case ReceptionPlacesActions.SET_RECEPTIONPLACES:
      return { ...state, selectedReceptionPlaces: payload };
    case ReceptionPlacesActions.SET_POSTAL_CODE:
      return { ...state, postalCode: payload.postalCode };
    case ReceptionPlacesActions.TOGGLE_PANEL:
      return { ...state, receptionPlacesPanelOpen: payload.isOpen};
    case ReceptionPlacesActions.CLEAR:
      return { ...initState }
    default:
      return state;
  }
};