import { IQuantity } from "../../../common/models/quantity.model";
import { NavigationPath } from "../../../../config/routing/NavigationPath";
import { generateValidators } from "./validators/generateValidatorsCommands";

export const generateItems = (
  quantity: IQuantity,
  pathname: string,
  actions: any,
  selectedValidator?: any,
  dispatch?: any,
) => {
  switch (pathname) {
    case NavigationPath.CONTAINERS_VALIDATORS:
      return generateValidators(quantity, actions, selectedValidator)
    case NavigationPath.RECEPTION_PLACES_VALIDATORS:
      return generateValidators(quantity, actions, selectedValidator)
    case NavigationPath.ORDERS_VALIDATORS:
      return generateValidators(quantity, actions, selectedValidator)
    default:
      return generateValidators(quantity, actions, selectedValidator)
  }
};