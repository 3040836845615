import { IQuantity, Quantity } from "../../../../common/models/quantity.model";

export const generateInProgress = (quantity: IQuantity, actions: any) => {
  const items = [{
    key: "delete",
    text: "Anuluj zamówienie",
    iconProps: { iconName: "delete" },
    name: "delete",
    onClick: actions.setOrderCanceled,
  },
  {
    key: "restore",
    text: "Przywróć do przyjętych",
    iconProps: { iconName: "Back" },
    name: "restore",
    onClick: actions.setOrderAccepted,
  },
  {
    key: "sms",
    text: "SMS do kierowcy",
    iconProps: { iconName: "Message" },
    name: "sms",
    onClick: actions.sendSMS,
  },]

  switch (quantity) {
    case Quantity.NONE:
      return [];
    case Quantity.ONE:
      return [
        {
          key: "see",
          text: "Zobacz",
          iconProps: { iconName: "textDocument" },
          onClick: actions.openPanelToSee,
        },
        {
          key: "edit",
          text: "Zakończ",
          iconProps: { iconName: "edit" },
          onClick: actions.openPanel,
        },
        ...items
      ];
    case Quantity.MANY:
      return [...items];
  }
};
