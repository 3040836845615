import { useTranslation } from "react-i18next";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { toggleReceptionPlacePanel } from "../../../config/redux/client/clientActions";
import { Quantity } from "../../../features/common/models/quantity.model";
import { IReceptionPlacesCommandBarLang } from "../../../localization/abstract/client/receptionPlaces";

export const useReceptionPlacesCommandBar = () => {
  const dispatch = useDispatch();
  const { selectedItem } = useSelector((state: RootStateOrAny) => state.client);
  const quantity = selectedItem ? Quantity.ONE : Quantity.NONE;

  const openForm = () => {
    dispatch(toggleReceptionPlacePanel({isOpen: true, formActive: true}));
  };

  const openDisplay = () => {
    dispatch(toggleReceptionPlacePanel({isOpen: true, formActive: false}));
  }

  const { t } = useTranslation("receptionPlaces");
  const commandBarNames : IReceptionPlacesCommandBarLang= t("commandBar", {
    returnObjects: true,
  });
    
  const generateItems = () => {
    switch (quantity) {
      case Quantity.NONE:
        return [
          {
            key: "add",
            text: commandBarNames.add,
            iconProps: { iconName: "add" },
            name: "add",
            onClick: openForm,
          },
        ];
      case Quantity.ONE:
        if  (selectedItem.isActive) {
          return [
            {
              key: "edit",
              text: commandBarNames.edit,
              iconProps: { iconName: "edit" },
              name: "edit",
              onClick: openForm,
            },
            {
              key: "see",
              text: commandBarNames.see,
              iconProps: { iconName: "textDocument" },
              name: "see",
              onClick: openDisplay,
            },
          ];
        } else {
          return [
            {
              key: "see",
              text: commandBarNames.see,
              iconProps: { iconName: "textDocument" },
              name: "see",
              onClick: openDisplay,
            },
          ];
        }
      default:
        return [];
    }
  };

  const items = generateItems()

  return{
      items
  }
};
