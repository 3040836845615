import { ClientAction } from "./clientActions.types";
import { ClientActions } from "./clientActions.enum";

const initState = {
  navOpen: false,
  selfEditPanelOpen: false,
  receptionPlacePanelOpen: false,
  selectedItem: "",
  formActive: false,
  orderPanelOpen: false,
  editOrder: false,
  orderDetails: {},
  ordersReportPanelOpen: false,
  reloadOrders: false
};

export const clientReducer = (state = initState, action: ClientAction) => {
  const { type, payload } = action;

  switch (type) {
    case ClientActions.TOGGLE_NAV:
      return { 
        ...state, 
        navOpen: payload };
    case ClientActions.TOGGLE_SELF_EDIT_PANEL:
      return { 
        ...state, 
        selfEditPanelOpen: payload };
    case ClientActions.TOGGLE_RECEPTIONPLACE_PANEL:
      return {
        ...state,
        receptionPlacePanelOpen: payload.isOpen,
        formActive: payload.formActive,
      };
    case ClientActions.TOGGLE_ORDER_PANEL:
      return {
        ...state,
        orderPanelOpen: payload.isOpen,
        formActive: payload.formActive,
        editOrder: payload.editOrder
      };
    case ClientActions.TOGGLE_ORDERS_REPORT_PANEL:
      return {
        ...state,
        ordersReportPanelOpen: payload.isOpen,
      };
    case ClientActions.SET_SELECTED_ITEM:
      return { ...state, selectedItem: payload };
    case ClientActions.SET_ORDER_DETAILS:
      return { ...state, orderDetails: payload};
    case ClientActions.RELOAD_ORDERS:
      return { ...state, reloadOrders: payload};
    default:
      return state;
  }
};
