export const receptionPlacesColumnsShort = (columnNames: any) => [
  {
    key: "thumbnail",
    minWidth: 16,
    maxWidth: 16,
    fieldName: "thumbnail",
    name: "thumbnail",
    isIconOnly: true,
    isResizable: false,
  },
  {
    key: "street",
    name: columnNames.streetAndNumber,
    fieldName: "street",
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
  },
  {
    key: "postalCode",
    name: columnNames.postalCode,
    fieldName: "postalCode",
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
  },
  {
    key: "city",
    name: columnNames.city,
    fieldName: "city",
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
  },
  {
    key: "phone",
    name: columnNames.phoneNumber,
    fieldName: "phone",
    minWidth: 150,
    maxWidth: 200,
    isResizable: true,
  },
  {
    key: "bdoNumber",
    name: columnNames.bdoNumber,
    fieldName: "bdoNumber",
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
  },
];