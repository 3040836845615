import { editUser } from "./../../../connectors/clients/connectors/index";
import { useMemo, useState } from "react";
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";
import { FormikValues } from "formik";

import {
  toggleEditing,
  setSelectedIds,
} from "./../../../config/redux/clients/panel/clientPanelActions";
import { inviteUser } from "./../../../connectors/auth/connectors/index";
import {
  clearAlerts,
  setSuccessAlert,
  setErrorAlert,
} from "./../../../config/redux/alerts/alertsActions";
import { IResetForm } from "./../../common/models/resetForm.type";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

export const useUserForm = () => {
  const [isLoading, setIsLoading] = useState(false);

  const { activeClient } = useSelector(
    (state: RootStateOrAny) => state.clients
  );
  const { t } = useTranslation("common");
  
  const { selectedIds, basicInfo } = useSelector(
    (state: RootStateOrAny) => state.clientPanel
  );
  const dispatch = useDispatch();

  const initValues = useMemo(
    () => ({
      email: selectedIds[0] ? selectedIds[0].email : "",
      firstName: selectedIds[0] ? selectedIds[0].firstName : "",
      lastName: selectedIds[0] ? selectedIds[0].lastName : "",
      department: selectedIds[0] ? selectedIds[0].department : "",
      phoneNumber: selectedIds[0] ? selectedIds[0].phone : "",
      isClientAdmin: selectedIds[0] ? selectedIds[0].isClientAdmin : false,
    }),
    [JSON.stringify(selectedIds)]
  );

  const add = async (values: FormikValues, { resetForm }: IResetForm) => {
    setIsLoading(true);
    try {
      dispatch(clearAlerts());
      const body = getBody(values);
      const departmentId = values.department
        ? values.department
        : basicInfo.departments[0].id;

      body.companyId = basicInfo.basicInformations.id
      await inviteUser(body)

      resetForm();
      setIsLoading(false);
      whileSuccesAdd();
    } catch (err:any) {
      if (err.response.data.Errors[0]) {
        setIsLoading(false);
        dispatch(setErrorAlert('Wystąpił błąd'));
      }
    }
  };

  const edit = async (values: FormikValues, { resetForm }: IResetForm) => {
    setIsLoading(true);
    try {
      dispatch(clearAlerts());
      const body = {
        departmentId: values.department,
        firstName: values.firstName,
        lastName: values.lastName,
        phoneNumber: values.phoneNumber,
        isClientAdmin: values.isClientAdmin,
      };

      await editUser(selectedIds[0].id, body);
      setIsLoading(false);
      resetForm();
      whileSuccesEdit();
    } catch (err:any) {
      if (err.response.data.Errors[0]) {
        setIsLoading(false);
        var response = t("errorOccured");
        dispatch(setSuccessAlert(response));
      }
    }
  };

  const whileSuccesAdd = () => {
    var response = t("invitationSent");
    dispatch(setSuccessAlert(response));
    dispatch(setSelectedIds({ selectedIds: [] }));
    dispatch(toggleEditing({ editing: false }));
  };

  const whileSuccesEdit = () => {
    var response = t("changesSaved");
    dispatch(setSuccessAlert(response));
    dispatch(setSelectedIds({ selectedIds: [] }));
    dispatch(toggleEditing({ editing: false }));
  };

  const getBody = (values: any) => ({
    email: values.email,
    // password: values.pass,
    // passwordConfirm: values.pass2,
    firstName: values.firstName,
    lastName: values.lastName,
    companyId: values.companyId
    // phone: values.phoneNumber,
    // isClientAdmin: values.isClientAdmin
  });

  const departments = useMemo(() => {
    return basicInfo.departments.map((department: any) => {
      return {
        key: department.id,
        text: `${department.city} ${department.street}`,
      };
    });
  }, [basicInfo]);

  return {
    add,
    initValues,
    isLoading,
    selectedIds,
    activeClient: basicInfo,
    edit,
    departments,
  };
};
