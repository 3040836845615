import { IUnverifiedActions } from "../../../models/action.types";
import { Quantity, IQuantity } from "../../../../common/models/quantity.model";
import { OrderTypeString } from "../../../../common/enums/orderTypes.enum";


export const generateUnverifiedcommands = (
    quantity: IQuantity,
    actions: IUnverifiedActions,
    orderTypes:any
  ) => {
    const itemsArr = orderTypes.map(({ id, name }: any) => {
      let icon = { iconProps: {} };

      if (name === OrderTypeString.Vehicle) {
        icon = { iconProps: { iconName: "Car" } };
      } else if (name === OrderTypeString.Container) {
        icon = { iconProps: { iconName: "Inbox" } };
      } else if (name === OrderTypeString.Weight) {
        icon = { iconProps: { iconName: "Weights" } };
      }

      return {
        key: id,
        id: id,
        text: name,
        iconProps: icon.iconProps,
        onClick: actions.verify,
      };
    })

    if (quantity === Quantity.NONE) {
      return [
        {
          key: "add",
          text: "Dodaj",
          iconProps: { iconName: "add" },
          name: "add",
          onClick: actions.addCompany,
        },
      ];
    } else if(quantity === Quantity.ONE){
      

      return [
        {
          key: "see",
          text: "Zobacz",
          iconProps: { iconName: "textDocument" },
          name: "see",
          onClick: actions.see,
        },
        {
          key: "add",
          text: "Dodaj",
          iconProps: { iconName: "add" },
          name: "add",
          onClick: actions.addCompany,
        },
        {
          key: "edit",
          text: "Edytuj",
          iconProps: { iconName: "edit" },
          name: "edit",
          onClick: actions.edit,
        },
        {
          key: "verify",
          text: "Zweryfikuj",
          iconProps: { iconName: "send" },
          name: "verify",
          subMenuProps: {
            items: [...itemsArr],
          },
        },
        {
          key: "delete",
          text: "Usuń",
          iconProps: { iconName: "delete" },
          name: "delete",
          onClick: actions.removeCompany,
        },
      ];
  
    }else {
      return [
        {
          key: "add",
          text: "Dodaj",
          iconProps: { iconName: "add" },
          name: "add",
          onClick: actions.addCompany,
        },
        {
          key: "verify",
          text: "Zweryfikuj",
          iconProps: { iconName: "send" },
          name: "verify",
          onClick: actions.verify,
          subMenuProps: {
            items: [...itemsArr],
          },
        },
        {
          key: "delete",
          text: "Usuń",
          iconProps: { iconName: "delete" },
          name: "delete",
          onClick: actions.removeCompany,
        },
      ];
    }
  };