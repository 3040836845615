import { INavLinkGroup } from "office-ui-fabric-react";
import { NavigationPath } from "../../../config/routing/NavigationPath";
import { LinksNames } from "../models/LinkNames.enum"

export const navLinkGroups: INavLinkGroup[] = [
    {
        name: "Klienci",
        links: [
            {
                key: `${NavigationPath.UNVERIFIED_CLIENTS}`,
                name: `${LinksNames.UNVERIFIED_CLIENTS}`,
                url: "",
            },
            {
                key: `${NavigationPath.ACTIVE_CLIENTS}`,
                name: `${LinksNames.ACTIVE_CLIENTS}`,
                url: "",
            },
            {
                key: `${NavigationPath.INACTIVE_CLIENTS}`,
                name: `${LinksNames.INACTIVE_CLIENTS}`,
                url: "",
            },
            {
                key: `${NavigationPath.RECEPTION_PLACES}`,
                name: `${LinksNames.RECEPTION_PLACES}`,
                url: "",
            },
        ],
    },
    {
        name: "Zamówienia",
        links: [
            {
                key: `${NavigationPath.NEW_ORDERS}`,
                name: `${LinksNames.NEW_ORDERS}`,
                url: "",
            },
            {
                key: `${NavigationPath.ACCEPTED_ORDERS}`,
                name: `${LinksNames.ACCEPTED_ORDERS}`,
                url: "",
            },
            {
                key: `${NavigationPath.IN_PROGRESS_ORDERS}`,
                name: `${LinksNames.IN_PROGRESS_ORDERS}`,
                url: "",
            },
            {
                key: `${NavigationPath.COMPLETED_ORDERS}`,
                name: `${LinksNames.COMPLETED_ORDERS}`,
                url: "",
            },
            {
                key: `${NavigationPath.INVOICED_ORDERS}`,
                name: `${LinksNames.INVOICED_ORDERS}`,
                url: "",
            },
            {
                key: `${NavigationPath.CANCELED_ORDERS}`,
                name: `${LinksNames.CANCELED_ORDERS}`,
                url: "",
            },
            {
                key: `${NavigationPath.ALL_ORDERS}`,
                name: `${LinksNames.ALL_ORDERS}`,
                url: "",
            },
        ],
    },
    {
        name: "Walidatory",
        links: [
            {
                key: `${NavigationPath.CONTAINERS_VALIDATORS}`,
                name: `${LinksNames.CONTAINERS_VALIDATORS}`,
                url: "",
            },
            {
                key: `${NavigationPath.RECEPTION_PLACES_VALIDATORS}`,
                name: `${LinksNames.RECEPTION_PLACES_VALIDATORS}`,
                url: "",
            },
            {
                key: `${NavigationPath.ORDERS_VALIDATORS}`,
                name: `${LinksNames.ORDERS_VALIDATORS}`,
                url: "",
            },
        ],
    },
    {
        name: "Cennik",
        links: [
            {
                key: `${NavigationPath.GENERAL_PRICE_LIST}`,
                name: `${LinksNames.GENERAL_PRICE_LIST}`,
                url: "",
            },
            {
                key: `${NavigationPath.DETAILED_PRICE_LIST}`,
                name: `${LinksNames.DETAILED_PRICE_LIST}`,
                url: "",
            },
        ],
    },
    {
        name: "Magazyn",
        collapseByDefault: true,
        links: [
            {
                key: `${NavigationPath.STORE_LIST}`,
                name: `${LinksNames.STORE_LIST}`,
                url: "",
            },
            {
                key: `${NavigationPath.INTERALSTORE_LIST}`,
                name: `${LinksNames.INTERALSTORE_LIST}`,
                url: "",
            },
            {
                key: `${NavigationPath.STORE_DOCS_LIST}`,
                name: `${LinksNames.STORE_DOCS_LIST}`,
                url: "",
            },
            {
                key: `${NavigationPath.CONTAINERS_LIST}`,
                name: `${LinksNames.CONTAINERS_LIST}`,
                url: "",
            },
            {
                key: `${NavigationPath.STORE_MAP}`,
                name: `${LinksNames.STORE_MAP}`,
                url: "",
            },
        ]
    },
    {
        name: "Raporty",
        collapseByDefault: true,
        links: [
            {
                key: `${NavigationPath.REPORT_LIST}`,
                name: `${LinksNames.REPORT_LIST}`,
                url: "",
            },
        ]
    },
    {
        name: "Ustawienia",
        collapseByDefault: true,
        links: [
            {
                key: `${NavigationPath.ORDER_TYPES}`,
                name: `${LinksNames.ORDER_TYPES}`,
                url: "",
            },
            {
                key: `${NavigationPath.VEHICLES}`,
                name: `${LinksNames.VEHICLES}`,
                url: "",
            },
            {
                key: `${NavigationPath.DRIVERS}`,
                name: `${LinksNames.DRIVERS}`,
                url: "",
            },
            {
                key: `${NavigationPath.OPERATORS}`,
                name: `${LinksNames.OPERATORS}`,
                url: "",
            },
            {
                key: `${NavigationPath.CANCELLATION_REASONS}`,
                name: `${LinksNames.CANCELLATION_REASONS}`,
                url: "",
            },
            {
                key: `${NavigationPath.VALIDATOR_TYPES}`,
                name: `${LinksNames.VALIDATOR_TYPES}`,
                url: "",
            },
            {
                key: `${NavigationPath.NOT_CONFIRMED_USERS}`,
                name: `${LinksNames.NOT_CONFIRMED_USERS}`,
                url: "",
            },
            {
                key: `${NavigationPath.ADMINS}`,
                name: `${LinksNames.ADMINS}`,
                url: "",
            },
        ],
    },
];