import { OperatorsActions } from "./operatorsActions.enum";

export const toggleOperatorsPanel = (isOpen: boolean) => {
  return {
    type: OperatorsActions.TOGGLE_OPERATORS_PANEL,
    payload: isOpen,
  };
};

export const setSelectedOperator = (operatorId: string) => {
  return {
    type: OperatorsActions.SET_SELECTED_OPERATOR,
    payload: operatorId,
  };
};
