import React, { } from 'react';
import { wrapper } from '../../reports/styles/style';
import OrderDetailTxt from '../../orders/components/forms/edditOrder/OrderDetailTxt';

interface IContainerShowForm {
    initValues: any
}

const ContainerShowForm: React.FC<IContainerShowForm> = ({ initValues }) => {

    return (
        <div className={wrapper}>
            <div>
                <OrderDetailTxt
                    title="Numer kontenera"
                    value={initValues.ContainerNumber}
                />
                <OrderDetailTxt
                    title="Status kontenera"
                    value={initValues.statusString}
                />
                {/* <OrderDetailTxt
                    title="Kod QR / Identyfikator kontenera"
                    value={initValues.identifier}
                /> */}
                <OrderDetailTxt
                    title="Numer ICCID"
                    value={initValues.iccid}
                />
                <OrderDetailTxt
                    title="Numer IMEI"
                    value={initValues.imei}
                />
                <OrderDetailTxt
                    title="Numer Seryjny"
                    value={initValues.serNo}
                />
                <OrderDetailTxt
                    title="Numer kontenera"
                    value={initValues.containerNumber}
                />
                <OrderDetailTxt
                    title="Opis"
                    value={initValues.description}
                />
                {/* <OrderDetailTxt
                    title="Kod UHF bramki"
                    value={initValues.uhfIdentifier}
                /> */}
                <OrderDetailTxt
                    title="Poziom baterii [V]"
                    value={initValues.currentBatteryLevel}
                />
                <OrderDetailTxt
                    title="Ostatnia aktywność"
                    value={initValues.lastActiveDate}
                />
                <OrderDetailTxt
                    title="Szerokość geograficzna"
                    value={initValues.latitude}
                />
                <OrderDetailTxt
                    title="Długość geograficzna"
                    value={initValues.longitude}
                />
                <OrderDetailTxt
                    title="Typ najbliższego punktu odbioru"
                    value={initValues.closestReceptionPlaceType}
                />
                <OrderDetailTxt
                    title="Adres najbliższego punktu odbioru"
                    value={initValues.closestReceptionPlaceAddress}
                />
                <OrderDetailTxt
                    title="Odległość od najbliższego punktu odbioru [metry]"
                    value={initValues.closestReceptionPlaceDistance}
                />
                <OrderDetailTxt
                    title="Najbliższa nazwa firmy"
                    value={initValues.closestCompanyName}
                />
                <OrderDetailTxt
                    title="Najbliższy NIP firmy"
                    value={initValues.closestCompanyTaxNumber}
                />
            </div>
        </div>
    )
};

export default ContainerShowForm;