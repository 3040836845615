import * as React from "react";
import { Route, Redirect } from "react-router-dom";
import { mergeStyles } from "office-ui-fabric-react";
import { NavigationPath } from "./NavigationPath";
import { authorize, Roles } from "./actions/authService";
import ClientLayout from "../../client/components/layout/ClientLayout";
import { Breakpoints } from "../../features/common/styles/utils/Breakpoints";

const main = mergeStyles({
    marginTop: "96px",

    selectors: {
        [`@media ${Breakpoints.LARGE}`]: {
            marginLeft: "230px",
            width: "calc(100% - 230px)",
        },
    },
});

export const AdminClientLayoutRoute = ({
    component: Component,
    layout: Layout,
    commandBar: CommandBar,
    ...rest
}: any) => {
    const { token, role, expired, isActivated, hasAllRequiredData } = authorize();
    return (
        <Route
            {...rest}
            render={(props) => {
                if (token && role === Roles.CLIENT_ADMIN && !expired) {
                    if (!isActivated || !hasAllRequiredData) {
                        return (
                            <Redirect to={{ pathname: NavigationPath.INACTIVE_ACCOUNT }} />
                        );
                    } else {
                        return <>
                            <ClientLayout />
                            {CommandBar && <CommandBar />}
                            <div className={main}>
                                <Component {...props} />
                            </div>
                        </>;
                    }
                } else if (token && !expired) {
                    return <Redirect to={{ pathname: NavigationPath.CLIENT_DASHBOARD }} />;
                } else {
                    return <Redirect to={{ pathname: NavigationPath.UNAVAILABLE }} />;
                }
            }}
        />
    );
};