import { DefaultButton, mergeStyles } from "office-ui-fabric-react";
import { Panel, PanelType } from "office-ui-fabric-react/lib/components/Panel";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { togglePricing } from "../../../../config/redux/register/registerActions";
import { getGroupedPrices } from "../../../../connectors/pricing/connectors";
import i18n from "../../../../localization/i18n";
import { closeBtnS } from "../../styles/pricingParagraph.styles";
import Map from "./Map";
import PricingParagraph from "./PricingParagraph";
import PricingTable from "./PricingTable";

const wrapper = mergeStyles({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

const container = mergeStyles({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",

  selectors: {
    ["@media (min-width: 768px)"]: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
    },
  },
});

const PricingPanel: React.FC = () => {

  const { pricingOpen } = useSelector(
    (state: RootStateOrAny) => state.register
  );
  const dispatch = useDispatch();

  const dismiss = () => {
    dispatch(togglePricing(false));
  }
  const [isLoading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>(undefined);

  useEffect(() => {
    setLoading(true);
    try {
      setPriceListData();
      setLoading(false);
    }
    catch (error: any) {
      console.log(error);
      setLoading(false);
    }

  }, [])

  const setPriceListData = async () => {

    var langId = 1
    if (i18n.language.startsWith("pl")) {
      langId = 1;
    }
    else if (i18n.language.startsWith("en")) {
      langId = 2;
    }
    else if (i18n.language.startsWith("de")) {
      langId = 3;
    }
    else if (i18n.language.startsWith("sk")) {
      langId = 4;
    }
    else if (i18n.language.startsWith("et")) {
      langId = 5;
    }
    else if (i18n.language.startsWith("cs")) {
      langId = 6;
    }

    var newData = await getGroupedPrices(langId.toString());
    setData(newData.data.data);
  };

  const { t } = useTranslation("pricelist");

  const headerText = t("price-list-from-date");

  return (
    <Panel
      //headerText="Cennik - obowiązuje od dnia: 06.07.2020r."
      headerText={headerText}
      isOpen={pricingOpen}
      closeButtonAriaLabel="Close"
      onDismiss={dismiss}
      type={PanelType.custom}
      onOuterClick={() => { }}
      isHiddenOnDismiss
      customWidth={"888px"}
    >
      <div className={container}>
        <div className={wrapper}>
          {isLoading == false && data != undefined && data[0] && data[0].items && <PricingTable data={data} />}
        </div>
        <div>
          <Map />
        </div>
      </div>
      <PricingParagraph paragraphs={[t("adress-title"), t("adress-first"), t("adress-second"), t("adress-thrid")]} />
      <PricingParagraph paragraphs={[t("alert-second")]} />
      <PricingParagraph paragraphs={[t("alert-first-part-one") + t("alert-first-part-two") + t("alert-first-part-three")]} />
      <PricingParagraph paragraphs={[t("alert-third-part-one") + t("alert-third-part-two")]} />
      <DefaultButton styles={closeBtnS} onClick={dismiss}>
        {t("close")}
      </DefaultButton>
    </Panel>
  );
};

export default PricingPanel;