import React from "react";
import RealizationError from "../../orders/components/RealizationError";
import { useLocation } from "react-router-dom";
import ListSuccessMessage from "../../common/layout/ListSuccessMessage";
import DetailedPriceList from "./DetailedPriceList";

export interface IStateProps {
  isPanelOpen: boolean,
}

const DetailedPriceLayout: React.FC<IStateProps> = ({ isPanelOpen }) => {
  const location = useLocation();
  return (
    <>
      <ListSuccessMessage />
      <DetailedPriceList pathname={location.pathname} isPanelOpen={false} />
      <RealizationError />
    </>
  );
};

export default DetailedPriceLayout;