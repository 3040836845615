import * as React from "react";
import {
  Text,
  ITextStyles,
} from "office-ui-fabric-react";
import { FontSizes } from "@uifabric/fluent-theme";
import { FontWeights } from "office-ui-fabric-react";



const headerS: ITextStyles = {
  root: {
    fontSize: FontSizes.size20,
    fontWeight: FontWeights.semibold,
    paddingBottom: 30
  },
};


interface FormHeaderProps {
 text: string
}
 
const FormHeader: React.FC<FormHeaderProps> = ({text}) => {
    return ( 
        <Text styles={headerS}>
            {text}
        </Text>
     );
}
 
export default FormHeader;