import { setListMessage } from './../../../../config/redux/alerts/alertsActions';
import { toggleReasonsPanel, setActiveReasonType } from './../../../../config/redux/cancellationReasons/cancellationReasonsActions';
import { useSelector, RootStateOrAny, useDispatch } from "react-redux";

import { checkQuantity } from "../../actions/checkQuantity";
import { generateItems } from "../../actions/generateCommands/generateCancellationReasonsItems";
import { useEffect, useState } from "react";
import { deleteReasonById, getReasons } from "../../../../connectors/cancellationReasons/connectors";
import { useMutation, useQueryCache } from 'react-query';

export const useCancellationReasonsCommandBar = () => {
  const [reasons, setReasons] = useState([]);
  const cache = useQueryCache();
  const setReasonsData = async () => {
    const res = await getReasons();
    setReasons(res.data.data);
  };
  useEffect(() => {
    setReasonsData();
  }, []);

  const [mutate] = useMutation(deleteReasonById, {
    onSuccess: () => {
      cache.invalidateQueries("reasons");
      dispatch(setListMessage("Operacja przebiegła pomyślnie"))
    },
    onError: (err) => {
      throw new Error();
    },
  });

  const dispatch = useDispatch();
  const { selectedReason } = useSelector(
    (state: RootStateOrAny) => state.cancellationReasons
  );

  const openPanel = async () => {
    dispatch(toggleReasonsPanel(true));
  };

  const addReason = async (e: any) => {
    await dispatch(setActiveReasonType(e.currentTarget.id))
    dispatch(toggleReasonsPanel(true));
  }

  const deleteReason = async () => {
    await mutate(selectedReason[0].id)
  };

  const actions = {
    openPanel,
    delete: deleteReason,
    addReason
  };

  const quantity = checkQuantity(selectedReason.length);

  const items = generateItems(quantity, actions, reasons);

  return {
    items,
  };
};
