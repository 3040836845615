import { IStackTokens, mergeStyles, Stack } from '@fluentui/react';
import * as React from 'react';
import { getOrdersChartCountPerDate, getOrdersChartValuesPerDate } from '../../../connectors/dashboard/connectors';
import LineChartBasicExample from './LineChartBasicExample';

interface IDashboardLineChartsState {
    chartDataValuesPerDate: any;
    chartDataCountPerDate: any;
}

class DashboardLineCharts extends React.Component<{}, IDashboardLineChartsState> {
    constructor(props: any = {}) {
        super(props);
        
        this.state = {
            chartDataValuesPerDate: {
                regions: {} as any,
                general: {} as any
            },
            chartDataCountPerDate: {
                regions: {} as any,
                general: {} as any
            },
        };
    }

    async componentDidMount() {
        const chartDataValuesPerDate = await getOrdersChartValuesPerDate(7, []);
        const chartDataValuesPerDateRegions = await getOrdersChartValuesPerDate(7, ['0','1','2','3']);
        const chartDataCountPerDate = await getOrdersChartCountPerDate(7, []);
        const chartDataCountPerDateRegions = await getOrdersChartCountPerDate(7, ['0','1','2','3']);

        this.setState((prevState: IDashboardLineChartsState) => {
            return {
                ...prevState,
                chartDataValuesPerDate: { general: chartDataValuesPerDate.data, regions: chartDataValuesPerDateRegions.data },
                chartDataCountPerDate: { general: chartDataCountPerDate.data, regions: chartDataCountPerDateRegions.data },
            };
        });
    }

    public render(): JSX.Element {
        const { chartDataValuesPerDate, chartDataCountPerDate } = this.state;
        const stackWithTopBorder = mergeStyles({
            marginRight: 44,
            paddingTop: 25,
            borderTop: '1px solid rgba(128,128,128, 0.25)',
        });
        const sectionStackTokens: IStackTokens = { childrenGap: 40 };

        return <>
            <Stack horizontal tokens={sectionStackTokens} className={stackWithTopBorder}>
                <LineChartBasicExample lineChartData={chartDataValuesPerDate.regions.lineChartData} lineChartTitle={"Wartości zamówień względem daty dla regionu Chełma:"} />
                <LineChartBasicExample lineChartData={chartDataCountPerDate.regions.lineChartData} lineChartTitle={"Ilość zamówień względem daty dla regionu Chełma:"} />
            </Stack>

            <Stack horizontal tokens={sectionStackTokens} className={stackWithTopBorder}>
                <LineChartBasicExample lineChartData={chartDataValuesPerDate.general.lineChartData} lineChartTitle={"Wartości zamówień względem daty ogółem:"} />
                <LineChartBasicExample lineChartData={chartDataCountPerDate.general.lineChartData} lineChartTitle={"Ilość zamówień względem daty ogółem:"} />
            </Stack>
        </>;
    }
}

export default DashboardLineCharts;