import { setListMessage, clearListMessages } from './../../../../config/redux/alerts/alertsActions';
import { useEffect, useState } from "react";
import { useSelector, RootStateOrAny, useDispatch } from "react-redux";

import { checkQuantity } from "../../actions/checkQuantity";
import {
  setActiveOrderType,
  setEditing,
} from "../../../../config/redux/orderTypes/orderTypesActions";
import { generateItems } from "../../actions/generateCommands/generateOrderTypesComands";
import {
  getOrderTypes,
  deleteOrderTypeItem,
} from "../../../../connectors/orderTypes/connectors/index";
import { useMutation, useQueryCache } from "react-query";
import { setListErr } from "../../../../config/redux/alerts/alertsActions";

export const useOrderTypeCommandBar = () => {
  const [orderTypes, setOrderTypes] = useState([]);
  const dispatch = useDispatch();
  const { selectedOrderTypes } = useSelector(
    (state: RootStateOrAny) => state.orderTypes
  );
  const cache = useQueryCache();
  const [mutate] = useMutation(deleteOrderTypeItem, {
    onSuccess: () => {
      cache.invalidateQueries("orderTypes");
      dispatch(setListMessage("Operacja przebiegła pomyślnie"))
    },
    onError: (err) => {
      throw new Error();
    },
  });

  useEffect(() => {
    setOrderTypesData();
  }, []);

  const setOrderTypesData = async () => {
    const res = await getOrderTypes();
    setOrderTypes(res.data.data);
  };

  const quantity = checkQuantity(selectedOrderTypes.length);

  const setOrderType = (e: any) => {
    dispatch(setActiveOrderType({ activeOrderType: e.currentTarget.id }));
  };

  const addOrderType = (e: any) => {
    dispatch(setActiveOrderType({ activeOrderType: e.currentTarget.id }));
    dispatch(setEditing({ editing: true }));
  };

  const deleteOrderTypes = async () => {
    dispatch(clearListMessages())
    try{
      await mutate({
        orderTypeId: selectedOrderTypes[0].orderTypeId,
        itemId: selectedOrderTypes[0].id,
      });
      
    }catch(err){
      dispatch(setListErr("Typ jest przypisany do zamówienia"))
    }
    
  };

  const edit = () => {
    dispatch(setEditing({ editing: true }));
  };

  const actions = { setOrderType, addOrderType, deleteOrderTypes, edit };
  const items = generateItems(quantity, actions, orderTypes, selectedOrderTypes);

  return {
    items,
  };
};