import * as React from "react";
import {
    CommandBar,
} from "office-ui-fabric-react";
import { comandBarS } from "../../styles/commandBar.styles"
import { useNotConfirmedUsersCommandBar } from "../../hooks/settings/useNotConfirmedUsersCommandBar";

const NotConfirmedUsersCommandBar: React.FC = () => {
    
    const { items } = useNotConfirmedUsersCommandBar()

    return <CommandBar items={items} styles={comandBarS} />;
}

export default NotConfirmedUsersCommandBar;