import React from "react";
import { RootStateOrAny, useSelector, useDispatch } from "react-redux";
import { Panel } from "office-ui-fabric-react";
import { panelS } from "../../common/styles/panelStyles";
import { toggleStoreMapPanel } from "../../../config/redux/storeMap/StoreMapActions";
import ContainerShowForm from "../../containers/components/ContainerShowForm";
import ContainerEditForm from "../../containers/components/ContainerEditForm";
import { useStoreMapContainerForm } from "../hooks/useStoreMapContainerForm";
import ActivityIndicator from "../../common/layout/ActivityIndicator";

const StoreMapPanel: React.FC = () => {
    const dispatch = useDispatch();
    const { containerPanelOpen, isEditContainerForm } = useSelector(
        (state: RootStateOrAny) => state.storeMap
    );

    const dismissPanel = () => {
        dispatch(toggleStoreMapPanel({ isOpen: false }));
    };

    const {
        initValues,
        cancelForm,
        handleSubmit,
        fetchingContainer,
        loading,
        gpsDeviceStatuses

    } = useStoreMapContainerForm()

    return (
        <Panel
            isOpen={containerPanelOpen}
            closeButtonAriaLabel="Close"
            headerText={isEditContainerForm ? "Edytuj kontener" : "Szczegóły konteneru"}
            onDismiss={dismissPanel}
            type={3}
            styles={panelS}
            isHiddenOnDismiss={true}
        >
            {fetchingContainer ? <ActivityIndicator /> :
                isEditContainerForm ?
                    <ContainerEditForm
                        initValues={initValues}
                        handleSubmit={handleSubmit}
                        cancelForm={cancelForm}
                        loading={false}
                        gpsDeviceStatuses={gpsDeviceStatuses}
                    /> :
                    <ContainerShowForm
                        initValues={initValues}
                    />}
        </Panel>
    );
};

export default StoreMapPanel;