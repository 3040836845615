import * as yup from "yup";

///

export const validationSchemaDrivers = (validationSchemaNames: any) => yup.object().shape({
  lastName: yup.string().required(validationSchemaNames.checkDriverLastName).max(50),
  firstName: yup.string().required(validationSchemaNames.checkDriverFirstName).max(50),
  phone: yup
    .string()
    .required(validationSchemaNames.checkDriverPhoneNumber)
    .matches(/^[0-9]+$/, validationSchemaNames.checkDriverPhoneNumber)
    .min(9, validationSchemaNames.checkDriverPhoneNumber)
    .max(9, validationSchemaNames.checkDriverPhoneNumber),
  email: yup
    .string()
    .email(validationSchemaNames.checkDriverEmail)
    .required(validationSchemaNames.checkDriverEmail)
    .max(50),
});