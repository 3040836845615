import * as React from "react";
import { useSelector, RootStateOrAny } from "react-redux";
import BasicList from "../../common/layout/BasicList";
import { notConfirmedUsersColumns } from "../columns/notConfirmedUsersColumns";
import { getNotConfirmedUsers } from "../../../connectors/notConfirmedUsers/connectors";
import { reloadNotConfirmedUser, setNotConfirmedUser, setSelectedNotConfirmedUser, toggleNotConfirmedUserPanel } from "../../../config/redux/notConfirmedUsers/notConfirmedUsersActions";

const NotConfirmedUsersList: React.FC = () => {
  const {
    selectedNotConfirmedUsers,
    activeNotConfirmedUser,
    notConfirmedUserPanelOpen,
    reloadNotConfirmedUsers,
    addDriver,
  } = useSelector((state: RootStateOrAny) => state.notConfirmedUsers);

  const parseNotConfirmedUser = (items: Array<any>) => {
    const notConfirmedUsers = items.map((item: any) => item.userData);
    return notConfirmedUsers;
  };

  return (
    <>
      <BasicList
        setSelectedItems={setSelectedNotConfirmedUser}
        setActiveItem={setNotConfirmedUser}
        togglePanel={toggleNotConfirmedUserPanel}
        reload={reloadNotConfirmedUser}
        editList={reloadNotConfirmedUser}
        getData={getNotConfirmedUsers}
        activeItem={activeNotConfirmedUser}
        panelOpen={notConfirmedUserPanelOpen}
        selectedItems={selectedNotConfirmedUsers}
        removeItems={reloadNotConfirmedUsers}
        listEdited={reloadNotConfirmedUsers}
        columns={notConfirmedUsersColumns}
        parseItems={parseNotConfirmedUser}
      />
    </>
  );
};

export default NotConfirmedUsersList;