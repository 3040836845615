import * as React from "react";
import {
  Stack,
  IStackStyles,
  mergeStyles,
  getTheme,
  IStackTokens,
  SpinnerSize,
  Spinner,
  Text,
  ITextStyles,
  FontSizes,
  FontIcon,
} from "office-ui-fabric-react";
import { Depths } from "@uifabric/fluent-theme";
import { Breakpoints } from "../features/common/styles/utils/Breakpoints";
import Title from "../features/auth/components/login/Title";

import { QueryStatus } from "react-query";
import { useTranslation } from "react-i18next";
import { ICommonUnavailableLang } from "../localization/abstract/client/common";

const theme = getTheme();
const wrapperS: IStackStyles = {
  root: {
    width: "100%",
    height: "100vh",
    background: "radial-gradient(circle, rgba(204,204,204,1) 6%, rgba(0,103,184,0.15730042016806722) 94%)",
    backgroundSize: 'cover',
    selectors: {
      [`@media ${Breakpoints.LARGE_MAX}`]: {
        backgroundColor: theme.palette.white,
        backgroundSize: 'cover',
      },
    },
  },
};

const borderStackS: IStackStyles = {
  root: {
    border: '2px solid' + theme.palette.neutralLight,
    padding: '15px'
  },
};

const containerS: IStackStyles = {
  root: {
    display: "flex",
    flexDirection: "column",
    margin: "auto auto",
    backgroundColor: theme.palette.white,

    selectors: {
      [`@media ${Breakpoints.LARGE}`]: {

        minWidth: "320px",
        minHeight: "338px",
        width: "440px",
        padding: "44px",

        position: "relative",
        boxShadow: Depths.depth16,
      },
      [`@media ${Breakpoints.LARGE_MAX}`]: {
        width: "100%",
        height: "100%"
      },
    },
  },
};

const stackStyles: IStackStyles = {
  root: {
  },
};

const stackItemStyles = mergeStyles({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  padding: '5px',
  textAlign: 'center',
  selectors: {
    [`@media ${Breakpoints.LARGE_MAX}`]: {
      marginTop: "20px",

    },
  },
});

const customSpacingStackTokens: IStackTokens = {
  childrenGap: '10%',
};

const customSpacingStackTokens2: IStackTokens = {
  childrenGap: '2%',
};

interface UnavailableProps {
  queryStatus: QueryStatus
}

const textS: ITextStyles = {
  root: {
    fontSize: FontSizes.large,
  },
};

const Unavailable: React.FC<UnavailableProps> = ({ queryStatus }) => {
  const { t } = useTranslation("common");
  const unavailableNames: ICommonUnavailableLang = t("unavailable", {
    returnObjects: true,
  });

  return (
    <>
      <Stack styles={wrapperS}>
        <Stack styles={containerS}>
          <Stack styles={stackStyles} tokens={customSpacingStackTokens}>
            <Stack.Item className={stackItemStyles}><Title /></Stack.Item >
            <Stack.Item className={stackItemStyles}>
              {queryStatus === 'loading' ?
                <Spinner size={SpinnerSize.large} /> :
                <Text styles={textS}>{unavailableNames.problemOccured} <br />{unavailableNames.contactWithAdmin} <br /><br /><FontIcon iconName="Warning" /> </Text>}
            </Stack.Item>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default Unavailable;