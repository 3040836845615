import api from "../../../config/axios/configAxios";
import { IDetailedPriceParams, IDatailedReportParams } from "../model";

export const getDetailedPrices = async (params?: IDetailedPriceParams) =>
  api.get(`/api/Admin/Prices/detailed`, { params });

export const getDatailedReport = async (params?: IDatailedReportParams) => {
  const res = await api.get("/api/Admin/Prices/detailed/report", {
    responseType: 'blob',
    params: params
  })

    const url = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'Raport.xlsx');
    document.body.appendChild(link);
    link.click();
};