import { ReportsActions } from "./reportsAction.enum";
import { IReportsAction } from "./reportsAction.types";

const initState = {
    selectedItem: [],
    activeReport: {},
    reportDetails: {},
    reportsPanelOpen: false,
    reloadReportList: false,
}

export const reportsReducer = (state = initState, action: IReportsAction) => {
    const { type, payload } = action;

    switch (type) {
        case ReportsActions.SET_SELECTED_REPORTS:
            return { ...state, selectedItem: payload };
        case ReportsActions.SET_REPORT_DETAILS:
            return { ...state, reportDetails: payload}
        case ReportsActions.TOGGLE_REPORTS_PANEL:
            return { ...state, reportsPanelOpen: payload.open }
        case ReportsActions.RELOAD_REPORTS:
            return { ...state, reloadReportList: payload.reload }
        case ReportsActions.SET_REPORT:
            return { ...state, activeReport: payload }
        case ReportsActions.CLEAR:
            return { ...initState }
        default:
            return state;
    }
}