import { useMemo, useState } from "react"
import { RootStateOrAny, useDispatch, useSelector } from "react-redux"
import { toggleNotConfirmedUserPanel } from "../../../config/redux/notConfirmedUsers/notConfirmedUsersActions"
import { FormikValues } from "formik"
import { IResetForm } from "../../common/models/resetForm.type"
import { setErrorAlert, setListMessage } from "../../../config/redux/alerts/alertsActions"

export const useNotConfirmedUsers = () => {
    const dispatch = useDispatch()
    const [loading, setIsLoading] = useState(false)
    const { selectedNotConfirmedUsers } = useSelector((state: RootStateOrAny) => state.notConfirmedUsers)


    const initValues = useMemo(() => {
        if(selectedNotConfirmedUsers[0] != null ){
            return {
                firstName: selectedNotConfirmedUsers[0].firstName,
                lastName: selectedNotConfirmedUsers[0].lastName,
                phone: selectedNotConfirmedUsers[0].phone,
                email: selectedNotConfirmedUsers[0].email,
                phoneNumber: selectedNotConfirmedUsers[0].phone,
            }
        }
        else {
            return {
                firstName: "",
                lastName: "",
                phone: "",
                email: "",
                phoneNumber: "",
            }
        }
    }, []);

    const cancelForm = () => {
        dispatch(toggleNotConfirmedUserPanel({ open: false }))
    }

    const confirm  = async (
        values: FormikValues,
        { resetForm }: IResetForm
      ) => {
        setIsLoading(false);
        try {
            

            resetForm();
            dispatch(setListMessage("Operacja przebiegła pomyślnie"))
        }
        catch (err) {
            setIsLoading(false)
            dispatch(setErrorAlert("Wystąpił błąd"))
          }
      }

    // const handleSubmit = async (
    //     values: FormikValues,
    //     { resetForm }: IResetForm
    //   ) => {
    //     setIsLoading(true)
    //     const body = {
    //       email: values.email,
    //       firstName: values.firstName,
    //       lastName: values.lastName,
    //       phone: values.phone,
    //       phoneNumber: values.phone,
    //     };
    //     try {
    //       dispatch(clearAlerts())
    //       selectedAdmins.length >= 1 ? await edit(body) : await add(body);
    //       setIsLoading(false)
    //       dispatch(setAdmin({}));
    //       dispatch(addNewAdmin({ add: true }));
    //       resetForm();
    //       dispatch(toggleAdminsPanel({ open: false }));
    //       dispatch(setListMessage("Operacja przebiegła pomyślnie"))
    //     } catch (err) {
    //       setIsLoading(false)
    //       dispatch(setErrorAlert("Wystąpił błąd"))
    //     }
    //   };
    


    return {
        loading,
        cancelForm,
        confirm,
        initValues
    }
}