import { IQuantity } from "../../../common/models/quantity.model";
import { NavigationPath } from "../../../../config/routing/NavigationPath";
import { generateDetailedPrice } from "./detailedPrice/detailedPriceCommands";

export const generateItems = (
  quantity: IQuantity,
  pathname: string,
  actions: any,
  selectedValidator?: any,
  dispatch?: any,
) => {
  switch (pathname) {
    case NavigationPath.DETAILED_PRICE_LIST:
      return generateDetailedPrice(quantity, actions, selectedValidator)
    default:
      return generateDetailedPrice(quantity, actions, selectedValidator)
  }
};