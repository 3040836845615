export const columns = [
    {
      key: "lastName",
      name: "Nazwisko",
      fieldName: "lastName",
      minWidth: 250,
      maxWidth: 300,
      isResizable: true,
    },
    {
      key: "firstName",
      name: "Imię",
      fieldName: "firstName",
      minWidth: 100,
      maxWidth: 100,
    },
    {
      key: "phone",
      name: "Telefon",
      fieldName: "phone",
      minWidth: 200,
      maxWidth: 250,
    },
  ];