import { IResetForm } from './../../common/models/resetForm.type';
import { completeOrder, editOrder } from './../../../connectors/orders/connectors/index';
import { FormikValues } from 'formik';
import { reloadOrders } from './../../../config/redux/orders/ordersActions';
import { clearAlerts, setListMessage } from './../../../config/redux/alerts/alertsActions';
import { useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { toggleOrderPanel } from '../../../config/redux/orders/ordersPanel/orderPanelActions';
import { OrderTypeNumber } from '../../common/enums/orderTypes.enum';

export const useEndOrder = () => {
  // const { orderPanelOpen } = useSelector((state: RootStateOrAny) => state.orders);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { orderDetails } = useSelector((state: RootStateOrAny) => state.orderPanel);

  const initValues = {
    driver: orderDetails ? orderDetails.vehicleId : "",
    orderTypeItemId: orderDetails ? orderDetails.orderTypeItemId : "",
    quantity: orderDetails ? orderDetails.quantity.toString() : "",
    quantityTaken: orderDetails ? orderDetails.quantity.toString() : "",
    receivingDate: "",
    netPrice: orderDetails ? orderDetails.netPrice : 0,
    grossPrice: orderDetails ? orderDetails.grossPrice : 0
  };

  const cancelForm = () => {
    dispatch(clearAlerts());
    dispatch(toggleOrderPanel(false));
  };

  const parseTonnage = (tonnage: any) => {
    return tonnage.replace(',', '.');
  }

  const TaxValue = 1.08;

  

  const handleSubmit = async (values: FormikValues, { resetForm }: IResetForm) => {
    setIsLoading(true);
    try {
      console.log(orderDetails)
      var newQuantity = orderDetails.orderTypeId == OrderTypeNumber.Tonnage ? values.tonnage : values.quantityTaken

      await editOrder(orderDetails.orderInfo.id, {
        receptionPlace: orderDetails.receptionPlaceId,
        quantity: values.quantityTaken,
        orderTypeItemId: values.orderTypeItemId,
        recycleOperatorId: values.operator,
        receivingDate: values.receivingDate,
        tonnage: parseTonnage(values.tonnage),
        kpo: values.kpo,
        netPrice: !!values.updatedPrice ? values.updatedPrice : values.netPrice,
        grossPrice: !!values.updatedPrice ? values.updatedPrice * TaxValue : values.grossPrice,
        paymentType: orderDetails.paymentType,
        netValue: !!values.updatedPrice ? values.updatedPrice * newQuantity : values.netPrice * newQuantity,
        grossValue: !!values.updatedPrice ? (values.updatedPrice * TaxValue) * newQuantity : values.grossPrice * newQuantity,
        vatValue: orderDetails.vatValue,
        vatRate: orderDetails.vatRate,
        vatSymbol: orderDetails.vatSymbol,
        vehicleId: values.driver,
        internalStoreId: values.internalStoreId
      });

      await completeOrder(orderDetails.orderInfo.id);
      setIsLoading(false);
      dispatch(reloadOrders(true));
      resetForm();
      dispatch(toggleOrderPanel(false));
      dispatch(setListMessage("Pomyślnie zakończono zamówienie"));
    } catch (err) {
      setIsLoading(false);
      console.error(err);
    }
  };

  return {
    initValues,
    cancelForm,
    handleSubmit,
    isLoading,
    orderDetails
  };
};