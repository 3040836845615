import * as React from "react";


import ListSuccessMessage from "../../common/layout/ListSuccessMessage";
import { mergeStyles } from "office-ui-fabric-react";
import AdminsList from "../components/AdminsList";
import AdminPanel from "../components/AdminPanel";

const padding = mergeStyles({
  paddingTop: 15
})

const wrapper = mergeStyles({
  width: "95%",
  margin: "0px auto",
});


const Admins: React.FC = () => {
  return (
    <div className={wrapper}>
      <div className={padding}> 
        <ListSuccessMessage/>
      </div>
      <AdminsList />
      <AdminPanel />
    </div>
  );
};

export default Admins;
