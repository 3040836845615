import axios from "axios";
import { useDispatch } from "react-redux";
import api from "../../../config/axios/configAxios";
import { setErrorAlert } from "../../../config/redux/alerts/alertsActions";
import {
  IOrderParams,
  IRealizationDate,
  IAddOrder,
  IOrdersReportParams,
} from "../../../connectors/orders/models/index";

export const getOrders = async (params?: IOrderParams) =>
  api.get(`/api/Admin/Orders`, { params });

export const getOrderHistory = async (orderId: string) =>
  api.get(`/api/Admin/Orders/${orderId}/history`);

export const getPickUpPoints = async (departmentId: string) =>
  api.get(`/api/Admin/Departments/${departmentId}/reception-places`);

export const getOrderDetails = async (orderId: string) =>
  api.get(`/api/Admin/Orders/${orderId}/details`);

export const getOrderComments = async (orderId: string) =>
  api.get(`/api/Admin/Orders/${orderId}/comments`);

export const addComment = async (orderId: string, data: any) =>
  api.post(`/api/Admin/Orders/${orderId}/add-comment`, data);

export const setToRealization = async (realizationInfo: IRealizationDate) =>
  api.put(`/api/Admin/Orders/set-realization`, realizationInfo);

export const acceptOrder = async (orderId: string) =>
  api.put(`/api/Admin/Orders/${orderId}/accept`);

export const cancelOrder = async (orderId: string, reason: string, comment: string) =>
  api.put(`/api/Admin/Orders/${orderId}/cancel`, { reasonForCancel: reason, Comment: comment });

export const addOrder = async (data: IAddOrder) =>
  api.post(`/api/Admin/Orders`, data);

export const editOrder = async (orderId: string, data: any) =>
  api.put(`/api/Admin/Orders/${orderId}`, data);

export const completeOrder = async (orderId: string) =>
  api.put(`/api/Admin/Orders/${orderId}/set-realized`);

export const correctOrder = async (orderId: string, data: any) => 
  api.put(`/api/Admin/Orders/${orderId}/correct`, data);

export interface IGetNetto {
  receptionPlaceId: number,
  itemId: number
}
export const syncOptima = async (orderId: string) => 
api.put(`/api/Admin/Orders/${orderId}/sync`, {});

export const getNetto = async (data: IGetNetto) =>
  api.get(`/api/Admin/OrderTypeItems/${data.itemId}?receptionPlaceId=${data.receptionPlaceId}`);

export const getOrdersReport = async (data: IOrdersReportParams) => {
  return api.get("/api/Admin/Orders/report", { responseType: 'blob', params: data})
};

export const sendSMSToDrivers = async (orders: any) =>
  api.post(`/api/Admin/Orders/notify-driver`, {orders: orders, channel: 0});
