import { clearAlerts, setListMessage, setErrorAlert } from './../../../config/redux/alerts/alertsActions';
import { useDispatch, RootStateOrAny, useSelector } from "react-redux";
import { FormikValues } from "formik";
import {
  setDriver,
  addNewDriver,
  toggleDriversPanel,
} from "../../../config/redux/drivers/driversActions";
import { addDriver, editDriver } from "../../../connectors/drivers/connectors/index";
import { IDriver } from "../../../connectors/drivers/models/index"
import { IResetForm } from "../../common/models/resetForm.type";
import { useState } from "react";

export const useDriverForm = () => {
  const [loading, setIsLoading] = useState(false)
  const { selectedDrivers } = useSelector(
    (state: RootStateOrAny) => state.drivers
  );
  const dispatch = useDispatch();
  let initValues = {
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
  };

  if (selectedDrivers[0]) {
    initValues = {
      firstName: selectedDrivers[0].firstName,
      lastName: selectedDrivers[0].lastName,
      phone: selectedDrivers[0].phone,
      email: selectedDrivers[0].email,
    };
  }

  const add = async (data: IDriver) => {
    const res = await addDriver(data);
  };

  const edit = async (data: IDriver) => {
    editDriver(data, selectedDrivers[0].id);
  };

  const handleSubmit = async (
    values: FormikValues,
    { resetForm }: IResetForm
  ) => {
    setIsLoading(true)
    const body = {
      email: values.email,
      firstName: values.firstName,
      lastName: values.lastName,
      phone: values.phone,
    };

    try {
      dispatch(clearAlerts())
      selectedDrivers.length >= 1 ? await edit(body) : await add(body);
      setIsLoading(false)
      dispatch(setDriver({}));
      dispatch(addNewDriver({ add: true }));
      resetForm();
      dispatch(toggleDriversPanel({ open: false }));
      dispatch(setListMessage("Operacja przebiegła pomyślnie"))
    } catch (err) {
      setIsLoading(false)
      dispatch(setErrorAlert("Wystąpił błąd"))
    }
  };

  const cancelForm = () => {
    dispatch(toggleDriversPanel({ open: false }));
    dispatch(clearAlerts())
  };

  return {
    handleSubmit,
    initValues,
    cancelForm,
    selectedDrivers,
    loading
  };
};
