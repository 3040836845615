import { StoreActions } from "./storeAction.enums";
import { IReload, IStoreItem, IStoreItemPanel } from "./storeAction.types";

export const setStoreItem = (data: IStoreItem) => {
    return {
        type: StoreActions.SET_STOREITEM,
        payload: data
    }
}

export const setSelectedStoreItem = (data: Array<IStoreItem>) => {
    return {
        type: StoreActions.SET_SELECTED_STOREITEM,
        payload: data
    }
}

export const setStoreItemDetails = (data: any) => {
    return {
        type: StoreActions.SET_STOREITEM_DETAILS,
        payload:data
    }
}
export const toggleStoreItemPanel = (data: IStoreItemPanel) => {
    return {
        type: StoreActions.TOGGLE_STOREITEM_PANEL,
        payload: data
    }
}

// export const prepareNewReport = (data: IPrepare) => {
//     return {
//         type: ReportsActions.PREPARE_REPORT,
//         payload: data
//     }
// }

export const reloadStoreItems = (data: IReload) => {
    return {
        type: StoreActions.RELOAD_STOREITEMS,
        payload: data
    }
}

export const setMagazineType = (data: number | undefined) => {
    return {
        type: StoreActions.SET_MAGAZINETYPE,
        payload: data
    }
}

export const clearStoreItems = () => {
    return {
        type: StoreActions.CLEAR
    }
}