import { LangEnum } from "../enums/lang.enum";

export const ShortLangToEnum = (lang: string): 1 | 2 | 3 | 4 | 5 | 6 => {
    switch (lang) {
        case "pl":
            return LangEnum.Polish;
        case "en":
            return LangEnum.English;
        case "de":
            return LangEnum.German;
        case "sk":
            return LangEnum.Slovak;
        case "et":
            return LangEnum.Estonian;
        case "cs":
            return LangEnum.Czech;
    }
    return LangEnum.Polish;
}

export const LangEnumToCountryName = (lang: LangEnum) =>{
    switch (lang) {
        case LangEnum.Polish:
            return "Polska";
        case LangEnum.English: //api will not return this
            return "Anglia";
        case LangEnum.German:
            return "Niemcy";
        case LangEnum.Slovak:
            return "Słowacja";
        case LangEnum.Estonian:
            return "Estonia";
        case LangEnum.Czech:
            return "Estonia";
    }
}