import { useTranslation } from "react-i18next";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { reloadOrders, setSelectedListItem, toggleOrderPanel, toggleOrdersReportPanel } from "../../../config/redux/client/clientActions";
import { cancelOrder } from "../../../connectors/orders/connectors";
import { Quantity } from "../../../features/common/models/quantity.model";
import { IOrdersCommandBarLang } from "../../../localization/abstract/client/orders";

export const useOrdersCommandBar = () => {
  const dispatch = useDispatch();
  const { selectedItem } = useSelector((state: RootStateOrAny) => state.client);
  const quantity = selectedItem ? Quantity.ONE : Quantity.NONE;

  const { t } = useTranslation("orders");
  const commandBarNames: IOrdersCommandBarLang = t("commandBar", {
    returnObjects: true,
  });

  const canEdit = () => {
    if (selectedItem === undefined) return false;
    if (selectedItem.statusId === 0) return true;
    return false;

  }
  const openForm = () => {
    dispatch(toggleOrderPanel({ isOpen: true, formActive: true, editOrder: false }));
  };

  const openFormReport = () => {
    dispatch(toggleOrdersReportPanel({ isOpen: true }));
  };

  const openDisplay = () => {
    dispatch(toggleOrderPanel({ isOpen: true, formActive: false, editOrder: false }));
  }

  const edit = () => {
    dispatch(toggleOrderPanel({ isOpen: true, formActive: true, editOrder: true }));
    dispatch(setSelectedListItem(selectedItem))
  }

  const cancelOrderByUser = async () => {
    await cancelOrder(selectedItem ? selectedItem.key : "", "Anulowane przez użytkownika", "");
    dispatch(reloadOrders(true));
    dispatch(setSelectedListItem(""))
  }

  const generateItems = () => {
    switch (quantity) {
      case Quantity.NONE:
        return [
          {
            key: "add",
            text: commandBarNames.add,
            iconProps: { iconName: "add" },
            name: "add",
            onClick: openForm,
          },
          {
            key: "report",
            text: commandBarNames.download,
            iconProps: { iconName: "ReportDocument" },
            name: "report",
            onClick: openFormReport,
          },
        ];
      case Quantity.ONE:
        if (canEdit()) {
          return [
            {
              key: "see",
              text: commandBarNames.see,
              iconProps: { iconName: "textDocument" },
              name: "see",
              onClick: openDisplay,
            },
            {
              key: "edit",
              text: commandBarNames.edit,
              iconProps: { iconName: "edit" },
              onClick: edit
            },
            {
              key: "cancel",
              text: commandBarNames.cancel,
              iconProps: { iconName: "cancel" },
              onClick: cancelOrderByUser
            },
            {
              key: "report",
              text: commandBarNames.download,
              iconProps: { iconName: "ReportDocument" },
              name: "report",
              onClick: openFormReport,
            },
          ];
        }
        else {
          return [
            {
              key: "see",
              text: commandBarNames.see,
              iconProps: { iconName: "textDocument" },
              name: "see",
              onClick: openDisplay,
            },
            {
              key: "report",
              text: commandBarNames.download,
              iconProps: { iconName: "ReportDocument" },
              name: "report",
              onClick: openFormReport,
            },
          ]
        }
      default:
        return [];
    }
  };

  const items = generateItems()

  return {
    items
  }
};