import React from "react";
import { Formik, Form } from "formik";
import { Stack } from "office-ui-fabric-react";
import NewFormField from "../../common/layout/form/NewFormField";
import { useAdminForm } from "../hooks/useAdminForm";
import NewFormBtns from "../../common/layout/form/NewFormBtns";
import ActivityIndicator from "../../common/layout/ActivityIndicator";
import MessageContainer from "../../auth/components/register/forms/MessageContainer";

const AdminForm: React.FC = () => {
  const {
    handleSubmit,
    initValues,
    cancelForm,
    selectedAdmins,
    loading
  } = useAdminForm();

  return (
    <Formik
      initialValues={initValues}
      onSubmit={handleSubmit}
    >
      {({ handleSubmit }) => (
        <Form translate="yes" onSubmit={handleSubmit}>
          <Stack>
            <NewFormField name="lastName" label="Nazwisko" />
            <NewFormField name="firstName" label="Imię" />
            <NewFormField name="phone" label="Telefon" />
            <NewFormField name="email" label="Email" />
            <NewFormBtns
              positionAbsolute={true}
              cancelForm={cancelForm}
              submitBtnText={selectedAdmins[0] ? "Zapisz" : "Dodaj"}
              disabled={loading}
            />
          </Stack>
          {loading ? <ActivityIndicator /> : <MessageContainer />}
        </Form>
      )}
    </Formik>
  );
};

export default AdminForm;