import React, { useEffect, useMemo, useState } from "react";
import {
  CommandBar,
  DetailsList,
  IColumn,
  Selection,
  SelectionMode,
  TextField,
  Modal,
} from "office-ui-fabric-react";
import {
  DatePicker,
  DefaultButton,
  Dropdown,
  PrimaryButton,
} from "@fluentui/react";
import { getOrderTypeItems } from "../../../../../connectors/orderTypes/connectors";
import { useField } from "formik";
import { getSpecialPrices } from "../../../../../connectors/clients/connectors";

interface ListItem {
  id: number | null;
  value: number;
  dateFrom: Date | string;
  dateTo: Date | string;
  orderTypeItemId: number;
  orderTypeItemName?: string;
}

interface EditableListFormProps {
  setSpecialPrices: (specialPrices: ListItem[]) => void;
  specialPrices: ListItem[];
  receptionPlaceId: number;
  orderTypeId: number;
}

const EditableListForm: React.FC<EditableListFormProps> = ({
  setSpecialPrices,
  specialPrices,
  receptionPlaceId,
}: EditableListFormProps) => {
  const [selectedItem, setSelectedItem] = useState<ListItem | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editedItem, setEditedItem] = useState<ListItem | null>(null);
  const [newItem, setNewItem] = useState<ListItem | null>(null);
  const [datePicker1Message, setDatePicker1Message] = useState("");
  const [datePicker2Message, setDatePicker2Message] = useState("");
  const [datePicker3Message, setDatePicker3Message] = useState("");
  const [datePicker4Message, setDatePicker4Message] = useState("");
  const [orderSubtype, setOrderSubtype] = useState<
    {
      id: number;
      name: string;
      orderTypeId: number;
      value: number;
    }[]
  >([]);
  const [
    field1,
    { value: valueType, error: errorType },
    { setValue: setValueType },
  ] = useField("orderTypeId");
  const today = new Date();
  const nextYear = new Date(
    today.getFullYear() + 1,
    today.getMonth(),
    today.getDate()
  );

  const modalStyles = {
    main: {
      padding: 20,
      width: 500,
    },
    button: {
      margin: "10px",
    },
  };

  useEffect(() => {
    const fetchSpecialPrices = async () => {
      try {
        const params = { ReceptionPlaceId: receptionPlaceId };
        const response = await getSpecialPrices(params);
        if (response) setSpecialPrices(response.data.data);
      } catch (error) {
        console.log(error);
      }
    };

    if (receptionPlaceId) fetchSpecialPrices();
  }, [receptionPlaceId]);

  useEffect(() => {
    const fetchOrderSubtypes = async () => {
      try {
        const orderTypeResponse = await getOrderTypeItems(valueType);
        const orderTypeData = orderTypeResponse.data.data;
        setOrderSubtype(orderTypeData);
      } catch (error) {
        console.log(error);
      }
    };

    fetchOrderSubtypes();
  }, [valueType]);

  const intervals = specialPrices.map((price) => ({
    id: price.orderTypeItemId,
    dateFrom: price.dateFrom,
    dateTo: price.dateTo,
  }));

  const checkDateInRange = (selectedDate: any) => {
    let id = 0;
    if (editedItem) id = Number(editedItem.orderTypeItemId);
    if (newItem) id = Number(newItem.orderTypeItemId);
    let selectedDateObj = new Date(selectedDate);

    let editedIntervals = intervals.filter(interval => !(interval.id === id && editedItem && interval.dateFrom === editedItem.dateFrom && interval.dateTo === editedItem.dateTo));

    for (let i = 0; i < editedIntervals.length; i++) {
      let interval = editedIntervals[i];
      if (interval.id !== id) continue;

      let dateFrom = new Date(interval.dateFrom);
      let dateTo = new Date(interval.dateTo);
      if (selectedDateObj >= dateFrom && selectedDateObj <= dateTo) {
        return "Wybrana data jest już zajęta";
      }
    }
    return "";
  };

  const dropdownOptions = orderSubtype.map((subtype) => ({
    key: subtype.id.toString(),
    text: subtype.name,
    orderTypeId: subtype.orderTypeId,
    value: subtype.value,
  }));

  const selection = useMemo(
    () =>
      new Selection({
        onSelectionChanged: () => {
          const selectedItems = selection.getSelection() as ListItem[];
          setSelectedItem(selectedItems.length > 0 ? selectedItems[0] : null);
        },
      }),
    []
  );

  const handleItemInvoked = (item: ListItem) => {
    setSelectedItem(item);
  };

  const handleCommandClick = (command: string) => {
    if (command === "new") {
      setNewItem({
        id: null,
        value: 1000,
        dateFrom: today.toISOString(),
        dateTo: nextYear.toISOString(),
        orderTypeItemId: orderSubtype[0].id,
        orderTypeItemName: orderSubtype[0].name,
      });
      setIsModalOpen(true);
    } else if (command === "delete" && selectedItem) {
      const updatedItems = specialPrices.filter(
        (item) => item !== selectedItem
      );
      setSpecialPrices(updatedItems);
      setSelectedItem(null);
    }
  };

  const handleEditClick = () => {
    if (selectedItem) {
      setIsEditModalOpen(true);
      setEditedItem(selectedItem);
      setNewItem(null);
    }
  };

  const handleSaveClick = () => {
    setIsModalOpen(false);
    setIsEditModalOpen(false);
    let updatedItems: ListItem[];

    if (editedItem) {
      updatedItems = specialPrices.map((item) =>
        item === selectedItem ? editedItem : item
      );
    } else if (newItem) {
      updatedItems = [...specialPrices, newItem];
      setNewItem(null);
    } else {
      updatedItems = specialPrices;
    }

    setSpecialPrices(updatedItems);
    setSelectedItem(null);
  };

  const handleCancelClick = () => {
    setIsModalOpen(false);
    setIsEditModalOpen(false);
    setEditedItem(null);
    setNewItem(null);
    setDatePicker1Message("");
    setDatePicker2Message("");
    setDatePicker3Message("");
    setDatePicker4Message("");
  };

  const handleFieldChange = (
    field: keyof ListItem,
    value: string | number | Date,
    secondField?: keyof ListItem,
    secondValue?: string | number | Date
  ) => {
    if (editedItem) {
      let updatedItem = { ...editedItem, [field]: value };
      if (secondField) {
        updatedItem = { ...updatedItem, [secondField]: secondValue };
      }
      setEditedItem(updatedItem);
    } else if (newItem) {
      let updatedItem = { ...newItem, [field]: value };
      if (secondField) {
        updatedItem = { ...updatedItem, [secondField]: secondValue };
      }
      setNewItem(updatedItem);
    }
  };

  const columns: IColumn[] = [
    {
      key: "orderTypeItemName",
      name: "Podkategoria",
      fieldName: "orderTypeItemName",
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },
    {
      key: "dateFrom",
      name: "Data początkowa",
      fieldName: "dateFrom",
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      onRender: (item: ListItem) => (
        <span>{formatDate(new Date(item.dateFrom))}</span>
      ),
    },
    {
      key: "dateTo",
      name: "Data końcowa",
      fieldName: "dateTo",
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      onRender: (item: ListItem) => (
        <span>{formatDate(new Date(item.dateTo))}</span>
      ),
    },
    {
      key: "value",
      name: "Cena",
      fieldName: "value",
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },
  ];

  const formatDate = (date?: Date) => {
    if (!date) return "";
    const day = ("0" + date.getDate()).slice(-2);
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };

  return (
    <div>
      <CommandBar
        items={[
          {
            key: "newItem",
            text: "Dodaj",
            iconProps: { iconName: "Add" },
            onClick: () => handleCommandClick("new"),
            disabled: !valueType,
          },
          {
            key: "editItem",
            text: "Edytuj",
            iconProps: { iconName: "Edit" },
            onClick: handleEditClick,
            disabled: !selectedItem,
          },
          {
            key: "deleteItem",
            text: "Usuń",
            iconProps: { iconName: "Delete" },
            onClick: () => handleCommandClick("delete"),
            disabled: !selectedItem,
          },
        ]}
      />
      <DetailsList
        items={specialPrices}
        columns={columns}
        selectionMode={SelectionMode.single}
        onItemInvoked={handleItemInvoked}
        selection={selection}
      />
      <Modal
        isOpen={isEditModalOpen}
        onDismiss={handleCancelClick}
        isBlocking={false}
        containerClassName="modal-container"
        styles={modalStyles}
      >
        <div>
          <h2>Edytuj element</h2>
          <Dropdown
            style={{ marginBottom: "10px" }}
            label="Podkategoria"
            selectedKey={editedItem ? editedItem.orderTypeItemName : ""}
            placeholder={editedItem ? editedItem.orderTypeItemName : ""}
            onChange={(ev, option) => {
              if (option) {
                handleFieldChange(
                  "orderTypeItemId",
                  Number(option.key),
                  "orderTypeItemName",
                  option.text
                );
              }
            }}
            options={dropdownOptions}
          />
          <DatePicker
            style={{ marginBottom: "10px" }}
            label="Data początkowa"
            value={
              editedItem && editedItem.dateFrom
                ? new Date(editedItem.dateFrom)
                : today
            }
            onSelectDate={(date) => {
              handleFieldChange("dateFrom", date ? formatDate(date) : "");
              const message = checkDateInRange(date ? formatDate(date) : "");
              setDatePicker1Message(message);
            }}
            formatDate={formatDate}
            minDate={today}
          />
          <p style={{ color: 'red' }}>{datePicker1Message}</p>
          <DatePicker
            style={{ marginBottom: "10px" }}
            label="Data końcowa"
            value={
              editedItem && editedItem.dateTo
                ? new Date(editedItem.dateTo)
                : nextYear
            }
            onSelectDate={(date) => {
              handleFieldChange("dateTo", date ? formatDate(date) : "");
              const message = checkDateInRange(date ? formatDate(date) : "");
              setDatePicker2Message(message);
            }}
            formatDate={formatDate}
            minDate={today}
          />
          <p style={{ color: 'red' }}>{datePicker2Message}</p>
          <TextField
            label="Cena"
            value={((editedItem && editedItem.value) || 0).toString()}
            onChange={(ev, val) =>
              handleFieldChange(
                "value",
                parseFloat((val || "").replace(",", "."))
              )
            }
          />
          <div>
            <PrimaryButton
              text="Zapisz"
              onClick={handleSaveClick}
              style={{ margin: "20px 10px 10px 0" }}
            />
            <DefaultButton
              text="Anuluj"
              onClick={handleCancelClick}
              style={{
                margin: "20px 10px 10px 10px",
                border: "1px solid black",
              }}
            />
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={isModalOpen}
        onDismiss={handleCancelClick}
        isBlocking={false}
        containerClassName="modal-container"
        styles={modalStyles}
      >
        <div>
          <h2>Dodaj element</h2>
          <Dropdown
            style={{ marginBottom: "10px" }}
            label="Podkategoria"
            selectedKey={newItem ? newItem.orderTypeItemName : ""}
            placeholder={newItem ? newItem.orderTypeItemName : ""}
            onChange={(ev, option) => {
              if (option) {
                handleFieldChange(
                  "orderTypeItemId",
                  option.key,
                  "orderTypeItemName",
                  option.text
                );
              }
            }}
            options={dropdownOptions}
          />
          <DatePicker
            style={{ marginBottom: "10px" }}
            label="Data początkowa"
            value={
              newItem && newItem.dateFrom ? new Date(newItem.dateFrom) : today
            }
            onSelectDate={(date) => {
              handleFieldChange("dateFrom", date ? formatDate(date) : "");
              const message = checkDateInRange(date ? formatDate(date) : "");
              setDatePicker3Message(message);
            }}
            formatDate={formatDate}
            minDate={today}
          />
          <p style={{ color: 'red' }}>{datePicker3Message}</p>
          <DatePicker
            style={{ marginBottom: "10px" }}
            label="Data końcowa"
            value={
              newItem && newItem.dateTo ? new Date(newItem.dateTo) : nextYear
            }
            onSelectDate={(date) => {
              handleFieldChange("dateTo", date ? formatDate(date) : "");
              const message = checkDateInRange(date ? formatDate(date) : "");
              setDatePicker4Message(message);
            }}
            formatDate={formatDate}
            minDate={today}
          />
          <p style={{ color: 'red' }}>{datePicker4Message}</p>
          <TextField
            label="Cena"
            value={((newItem && newItem.value) || 0).toString()}
            onChange={(ev, val) =>
              handleFieldChange(
                "value",
                parseFloat((val || "").replace(",", "."))
              )
            }
          />
          <div>
            <PrimaryButton
              text="Zapisz"
              onClick={handleSaveClick}
              style={{ margin: "20px 10px 10px 0" }}
            />
            <DefaultButton
              text="Anuluj"
              onClick={handleCancelClick}
              style={{
                margin: "20px 10px 10px 10px",
                border: "1px solid black",
              }}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default EditableListForm;
