import { IValidatorPanelAction } from "./validatorPanelActions.types";
import { ValidatorPanelActions } from "./validatorPanel.enum";

const initState = {
  validatorDetails: "",
  validatorPanelOpen: false,
  validatorPanelApprovedOpen: false,
  validatorHistory: "",
  validatorComments: "",
  newComment: false,
  editing: false,
  addNewValidator: false,
};

export const validatorPanelReducer = (
  state = initState,
  action: IValidatorPanelAction
) => {
  const { type, payload } = action;
  switch (type) {
    case ValidatorPanelActions.SET_VALIDATOR_DETAILS:
      return { ...state, validatorDetails: payload };
    case ValidatorPanelActions.TOGGLE_VALIDATOR_PANEL:
      return { ...state, validatorPanelOpen: payload };
    case ValidatorPanelActions.TOGGLE_VALIDATOR_PANEL_APPROVED:
      return { ...state, validatorPanelApprovedOpen: payload };
    case ValidatorPanelActions.SET_VALIDATOR_COMMENTS:
      return { ...state, validatorComments: payload };
    case ValidatorPanelActions.SET_VALIDATOR_HISTORY:
      return { ...state, validatorHistory: payload };
    case ValidatorPanelActions.ADD_NEW_COMMENT:
      return { ...state, newComment: payload };
    default:
      return state;
  }
};