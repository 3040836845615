import { getInternalStores } from '../../../connectors/orderTypes/connectors/index';
import { useState, useEffect } from 'react';

export const useOrderInternalStores = (orderId: string) => {
    const [internalStores, setInternalStores] = useState([])
    const [averageDistance, setAverageDistance] = useState(0);
    useEffect(() => {
        if (orderId) {
            fetch()
        }
    }, [orderId])

    const fetch = async () => {
        const res = await getInternalStores(orderId)
        if (res.data.success == true) {
            const parsedRed = res.data.data.map((item: any) => ({
                key: item.internalStoreId,
                text: `${item.name} (${Math.round(item.distance / 10) / 100}km)`
            }))

            var sum = 0;
            res.data.data.forEach(function (num: any) { sum += num.distance });
            var average = Math.round((sum / res.data.data.length) / 10) / 100;

            setInternalStores(parsedRed)
            setAverageDistance(average)
        }
    }

    return {
        internalStores,
        averageDistance
    }
}