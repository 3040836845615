import * as yup from "yup";

export const validationSchemaDrivers = yup.object().shape({
  lastName: yup.string().required("Żeby dodać kierowce podaj nazwisko").max(50),
  firstName: yup.string().required("Żeby dodać kierowce podaj Imię").max(50),
  phone: yup
    .string()
    .required("Żeby dodać kierowce podaj nr telefonu")
    .matches(/^[0-9]+$/, "niepoprawny numer telefonu")
    .min(9, "niepoprawny numer telefonu")
    .max(9, "niepoprawny numer telefonu"),
  email: yup
    .string()
    .email("Podaj poprawny email")
    .required("Żeby dodać kierowce podaj email")
    .max(50),
});
