import React from "react";
import { Formik, Form } from "formik";
import { Stack } from "office-ui-fabric-react";

import { useUserForm } from "../hooks/useUserForm";
import NewFormField from "../../common/layout/form/NewFormField";
import NewFormBtns from "../../common/layout/form/NewFormBtns";
import ActivityIndicator from "../../common/layout/ActivityIndicator";
import MessageContainer from "../../auth/components/register/forms/MessageContainer";
import { validationSchemaDrivers } from "../actions/validationSchemas";
import { useTranslation } from "react-i18next";
import { IUsersPanelLang, IUsersValidationSchemaLang } from "../../../localization/abstract/client/users";

const UserForm: React.FC = () => {
  const {
    handleSubmit,
    initValues,
    cancelForm,
    selectedUsers,
    loading
  } = useUserForm();

  const { t } = useTranslation("users");
  const userNames: IUsersPanelLang = t("panel", {
    returnObjects: true,
  });
  const validationSchemaNames: IUsersValidationSchemaLang = t("validationSchema", {
    returnObjects: true,
  });
  return (
    <Formik
      initialValues={initValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchemaDrivers(validationSchemaNames)}
    >
      {({ handleSubmit }) => (
        <Form translate="yes" onSubmit={handleSubmit}>
          <Stack>
            <NewFormField name="lastName" label={userNames.lastName} />
            <NewFormField name="firstName" label={userNames.firstName} />
            <NewFormField name="phone" label={userNames.phoneNumber} />
            <NewFormField name="email" label={userNames.email} />
            <NewFormBtns
              cancelForm={cancelForm}
              submitBtnText={selectedUsers[0] ? userNames.save : userNames.invite}
              disabled={loading}
              positionAbsolute={true}
            />
          </Stack>
          {loading ? <ActivityIndicator /> : <MessageContainer />}
        </Form>
      )}
    </Formik>
  );
};

export default UserForm;