import { FontWeights, getTheme, IIconProps, mergeStyleSets, Modal } from "@fluentui/react";
import React from "react";
import { useId } from '@fluentui/react-hooks';
import { IconButton } from '@fluentui/react/lib/Button';
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { toggleContainersModal } from "../../../config/redux/container/containersActions";
import ContainerDetailMap from "../components/ContainerDetailMap";
import { iconButtonStyles } from "../../auth/styles/regulations.styles";
import { useContainerDetailMap } from "../hooks/useContainerDetailMap";
import { useHistory, useLocation } from "react-router-dom";
import { NavigationPath } from "../../../config/routing/NavigationPath";
import ContainerDetailFotos from "../components/ContainerDetailFotos";

const theme = getTheme();
const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
    },
    header: [
        // eslint-disable-next-line deprecation/deprecation
        theme.fonts.xLargePlus,
        {
            flex: '1 1 auto',
            borderTop: `4px solid ${theme.palette.themePrimary}`,
            color: theme.palette.neutralPrimary,
            display: 'flex',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '12px 12px 14px 24px',
        },
    ],
    heading: {
        color: theme.palette.neutralPrimary,
        fontWeight: FontWeights.semibold,
        fontSize: 'inherit',
        margin: '0',
        width: '100vh'
    },
    body: {
        flex: '4 4 auto',
        padding: '0 4px 4px 4px',
        overflowY: 'hidden',
        selectors: {
            p: { margin: '4px 0' },
            'p:first-child': { marginTop: 0 },
            'p:last-child': { marginBottom: 0 },
        },
    },
});

const ContainerMapModal: React.FC = () => {
    const dispatch = useDispatch();
    const { containersModalOpen, containerModalMap, containterModalFotos } = useSelector(
        (state: RootStateOrAny) => state.containers
    );
    const history = useHistory();
    const location = useLocation();
    const dismissModal = () => {
        dispatch(toggleContainersModal({
            isOpen: false,
            isMap: false,
            isFotos: false
        }));

        if (location.pathname.split("/")[1] === "store" &&
            location.pathname.split("/")[2] === "container" &&
            location.pathname.split("/")[3] === "map") {
            history.push(`${NavigationPath.HOME}`);
        }
    };
    const { containerNumber } = useContainerDetailMap();

    const cancelIcon: IIconProps = { iconName: 'Cancel' };
    const titleId = useId('title');
    return (
        <Modal
            titleAriaId={titleId}
            isOpen={containersModalOpen}
            onDismiss={dismissModal}
            isBlocking={false}
            containerClassName={contentStyles.container}
        //dragOptions={isDraggable ? dragOptions : undefined}
        >
            <div className={contentStyles.header}>
                <h2 className={contentStyles.heading} id={titleId}>
                    {containerNumber}
                </h2>
                <IconButton
                    styles={iconButtonStyles}
                    iconProps={cancelIcon}
                    ariaLabel="Close popup modal"
                    onClick={dismissModal}
                />
            </div>
            <div className={contentStyles.body}>
                {containerModalMap ?
                    <ContainerDetailMap /> : <></>
                }
                {containterModalFotos ? 
                    <ContainerDetailFotos /> : <></>}
            </div>
        </Modal>
    )
}

export default ContainerMapModal;