import { DefaultButton, mergeStyles, mergeStyleSets, PrimaryButton, Stack } from 'office-ui-fabric-react';
import React from 'react';
import { Form, Formik } from 'formik';
import FormHeader from '../FormHeader';
import NewFormField from '../../../../common/layout/form/NewFormField';
import FormDropDown from '../../../../common/layout/form/FormDropDown';
import { buttonS } from '../../../../common/styles/formBtns.styles';
import { useCancelForm } from '../../../hooks/useCancelForm';
import { useFilesUploadForm } from '../../../hooks/useFilesUploadForm';
import { margin, btnContainer } from "../../../styles/panelForm.styles";
import ActivityIndicator from '../../../../common/layout/ActivityIndicator';
import ErrorMessage from "../../../../common/layout/form/ErrorMessage";
import {
  containerS,
} from "../../../styles/pickUpPointsForm.styles";

// const controlClass = mergeStyleSets({
//   control: {
//     margin: "0 40px 0 0",
//     backgroundColor: "red",
//     maxWidth: "300px",
//   },
// });

const FilesUploadForm = () => {
  const {
    arrangementFileHandler, 
    handleSubmit,
    isLoading
  } = useFilesUploadForm();

  const cancel = useCancelForm();

  const btns = mergeStyles({
    position: "absolute",
    bottom: 30,
    left: 40,
  });
  
  return(
    <Formik 
      onSubmit={handleSubmit} 
      initialValues={{}}
      //validationSchema={FilesUploadSchema}
    >
      {({ handleSubmit }) => (
        <Form translate="yes" onSubmit={handleSubmit}>
          <Stack styles={containerS} verticalAlign="space-between" verticalFill>
          <Stack.Item>

          <FormHeader text="Dodaj plik"/>
          <NewFormField name="description" label="Opis" />
          <NewFormField name="documentDate" label="Data dokumentu" type="date"/>
          {/* <DatePicker
              className={controlClass.control}
              firstDayOfWeek={firstDayOfWeek}
              strings={datePickerStrings}
              placeholder="Wybierz datę"
              ariaLabel="Wybierz datę"
              value={value!}
              onSelectDate={onSelectDate}
              formatDate={onFormatDate}
              minDate={today}
              parseDateFromString={onParseDateFromString}
            /> */}
        
          <FormDropDown
            headerText="Typ pliku"
            name="documentType"
            options={[
              {key: 1, text: "umowa"},
              {key: 2, text: "protokół odbioru"},
              {key: 3, text: "protokół przekazania"}
          ]} />

          <FormDropDown
            headerText="Ilość"
            name="containersNumber"
            options={[
              { key: 0, text: "0"},
              { key: 1, text: "1"},
              { key: 2, text: "2"}
          ]} />

          {/* <FormFileUpload name={"file"} onChange={arrangementFileHandler} />  */}
          <input type="file" name="file" onChange={arrangementFileHandler} /> <br />

          <div className={btns}>
            <PrimaryButton text={"Dodaj"} type="submit" styles={buttonS} />
            <DefaultButton text={"Anuluj"} type="reset" onClick={cancel} />
          </div>
          </Stack.Item>
            <div className={margin}>
              {isLoading ? <ActivityIndicator /> : <ErrorMessage />}
            </div>
          </Stack>
        </Form>
        )}
    </Formik>
  )
};

export default FilesUploadForm;