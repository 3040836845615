import React, { useState, useEffect } from "react";
import { useSelector, RootStateOrAny, useDispatch } from "react-redux";
import { Panel, IPanelStyles } from "office-ui-fabric-react";
import { RenderNavigation } from "../../../orders/components/CustomNavigation";
import { clearAlerts } from "../../../../config/redux/alerts/alertsActions";
import { useValidatorData } from "../../../orders/hooks/useValidatorData";
import { toggleValidatorPanel } from "../../../../config/redux/validators/validatorsPanel/validatorPanelActions";
import CheckValidatorForm from "../forms/CheckVaildatorForm";
import { getValidatorTypes } from "../../../../connectors/validators/connectors";

const panelS: Partial<IPanelStyles> = {
  content: {
    padding: 20,
  },
  main: {
    height: "100%",
  },
};

const ValidatorPanel: React.FC = () => {
  const dispatch = useDispatch();

  const { validatorPanelOpen } = useValidatorData();

  const dissmisPanel = (ev: any) => {
    dispatch(clearAlerts());
    dispatch(toggleValidatorPanel(false));
  };
  const [validatorTypes, setValidatorTypes] = useState<any>();

  const cancelForm = () => {
    dispatch(toggleValidatorPanel(false));
  };

  useEffect(() => {
    const fetchData = async () => {
      const res = await getValidatorTypes();
      setValidatorTypes(res.data.data.map((validatorType: any) => ({
        ...validatorType,
        key: validatorType.type
      })));
    };

    fetchData();
  }, []);

  return (
    <Panel
      isOpen={validatorPanelOpen}
      closeButtonAriaLabel="Close"
      type={3}
      onDismiss={dissmisPanel}
      onOuterClick={() => { }}
      isLightDismiss={false}
      isHiddenOnDismiss={false}
      onRenderNavigation={RenderNavigation}
      styles={panelS}
      isFooterAtBottom={true}
    >
      <>
        <CheckValidatorForm
          cancelForm={cancelForm}
          validatorTypes={validatorTypes}
        />
      </>
    </Panel>
  );
};

export default ValidatorPanel;