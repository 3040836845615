import * as React from "react";
import { connect, RootStateOrAny } from "react-redux";
import {
  DetailsList,
  DetailsListLayoutMode,
  Selection,
  IDetailsListStyles,
  SelectionMode,
  IDetailsListProps,
  IDetailsRowStyles,
  DetailsRow,
} from "office-ui-fabric-react/lib/DetailsList";
import { MarqueeSelection } from "office-ui-fabric-react/lib/MarqueeSelection";
import {
  Stack,
  IStackStyles,
  mergeStyles,
  getTheme,
} from "office-ui-fabric-react";
import _ from "lodash";

import {
  IProps,
  ICompanyDetailDataProps,
  IDispatchProps,
  IStateProps,
} from "../../models/companyDetailDataTypes";
import {
  setSelectedIds,
  reload,
  refetchClientPanelData,
} from "../../../../config/redux/clients/panel/clientPanelActions";
import MessageContainer from "../../../auth/components/register/forms/MessageContainer";

const theme = getTheme();

const margin = mergeStyles({
  marginTop: 20,
});

const listContainerS: IStackStyles = {
  root: {
    marginTop: 25,
  },
};

const listS: Partial<IDetailsListStyles> = {
  contentWrapper: {
    selectors: {
      "div[data-automationid=DetailsRowCell]": {
        cursor: "pointer",
      },
      "div[data-automationid=DetailsRowCheck]": {
        cursor: "pointer",
      },
    },
  },
};

export interface ICompanyDetailDataState {
  items: Array<any>;
  loading: boolean;
}

export class CompanyDetailData extends React.Component<
  IProps,
  ICompanyDetailDataState
> {
  private _selection: Selection;
  private _onItemInvoked: (item?: any, index?: number, e?: Event) => void;

  constructor(props: IProps) {
    super(props);

    this._selection = new Selection({
      onSelectionChanged: () => {
        const items = this._getSelectedKeys();
        const ids = items.map((item: any) => {
          return item;
        });
        props.dispatch(setSelectedIds({ selectedIds: [...ids] }));
      },
    });

    this._onItemInvoked = (item: any) => {
      props.dispatch(props.setActiveData(item));
    };

    this.state = {
      items: [],
      loading: false,
    };
  }

  componentDidMount() {
    this._setData();
  }

  componentDidUpdate() {
    if (this.props.change) {
      this._selection.selectToKey("", true);
      this._reload();
      this._removeItems();
    }

    if (this.props.refetch) {
      this._selection.selectToKey("", true);
      this.props.dispatch(refetchClientPanelData(false));
      this._setData()
    }
  }

  public render(): JSX.Element {
    const { items } = this.state;

    return (
      <Stack styles={listContainerS}>
        <MarqueeSelection selection={this._selection}>
          <DetailsList
            items={items}
            columns={this.props.columns}
            setKey="items"
            layoutMode={DetailsListLayoutMode.justified}
            selectionMode={SelectionMode.single}
            selection={this._selection}
            // onItemInvoked={this._onItemInvoked}
            selectionPreservedOnEmptyClick={true}
            onRenderRow={this._onRenderRow}
            styles={listS}
          />
        </MarqueeSelection>
        <div className={margin}>
          <MessageContainer />
        </div>
      </Stack>
    );
  }

  private _onRenderRow: IDetailsListProps["onRenderRow"] = (props: any) => {
    const customStyles: Partial<IDetailsRowStyles> = {};
    if (props) {
      if (props.item.isDefault || props.item.isClientAdmin) {
        customStyles.root = { color: theme.palette.blue };
      } 
      if (props.item.isActive === false) {
        customStyles.root = { color: theme.palette.red };
      }
      if (props.item.isInvitationInProgress === true) {
        customStyles.root = { color: 'rgba(50, 49, 48, 0.5)' };
      }

      return <DetailsRow {...props} styles={customStyles} />;
    }
    return null;
  };

  private _reload = async () => {
    this.props.dispatch(reload({ reload: false }));
  };

  private _removeItems = () => {
    const items = _.remove(this.state.items, (item: any) => {
      if (this.props.selectedIds[0].id !== item.id) {
        return item;
      }
    });

    this.setState({ items });
  };

  private _setData = async () => {
    const id = this.props.selectedClients[0];
    const res = await this.props.getData(id);
    const data = this.props.generateItems(res.data.data);
    this.setState({ items: [...data] });
  };

  private _getSelectedKeys = () => {
    const keys = this._selection.getSelection();
    return keys;
  };
}

const mapStateToProps = (state: RootStateOrAny) => {
  return {
    selectedIds: state.clientPanel.selectedIds,
    activeClient: state.clients.activeClient,
    selectedClients: state.clients.selectedClients,
    refetch: state.clientPanel.refetch,
  };
};

export default connect<IStateProps, IDispatchProps, ICompanyDetailDataProps>(
  mapStateToProps
)(CompanyDetailData);
