import { toggleSelfEditPanel } from './../../../config/redux/admins/adminsActions';
import { useDispatch } from 'react-redux';

export const useSelfEditForm = () => {
    const dispatch = useDispatch()

    const cancelForm = () => {
        dispatch(toggleSelfEditPanel(false))
    }

    const handleSubmit = () => {

    }

    return{
        cancelForm,
        handleSubmit
    }
}