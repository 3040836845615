export const wrapperStyles = {
    map: {
        height: '500px',
    },
    wrapper: {
        padding: '15px',
        marginTop: '15px',
    },
    buttonContainer: {
        display: 'grid',
        gridAutoFlow: 'column',
        gridGap: '10px',
        gridAutoColumns: 'max-content',
        padding: '10px 0',
    },
    buttons: {
        padding: '15px',
        flex: 1,
    },
    popupStyles: {
        padding: '10px',
        color: 'black',
    },
};