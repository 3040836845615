import React from "react";
import { mergeStyles } from "office-ui-fabric-react";
import OrderDetailTxt from "./OrderDetailTxt";
import { useTranslation } from "react-i18next";
import { IOrdersOrderBasicInfoLang } from "../../../../../localization/abstract/client/orders";

const divider = mergeStyles({
  width: 300,
});

const margin = mergeStyles({
  width: 150,
});

const basicInfoContainer = mergeStyles({
  display: "flex",
  justifyContent: "space-between",
});

export interface OrderBasicInfoProps {
  activeOrder?: any;
}

const OrderBasicInfo: React.FC<OrderBasicInfoProps> = ({ activeOrder }) => {
  const { t } = useTranslation("orders");
  const orderBasicInfoNames: IOrdersOrderBasicInfoLang = t("orderBasicInfo", {
    returnObjects: true,
  });

  return (
    <div className={basicInfoContainer}>
      <div className={margin}>
        <OrderDetailTxt
          title={orderBasicInfoNames.orderNumber}
          value={activeOrder? activeOrder.orderInfo ? activeOrder.orderInfo.number : ".": ""}
        />
      </div>
      <div className={divider}>
        <OrderDetailTxt
          title={orderBasicInfoNames.clientName}
          value={activeOrder? activeOrder.orderInfo ? activeOrder.orderInfo.company : ".": ""}
        />
      </div>
    </div>
  );
};

export default OrderBasicInfo;