import api from "../../../config/axios/configAxios";

export const getOrdersStatistics = async (date: string, regions: string[]) => {
    let regionsString: string = "";

    regions.forEach((region: string) => {
        if(!!region && region.length > 0) {
            regionsString += `&Regions=${region}`;
        }
    });

    return api.get(`/api/Dashboard/orders/statistics?Date=${date}${regionsString}`);
}

export const getCompaniesStatistics = async (date: string) => api.get(`/api/Dashboard/companies/statistics?Date=${date}`);

export const getReceptionPlacesStatistics = async (date: string) => api.get(`/api/Dashboard/reception-places/statistics?Date=${date}`);

export const getOrdersChartValuesPerDate = async (days: number, regions: string[]) => {
    let regionsString: string = "";

    regions.forEach((region: string) => {
        if(!!region && region.length > 0) {
            regionsString += `&Regions=${region}`;
        }
    });

    return api.get(`/api/Dashboard/orders/charts/values-per-date?Days=${days}${regionsString}`);
}

export const getOrdersChartCountPerDate = async (days: number, regions: string[]) => {
    let regionsString: string = "";

    regions.forEach((region: string) => {
        if(!!region && region.length > 0) {
            regionsString += `&Regions=${region}`;
        }
    });

    return api.get(`/api/Dashboard/orders/charts/count-per-date?Days=${days}${regionsString}`);
}