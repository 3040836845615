import * as React from "react";
import ValidatorList from "./list/ValidatorList";

const ValidatorTypes: React.FC = () => {
  return (
    <>
      <ValidatorList />
    </>
  );
};

export default ValidatorTypes;