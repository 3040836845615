import { mergeStyles } from "office-ui-fabric-react";
import React from "react";
import ReceptionPlacePanel from "../../../client/components/panels/ReceptionPlacesPanel";
import ListSuccessMessage from "../../common/layout/ListSuccessMessage";

import UnactivePickupPointsList from "../components/UnactivePickupPointsList";

const padding = mergeStyles({
  paddingTop: 15,
});

const UnactivePickupPoints: React.FC = () => {
  return (
    <div >
      <div className={padding}>
        <ListSuccessMessage />
      </div>
      <UnactivePickupPointsList />
      <ReceptionPlacePanel />
    </div>
  );
};

export default UnactivePickupPoints;