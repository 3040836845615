export const columns = [
    {
      key: "lastName",
      name: "Nazwisko",
      fieldName: "lastName",
      minWidth: 100,
      maxWidth: 300,
      isResizable: true,
    },
    {
      key: "firstName",
      name: "Imię",
      fieldName: "firstName",
      minWidth: 100,
      maxWidth: 300,
    },
    {
      key: "email",
      name: "Email",
      fieldName: "email",
      minWidth: 100,
      maxWidth: 300,
    },
    {
      key: "phone",
      name: "Telefon",
      fieldName: "phone",
      minWidth: 100,
      maxWidth: 300,
    },
  ];