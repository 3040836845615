import api from "../../../config/axios/configAxios";
import { ShortLangToEnum } from "../../../features/common/converters/language";

export const getOrderType = async (id: string) =>
    api.get(`/api/admin/orderTypes/items/${id}`);

export const getOrderTypes = async () => api.get("/api/admin/OrderTypes");

export const getOrderTypeItems = async (id: string) =>
    api.get(`/api/Admin/orderTypes/${id}`);

export const getOrderTypeItemsWithLang = async (id: string, lang: string) =>
    api.get(`/api/Admin/orderTypes/${id}?LangEnum=${ShortLangToEnum(lang)}`);

export const addOrderTypeItem = async ({ id, orderType }: any) =>
    api.post(`/api/Admin/OrderTypes/${id}/add-item`, orderType);

export const deleteOrderTypeItem = async ({ orderTypeId, itemId }: any) =>
    api.delete(`/api/Admin/OrderTypes/${orderTypeId}/items/${itemId}`);

export const editOrderTypeItem = async ({
    orderTypeId,
    itemId,
    orderType,
}: any) =>
    api.put(`/api/Admin/OrderTypes/${orderTypeId}/items/${itemId}`, {
        name: orderType.name,
        value: orderType.value,
    });

export const syncOrderWithOptima = async ({
    numberOptima,
    orderId,
}: any) =>
    api.put(`/api/Admin/Orders/${orderId}/set-optima-number`, {
        optimaNumber: numberOptima,
        orderId: orderId,
    });

export const getInternalStores = async (id: string) =>
    api.get(`api/Admin/stores/${id}`);