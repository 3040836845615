import { useMemo } from "react";
import { RootStateOrAny, useSelector } from "react-redux";

export const useContainerDetailMap = () => {

    const { selectedContainers } = useSelector(
        (state: RootStateOrAny) => state.containers
    );

    const initValues = useMemo(
        () => ({
            id: selectedContainers[0] ? selectedContainers[0].id : "",
            closestCompanyName: selectedContainers[0] ? selectedContainers[0].closestCompanyName : "",
            closestCompanyTaxNumber: selectedContainers[0] ? selectedContainers[0].closestCompanyTaxNumber : "",
            closestReceptionPlaceAddress: selectedContainers[0] ? selectedContainers[0].closestReceptionPlaceAddress : "",
            closestReceptionPlaceDistance: selectedContainers[0] ? selectedContainers[0].closestReceptionPlaceDistance : "",
            closestReceptionPlaceType: selectedContainers[0] ? selectedContainers[0].closestReceptionPlaceType : "",
            iccid: selectedContainers[0] ? selectedContainers[0].id : "",
            imei: selectedContainers[0] ? selectedContainers[0].imei : "",
            lastActiveDate: selectedContainers[0] ? selectedContainers[0].lastActiveDate : "",
            lastActiveDateStr: selectedContainers[0] ? selectedContainers[0].lastActiveDateStr : "",
            latitude: selectedContainers[0] ? selectedContainers[0].latitude : "",
            longitude: selectedContainers[0] ? selectedContainers[0].longitude : "",
            serNo: selectedContainers[0] ? selectedContainers[0].serNo : "",
            containerNumber: selectedContainers[0] ? selectedContainers[0].containerNumber : "",
            status: selectedContainers[0] ? selectedContainers[0].status : "",
            statusString: selectedContainers[0] ? selectedContainers[0].statusString : "",
            currentBatteryLevel: selectedContainers[0] ? selectedContainers[0].currentBatteryLevel : "",
            //identifier: selectedContainers[0] ? selectedContainers[0].identifier : "",
        }),
        [JSON.stringify(selectedContainers)]
    )

    const containerNumber = useMemo(
        () => ({
            containerNumber: selectedContainers[0] ? selectedContainers[0].containerNumber ? selectedContainers[0].containerNumber.toString() : "" : "",
        }),
        [JSON.stringify(selectedContainers)]
    )

    return { initValues, containerNumber: containerNumber.containerNumber };
}