import React, { useCallback } from "react";
import { useSelector, RootStateOrAny, useDispatch } from "react-redux";
import {
  Panel,
  IPanelStyles,
  IRenderFunction,
  IPanelProps,
  Stack,
  getTheme,
  IStackStyles,
} from "office-ui-fabric-react";

import { setActiveClient } from "../../../../config/redux/clients/clientsActions";
import {
  clearData,
  setActiveTab,
  setBasicInfo,
  toggleClientsPanel,
} from "../../../../config/redux/clients/panel/clientPanelActions";
import ClientTabs, { TabsNames } from "./CTabs";
import ClientPanelCommandBar from "../../../commandBar/components/clients/ClientPanelCommandBar";
import { setSelectedClients } from "../../actions/clientList";
import { clearAlerts } from "../../../../config/redux/alerts/alertsActions";
import { useBasicInfo } from "../../hooks/useBasicInfo";

const theme = getTheme();

const navS: IStackStyles = {
  root: {
    width: "100%",
    borderBottom: `2px solid ${theme.palette.neutralLighter}`,
  },
};

const PanelS: Partial<IPanelStyles> = {
  content: {
    padding: 20,
  },
  main: {
    height: "100%",
  },
};

const ClientPanel: React.FC = () => {
  const dispatch = useDispatch();
  const { clientPanelOpen } = useSelector(
    (state: RootStateOrAny) => state.clientPanel
  );
  useBasicInfo();

  const RenderNavigation: IRenderFunction<IPanelProps> = useCallback(
    (props, defaultRender) => (
      <Stack horizontal styles={navS} horizontalAlign="space-between">
        <ClientPanelCommandBar />
        {defaultRender!(props)}
      </Stack>
    ), []
  );

  const dismissPanel = () => {
    dispatch(clearData());
    dispatch(setActiveClient({ activeClient: "" }));
    dispatch(setSelectedClients({ selectedClients: [] }));
    dispatch(setActiveTab(TabsNames.BASIC_INFO));
    dispatch(clearAlerts());
    dispatch(toggleClientsPanel(false));
    dispatch(setBasicInfo(""))
  };

  return (
    <Panel
      isOpen={clientPanelOpen}
      closeButtonAriaLabel="Close"
      onDismiss={dismissPanel}
      type={3}
      styles={PanelS}
      isHiddenOnDismiss={false}
      isBlocking={true}
      onRenderNavigation={RenderNavigation}
    >
      <>
        <ClientTabs />
      </>
    </Panel>
  );
};

export default ClientPanel;
