export enum NavigationPath {
    HOME = "/",
    AUTH = "/authorization",
    NEW_PASS = "/setnewPassword",
    INACTIVE_ACCOUNT = "/inactive",
    UNAVAILABLE = "/unavailable",
    DRIVER_APP_MESSAGE = "/driver/app",
    //client
    CLIENT_DASHBOARD = "/client/dashboard",
    CLIENT_ORDERS = "/client/orders",
    CLIENT_RECEPTION_PLACES = "/client/receptionPlaces",
    CLIENT_USERS = "/client/users",
    //orders
    ALL_ORDERS = "/orders/all",
    NEW_ORDERS = "/orders/new",
    ACCEPTED_ORDERS = "/orders/accepted",
    IN_PROGRESS_ORDERS = "/orders/in-progress",
    COMPLETED_ORDERS = "/orders/completed",
    INVOICED_ORDERS = "/orders/invoiced",
    CANCELED_ORDERS = "/orders/canceled",
    //validators
    CONTAINERS_VALIDATORS = "/validators/ContainersValidation",
    RECEPTION_PLACES_VALIDATORS = "/validators/receptionPlacesValidation",
    ORDERS_VALIDATORS = "/validators/ordersValidation",
    //price list
    DETAILED_PRICE_LIST = "/priceList/detailedPriceList",
    GENERAL_PRICE_LIST = "/priceList/generalPriceList",
    //clients
    UNVERIFIED_CLIENTS = "/clients/unverified",
    // NEW_CLIENTS="/clients/new",
    ACTIVE_CLIENTS = "/clients/active",
    INACTIVE_CLIENTS = "/clients/inactive",
    RECEPTION_PLACES = "/clients/receptionPlaces",
    UNACTIVE_USERS = "/users/inactive",
    UNACTIVE_PICKUP_POINTS = "/pickuppoints/inactive",
    //config
    ORDER_TYPES = "/config/orderTypes",
    VEHICLES = "/config/vehicles",
    DRIVERS = "/config/drivers",
    OPERATORS = '/config/operators',
    CANCELLATION_REASONS = '/config/cancellationReasons',
    VALIDATOR_TYPES = "/config/validatorTypes",
    NOT_CONFIRMED_USERS = "/config/notConfirmedUsers",
    ADMINS = `/config/admins`,
    //temp
    TEMP = "/TEMP",
    //report
    REPORT_LIST = "/report/reportList",
    //warehouse
    STORE_LIST = "/store/list",
    INTERALSTORE_LIST = "/internalstore/list",
    CONTAINERS_LIST = "/store/containers",
    STORE_DOCS_LIST = "/store/docs",
    STORE_MAP = "/store/map",
    STORE_CONTAINER_MAP = "/store/container/map"
}

export enum BreadcrumbPath {
    //orders
    ALL_ORDERS = "Zamówienia/Wszystkie",
    NEW_ORDERS = "Zamówienia/Nowe",
    ACCEPTED_ORDERS = "Zamówienia/Przyjęte",
    IN_PROGRESS_ORDERS = "Zamówienia/W trakcie realizacji",
    COMPLETED_ORDERS = "Zamówienia/Zrealizowane",
    INVOICED_ORDERS = "Zamówienia/Zafakturowane",
    CANCELED_ORDERS = "Zamówienia/Anulowane",
    //validators
    CONTAINERS_VALIDATORS = "Walidatory/Kontenery",
    RECEPTION_PLACES_VALIDATORS = "Walidatory/Punkty odbioru",
    ORDERS_VALIDATORS = "Walidatory/Zamówienia",
    //price list
    DETAILED_PRICE_LIST = "Cennik/Cennik szczegółowy",
    GENERAL_PRICE_LIST = "Cennik/Cennik ogólny",
    //clients
    UNVERIFIED_CLIENTS = "Klienci/Bez weryfikacji",
    NEW_CLIENTS = "Klienci/Nowi",
    ACTIVE_CLIENTS = "Klienci/Aktywni",
    INACTIVE_CLIENTS = "Klienci/Nieaktywni",
    RECEPTION_PLACES = "Klienci/Punkty odbioru",
    //config
    ORDER_TYPES = "Ustawienia/Typy zamówień",
    VEHICLES = "Ustawienia/Flota",
    DRIVERS = "Ustawienia/Kierowcy",
    PRICING = "Ustawienia/Cennik",
    OPERATORS = 'Ustawienia/Operatorzy',
    CANCELLATION_REASONS = 'Ustawienia/Powody anulowania',
    VALIDATOR_TYPES = "Ustawienia/Typy walidatorów",
    NOT_CONFIRMED_USERS = "Ustawienia/Niezatwierdzeni",
    ADMINS = `Ustawienia/Administratorzy`,
    //report
    REPORT_LIST = "Raporty/Lista raportów",
    //store
    STORE_LIST = "Magazyn/Magazyny zewnętrzne",
    INTERALSTORE_LIST = "Magazyn/Magazyny wewnętrzne",
    STORE_DOCS_LIST = "Magazyn/Dokumenty",
    CONTAINERS_LIST = "Magazyn/Kontenery",
    STORE_MAP = "Magazyn/Mapa",

    UNACTIVE_USERS = `Użytkownicy/Nieaktywni`,
    UNACTIVE_PICKUP_POINTS = `Klienci/Punkty Odbioru/Niekatywne`
}

export const PathMap = new Map<string, BreadcrumbPath>([
    //orders
    [NavigationPath.ALL_ORDERS, BreadcrumbPath.ALL_ORDERS],
    [NavigationPath.NEW_ORDERS, BreadcrumbPath.NEW_ORDERS],
    [NavigationPath.ACCEPTED_ORDERS, BreadcrumbPath.ACCEPTED_ORDERS],
    [NavigationPath.IN_PROGRESS_ORDERS, BreadcrumbPath.IN_PROGRESS_ORDERS],
    [NavigationPath.COMPLETED_ORDERS, BreadcrumbPath.COMPLETED_ORDERS],
    [NavigationPath.INVOICED_ORDERS, BreadcrumbPath.INVOICED_ORDERS],
    [NavigationPath.CANCELED_ORDERS, BreadcrumbPath.CANCELED_ORDERS],
    //validators
    [NavigationPath.CONTAINERS_VALIDATORS, BreadcrumbPath.CONTAINERS_VALIDATORS],
    [NavigationPath.RECEPTION_PLACES_VALIDATORS, BreadcrumbPath.RECEPTION_PLACES_VALIDATORS],
    [NavigationPath.ORDERS_VALIDATORS, BreadcrumbPath.ORDERS_VALIDATORS],
    //price list
    [NavigationPath.DETAILED_PRICE_LIST, BreadcrumbPath.DETAILED_PRICE_LIST],
    [NavigationPath.GENERAL_PRICE_LIST, BreadcrumbPath.GENERAL_PRICE_LIST],
    //clients
    [NavigationPath.UNVERIFIED_CLIENTS, BreadcrumbPath.UNVERIFIED_CLIENTS],
    // [NavigationPath.NEW_CLIENTS, BreadcrumbPath.NEW_CLIENTS],
    [NavigationPath.ACTIVE_CLIENTS, BreadcrumbPath.ACTIVE_CLIENTS],
    [NavigationPath.INACTIVE_CLIENTS, BreadcrumbPath.INACTIVE_CLIENTS],
    [NavigationPath.RECEPTION_PLACES, BreadcrumbPath.RECEPTION_PLACES],
    //config
    [NavigationPath.ORDER_TYPES, BreadcrumbPath.ORDER_TYPES],
    [NavigationPath.VEHICLES, BreadcrumbPath.VEHICLES],
    [NavigationPath.DRIVERS, BreadcrumbPath.DRIVERS],
    [NavigationPath.OPERATORS, BreadcrumbPath.OPERATORS],
    [NavigationPath.CANCELLATION_REASONS, BreadcrumbPath.CANCELLATION_REASONS],
    [NavigationPath.VALIDATOR_TYPES, BreadcrumbPath.VALIDATOR_TYPES],
    [NavigationPath.ADMINS, BreadcrumbPath.ADMINS],
    [NavigationPath.NOT_CONFIRMED_USERS, BreadcrumbPath.NOT_CONFIRMED_USERS],
    [NavigationPath.UNACTIVE_USERS, BreadcrumbPath.UNACTIVE_USERS],
    //report
    [NavigationPath.REPORT_LIST, BreadcrumbPath.REPORT_LIST],
    //warehouse
    [NavigationPath.STORE_LIST, BreadcrumbPath.STORE_LIST],
    [NavigationPath.INTERALSTORE_LIST, BreadcrumbPath.INTERALSTORE_LIST],
    [NavigationPath.STORE_DOCS_LIST, BreadcrumbPath.STORE_DOCS_LIST],
    [NavigationPath.CONTAINERS_LIST, BreadcrumbPath.CONTAINERS_LIST],
    [NavigationPath.STORE_MAP, BreadcrumbPath.STORE_MAP]
])