import { useState, useEffect } from "react";
import _ from "lodash";

export const useDelay = (setFilter: any) => {
  const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined)
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setFilter()
    }, 800)
    return () => clearTimeout(delayDebounceFn)
  }, [searchTerm])

  return {
    setSearchTerm,
    searchTerm,
  };
};