import { setListMessage } from './../../../../config/redux/alerts/alertsActions';
import { toggleOrderPanel } from './../../../../config/redux/orders/ordersPanel/orderPanelActions';
import { useSelector, RootStateOrAny, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { generateItems } from "../../actions/generateCommands/orders/generateOrderPanelCommands";
import {
  toggleOrderAccept,
  addOrderComment,
  toggleCancelReason
} from "../../../../config/redux/orders/ordersActions";
import { acceptOrder } from "../../../../connectors/orders/connectors/index";
import { setOrderEditing } from "../../../../config/redux/orders/ordersPanel/orderPanelActions";

export const useOrderPanelCommandBar = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const state = useSelector((state: RootStateOrAny) => state.orders);
  const { activeTab, editing } = useSelector((state: RootStateOrAny) => state.orderPanel);
  const { selectedOrders } = state;

  const addComment = () => {
    dispatch(addOrderComment(true));
  };

  const setOrderAccepted = async () => {
    try {
      await acceptOrder(selectedOrders[0].id);
      dispatch(toggleOrderAccept(true));
      dispatch(toggleOrderPanel(false))
      dispatch(setListMessage("Operacja przebiegła pomyślnie"))
    } catch (err) {
      console.error(err);
    }
  };

  const setOrderCanceled = async () => {
    dispatch(toggleCancelReason(true))
  };

  const setEditing = () => {
    dispatch(setOrderEditing(true))
  }

  const actions = {
    addComment,
    setOrderAccepted,
    setOrderCanceled,
    setEditing
  };

  const items = selectedOrders[0]
    ? generateItems(state, location.pathname, actions, activeTab, editing)
    : [];

  return {
    items,
  };
};