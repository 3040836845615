import { clearAlerts, setListMessage, setErrorAlert } from './../../../config/redux/alerts/alertsActions';
import { useDispatch, RootStateOrAny, useSelector } from "react-redux";
import { FormikValues } from "formik";

import {
  setAdmin,
  addNewAdmin,
  toggleAdminsPanel,
} from "../../../config/redux/admins/adminsActions";
import { addAdmin, editAdmin} from "../../../connectors/admins/connectors/index";
import {IAdmin} from "../../../connectors/admins/models/index"
import { IResetForm } from "../../common/models/resetForm.type";
import { useState } from "react";

export const useAdminForm = () => {
  const [loading, setIsLoading] = useState(false)
  const { selectedAdmins, activeAdmin } = useSelector(
    (state: RootStateOrAny) => state.admins
  );
  const dispatch = useDispatch();
  let initValues = {
    firstName: "",
    lastName: "",
    phone: "",
    phoneNumber: "",
    email: "",
  };

  if (selectedAdmins[0]) {
    initValues = {
      firstName: selectedAdmins[0].firstName,
      lastName: selectedAdmins[0].lastName,
      phone: selectedAdmins[0].phone,
      email: selectedAdmins[0].email,
      phoneNumber: selectedAdmins[0].phone,
    };
  }

  const add = async (data: IAdmin) => {
    const res = await addAdmin(data);
  };

  const edit = async (data: IAdmin) => {
    data.phoneNumber = data.phone;
    editAdmin(data, selectedAdmins[0].id);
  };

  const handleSubmit = async (
    values: FormikValues,
    { resetForm }: IResetForm
  ) => {
    setIsLoading(true)
    const body = {
      email: values.email,
      firstName: values.firstName,
      lastName: values.lastName,
      phone: values.phone,
      phoneNumber: values.phone,
    };
    try {
      dispatch(clearAlerts())
      selectedAdmins.length >= 1 ? await edit(body) : await add(body);
      setIsLoading(false)
      dispatch(setAdmin({}));
      dispatch(addNewAdmin({ add: true }));
      resetForm();
      dispatch(toggleAdminsPanel({ open: false }));
      dispatch(setListMessage("Operacja przebiegła pomyślnie"))
    } catch (err) {
      setIsLoading(false)
      dispatch(setErrorAlert("Wystąpił błąd"))
    }
  };

  const cancelForm = () => {
    dispatch(toggleAdminsPanel({ open: false }));
    dispatch(clearAlerts())
  };

  return {
    handleSubmit,
    initValues,
    cancelForm,
    selectedAdmins,
    loading
  };
};
