import React, { useEffect } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { DatePicker, DayOfWeek, Label, mergeStyleSets } from "office-ui-fabric-react";

import { datePickerStrings } from "../../../../commandBar/components/orders/datePicker.data";
import { useEffectAfterMount } from "../../../../common/hooks/useEffectAfterMount";
import { useField } from "formik";

const controlClass = mergeStyleSets({
  control: {
    margin: "0 40px 0 0",
    backgroundColor: "red",
    maxWidth: "300px",
  },
});

interface IDateOfCompletionProp {
  name: string;
  values: any;
}

const DateOfCompletion: React.FC<IDateOfCompletionProp> = ({
  name,
  values,
}) => {
  const [firstDayOfWeek, setFirstDayOfWeek] = React.useState(DayOfWeek.Sunday);
  const { reloadOrders } = useSelector((state: RootStateOrAny) => state.orders);
  const [field, { value }, { setValue }] = useField(name);
  const [date, setDate] = React.useState<Date | null | undefined>(null);

  useEffect(() => {
    if (!values.receivingDate) {
      const today: Date = new Date(Date.now());
      const formatedDate = onFormatDate(today);
      setValue(formatedDate);
      setDate(today)
    }
  }, [values.receivingDate]);

  useEffectAfterMount(() => {
    if (reloadOrders) {
      setDate(null);
    }

    return () => {
      setDate(null);
    };
  }, [reloadOrders]);

  const onSelectDate = (date: Date | null | undefined): void => {
    setDate(date);

    const formatedDate = onFormatDate(date);
    setValue(formatedDate);
  };
  const today: Date = new Date(Date.now());
  const onFormatDate = (date: any): any => {
    const formatedDate = `${date.getFullYear()}-${add0toDate(
      date.getMonth() + 1
    )}-${add0toDate(date.getDate())}`;

    return formatedDate;
  };

  return (
    <div >
      <Label required={true}>
        Data zakończenia 
      </Label>
      <DatePicker
        className={controlClass.control}
        firstDayOfWeek={firstDayOfWeek}
        strings={datePickerStrings}
        placeholder="Wybierz datę"
        ariaLabel="Wybierz datę"
        value={date!}
        onSelectDate={onSelectDate}
        formatDate={onFormatDate}
        //minDate={today}
      />
    </div>
  );
};

export default DateOfCompletion;

const add0toDate = (date: any) => {
  if (parseInt(date) < 10) {
    return `0${date}`;
  }

  return date;
};
