import { IButtonStyles } from "office-ui-fabric-react/lib/Button";
import { SharedColors } from "@uifabric/fluent-theme";

export const errorButtonS: IButtonStyles = {
  root: {
    marginTop: 20,
    backgroundColor: SharedColors.red20,

    selectors: {
      [":hover"]: {
        backgroundColor: SharedColors.pinkRed10,
      },
    },
  },

  rootHovered: {
    backgroundColor: SharedColors.pinkRed10,
  },

  rootPressed: {
    backgroundColor: SharedColors.red20,
  },
};
