import React, { useEffect, useState } from "react";
import {
  container,
  dropDown,
} from "../../../styles/orderDetails.styles";
import Quantity from "./Quantity";
import FormDropDownOrderItemId from "../../../../common/layout/form/FormDropDownOrderItemId";
import { useTranslation } from "react-i18next";
import { IOrdersOrderPanelLang, IOrdersOrderTypeLang } from "../../../../../localization/abstract/client/orders";

export interface OrderParamsProps {
  orderType: any;
  setFieldValue: any;
  values: any;
  items: any;
  headerText: string,
  setOrderTypeItemId?: any;
  reception: number;
  setPrice?: any;
  onChange?: any;
  price?: any;
}

const OrderParams: React.FC<OrderParamsProps> = ({
  headerText,
  orderType,
  items,
  setPrice,
  reception,
  setOrderTypeItemId,
  onChange,
  price
}) => {
  const [tempOrderType, setTempOrderType] = useState<any>({});
  const [orderTypeHeader, setorderTypeHeader] = useState<string>("");

  const { t } = useTranslation("orders");
  const orderTypeNames: IOrdersOrderTypeLang = t("orderType", {
    returnObjects: true,
  });
  const orderPanelNames: IOrdersOrderPanelLang = t("orderPanel", {
    returnObjects: true,
  });

  useEffect(() => {
    setTempOrderType(orderType)
  }, [orderType])

  useEffect(() => {
    var header = getTextFromOrderTypeId(orderType, orderTypeNames)
    setorderTypeHeader(header);
  }, [orderType]
  )
  const getTextFromOrderTypeId = (headerText: string, orderTypeNames: any) => {

    switch (headerText) {
      case "Kontener":
        return orderTypeNames.container
      case "Tonaż":
        return orderTypeNames.tonnage
      case "Pojazd":
        return orderTypeNames.vehicle
      default:
        return headerText;
    }
  }
  return (
    <div className={container}>

      <div className={dropDown}>
        <FormDropDownOrderItemId
          name="orderTypeItemId"
          headerText={orderPanelNames.details}
          //headerText=""
          options={items}
          onChange={onChange}
          setOrderTypeItemId={setOrderTypeItemId}
          reception={reception}
          setPrice={setPrice}
          setTempOrderType={setTempOrderType}
          price={price}
        />
      </div>
      <Quantity
        orderType={tempOrderType}
      />
    </div>
  );
};

export default OrderParams;