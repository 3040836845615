import { mergeStyles } from "office-ui-fabric-react";
import React from "react";
import ListSuccessMessage from "../../common/layout/ListSuccessMessage";

import UnactiveUsersList from "../components/UnactiveUsersList";

const padding = mergeStyles({
  paddingTop: 15,
});

const UnactiveUsers: React.FC = () => {
  return (
    <div >
      <div className={padding}>
        <ListSuccessMessage />
      </div>
      <UnactiveUsersList />
    </div>
  );
};

export default UnactiveUsers;
