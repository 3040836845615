import React, {useEffect} from "react";
import { useQuery } from "react-query";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { setSelectedPrice } from "../../../../config/redux/pricing/pricingActions";

import { getPrices } from "../../../../connectors/pricing/connectors";
import { useList } from "../../../common/hooks/useList";
import { usePricing } from "../../hooks/usePricing";
import PricesList from "./PricesList";

const PricesListContainer: React.FC = () => {
  const {selectedPrice} = useSelector((state: RootStateOrAny) => state.pricing)
  const dispatch = useDispatch()
  const {  data, status } = useQuery("prices", getPrices)
  const {setPanelOpen, setPriceOnSelection} = usePricing()
  const fetchedData = data ? data.data.data : undefined
  const { handleInvoke, selection, items } = useList(fetchedData, setPanelOpen, setPriceOnSelection);

  useEffect(() => {
    if(!selectedPrice){
      selection.selectToKey("", true)
    }
  },[selectedPrice])

  useEffect(() => {

    return (() => {
      dispatch(setSelectedPrice(""))
    })
  },[])

  return (
    <PricesList
      items={items}
      handleInvoke={handleInvoke}
      selection={selection}
      status={status}
    />
  );
};

export default PricesListContainer;