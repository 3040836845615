import React from "react";
import { DirectionalHint, FontIcon, getTheme, TooltipDelay, TooltipHost } from "office-ui-fabric-react"
import { Stack } from "office-ui-fabric-react/lib/Stack";
import {
  DetailsList,
  DetailsListLayoutMode,
  SelectionMode,
  Selection,
  IDetailsListProps,
  IDetailsRowStyles,
  DetailsRow,
  IColumn,
} from "office-ui-fabric-react/lib/DetailsList";
import { listS } from "../../../../features/orders/styles/OrdersList.styles";
import { receptionPlacesColumnsShort } from "../columns/receptionPlacesColumnsShort";
import { useTranslation } from "react-i18next";
import { IReceptionPlacesColumnsLang } from "../../../../localization/abstract/client/receptionPlaces";

const theme = getTheme()

export interface ReceptionPlacesListProps {
  selection: Selection;
  items: Array<any>;
  handleInvoke: any;
}

const ReceptionPlacesList: React.FC<ReceptionPlacesListProps> = ({
  items,
  selection,
  handleInvoke,
}) => {

  const { t } = useTranslation("receptionPlaces");
  const columnsNames: IReceptionPlacesColumnsLang = t("columns", {
    returnObjects: true,
  });

  const onRenderRow: IDetailsListProps["onRenderRow"] = (props: any) => {
    const customStyles: Partial<IDetailsRowStyles> = {};
    if (props) {
      if (props.item.isDefault) {
        customStyles.root = { color: theme.palette.blue };
      }
      if (!props.item.isActive) {
        customStyles.root = { color: theme.palette.red };
      }
      return <DetailsRow {...props} styles={customStyles} />;
    }
    return null;
  };
  const onRenderItemColumn = (item?: any, index?: number, column?: IColumn): JSX.Element | string | number => {
    if (column === null) {
      return "";
    }
    if (!!column && column.key === 'thumbnail' && !item.isActive) {
      return <>
        <TooltipHost
          content={columnsNames.waitForAdminApprove}
          delay={TooltipDelay.zero}
          directionalHint={DirectionalHint.bottomCenter}
        >
          <FontIcon iconName='warning' />
        </TooltipHost>
        {item.email}
      </>;
    }
    if (!!column) {
      return item[column.key as keyof any];
    } else {
      return "";
    }
  };

  return (
    <Stack>
      <DetailsList
        items={items}
        columns={receptionPlacesColumnsShort(columnsNames)}
        styles={listS}
        setKey="items"
        selection={selection}
        layoutMode={DetailsListLayoutMode.justified}
        selectionPreservedOnEmptyClick={true}
        onRenderRow={onRenderRow}
        selectionMode={SelectionMode.single}
        onItemInvoked={handleInvoke}
        usePageCache={true}
        onRenderItemColumn={onRenderItemColumn}
      />
    </Stack>
  );
};

export default ReceptionPlacesList;