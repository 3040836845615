import {
  IStackStyles,
  getTheme,
  mergeStyles,
} from "office-ui-fabric-react";
import { Breakpoints } from "../../../features/common/styles/utils/Breakpoints";

const theme = getTheme();

export const containerS: IStackStyles = {
  root: {
    backgroundColor: theme.palette.themePrimary,
    width: "100%",
    height: "50px",
    padding: "10px 25px",
    zIndex: 20,
  },
};

export const textS = mergeStyles({
  fontSize: 20,
  fontWeight: 600,
  margin: 0,
  marginLeft: 10,
  lineHeight: 20,
  display: "block",
  padding: 0,
  color: "white",
});

export const link = mergeStyles({
  textDecoration: "none",
});

export const container = mergeStyles({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

export const burgerS = mergeStyles({
  color: "white",
  marginRight: 30,
  cursor: "pointer",
  selectors: {
    [`@media ${Breakpoints.LARGE}`]: {
      display: "none",
    },
  },
});
