import { NavigationPath } from './../../../config/routing/NavigationPath';
import { IClientOptionalParams } from "../models/client-params.model";

export const getParams = (pathname: string) => {
  let params: IClientOptionalParams = { isActive: false, Verified: false };
  // if(pathname === NavigationPath.NEW_CLIENTS){
  //   const date = new Date()
  //   const time = date.getTime()
  //   const tenDays = 1000 * 60 * 60 * 24 * 10
  //   const tenDaysAgo = time  - tenDays
  //   createdAfter = moment(tenDaysAgo).format("YYYY-MM-DD")
  // }

  switch (pathname) {
    case NavigationPath.UNVERIFIED_CLIENTS:
      return (params = { isActive: false, Verified: false });
    // case NavigationPath.NEW_CLIENTS:
    //   return (params = { isActive: true, Verified: true,  CreatedAfter: createdAfter });
    case NavigationPath.ACTIVE_CLIENTS:
      return (params = { isActive: true, Verified: true });
    case NavigationPath.RECEPTION_PLACES:
      return (params = { isActive: true, Verified: true });
    case NavigationPath.INACTIVE_CLIENTS:
      return (params = { isActive: false, Verified: true });
  }

  return params;
};