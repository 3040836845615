import { DetailedPriceActions } from "./detailedPriceActions.enum";
import { IDetailedPriceAction } from "./detailedPriceActions.types";

const initState = {
  selectedDetailedPrice: [],
  activeDetailedPrice: {},
  reloadDetailedPrice: false,
};

export const detailedPriceReducer = (
  state = initState,
  action: IDetailedPriceAction
) => {
  const { type, payload } = action;
  switch (type) {
    case DetailedPriceActions.SET_ACTIVE_DETAILED_PRICE:
      return { ...state, activeDetailedPrice: payload };
    case DetailedPriceActions.SET_DETAILED_PRICE:
      return { ...state, selectedDetailedPrice: payload.selectedDetailedPrice };
    case DetailedPriceActions.REMOVE_DATA:
      return { ...state, reload: payload.reload };
    case DetailedPriceActions.ACCEPT_DETAILED_PRICE:
      return { ...state, detailedPriceAccepted: payload };
    case DetailedPriceActions.CANCEL_DETAILED_PRICE:
      return { ...state, detailedPriceCanceled: payload };
    case DetailedPriceActions.RELOAD:
      return { ...state, reloadDetailedPrice: payload };
    default:
      return state;
  }
};