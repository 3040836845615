import { StoreDocsActions } from "./storeDocsActions.enum";
import { IStoreDocsAction } from "./storeDocsActions.types";

const initState = {
  selectedStoreDocs: [],
  activeStoreDocs: "",
  reload: false,
  toggleStoreDocsPanel: false,
  addStoreDoc: false,
  isOpen: false,
  add: false,
  approve: false,
  cancel: false
};

export const storeDocsReducer = (state = initState, action: IStoreDocsAction) => {
  const { type, payload } = action;
  switch (type) {
    case StoreDocsActions.SET_STOREDOCS:
      return { ...state, selectedStoreDocs: payload.selectedStoreDocs };
    case StoreDocsActions.SET_ACTIVE_STOREDOC:
      return { ...state, activeStoreDocs: payload.activeStoreDocs };
    case StoreDocsActions.RELOAD_DATA:
      return { ...state, reload: payload.reload };
    case StoreDocsActions.TOGGLE_PANEL:
      return { ...state, toggleStoreDocsPanel: payload.isOpen, toggleStoreDocsPanelToAdd: payload.add};
    case StoreDocsActions.ADD_NEW_STOREDOC:
      return { ...state, addStoreDoc: payload };
    case StoreDocsActions.SET_APPROVE:
      return { ...state, approve: payload}
    case StoreDocsActions.SET_CANCEL:
      return { ...state, cancel: payload}      
    case StoreDocsActions.CLEAR_LIST_DATA:
      return { ...initState };
    default:
      return state;
  }
};
