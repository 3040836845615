import React, { useEffect, useMemo, useState } from 'react';
import { AzureDataPosition, AzureMap, AzureMapDataSourceProvider, AzureMapFeature, AzureMapLayerProvider, AzureMapPopup, AzureMapsProvider, IAzureMapFeatureType, IAzureMapOptions } from "react-azure-maps";
import { AuthenticationType, data } from 'azure-maps-control'
import { wrapper } from '../../common/styles/formDropDown.styles';
import { useContainerDetailMap } from '../hooks/useContainerDetailMap';
import { Dropdown, Slider } from '@fluentui/react';
import { useContainerHistoryPoints } from '../hooks/useContainerHistoryPoints';
import { IContainerHistoryPoint } from '../interfaces/IContainerHistoryPoint';
import { useContainerHistoryMap } from '../hooks/useContainerHistoryMap';
import { ContainerHistoryPopupHtml } from './ContainerHistoryPopupHtml';
import { ContainerDetailPopupHtml } from './ContainerDetailPopupHtml';

const { REACT_APP_AZURE_MAP_KEY } = process.env;

const option: IAzureMapOptions = {
    authOptions: {
        authType: AuthenticationType.subscriptionKey,
        subscriptionKey: REACT_APP_AZURE_MAP_KEY
    },
    center: [19.4803, 52.0693],
    zoom: 10,
    //view: 'Auto',
}

const ContainerDetailMap: React.FC = () => {
    const [daysBack, setDaysBack] = useState<number>(0)
    const { initValues } = useContainerDetailMap()
    const { containerHistoryPoints, dateOptions } = useContainerHistoryMap();
    const { filteredContainerHistoryPoints } = useContainerHistoryPoints(containerHistoryPoints, daysBack)
    option.center = [initValues.longitude, initValues.latitude]
    const [popupVisible, setPopupVisible] = useState<boolean>(false);
    const [popupPosition, setPopupPosition] = useState<data.Position>(new data.Position(
        0,
        0));

    function showPopup(e: any) {
        if (e.shapes && e.shapes.length > 0) {
            setPopupVisible(true);
            setPopupPosition(createDataPosition(initValues.longitude, initValues.latitude))
        }
    }

    const preparePopupOptions = (pointPosition: any, offset: number = -25) => {
        var options = {
            position: pointPosition,
            pixelOffset: [0, offset]
        }
        return options;
    }

    const memoizedMarkerRender = useMemo(
        () => (
            <AzureMapFeature
                key={initValues.id}
                id={initValues.id}
                type="Point"
                // coordinate={data}
                coordinate={[initValues.longitude, initValues.latitude]}
                properties={{
                    id: initValues.id,
                }}
            />
        ),
        [initValues]
    );

    const currentContainerHistoryLinePoints: AzureDataPosition[] = [];
    const [currentContainerHistoryPoints, setCurrentContainerHistoryPoints] = useState<any[]>([]);

    useEffect(() => {
        const currentContainerHistoryPoints: any[] = [];
        filteredContainerHistoryPoints.map((x: IContainerHistoryPoint) => {
            currentContainerHistoryLinePoints.push(createDataPosition(x.longitude, x.latitude));
            currentContainerHistoryPoints.push({
                id: x.id,
                key: x.id,
                longitude: x.longitude,
                latitude: x.latitude
            });
        })
        setCurrentContainerHistoryPoints(currentContainerHistoryPoints)

    }, [filteredContainerHistoryPoints])

    const memomizedContainerHistoryPointsFunc = React.useMemo(
        () => (
            renderMultiLine('LineString', currentContainerHistoryLinePoints, {})
        ), [filteredContainerHistoryPoints]
    )

    const memomizedContainerHistoryPoints = React.useMemo(
        () => (
            renderMultiLine1(currentContainerHistoryPoints)
        ),
        [filteredContainerHistoryPoints, currentContainerHistoryPoints]
    )

    const memoizedContainerDetailPopup = React.useMemo(
        () => (
            <AzureMapPopup
                isVisible={popupVisible}
                options={preparePopupOptions(popupPosition)}
                popupContent={<ContainerDetailPopupHtml containerDetail={initValues} />}
            />
        ),
        [popupVisible, popupPosition],
    );

    const createDataPosition = (longitude: number, latitude: number) => {
        return new data.Position(
            longitude,
            latitude)
    }

    const [sliderValue, setSliderValue] = React.useState<IContainerHistoryPoint>();

    const sliderOnChange = (value: number) => {
        if (filteredContainerHistoryPoints[value] !== undefined) {
            var containerHistoryPointSelected: IContainerHistoryPoint = {
                id: filteredContainerHistoryPoints[value].id,
                key: filteredContainerHistoryPoints[value].key,
                latitude: filteredContainerHistoryPoints[value].latitude,
                longitude: filteredContainerHistoryPoints[value].longitude,
                lastActiveDate: filteredContainerHistoryPoints[value].lastActiveDate,
                lastActiveDateStr: filteredContainerHistoryPoints[value].lastActiveDateStr,
                firstActiveDateStr: filteredContainerHistoryPoints[value].firstActiveDateStr
            }
            setSliderValue(containerHistoryPointSelected);
            setPopupVisible(false);
        }
    }

    const selectedDeviceHistoryPoint = React.useMemo(
        () => (
            <AzureMapFeature
                key={sliderValue !== undefined ? sliderValue.id : 0}
                id={sliderValue !== undefined ? sliderValue.id.toString() : ""}
                type="Point"
                coordinate={[sliderValue !== undefined ? sliderValue.longitude : 0,
                sliderValue != undefined ? sliderValue.latitude : 0]}
            />
        ), [sliderValue]
    )
    const selectedDeviceHistoryPopup = React.useMemo(
        () => (
            <AzureMapPopup
                isVisible={true}
                options={preparePopupOptions(createDataPosition(
                    sliderValue !== undefined ? sliderValue.longitude : 0,
                    sliderValue !== undefined ? sliderValue.latitude : 0), -5)}
                popupContent={
                    <ContainerHistoryPopupHtml containerHistoryPoint={sliderValue} />
                }
            />
        ),
        [sliderValue]
    );

    const onChange = (
        event: React.FormEvent<HTMLDivElement>,
        option?: any
    ): void => {
        if (option) {
            setDaysBack(option.days)
        }
    };

    return (<>
        <div className={wrapper}>
            <div style={{
                height: "70vh",
                width: "150vh",
                // paddingRight: '1vh',
                // paddingLeft: '1vh',
                // paddingTop: '1vh'
            }}>

                <AzureMapsProvider>
                    <AzureMap options={option}>
                        <AzureMapDataSourceProvider
                            id={'AzureMapDataSourceProvider main'}
                        >
                            <AzureMapDataSourceProvider id={'AzureMapDataSourceProvider memoizedContainerDetailPopup'}>
                                <AzureMapLayerProvider
                                    id={'AzureMapLayerProvider memoizedContainerDetailPopup'}
                                    type={"SymbolLayer"} />
                                {memoizedContainerDetailPopup}
                            </AzureMapDataSourceProvider>
                            <AzureMapDataSourceProvider id={'DataSource Provider1222'}>
                                <AzureMapLayerProvider
                                    id={'shape AzureMapLayerProvider1162'}
                                    type={"LineLayer"} />
                                {/* {lines.map((value: AzureDataPosition[]) => renderMultiLine('LineString', value, {}))} */}
                                {memomizedContainerHistoryPointsFunc}
                            </AzureMapDataSourceProvider>
                            <AzureMapDataSourceProvider id={'DataSource Provider2'}>
                                <AzureMapLayerProvider
                                    options={{
                                        radius: 6,
                                        strokeWidth: 0,
                                        color: "#0066CC",
                                    }}
                                    id={'shape AzureMapLayerProvider2'}
                                    type={'BubbleLayer'}
                                />
                                {memomizedContainerHistoryPoints}
                            </AzureMapDataSourceProvider>
                            {sliderValue !== undefined ?
                                <AzureMapDataSourceProvider id={'DataSource Provider222'}>
                                    <AzureMapLayerProvider
                                        options={{
                                            radius: 9,
                                            strokeWidth: 0,
                                            color: "#0066CC",
                                        }}
                                        id={'shape AzureMapLayerProvider222'}
                                        type={'BubbleLayer'}
                                    />
                                    {selectedDeviceHistoryPoint}
                                </AzureMapDataSourceProvider>

                                : <></>}
                            {sliderValue !== undefined ?
                                <AzureMapDataSourceProvider id={'DataSource Provider12211'}>
                                    <AzureMapLayerProvider
                                        id={'shape AzureMapLayerProvider11211'}
                                        type={"SymbolLayer"} />
                                    {selectedDeviceHistoryPopup}
                                </AzureMapDataSourceProvider>
                                : <></>}
                            <AzureMapLayerProvider
                                id={'shape AzureMapLayerProvider11'}
                                events={{
                                    click: showPopup,
                                }}
                                type={"SymbolLayer"} />
                            {memoizedMarkerRender}
                        </AzureMapDataSourceProvider>
                    </AzureMap>
                </AzureMapsProvider>
            </div>
            <Dropdown
                placeholder="Wybierz ilość dni"
                label="Podaj zakres dni wstecz ile chcesz zobaczyć"
                options={dateOptions}
                onChange={onChange}
                defaultSelectedKey={0}
            // styles={dropdownStyles}
            />
            <Slider aria-label="Basic example"
                onChange={sliderOnChange}
                max={filteredContainerHistoryPoints.length - 1}
            />
        </div>
    </>)

}
export default ContainerDetailMap;

export const renderMultiLine = (
    type: IAzureMapFeatureType,
    coordinates: data.Position[],
    properties: Record<string, unknown>,
) => {
    const rendId = Math.random();

    return (
        <AzureMapFeature
            key={rendId}
            id={rendId.toString()}
            type={type}
            coordinates={coordinates}
            properties={properties}
        />
    );
};

export const renderMultiLine1 = (
    points: any[]
) => {
    return (
        <>
            {points.map(x => {
                const popupOptions = {
                    position: new data.Position(x.longitude, x.latitude)
                };
                return <>
                    <AzureMapFeature
                        key={x.key}
                        id={x.id.toString()}
                        variant={'shape'}
                        type="Point"
                        coordinate={popupOptions.position}
                    //setCoords={x}
                    // properties={{
                    //   x: x.id,
                    //   y: x.groupId
                    // }}
                    />
                </>
            })}
        </>
    )
}