import { IDetailsListStyles } from "office-ui-fabric-react";

export const listS: Partial<IDetailsListStyles> = {
  

    focusZone: {
    width: "100px",
  },

  contentWrapper: {
    selectors: {
      "div[data-automationid=DetailsRowCell]": {
        cursor: "pointer",
      },
      "div[data-automationid=DetailsRowCheck]": {
        cursor: "pointer",
      },
    },
  },
};
