import * as React from "react";
import { useSelector, RootStateOrAny } from "react-redux";
import BasicList from "../../common/layout/BasicList";
import {
    setDriver,
    setSelectedDrivers,
    toggleDriversPanel,
    reloadDrivers,
    addNewDriver,
} from "../../../config/redux/drivers/driversActions";
import { columns } from "./driversColumns"
import { getDrivers } from "../../../connectors/drivers/connectors/index";

const DriversList: React.FC = () => {
    const {
        selectedDrivers,
        activeDriver,
        driverPanelOpen,
        reloadDriversList,
        addDriver,
    } = useSelector((state: RootStateOrAny) => state.drivers);

    const parseDriver = (items: Array<any>) => {
        const drivers = items.map((item: any) => item.userData);
        return drivers;
    };

    return (
        <>
            <BasicList
                setSelectedItems={setSelectedDrivers}
                setActiveItem={setDriver}
                togglePanel={toggleDriversPanel}
                reload={reloadDrivers}
                editList={addNewDriver}
                getData={getDrivers}
                activeItem={activeDriver}
                panelOpen={driverPanelOpen}
                selectedItems={selectedDrivers}
                removeItems={reloadDriversList}
                listEdited={addDriver}
                columns={columns}
                parseItems={parseDriver}
            />
        </>
    );
};

export default DriversList;


