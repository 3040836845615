import { ContainerBatteryStatusEnum } from "../../storeMap/enums/ContainerBatteryStatusEnum";

export const ContainerBatteryStatusEnumToString = (batteryStatus: ContainerBatteryStatusEnum) => {
    switch (batteryStatus) {
        case ContainerBatteryStatusEnum.NoInfo:
            return "brak informacji";
        case ContainerBatteryStatusEnum.Normal:
            return "bateria w normie";
        case ContainerBatteryStatusEnum.Low:
            return "niski poziom baterii";
        case ContainerBatteryStatusEnum.Critical:
            return "krytyczny poziom baterii";
    }
}

export const ContainerBatteryStatusEnumToStringFirstCapitalLetter = (batteryStatus: ContainerBatteryStatusEnum) => {
    let result = ContainerBatteryStatusEnumToString(batteryStatus)
    return capitalizeFirstLetter(result);
}

function capitalizeFirstLetter(text: string) {
    return text.charAt(0).toUpperCase() + text.slice(1);
}