import { setListMessage } from './../../../../config/redux/alerts/alertsActions';
import { useSelector, RootStateOrAny, useDispatch } from "react-redux";
import { checkQuantity } from "../../actions/checkQuantity";
import { generateItems } from "../../actions/generateCommands/generateUnactiveUsersCommands";
import { setListErr } from "../../../../config/redux/alerts/alertsActions";
import { setActivatedUsers, setSelectedUnactiveUsers } from '../../../../config/redux/unactiveusers/unactiveusersActions';
import { activateUser } from '../../../../connectors/clients/connectors';

export const useUnactiveUsersCommandBar = () => {
    const dispatch = useDispatch();
    const { selectedUnactiveUsers, activatedUsers } = useSelector(
        (state: RootStateOrAny) => state.unactiveusers
    );

    const activateUserById = async () => {
      try {
        let temp = [];
        temp = Object.assign({}, activatedUsers);
        temp[selectedUnactiveUsers[0].id]  = true;
        dispatch(setActivatedUsers(temp));
        const res = await activateUser(selectedUnactiveUsers[0].id);
        dispatch(setListMessage("Operacja przebiegła pomyślnie"));
        dispatch(setSelectedUnactiveUsers(""));
      } catch(err) {
        console.error(err);
        dispatch(setListErr("Wystąpił błąd podczas aktywacji użytkownika"));
      }
    };

    const actions = {
        activateUserById,
    };

    const quantity = checkQuantity(selectedUnactiveUsers.length);

    const items = generateItems(quantity, actions, selectedUnactiveUsers[0], activatedUsers);

    return {
        items,
    };
};