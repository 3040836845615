import {  mergeStyles, ITextStyles } from "office-ui-fabric-react";
import { FontSizes } from "@uifabric/fluent-theme";
import { FontWeights } from "office-ui-fabric-react";

export const container = mergeStyles({
  padding: 15,
  display: "flex",
  flexDirection: "column",
});

export const orderTypeContainer = mergeStyles({
  marginBottom: 15,
});

export const textS: ITextStyles = {
  root: {
    marginBottom: 20,
  },
};

export const headerS: ITextStyles = {
  root: {
    marginBottom: 15,
    marginTop: 15,
    fontSize: FontSizes.size16,
    fontWeight: FontWeights.semibold,
  },
};
