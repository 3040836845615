import * as React from "react";
import { useSelector, RootStateOrAny } from "react-redux";
import BasicList from "../../common/layout/BasicList";
import {
  setUser,
  setSelectedUsers,
  toggleUsersPanel,
  reloadUsers,
  addNewUser,
} from "../../../config/redux/users/usersActions";
import { getUsers } from "../../../connectors/clients/connectors";
import { DetailsRow, DirectionalHint, FontIcon, getTheme, IDetailsListProps, IDetailsRowStyles, TooltipDelay, TooltipHost } from "office-ui-fabric-react";
import { useTranslation } from "react-i18next";
import { IUsersColumnsLang } from "../../../localization/abstract/client/users";

const UsersList: React.FC = () => {
  const {
    selectedUsers,
    activeUser,
    userPanelOpen,
    reloadUsersList,
    addUser,
  } = useSelector((state: RootStateOrAny) => state.users);

  const { t } = useTranslation("users");
  const userColumns: IUsersColumnsLang = t("columns", {
    returnObjects: true,
  });

  const usersColumns = [
    {
      key: "column1",
      name: userColumns.email,
      fieldName: "email",
      minWidth: 50,
      maxWidth: 100,
      isResizable: true,
      onRender: (item: any) => (item.isInvitationInProgress ?
        <><TooltipHost
          content={userColumns.invitationSend}
          delay={TooltipDelay.zero}
          directionalHint={DirectionalHint.bottomCenter}
        ><FontIcon iconName='mail' /> </TooltipHost>{item.email}</> : item.email
      ),
    },
    {
      key: "column2",
      name: userColumns.firstName,
      fieldName: "firstName",
      minWidth: 50,
      maxWidth: 100,
      isResizable: true,
    },
    {
      key: "column3",
      name: userColumns.lastName,
      fieldName: "lastName",
      minWidth: 50,
      maxWidth: 100,
      isResizable: true,
    },
    {
      key: "column4",
      name: userColumns.phoneNumber,
      fieldName: "phone",
      minWidth: 50,
      maxWidth: 100,
      isResizable: true,
    },
    {
      key: "column5",
      name: userColumns.departmentName,
      fieldName: "departmentName",
      minWidth: 100,
      maxWidth: 150,
      isResizable: true,
    },
  ];


  let conmapnId = sessionStorage.getItem('companyId') !== null ? sessionStorage.getItem('companyId') : '';

  const getUsersDel = () => getUsers(String(conmapnId));

  const parseUser = (users: Array<any>) => {
    // return items;
    const items: Array<any> = [];

    users.forEach((user: any) => {
      items.push({
        key: user.id,
        id: user.id,
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        phone: user.phone,
        isActive: user.isActivated,
        department: user.department,
        departmentName: user.departmentName,
        isClientAdmin: user.isClientAdmin,
        isInvitationInProgress: user.isInvitationInProgress
      });
    });

    return [...items];
  };


  const onRenderRow: IDetailsListProps["onRenderRow"] = (props: any) => {
    const customStyles: Partial<IDetailsRowStyles> = {};
    if (props) {
      if (props.item.isDefault || props.item.isClientAdmin) {
        customStyles.root = { color: theme.palette.blue };
      }
      if (props.item.isActive === false) {
        customStyles.root = { color: theme.palette.red };
      }
      if (props.item.isInvitationInProgress === true) {
        customStyles.root = { color: 'rgba(50, 49, 48, 0.5)' };
      }

      return <DetailsRow {...props} styles={customStyles} />;
    }
    return null;
  };

  const theme = getTheme();

  return (
    <>
      <BasicList
        setSelectedItems={setSelectedUsers}
        setActiveItem={setUser}
        togglePanel={toggleUsersPanel}
        reload={reloadUsers}
        editList={addNewUser}
        getData={getUsersDel}
        activeItem={activeUser}
        panelOpen={userPanelOpen}
        selectedItems={selectedUsers}
        removeItems={reloadUsersList}
        listEdited={addUser}
        columns={usersColumns}
        parseItems={parseUser}
        onRenderRow={onRenderRow}
      />
    </>
  );
};

export default UsersList;