import { Breakpoints } from "../../common/styles/utils/Breakpoints";
import { mergeStyles } from "office-ui-fabric-react";

export const container = mergeStyles({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  width: "100%",
  alignItems: "center",
  marginBottom: "30px",
  selectors: {
    [`@media ${Breakpoints.LARGE}`]: {
      flexDirection: "row",
      flexWrap: "wrap",
      width: "100%",
      justifyContent: "space-between",
    },
  },
})


export const divider = mergeStyles({
  display: "flex",
  width: "100%",
  flexWrap: "wrap",
  justifyContent: "center",
  selectors: {
    [`@media ${Breakpoints.LARGE}`]: {
      flexWrap: "nowrap",
      justifyContent: "space-between",
    },
  },
})

export const fieldContainer = mergeStyles({

  width: "100%",
  selectors: {
    [`@media ${Breakpoints.LARGE}`]: {
      width: "250px"
    },
  },
})