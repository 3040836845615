import React from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { DatePicker, DayOfWeek, mergeStyleSets } from "office-ui-fabric-react";

import { setRealizationDate } from "../../../../config/redux/orders/ordersActions";
import { datePickerStrings } from "./datePicker.data";
import { useEffectAfterMount } from "../../../common/hooks/useEffectAfterMount";

const controlClass = mergeStyleSets({
  control: {
    margin: "0 40px 0 0",
    backgroundColor: "red",
    maxWidth: "300px",
  },
});

const RealizationDatePicker: React.FC = () => {
  const [firstDayOfWeek, setFirstDayOfWeek] = React.useState(DayOfWeek.Monday);
  const {reloadOrders} = useSelector((state: RootStateOrAny) => state.orders)
  const [value, setValue] = React.useState<Date | null | undefined>(null);
  const dispatch = useDispatch();


  useEffectAfterMount(() => {
    if(reloadOrders){
      setValue(null)
    }

    return () => {
      setValue(null)
    }
  },[reloadOrders])

  const onSelectDate = (date: Date | null | undefined): void => {
    setValue(date);

    const formatedDate = onFormatDate(date);
    dispatch(setRealizationDate({ date: formatedDate }));
  };
  const today: Date = new Date(Date.now());
  const onFormatDate = (date: any): any => {
    const formatedDate = `${date.getFullYear()}-${add0toDate(
      date.getMonth() + 1
    )}-${add0toDate(date.getDate())}`;

    return formatedDate;
  };

  
  return (
    <div>
      <DatePicker
        className={controlClass.control}
        firstDayOfWeek={firstDayOfWeek}
        strings={datePickerStrings}
        placeholder="Wybierz datę"
        ariaLabel="Wybierz datę"
        value={value!}
        onSelectDate={onSelectDate}
        formatDate={onFormatDate}
        //minDate={today}
        
        // parseDateFromString={onParseDateFromString}
      />
    </div>
  );
};

export default RealizationDatePicker;

const add0toDate = (date: any) => {
  if (parseInt(date) < 10) {
    return `0${date}`;
  }

  return date;
};


