import { IActions } from '../../../../../clients/models/commandBar-actions-model';
import { IQuantity } from '../../../../../common/models/quantity.model';
import {Quantity} from "../../../../../common/models/quantity.model"

export const generateOthers = (quantity: IQuantity, actions: IActions) => {
    if (quantity === Quantity.NONE) {
      return [
        {
          key: "add",
          text: "Dodaj",
          iconProps: { iconName: "add" },
          name: "add",
          onClick: actions.toggle,
        },
        
      ];
    } else {
      return [
        {
          key: "delete",
          text: "Usuń",
          iconProps: { iconName: "delete" },
          name: "delete",
          onClick: actions.deleteDepartmentById,
        },
        {
          key: "edit",
          text: "Edytuj",
          iconProps: { iconName: "edit" },
          name: "add",
          onClick: actions.toggle,
        },
      ];
    }
  };