import React from "react";
import { Text } from "office-ui-fabric-react/lib/Text";
import { titleS, btnS } from "../../../styles/title";
import NewFormField from "../../../../common/layout/form/NewFormField";
import { useTranslation } from "react-i18next";
export interface QuantityProps {
    orderType: any
}
const Quantity: React.FC<QuantityProps> = ({orderType}) => {
  const { t } = useTranslation("orders");
  let quantityField;
  if (orderType.orderTypeId === 1) {
    quantityField =
      <div className={btnS}>
        <Text styles={titleS}>{t("quantity")} (szt.) </Text>
        <Text>1</Text>
      </div>;
  }
  else if (orderType.orderTypeId === 2){
    quantityField =
    <>
      <NewFormField
      name="quantity"
      label={t("quantity")}
      type="number"
      min = "1"
      max = "50"
    />
  </>
  }
  else if (orderType.orderTypeId === 3){ // OrderTypes.CONTAINER
    quantityField =
    <>
      <NewFormField
      name="quantity"
      label={t("quantity")}
      type="number"
      min = "1"
      max = "2"
      />
    </>
  }
  else{
    quantityField = <>
      <NewFormField
          name="quantity"
          label={t("quantity")}
          type="number"
          min = "1"
          max = "50"
      />
    </>
  }
  return (
    <div>
      {quantityField}
    </div>
    );
}
export default Quantity;