import { mergeStyles } from "office-ui-fabric-react";
import React from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import OrderDetailTxt from "../edditOrder/OrderDetailTxt";

const ReadOnlyDetails: React.FC = () => {
  const { orderDetails } = useSelector(
    (state: RootStateOrAny) => state.orderPanel
  );

  const wrapper = mergeStyles({
    display: "flex",
    justifyContent: "space-between",
  });
  
  const mainWrapper = mergeStyles({
    paddingTop: 20,
    paddingBottom: 15
  })
  const divider = mergeStyles({
    width: 250,
  });

  return (
    <div className={mainWrapper}>
      <div className={wrapper}>
        <div className={divider}>
        <OrderDetailTxt
          title="Numer Zamówienia"
          value={orderDetails ? orderDetails.orderInfo.number : ""}
        />
        </div>
        <div className={divider}>
          <OrderDetailTxt
            title="Nazwa klienta"
            value={orderDetails ? orderDetails.orderInfo.company : ""}
          />
        </div>
      </div>
      <div className={wrapper}>
      <div className={divider}>
          <OrderDetailTxt
            title="Typ zamówienia"
            value={orderDetails ? orderDetails.orderInfo.type : ""}
          />
        </div>
         <div className={divider}>
          <OrderDetailTxt
            title="Adres odbioru"
            value={orderDetails ? orderDetails.orderInfo.receptionPlace : ""}
          />
         </div>
      </div>
    </div>
  );
};

export default ReadOnlyDetails;