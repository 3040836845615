import React from "react";
import { useField } from "formik";
import { TextField } from "office-ui-fabric-react";

import {textFieldS} from "../../styles/edditionField.styles";

export interface NewEdditionFieldProps {
  name: string;
  label: string;
  placeholder?: string;
  type?: string;
  required?: boolean;
}

const NewEdditionField: React.FC<NewEdditionFieldProps> = ({
  name,
  label,
  placeholder,
  type,
  required,
}) => {
  const [field, { value, error, touched }] = useField(name);
  return (
    <TextField
      type={type ? type : "text"}
      label={label}
      placeholder={placeholder}
      errorMessage={touched ? error : ""}
      required={required ? true : false}
      {...field}
      styles={textFieldS}
    />
  );
};

export default NewEdditionField;
