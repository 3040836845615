import React from "react";
import { container } from "../../../styles/prices.styles";
import OrderDetailTxt from "../edditOrder/OrderDetailTxt";
import PricesShow from "../PricesShow";
import { OrderTypeNumber } from "../../../../common/enums/orderTypes.enum";
import PriceValuesShow from "../PriceValuesShow";

export interface PricesProps {
  price: number,
  quantity: string,
  orderDetails: any,
  tonnage: number
}

const Prices: React.FC<PricesProps> = ({ price, quantity, orderDetails, tonnage }) => {
  return (
    <div className={container}>
      <OrderDetailTxt title="Forma Płatności" value={orderDetails.paymentType === 1 ? "Przelew" : "Gotówka"} />
      <PricesShow
          value={price ? price : 0}
          quantity={1}
          orderTypeId={orderDetails.orderTypeId}     // !=OrderTypeNumber.Tonnage
        />

      {orderDetails.orderTypeId != OrderTypeNumber.Tonnage ?
        <PriceValuesShow
          value={price ? price : 0}
          quantity={quantity ? Number(quantity) : 1}
          orderTypeId={orderDetails.orderTypeId}
        /> :
        <PriceValuesShow
          value={price ? price : 0}
          quantity={tonnage ? Number(tonnage) : 1}
          orderTypeId={OrderTypeNumber.Container}     // !=OrderTypeNumber.Tonnage
        />

      }
    </div>
  );
};

export default Prices;