import React from "react";
import { Formik } from "formik";
import NewFormField from "../../../common/layout/form/NewFormField";
import NewFormCheckbox from "../../../common/layout/form/NewFormCheckbox";
import ActivityIndicator from "../../../common/layout/ActivityIndicator";
import MessageContainer from "../../../auth/components/register/forms/MessageContainer";
import { DefaultButton, IButtonStyles, mergeStyles, PrimaryButton } from "office-ui-fabric-react";

const buttonS: Partial<IButtonStyles> = {
  root: {
    marginRight: "20px",
  },
};

const btns = mergeStyles({
  position: "absolute",
  bottom: 30,
  left: 40,
});

export interface OperatorFormProps {
  initValues: any;
  handleSubmit: any;
  cancelForm: any;
  loading: boolean;
  selectedOperator: any;
}

const OperatorForm: React.FC<OperatorFormProps> = ({
  initValues,
  handleSubmit,
  cancelForm,
  loading,
}) => {
  return (
    <Formik initialValues={initValues} onSubmit={handleSubmit}>
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <div>
            <NewFormField name="name" label="Nazwa" />
          </div>
          <NewFormCheckbox name="isActive" label="Aktywny" />
          {/* <FormBtns
            submitBtnText={!Object.keys(initValues).length ? "Dodaj" : "Zapisz"}
            cancelForm={cancelForm}
            disabled={loading}
          /> */}
          <div className={btns}>
            <PrimaryButton text={!Object.keys(initValues).length ? "Dodaj" : "Zapisz"} type="submit" styles={buttonS} />
            <DefaultButton text={"Anuluj"} type="reset" onClick={cancelForm} />
          </div>
          {loading ? <ActivityIndicator /> : <MessageContainer />}
        </form>
      )}
    </Formik>
  );
};

export default OperatorForm;