import { INavLinkGroup } from "office-ui-fabric-react";
import { NavigationPath } from "../../../config/routing/NavigationPath";

export const navLinkGroups = (linkNames: any): INavLinkGroup[] => [
  {
    links: [
      {
        name: linkNames.orders,
        url: `${NavigationPath.CLIENT_ORDERS}`,
        key: `${NavigationPath.CLIENT_ORDERS}`,
      },
      {
        name: linkNames.receptionPlaces,
          url: `${NavigationPath.CLIENT_RECEPTION_PLACES}`,
          key: `${NavigationPath.CLIENT_RECEPTION_PLACES}`,
      },
      {
        name: linkNames.users,
        url: `${NavigationPath.CLIENT_USERS}`,
        key: `${NavigationPath.CLIENT_USERS}`,
      },
    ],
  },
];