import { AlertsActions } from "./alertsActions.enum";
import { IAlertsAction } from "./alertsActions.types";

const initState = {
  errorMessage: "",
  successMessage: "",
  dialogError: "",
  listMessage: "",
  listErr: "",
};

export const alertsReducer = (state = initState, action: IAlertsAction) => {
  const { type, payload } = action;
  switch (type) {
    case AlertsActions.SET_ERROR_ALERT:
      return { ...state, errorMessage: payload };
    case AlertsActions.SET_SUCCES_ALERT:
      return { ...state, successMessage: payload };
    case AlertsActions.SET_LIST_MESSAGE:
      return { ...state, listMessage: payload };
    case AlertsActions.SET_DIALOG_ERROR:
      return { ...state, dialogError: payload };
    case AlertsActions.CLEAR_LIST_MESSAGES:
      return { ...state, listMessage: "", listErr: "",};
    case AlertsActions.SET_LIST_ERR:
      return { ...state, listErr: payload };
    case AlertsActions.CLEAR_ALERTS:
      return {
        ...state,
        errorMessage: "",
        successMessage: "",
        dialogError: "",
      };
    default:
      return state;
  }
};
