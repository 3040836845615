import * as React from "react";
import {
    CommandBar,
} from "office-ui-fabric-react";
import { comandBarS } from "./../../../features/commandBar/styles/commandBar.styles";

const StoreCommandBar: React.FC = () => {
    // const { items } = useReportsCommandBar()
    return <CommandBar items={[]} styles={comandBarS} />;
}

export default StoreCommandBar;