import {
  IButtonStyles,
  mergeStyles,
} from "office-ui-fabric-react";

export const buttonS: Partial<IButtonStyles> = {
  root: {
    marginRight: "20px",
  },
};

export const container = mergeStyles({
  marginTop: "30px",
  display: "flex",
});
