import * as React from "react";
import {
  CommandBar,
  ICommandBarStyles,
  getTheme,
  Stack,
  IStackStyles,
  mergeStyles,
} from "office-ui-fabric-react";
import { useValidatorsCommandBar } from "../../../../client/hooks/validators/useValidatorsCommandBar";

const theme = getTheme();

const containerS: IStackStyles = {
  root: {
    position: "fixed",
    top: 50,
    left: 230,
    width: "calc(100% - 230px)",
    zIndex: 10,
    backgroundColor: "white",
    borderBottom: `2px solid ${theme.palette.neutralLighter}`,
  },
};

const btnsContainer = mergeStyles({
  display: "flex",
  alignContent: "center"
})

const commandBarS: ICommandBarStyles = {
  root: {
    paddingLeft: 0,
    width: 658,
  },
};

const ValidatorsCommandBar: React.FC = () => {
  const { items } = useValidatorsCommandBar();
  return (
    <Stack
      styles={containerS}
      horizontal
      horizontalAlign="space-between"
      verticalAlign="center"
    >
      <div className={btnsContainer}>
        <CommandBar items={items} styles={commandBarS} />
      </div>
    </Stack>
  );
};

export default ValidatorsCommandBar;