import React from 'react';
import {
    CommandBar,
    ICommandBarStyles,
  } from "office-ui-fabric-react";

import { useOrderPanelCommandBar } from '../../../hooks/orders/useOrderPanelCommandBar';

const commandBarS: ICommandBarStyles = {
    root: {
      width: 500,
    },
  };

const OrderPanelCommandBar: React.FC = () => {
    const {items} = useOrderPanelCommandBar()

    return ( 
        <CommandBar items={items} styles={commandBarS}/>
     );
}
 
export default OrderPanelCommandBar;
