import { NavigationPath } from "./../../../config/routing/NavigationPath";
import { ContainersValidatorsColumns } from "../components/columns/containers-validators-columns";
import { receptionPlacesValidatorsColumns } from "../components/columns/reception-places-validators-columns";
import { ordersValidatorsColumns } from "../components/columns/orders-validators-columns";

export const generateColumns = (pathname: string) => {
  if (pathname === NavigationPath.CONTAINERS_VALIDATORS) {
    return ContainersValidatorsColumns;
  }
  if (pathname === NavigationPath.RECEPTION_PLACES_VALIDATORS) {
    return receptionPlacesValidatorsColumns;
  }
  if (pathname === NavigationPath.ORDERS_VALIDATORS) {
    return ordersValidatorsColumns;
  }
};