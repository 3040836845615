import * as React from "react";
import { IReceptionPlacePoint } from "../interfaces/IReceptionPlacePoint";
import { wrapperStyles } from "../styles/style";

export interface ReceptionPlacesPopupHtmlProps {
    receptionPlacePoint: IReceptionPlacePoint;
}

export const ReceptionPlacesPopupHtml: React.FC<ReceptionPlacesPopupHtmlProps> = ({ receptionPlacePoint }) => {
    return (
        <div style={wrapperStyles.popupStyles}>
            <b>{receptionPlacePoint.companyName}</b>< br />
            NIP: {receptionPlacePoint.taxNumber} <br />
            BDO: {receptionPlacePoint.bdoNumber} <br />
            {receptionPlacePoint.street} <br />
            {receptionPlacePoint.city} {receptionPlacePoint.postalCode}< br />
            Odległości: < br />
            {receptionPlacePoint.distances.map(distance =>
                <>
                    {distance.name}: {distance.distance} km < br />
                </>
            )
            }
        </div>
    )
}