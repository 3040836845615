
import { IContainersActions } from "./containersActions.types";
import { ContainersActions } from "./containersActions.enum";

const initState = {
  reloadContainersList: false,
  selectedContainers: [],
  containerPanelOpen: false,
  containersModalOpen: false,
  containerModalMap: false,
  containterModalFotos: false,
  isEdit: false,
  isShow: false,
  isRfidHistory: false
};

export const containersReducer = (state = initState, action: IContainersActions) => {
  const { type, payload } = action;
  switch (type) {
    case ContainersActions.RELOAD_DATA:
      return { ...state, reloadContainersList: payload.reload };
    case ContainersActions.SET_CONTAINERS:
      return { ...state, selectedContainers: payload };
    case ContainersActions.TOGGLE_PANEL:
      return { ...state, containerPanelOpen: payload.isOpen};
    case ContainersActions.TOGGLE_MODAL:
      return { ...state, containersModalOpen: payload.isOpen, containerModalMap: payload.isMap, containterModalFotos: payload.isFotos };
    case ContainersActions.SET_FORM_TYPE:
      return { ...state, isEdit: payload.isEdit, isShow: payload.isShow, isRfidHistory: payload.isRfidHistory };
    case ContainersActions.CLEAR:
      return { ...initState }
    default:
      return state;
  }
};