import { toggleDeactivationReason } from './../../../../config/redux/clients/clientsActions';
import { setListMessage } from './../../../../config/redux/alerts/alertsActions';
import { toggleClientsPanel, toggleEditing } from './../../../../config/redux/clients/panel/clientPanelActions';
import { getOrderTypes } from './../../../../connectors/orderTypes/connectors/index';
import { useQuery } from 'react-query';
import { setErrorAlert } from '../../../../config/redux/alerts/alertsActions';
import { useEffect } from "react";
import { useSelector, RootStateOrAny, useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import { checkQuantity } from "../../actions/checkQuantity";
import { NavigationPath } from "../../../../config/routing/NavigationPath";
import { generateUnverifiedcommands } from "../../actions/generateCommands/clients/generateUnverifiedCommands";
import { generateActivecommands } from "../../actions/generateCommands/clients/generateActiveCommands";
import { generateInActivecommands } from "../../actions/generateCommands/clients/generateInactiveCommands";
import { ClientsAlerts } from "../../../clients/models/clientsAlerts.enum"
import {
  reloadData,
  clearListData,
  toggleAddComapnyPanel,
} from "../../../../config/redux/clients/clientsActions";
import { clearFilters } from "../../../../config/redux/filters/filterActions";
import {
  activateCompany,
  verifyCompany,
  deleteCompany,
  getActiveCompaniesReport,
  getInactiveCompaniesReport
} from "../../../../connectors/clients/connectors";

const message = "Operacja przebiegła pomyślnie"

export const useClientsCommandBar = () => {
  const { selectedClients } = useSelector(
    (state: RootStateOrAny) => state.clients
  );
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const quantity = checkQuantity(selectedClients.length);

  const addCompany = () => {
    dispatch(toggleAddComapnyPanel({ isOpen: true }));
  };

  const removeCompany = async () => {
    try {
      let companies = await Promise.all(
        selectedClients.map(async (id: string) => {
          deleteCompany(id);
        })
      ) 
      dispatch(reloadData({ reload: true }));
      dispatch(setListMessage(message))
    } catch (err) {}
  };



  const activate = async () => {
    try{
      activateCompany(selectedClients);
      dispatch(reloadData({ reload: true }));
      dispatch(setListMessage(message))
    }catch(err){

    }
  };

  const deactivate = async () => {
    dispatch(toggleDeactivationReason(true))
  };

  const edit = () => {
    dispatch(toggleClientsPanel(true))
    dispatch(toggleEditing({editing: true}))
  }

  const see = () => {
    dispatch(toggleClientsPanel(true))
  }

  const downloadActiveClientsReport = () => {
    getActiveCompaniesReport();
  }

  const downloadInactiveClientsReport = () => {
    getInactiveCompaniesReport();
  }

  const verify = async (e: any) => {
    try{
      const data = {
        companiesIds: selectedClients,
        orderTypeId: e.currentTarget.id,
      };
      await verifyCompany(data);
      dispatch(reloadData({ reload: true }));
      dispatch(setListMessage(message))
    }catch(err){
      dispatch(setErrorAlert(ClientsAlerts.CHOSE_ORDER_TYPE))
    }  
  };

  useEffect(() => {
    const unlisten = history.listen(() => {
      dispatch(clearListData());
      dispatch(clearFilters());
    });

    return () => {
      unlisten();
    };
  }, []);

  const {data} = useQuery("orderTypesToVerify", getOrderTypes)

  const _generateItems = () => {
    const { pathname } = location;
    switch (pathname) {
      case NavigationPath.UNVERIFIED_CLIENTS:
        const actions = {
          addCompany,
          verify,
          removeCompany,
          edit,
          see 
        };
        return generateUnverifiedcommands(quantity, actions, data ? data.data.data : []);
      // case NavigationPath.NEW_CLIENTS:
      //   return generateNewcommands(quantity, {
      //     addCompany,
      //     deactivate,
      //     removeCompany,
      //     edit
      //   });
      case NavigationPath.ACTIVE_CLIENTS:
        return generateActivecommands(quantity, {
          addCompany,
          deactivate,
          removeCompany,
          edit,
          see,
          downloadActiveClientsReport,
          //uploadFiles
        });

      case NavigationPath.INACTIVE_CLIENTS:
        return generateInActivecommands(quantity, {
          addCompany,
          removeCompany,
          activate,
          edit,
          see,
          downloadInactiveClientsReport
        });
      default:
        return [];
    }
  };

  const items = _generateItems();

  return {
    items,
  };
};