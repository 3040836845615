import * as React from "react";
import { IQuantity, Quantity } from "../../../../common/models/quantity.model";
import optimaIcon from './favicon-optima.png';
import { setListErr, setListMessage } from "../../../../../config/redux/alerts/alertsActions";
import { syncOptima } from "../../../../../connectors/orders/connectors";

interface ICompletedActions {
    openPanelToCorrect: void,
    openPanelSyncOrder: void,
    openPanel: void
}

export const generateCompleted = (quantity: IQuantity, actions: ICompletedActions, selectedOrder?: any, dispatch?: any) => {
    const { REACT_APP_OPTIMA_ENABLED } = process.env;
    
    switch (quantity) {
        case Quantity.NONE:
            return [];
        case Quantity.ONE:
            if (!selectedOrder.Manually) {
                let arr: any[] = [
                    {
                        key: "see",
                        text: "Zobacz",
                        iconProps: { iconName: "textDocument" },
                        onClick: actions.openPanel,
                    },
                    {
                        key: "correct",
                        text: "Popraw",
                        iconProps: { iconName: "SingleColumnEdit" },
                        onClick: actions.openPanelToCorrect,
                    }
                ];
                if(REACT_APP_OPTIMA_ENABLED === "1" && selectedOrder.optimaNumber == null){
                    arr.push(
                        {
                            key: "sync",
                            text: "Synchronizuj",
                            onRenderIcon: () => {
                                return <img src={optimaIcon}  alt="optima" style={{maxHeight: '16px'}}/>;
                            },
                            onClick: async () => {
                               const response : any = await syncOptima (selectedOrder.id)
                               if(dispatch && typeof dispatch === 'function') {
                                   if (response.data.success === false) {
                                    dispatch(setListErr("Wystąpił błąd synchronizacji"))
                                   } else if (response.data.success === true) {
                                    dispatch(setListMessage("Synchronizacja przebiegła pomyślnie"))
                                    selectedOrder.optimaNumber = response.data.data
                                   }
                               }
                              },
                            });
                }
                if(REACT_APP_OPTIMA_ENABLED === "1" && selectedOrder.optimaNumber == null){
                    arr.push(
                        {
                            key: "seee",
                            text: "Ręczna synchronizacja z Optimą",
                            onRenderIcon: () => {
                                return <img src={optimaIcon}  alt="optima" style={{maxHeight: '16px'}}/>;
                            },
                            onClick: actions.openPanelSyncOrder,
                        })
                }

                return arr;
            }
            return [
                {
                    key: "see",
                    text: "Zobacz",
                    iconProps: { iconName: "textDocument" },
                    onClick: actions.openPanel,
                },
                {
                    key: "correct",
                    text: "Popraw",
                    iconProps: { iconName: "SingleColumnEdit" },
                    onClick: actions.openPanelToCorrect,
                }
            ];
        case Quantity.MANY:
            return [];
    }
};