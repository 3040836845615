import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { setShowContainerPoints, setShowReceptionPlacePoints, setShowInternalStorePoints, toggleStoreMapPanel, isEditContainerForm, toggleStoreMapSettingsPanel, setShowContainerCriticalBatteryPoints, setShowContainerLowBatteryPoints } from "../../../config/redux/storeMap/StoreMapActions";

export const useStoreMapCommandBar = () => {

    const dispatch = useDispatch();
    const {
        showReceptionPlacePoints,
        showContainerPoints,
        showContainerCriticalBatteryPoints,
        showContainerLowBatteryPoints,
        showInternalStorePoints,
        selectedContainer
    } = useSelector((state: RootStateOrAny) => state.storeMap);

    const editContainer = () => {
        dispatch(toggleStoreMapPanel({ isOpen: true }));
        dispatch(isEditContainerForm(true));
    }

    const showContainer = () => {
        dispatch(toggleStoreMapPanel({ isOpen: true }));
        dispatch(isEditContainerForm(false));
    }

    const showSettingsPanel = () => {
        dispatch(toggleStoreMapSettingsPanel({ isOpen: true }));
    }

    const generateItems = () => {
        const receptionPlaceButton =
            [{
                key: "unhideReceptionPlacePoints",
                text: showReceptionPlacePoints == false ? "Pokaż punkty odbioru" : "Ukryj punkty odbioru",
                iconProps: { iconName: "reportdocument" },
                name: "prepare",
                onClick: () => { dispatch(setShowReceptionPlacePoints(!showReceptionPlacePoints)) },
            }]

        const containerSubButtons =
            [{
                key: "containerRestBatteryButton",
                text: showContainerPoints == false ? "Pokaż kontenery z optymalnym stanem baterii" : "Ukryj kontenery z optymalnym stanem baterii",
                iconProps: { iconName: "Accept" },
                name: "containerRestBatteryButton",
                onClick: () => { dispatch(setShowContainerPoints(!showContainerPoints)) }
            },
            {
                key: "containerLowBatteryButton",
                text: showContainerLowBatteryPoints == false ? "Pokaż kontenery z niskim stanem baterii" : "Ukryj kontenery z niskim stanem baterii",
                iconProps: { iconName: "Warning" },
                name: "containerLowBatteryButton",
                onClick: () => { dispatch(setShowContainerLowBatteryPoints(!showContainerLowBatteryPoints)) }
            },
            {
                key: "containerCriticalBatteryButton",
                text: showContainerCriticalBatteryPoints == false ? "Pokaż kontenery z krytycznym stanem baterii" : "Ukryj kontenery z krytycznym stanem baterii",
                iconProps: { iconName: "Error" },
                name: "containerCriticalBatteryButton",
                onClick: () => { dispatch(setShowContainerCriticalBatteryPoints(!showContainerCriticalBatteryPoints)) }
            }]

        const containerButton =
            [{
                key: "unhideContainerPoints",
                text: "Kontenery",
                iconProps: { iconName: "reportdocument" },
                name: "prepare",
                subMenuProps: {
                    items: [
                        ...containerSubButtons,
                    ],
                }
            }]

        const internalStoreButton =
            [{
                key: "unhideInternalStorePoints",
                text: showInternalStorePoints == false ? "Pokaż magazyny wewnętrzne" : "Ukryj magazyny wewnętrzne",
                iconProps: { iconName: "reportdocument" },
                name: "prepare",
                onClick: () => { dispatch(setShowInternalStorePoints(!showInternalStorePoints)) }
            }];

        const editContainerButton = selectedContainer != undefined ?
            [{
                key: "showContainer",
                text: "Pokaż kontener",
                iconProps: { iconName: "reportdocument" },
                name: "show",
                onClick: showContainer,
            },
            {
                key: "editContainer",
                text: "Edytuj kontener",
                iconProps: { iconName: "edit" },
                name: "edit",
                onClick: editContainer,
            }] : []

        const storeMapSettings = [{
            key: "storeMapSettings",
            text: "Ustawienia",
            iconProps: { iconName: "Settings" },
            name: "storeMapSettings",
            onClick: showSettingsPanel,
        }]

        return [
            ...containerButton,
            ...receptionPlaceButton,
            ...internalStoreButton,
            ...storeMapSettings,
            ...editContainerButton,
        ]
    };

    const items = generateItems()

    return {
        items
    }
};