import { IQuantity, Quantity } from "../../../../common/models/quantity.model";

export const generateAccepted = (quantity: IQuantity, actions: any) => {
  const realizationBtn = {
    key: "check out",
    text: "Realizuj",
    iconProps: { iconName: "BusSolid" },
    onClick: actions.pushToRealization,
  };

  switch (quantity) {
    case Quantity.NONE:
      return []
    case Quantity.ONE:
      return [
        {
          key: "add",
          text: "Zobacz",
          iconProps: { iconName: "textDocument" },
          onClick: actions.openPanelToSee,
        },
        {
          key: "edit",
          text: "Edytuj",
          iconProps: { iconName: "edit" },
          onClick: actions.openPanel,
        },

        realizationBtn,
        {
          key: "delete",
          text: "Anuluj zamówienie",
          iconProps: { iconName: "delete" },
          name: "delete",
          onClick: actions.setOrderCanceled,
        },
      ];
    case Quantity.MANY:
      return [realizationBtn, {
        key: "delete",
        text: "Anuluj zamówienie",
        iconProps: { iconName: "delete" },
        name: "delete",
        onClick: actions.setOrderCanceled,
      },];
  }
};
