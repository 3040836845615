import React, { useState } from "react";
import { useSelector, RootStateOrAny, useDispatch } from "react-redux";
import { Panel, IPanelStyles } from "office-ui-fabric-react";
import OrderTabs from "./OrderTabs";
import { RenderNavigation } from "./CustomNavigation";
import {
  addOrderComment,
  setActiveOrder,
  setSelectedOrders,
} from "../../../config/redux/orders/ordersActions";
import { OrderTabsNames } from "../models/OrderTabs.enum";
import NewOrderForm from "./forms/NewOrderForm"
import { clearAlerts } from "../../../config/redux/alerts/alertsActions";
import { useOrderData } from "../hooks/useOrderData";
import { createNewOrder, setActiveOrderTab, setOrderEditing, toggleOrderPanel } from "../../../config/redux/orders/ordersPanel/orderPanelActions";
import { useCompanyData } from "../hooks/useCompanyData";
import { useNewOrderFrom } from "../hooks/useNewOrderForm";
import CorrectOrderForm from "./forms/correctOrder/CorrectOrderForm";

const panelS: Partial<IPanelStyles> = {
  content: {
    padding: 20,
  },
  main: {
    height: "100%",
  },
};

const OrderPanel: React.FC = () => {
  const dispatch = useDispatch();
  // const {  selectedOrders } = useSelector(
  //   (state: RootStateOrAny) => state.orders
  // );
  const { addNewOrder, orderCorrecting } = useSelector(
    (state: RootStateOrAny) => state.orderPanel
  );

  const { orderPanelOpen } = useOrderData()

  const dissmisPanel = (ev: any) => {
    dispatch(setActiveOrder({}));
    dispatch(setSelectedOrders({ selectedOrders: [] }));
    dispatch(setActiveOrderTab(OrderTabsNames.DETAILS));
    dispatch(clearAlerts())
    dispatch(addOrderComment(false));
    dispatch(toggleOrderPanel(false));
    dispatch(setOrderEditing(false));
    dispatch(createNewOrder(false))
    setCompany({ key: "" });
    setReception(undefined);
    setDepartment({ key: "" });
    setOrderTypeItemId(undefined);
    setClear(true);
    localStorage.removeItem("receptionPlace");
    localStorage.removeItem("orderTypeItemId");
  };

  const { cancelForm, handleSubmit, isLoading } = useNewOrderFrom();
  const [company, setCompany] = useState<any>({ key: "" });
  const [department, setDepartment] = useState<any>({ key: "" });
  const [reception, setReception] = useState<any>();
  const [orderTypeItemId, setOrderTypeItemId] = useState<any>();

  const {
    departments,
    receptionPlaces,
    orderType,
    orderTypeItems,
    setClear
  } = useCompanyData(company, department, reception);

  return (
    <Panel
      isOpen={orderPanelOpen}
      closeButtonAriaLabel="Close"
      type={3}
      onDismiss={dissmisPanel}
      onOuterClick={() => { }}
      isLightDismiss={false}
      isHiddenOnDismiss={false}
      onRenderNavigation={RenderNavigation}
      styles={panelS}
      isFooterAtBottom={true}
    //onRenderFooterContent={addNewOrder ? onRenderFooterContent : () => {return <></>}}
    >
      <>
        {(addNewOrder && orderCorrecting == false) ?
          <NewOrderForm
            departments={departments}
            receptionPlaces={receptionPlaces}
            orderType={orderType}
            orderTypeItems={orderTypeItems}
            company={company}
            department={department}
            setCompany={setCompany}
            setDepartment={setDepartment}
            cancelForm={cancelForm}
            handleSubmit={handleSubmit}
            isLoading={isLoading}
            setReception={setReception}
            reception={reception}
            orderTypeItemId={orderTypeItemId}
            setOrderTypeItemId={setOrderTypeItemId}
          />
          : (addNewOrder == false && orderCorrecting == true) ?
            <CorrectOrderForm />
          : <OrderTabs />}
      </>
    </Panel>
  );
};

export default OrderPanel;