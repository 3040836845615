import * as React from 'react';
import { Depths } from "@uifabric/fluent-theme";
import {
    Stack,
    IStackTokens,
    Text,
    ITextStyles,
    FontIcon,
    mergeStyles,
    getTheme,
    mergeStyleSets,
} from 'office-ui-fabric-react';
import { FontSizes } from '@uifabric/fluent-theme';
import { NavLink } from 'react-router-dom';
import { NavigationPath } from '../config/routing/NavigationPath';
import StatisticTiles from '../features/statistics/components/StatisticTiles';
import DashboardLineCharts from '../features/statistics/components/DashboardLineCharts';

export class Home extends React.Component<{}, {}> {
    public render(): JSX.Element {
        const descriptionTextStyles: ITextStyles = {
            root: {
                color: '#323130',
                fontSize: FontSizes.size12,
                textAlign: 'center'
            },
        };
        const theme = getTheme();
        const iconClass = mergeStyles({
            fontSize: 32,
            margin: '20px auto 14px auto',
        });
        const space = mergeStyles({
            marginLeft: 44
        });
        const link = mergeStyles({
            textDecoration: 'none'
        });
        const classNames = mergeStyleSets({
            iconS: [{ color: theme.palette.themeDark }, iconClass],
        });
        const tileS = mergeStyles({
            cursor: 'pointer',
            backgroundColor: '#fff',
            width: '200px',
            height: '100px',
            border: "1px solid rgba(204,204,204,.8)",
            boxShadow: Depths.depth4,
            selectors: {
                ":hover": {
                    border: "1px solid rgba(204,204,204,.8)",
                    boxShadow: Depths.depth8,
                },
            },
        });        
        const sectionStackTokens: IStackTokens = { childrenGap: 40 };

        return (
            <Stack className={space} tokens={sectionStackTokens}>
                <Stack horizontal tokens={sectionStackTokens}>
                    <NavLink to={NavigationPath.UNVERIFIED_CLIENTS} className={link}>
                        <Stack className={tileS}>
                            <FontIcon iconName="AddGroup" className={classNames.iconS} />
                            <Text variant="small" styles={descriptionTextStyles}>
                                Klienci bez weryfikacji
                            </Text>
                        </Stack>
                    </NavLink>
                    <NavLink to={NavigationPath.NEW_ORDERS} className={link}>
                        <Stack className={tileS}>
                            <FontIcon iconName="FabricNewFolder" className={classNames.iconS} />
                            <Text variant="small" styles={descriptionTextStyles}>
                                Nowe zamówienia
                            </Text>
                        </Stack>
                    </NavLink>
                    <NavLink to={NavigationPath.ALL_ORDERS} className={link}>
                        <Stack className={tileS}>
                            <FontIcon iconName="DateTime" className={classNames.iconS} />
                            <Text variant="small" styles={descriptionTextStyles}>
                                Zamówienia po terminie
                            </Text>
                        </Stack>
                    </NavLink>
                    <NavLink to={NavigationPath.UNACTIVE_USERS} className={link}>
                        <Stack className={tileS}>
                            <FontIcon iconName="ReminderPerson" className={classNames.iconS} />
                            <Text variant="small" styles={descriptionTextStyles}>
                                Użytkownicy do weryfikacji
                            </Text>
                        </Stack>
                    </NavLink>
                    <NavLink to={NavigationPath.UNACTIVE_PICKUP_POINTS} className={link}>
                        <Stack className={tileS}>
                            <FontIcon iconName="HomeVerify" className={classNames.iconS} />
                            <Text variant="small" styles={descriptionTextStyles}>
                                Punkty odbioru do weryfikacji
                            </Text>
                        </Stack>
                    </NavLink>
                </Stack>

                <StatisticTiles />

                <DashboardLineCharts />
            </Stack>
        );
    }
}
export default Home