import { IStackTokens, ITextProps, mergeStyles, Stack, Text } from '@fluentui/react';
import * as React from 'react';
import { getCompaniesStatistics, getOrdersStatistics, getReceptionPlacesStatistics } from '../../../connectors/dashboard/connectors';
import StatisticTile from './StatisticTile';

interface IStatisticTilesState {
    companiesStatistics: any;
    receptionPlacesStatistics: any;
    ordersStatistics: any;
    dayBeforeTodayDate: Date;
}

class StatisticTiles extends React.Component<{}, IStatisticTilesState> {
    constructor(props: any = {}) {
        super(props);
        
        this.state = {
            companiesStatistics: {},
            receptionPlacesStatistics: {},
            ordersStatistics: {
                local: {} as any,
                general: {} as any,
            },
            dayBeforeTodayDate: new Date(),
        };
    }

    async componentDidMount() {
        this.state.dayBeforeTodayDate.setDate(this.state.dayBeforeTodayDate.getDate() - 1);
        const companiesStatistics = await getCompaniesStatistics(this.state.dayBeforeTodayDate.toLocaleString('en-US'));
        const receptionPlacesStatistics = await getReceptionPlacesStatistics(this.state.dayBeforeTodayDate.toLocaleString('en-US'));
        const ordersStatistics = await getOrdersStatistics(this.state.dayBeforeTodayDate.toLocaleString('en-US'), []);
        const ordersStatisticsLocal = await getOrdersStatistics(this.state.dayBeforeTodayDate.toLocaleString('en-US'), ['0','1','2','3']);

        this.setState((prevState: IStatisticTilesState) => {
            return {
                ...prevState,
                companiesStatistics: companiesStatistics.data,
                receptionPlacesStatistics: receptionPlacesStatistics.data,
                ordersStatistics: { general: ordersStatistics.data, local: ordersStatisticsLocal.data }
            };
        });
    }

    public render(): JSX.Element {
        return <div>{this._basicExample()}</div>;
    }

    private _basicExample(): JSX.Element {
        const { companiesStatistics, ordersStatistics, receptionPlacesStatistics } = this.state;
        const stackWithTopBorder = mergeStyles({
            marginRight: 44,
            paddingTop: 25,
            borderTop: '1px solid rgba(128,128,128, 0.25)',
        });
        const sectionStackTokens: IStackTokens = { childrenGap: 40 };

        return (
            <>
                <Stack className={stackWithTopBorder} tokens={sectionStackTokens}>
                    <Text variant={'large' as ITextProps['variant']}>Statystyki z dnia <strong>{this.state.dayBeforeTodayDate.toLocaleDateString('pl-PL')}</strong> dla regionu <strong>Chełma</strong>:</Text>

                    <Stack horizontal tokens={sectionStackTokens} style={{marginTop: 20}}>
                        <StatisticTile icon="NewFolder" value={ordersStatistics.local.newOrdersAmount}
                            backgroundColor="#ff6297" valueLabel="Nowych zamówień:" />

                        <StatisticTile icon="NumberField" value={ordersStatistics.local.activeOrdersAmount}
                            backgroundColor="#00bcd4" valueLabel="Łączna ilość zamówień:" />

                        <StatisticTile icon="Inbox" value={ordersStatistics.local.containerActiveOrdersAmount}
                            backgroundColor="#8bc34a" valueLabel="Ilość zamówień na kontenery:" />

                        <StatisticTile icon="Weights" value={ordersStatistics.local.tonnageActiveOrdersAmount}
                            backgroundColor="#ff9800" valueLabel="Ilość zamówień na tonaż:" />

                        <StatisticTile icon="Car" value={ordersStatistics.local.vehicleActiveOrdersAmount}
                            backgroundColor="#a871ff" valueLabel="Ilość zamówień na pojazdy:" />
                    </Stack>
                </Stack>

                <Stack className={stackWithTopBorder} tokens={sectionStackTokens} style={{marginTop: 40}}>
                    <Text variant={'large' as ITextProps['variant']}>Statystyki zamówień z dnia <strong>{this.state.dayBeforeTodayDate.toLocaleDateString('pl-PL')}</strong>:</Text>

                    <Stack horizontal tokens={sectionStackTokens} style={{marginTop: 20}}>
                        <StatisticTile icon="NewFolder" value={ordersStatistics.general.newOrdersAmount}
                            backgroundColor="#ff6297" valueLabel="Nowych zamówień:" />

                        <StatisticTile icon="NumberField" value={ordersStatistics.general.activeOrdersAmount}
                            backgroundColor="#00bcd4" valueLabel="Łączna ilość zamówień:" />

                        <StatisticTile icon="Inbox" value={ordersStatistics.general.containerActiveOrdersAmount}
                            backgroundColor="#8bc34a" valueLabel="Ilość zamówień na kontenery:" />

                        <StatisticTile icon="Weights" value={ordersStatistics.general.tonnageActiveOrdersAmount}
                            backgroundColor="#ff9800" valueLabel="Ilość zamówień na tonaż:" />

                        <StatisticTile icon="Car" value={ordersStatistics.general.vehicleActiveOrdersAmount}
                            backgroundColor="#a871ff" valueLabel="Ilość zamówień na pojazdy:" />
                    </Stack>
                </Stack>
                
                <Stack className={stackWithTopBorder} tokens={sectionStackTokens} style={{marginTop: 40}}>
                    <Text variant={'large' as ITextProps['variant']}>Statystyki klientów z dnia <strong>{this.state.dayBeforeTodayDate.toLocaleDateString('pl-PL')}</strong>:</Text>

                    <Stack horizontal tokens={sectionStackTokens} style={{marginTop: 20}}>
                        <StatisticTile icon="PeopleAdd" value={companiesStatistics.newClients}
                            backgroundColor="#ff6297" valueLabel="Nowych klientów:" />

                        <StatisticTile icon="People" value={companiesStatistics.totalClientsAmount}
                            backgroundColor="#00bcd4" valueLabel="Łączna ilość klientów:" />
                    </Stack>
                </Stack>

                <Stack className={stackWithTopBorder} tokens={sectionStackTokens} style={{marginTop: 40}}>
                    <Text variant={'large' as ITextProps['variant']}>Statystyki punktów odbioru z dnia <strong>{this.state.dayBeforeTodayDate.toLocaleDateString('pl-PL')}</strong>:</Text>

                    <Stack horizontal tokens={sectionStackTokens} style={{marginTop: 20}}>
                        <StatisticTile icon="NewFolder" value={receptionPlacesStatistics.newReceptionPlacesAmount}
                            backgroundColor="#ff6297" valueLabel="Nowych punktów:" />                      

                        <StatisticTile icon="Inbox" value={receptionPlacesStatistics.newContainerReceptionPlacesAmount}
                            backgroundColor="#8bc34a" valueLabel="Nowych punktów na kontenery:" />

                        <StatisticTile icon="Weights" value={receptionPlacesStatistics.newTonnageReceptionPlacesAmount}
                            backgroundColor="#ff9800" valueLabel="Nowych punktów na tonaż:" />

                        <StatisticTile icon="Car" value={receptionPlacesStatistics.newVehicleReceptionPlacesAmount}
                            backgroundColor="#a871ff" valueLabel="Nowych punktów na pojazdy:" />
                    </Stack>
                    <Stack horizontal tokens={sectionStackTokens} style={{marginTop: 20}}>
                        <StatisticTile icon="NewFolder" value={receptionPlacesStatistics.totalReceptionPlacesAmount}
                            backgroundColor="#ff6297" valueLabel="Łączna ilość punktów:" />                      

                        <StatisticTile icon="Inbox" value={receptionPlacesStatistics.totalContainerReceptionPlacesAmount}
                            backgroundColor="#8bc34a" valueLabel="Ilość punktów na kontenery:" />

                        <StatisticTile icon="Weights" value={receptionPlacesStatistics.totalTonnageReceptionPlacesAmount}
                            backgroundColor="#ff9800" valueLabel="Ilość punktów na tonaż:" />

                        <StatisticTile icon="Car" value={receptionPlacesStatistics.totalVehicleReceptionPlacesAmount}
                            backgroundColor="#a871ff" valueLabel="Ilość punktów na pojazdy:" />
                    </Stack>
                </Stack>

            </>
        );
    }
}

export default StatisticTiles;