export enum OrderTypeString {
    Vehicle = "Pojazd",
    Container = "Kontener",
    Weight = "Tonaż",
  }

export enum OrderTypeNumber {
  Vehicle = 1, 
  Tonnage = 2,
  Container = 3
}