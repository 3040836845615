import api from "../../../config/axios/configAxios";
import queryString from "query-string";

export const getStoreItems = async (params: any) =>
    api.get(`/api/Admin/Stores/stock-levels?${queryString.stringify(params)}`);

export const getInternalStoreItems = async () =>
    api.get(`/api/Admin/Stores/internal`);

export const getInternalStoreBorderPoints = async (internalStoreId: string) =>
    api.get(`/api/Admin/Stores/internal/${internalStoreId}/borderPoints`)

export const getDocuments = async (params: any) =>
    api.get(`/api/Admin/Documents?${queryString.stringify(params)}`);

export const approveDocument = async (documentId: number) =>
    api.put(`/api/Admin/Documents/approve`, { id: documentId });

export const cancelDocument = async (documentId: number) =>
    api.put(`/api/Admin/Documents/cancel`, { id: documentId });

export const getReceptionPlaceCoordaintes = async () =>
    api.get(`/api/Admin/reception-places/coordinates`);

export const getContainerCoordinates = async () =>
    api.get(`/IoT/containers`);

export const getReceptionPlaceLocalizations = async (receptionPlaceId: number) =>
    api.get(`/api/Admin/reception-places/${receptionPlaceId}/localizations`);