import React from "react";
import { Stack } from "office-ui-fabric-react/lib/Stack";
import {
  Selection,
  DetailsListLayoutMode,
  SelectionMode,
} from "office-ui-fabric-react/lib/DetailsList";
import { ShimmeredDetailsList } from 'office-ui-fabric-react/lib/ShimmeredDetailsList';
import { pricesColumns } from "../../data/columns";
import {listS} from "../../../common/styles/list.styles"
import { QueryStatus } from "react-query";

export interface PricesListProps {
  handleInvoke: any;
  selection: Selection;
  items: Array<any>;
  status: QueryStatus
}

const PricesList: React.FC<PricesListProps> = ({ handleInvoke, items, selection, status }) => {
  return (
    <Stack>
      <ShimmeredDetailsList
        items={items}
        columns={pricesColumns}
        styles={listS}
        setKey="items"
        selection={selection}
        layoutMode={DetailsListLayoutMode.justified}
        selectionPreservedOnEmptyClick={true}
        selectionMode={SelectionMode.single}
        onItemInvoked={handleInvoke}
        usePageCache={true}
        enableShimmer={status==='loading'}
      />
    </Stack>
  );
};

export default PricesList;
