import React from "react";

import ClientsLayout from "../components/ClientsLayout"

const ActiveClients: React.SFC = () => {
  return (
    <ClientsLayout/>
  );
};

export default ActiveClients;
