import { Actions } from "./register-actions.enum";
import { CompanyData, RegisterAction } from "./register-actions.types";

interface RegisterState {
  complete: boolean;
  error: string;
  company: {
    data: CompanyData | {};
    success: boolean;
  };
  pricingOpen: boolean;
  regulationsOpen: boolean;
}

const initState: RegisterState = {
  complete: false,
  error: "",
  company: {
    data: {},
    success: false,
  },
  pricingOpen: false,
  regulationsOpen: false,
};

export const register = (state = initState, action: RegisterAction) => {
  const { type, payload } = action;
  switch (type) {
    case Actions.SET_COMPANY_DATA:
      return { ...state, company: payload };
    case Actions.SET_REGISTRATION_COMPLETE:
      return { ...state, complete: true };
    case Actions.CLEAR_COMPANY_DATA:
      return { complete: false, company: { data: {}, success: false } };
    case Actions.SET_REGISTER_ERR:
      return { ...state, error: payload };
    case Actions.TOGGLE_REGULATIONS:
      return { ...state, regulationsOpen: payload };
    case Actions.TOGGLE_PRICING:
      return { ...state, pricingOpen: payload };
    case Actions.CLEAR_ERR:
      return { ...state, error: "" };
    default:
      return state;
  }
};
