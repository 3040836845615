import React, { useState } from "react";
import { Formik, Form, FormikValues, FormikHelpers } from "formik";
import { Stack, StackItem } from "office-ui-fabric-react/lib/Stack";
import FormBtns from "../../../../common/layout/form/NewFormBtns";
import ActivityIndicator from "../../../../common/layout/ActivityIndicator";
import ErrorMessage from "../../../../common/layout/form/ErrorMessage";
import { useCancelForm } from "../../../hooks/useCancelForm";
import MultiDepartmentRadio from "./MultiDepartmentRadio";
import { containerS, radioContainer } from "../../../styles/basicInfoForm.styles";
import { useBasicInfoForm } from "../../../hooks/useBasicInfoForm";
import DisplayText from "../displayData/DisplayText";
import FormHeader from "../FormHeader";
import { validationSchemaCompanyBasicInfo, validationSchemaNonPolishCompanyBasicInfo } from "../../../../auth/actions/validationSchema";
import NewFormField from "../../../../common/layout/form/NewFormField";
import FormDropDown from "../../../../common/layout/form/FormDropDown";
import { IResetForm } from "../../../../common/models/resetForm.type";
import EditableListFormClient from "./EditableListFormClient";
import { LangEnum } from "../../../../common/enums/lang.enum";

const BasicInfoForm: React.FC = () => {
    const cancel = useCancelForm();
    const [specialPrices, setSpecialPrices] = useState<any[]>([])
    const {
        initValues,
        handleSubmit,
        isLoading,
        readOnlyData,
    } = useBasicInfoForm();

    const handleEdit = async (values: FormikValues, formikHelpers: IResetForm) => {
        const { resetForm } = formikHelpers;
        const serializedSpecialPrices = JSON.stringify({
            specialPrices: specialPrices.map((price: any) => ({
                id: price.id,
                value: price.value,
                dateFrom: price.dateFrom,
                dateTo: price.dateTo,
                orderTypeItemId: price.orderTypeItemId
            }))
        });
        await handleSubmit(values, serializedSpecialPrices, { resetForm });
    };

    return (
        <Formik initialValues={initValues}
            onSubmit={handleEdit}
            enableReinitialize={true}
            validationSchema={readOnlyData.country == LangEnum.Polish ? validationSchemaCompanyBasicInfo : validationSchemaNonPolishCompanyBasicInfo}>
            {({ handleSubmit }) => (
                <>
                    <Form translate="yes" onSubmit={handleSubmit}>
                        <Stack styles={containerS} verticalAlign="space-between" verticalFill>
                            <FormHeader text="Edytuj informacje podstawowe" />
                            <DisplayText name="Nazwa firmy" value={readOnlyData.name} />
                            <DisplayText name="NIP" value={readOnlyData.taxNumber} />
                            <DisplayText name="Kraj" value={readOnlyData.countryStr} />
                            <NewFormField name="shortName" label="Nazwa skrócona" required={false} />
                            <NewFormField name="bdoNumber" label="BDO" required={false} />
                            <NewFormField name="city" label="Miejscowość" />
                            <NewFormField name="street" label="Ulica i numer" />
                            <NewFormField name="postalCode" label="Kod pocztowy" />
                            <FormDropDown
                                name={"paymentType"}
                                headerText={"Rodzaj płatności"}
                                options={[{ key: 1, text: "Gotówka" },
                                { key: 2, text: "Przelew" }]}
                            />
                            <StackItem>
                                <div className={radioContainer}>
                                    <MultiDepartmentRadio
                                        name="multiDepartment"
                                        label="Firma wielooddziałowa"
                                        options={[
                                            { key: "yes", text: "Tak" },
                                            { key: "no", text: "Nie" },
                                        ]}
                                    />
                                </div>
                            </StackItem>
                        </Stack>
                        <Stack styles={containerS} verticalAlign="space-between" verticalFill>
                            <FormHeader text={"Dodawanie ceny"} />
                            <EditableListFormClient
                                setSpecialPrices={setSpecialPrices}
                                specialPrices={specialPrices}
                                companyId={initValues ? initValues.id : null}
                            />
                        </Stack>
                        <div>
                            <FormBtns
                                cancelForm={cancel}
                                submitBtnText={"Zapisz"}
                                disabled={isLoading}
                            />
                        </div>
                        <div>{isLoading ? <ActivityIndicator /> : <ErrorMessage />}</div>
                    </Form>
                </>
            )}
        </Formik>
    );
};

export default BasicInfoForm;