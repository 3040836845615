import React from "react";
import { Text } from "office-ui-fabric-react/lib/Text";

import {textContainerS, subTitleS} from "../../../styles/detailTxt.style"
import {titleS} from "../../../styles/title"

export interface OrderDetailProps {
  title: string;
  value: string;
}

const OrderDetail: React.FC<OrderDetailProps> = ({ title, value }) => {
  return (
    <div className={textContainerS}>
      <Text styles={titleS}>{title}</Text>
      <Text styles={subTitleS}>{value}</Text>
    </div>
  );
};

export default OrderDetail;