import React from "react";
import { mergeStyles } from "office-ui-fabric-react";
import { Text, ITextStyles } from "office-ui-fabric-react";
import { FontSizes } from "@uifabric/fluent-theme";
import { FontWeights } from "office-ui-fabric-react";
import {
  titleS,
  subTitleS,
  textContainerS,
} from "../../styles/detailTxt.style";
import { useTranslation } from "react-i18next";
import { OrderTypeNumber } from "../../../common/enums/orderTypes.enum";
import { IOrdersPriceShowLang } from "../../../../localization/abstract/client/orders";

const wrapper = mergeStyles({
  display: "flex",
  justifyContent: "space-between",
});

const mainWrapper = mergeStyles({
  paddingTop: 20,
  paddingBottom: 15
});

const divider = mergeStyles({
  width: 250,
});

export interface PricesProps {
  value: number;
  quantity: number;
  orderTypeId: OrderTypeNumber;
}

const headerS: ITextStyles = {
  root: {
    display: "block",
    marginBottom: 15,
    fontSize: FontSizes.size16,
    fontWeight: FontWeights.semibold,
  },
};

const PriceValuesShow: React.FC<PricesProps> = ({ value, quantity, orderTypeId }) => {
  const { t } = useTranslation("orders");
  const priceShowNames: IOrdersPriceShowLang = t("priceShow", {
    returnObjects: true,
  });

  return (
    <div className={mainWrapper} >
        <Text styles={headerS}>Szacowana wartość zamówienia</Text>
      <div className={wrapper}>
        <div className={divider}>
          <div className={textContainerS}>
            <Text styles={titleS}>Wartość netto</Text>
            <Text styles={subTitleS}>
              {orderTypeId == OrderTypeNumber.Tonnage ? value.toFixed(2).toString() : (value * quantity).toFixed(2).toString()}
            </Text>
          </div>
        </div>
        <div className={divider}>
          <div className={textContainerS}>
            <Text styles={titleS}>Wartość brutto</Text>
            <Text styles={subTitleS}>
              {orderTypeId == OrderTypeNumber.Tonnage ? (value * 1.08).toFixed(2).toString() : (value * quantity * 1.08).toFixed(2).toString()}
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PriceValuesShow;