import React from "react";
import { RootStateOrAny, useSelector, useDispatch } from "react-redux";
import { Panel } from "office-ui-fabric-react";
import { panelS } from "../../common/styles/panelStyles";
import { toggleNotConfirmedUserPanel } from "../../../config/redux/notConfirmedUsers/notConfirmedUsersActions";
import NotConfirmedUserForm from "../forms/NotConfirmedUserForm";


const NotConfirmedUserPanel: React.FC = () => {
  const dispatch = useDispatch();
  const { notConfirmedUserPanelOpen } = useSelector(
    (state: RootStateOrAny) => state.notConfirmedUsers
  );

  const dismissPanel = () => {
    dispatch(toggleNotConfirmedUserPanel({ open: false }));
  };

  return (
    <Panel
      isOpen={notConfirmedUserPanelOpen}
      closeButtonAriaLabel="Close"
      headerText="Użytkownik"
      onDismiss={dismissPanel}
      type={3}
      styles={panelS}
      isHiddenOnDismiss={false}
    >
      <NotConfirmedUserForm />
    </Panel>
  );
};

export default NotConfirmedUserPanel;