import { IUser, IUsersPanel, IReload, IAdd } from "./unactivepickuppointsActions.types";
import {UnactivePickupPointsActions} from './unactivepickuppointsActions.enum'

export const setUnactiveUser = (data: any) => {
    return{
        type: UnactivePickupPointsActions.SET_USER,
        payload: data
    }
}

export const setSelectedUnactiveUsers = (data: any) => {
  return {
    type: UnactivePickupPointsActions.SET_SELECTED_USERS,
    payload: data
  }
}

export const toggleUsersPanel = (data: IUsersPanel) => {
  return {
    type: UnactivePickupPointsActions.TOGGLE_USERS_PANEL,
    payload: data
  }
}

export const reloadUsers = (data: IReload) => {
  return {
    type: UnactivePickupPointsActions.RELOAD_USERS,
    payload: data
  }
}

export const clearUsers = () => {
  return {
    type: UnactivePickupPointsActions.CLEAR,
  }
}

export const addNewUser = (data: IAdd) => {
  return {
    type: UnactivePickupPointsActions.ADD_USER,
    payload: data
  }
}

export const setActivatedUsers = (data: any) => {
  return {
    type: UnactivePickupPointsActions.SET_ACTIVATED_USERS,
    payload: data
  }
}