import React, { FormEvent, useCallback, useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import {
  IDropdownOption,
  IDropdownStyles,
} from "office-ui-fabric-react/lib/Dropdown";
import { setVehicle } from "../../../../config/redux/orders/ordersActions";
import {
  getMatchedVehicles,
} from "../../../../connectors/vehicles/connectors/index";
import { useEffectAfterMount } from "../../../common/hooks/useEffectAfterMount";
import {
  ComboBox,
  IComboBox,
  IComboBoxOption,
} from "office-ui-fabric-react/lib/ComboBox";
import _ from "lodash";
import { Spinner, SpinnerSize } from "@fluentui/react";

const dropdownStyles: Partial<IDropdownStyles> = {
  root: {
    marginRight: 30,
    width: 300
  },
  dropdown: { width: 300 },
};

const VehicleDropDown: React.FC = () => {
  const dispatch = useDispatch();
  const { reloadOrders, selectedOrders } = useSelector(
    (state: RootStateOrAny) => state.orders
  );
  const [options, setOptions] = useState<Array<IDropdownOption>>([]);
  const [inputValue, setInputValue] = useState<string>("");
  const [asyncActionInProgress, setAsyncActionInProgress] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<IDropdownOption>();
  const debouncedSetVehicles = useCallback(_.debounce((inputValue: string) => {
    setVehicles(inputValue);
  }, 350),
    [selectedOrders]
  );

  useEffect(() => {
    setVehicles(undefined);

    if (!options.length && selectedOrders.length) {
      setSelectedItem(undefined);
    }
  }, [selectedOrders]);

  useEffectAfterMount(() => {
    if (reloadOrders) {
      setSelectedItem(undefined);
    }

    debouncedSetVehicles(inputValue);

    return () => {
      setSelectedItem(undefined);
    };
  }, [reloadOrders, inputValue]);

  const setVehicles = async (currentInputValue?: string) => {
    setAsyncActionInProgress(true);

    let orderQuery = currentInputValue != undefined ? `?DriverNameOrRegistrationNumber=${currentInputValue}` : '';

    let orderQuery2 = "";
    if (selectedOrders != undefined) {
      selectedOrders.map((x: any) => {
        orderQuery2 += `&OrdersId=${x.id}`
      })

      if (orderQuery == '' && selectedOrders.length > 0) {
        orderQuery2 = orderQuery2.substring(1);
        orderQuery = "?" + orderQuery2
      }
      else {
        orderQuery = orderQuery + orderQuery2;
      }
    }

    const allVehicles = await getMatchedVehicles(orderQuery);
    const filtredVehicles: Array<any> = [];
    allVehicles.data.data.map((item: any) => {
      filtredVehicles.push({
        key: item.id,
        text: `${item.lastName} ${item.firstName} ${item.registerNumber}`,
      })
    })

    setOptions(filtredVehicles);
    setSelectedItem(undefined);
    dispatch(setVehicle({ vehicle: '' }));
    setAsyncActionInProgress(false);
  };

  const handleInputChange = useCallback(
    (option?: IComboBoxOption, index?: number, value?: string): void => {
      if (value !== undefined) {
        setInputValue(value);
      }
    },
    []
  );

  const onChange = (
    event: FormEvent<IComboBox>,
    option?: IComboBoxOption,
    index?: number,
    value?: string
  ): void => {
    setSelectedItem(option);
    option && dispatch(setVehicle({ vehicle: option.key.toString() }));
  };

  return <>
    {asyncActionInProgress &&
      <Spinner size={SpinnerSize.small} style={{ marginRight: 15, display: 'inline-block' }} />
    }
    <ComboBox
      selectedKey={selectedItem ? selectedItem.key : ""}
      onChange={onChange}
      allowFreeform
      placeholder="Wybierz pojazd"
      onPendingValueChanged={handleInputChange}
      options={options}
      styles={dropdownStyles}
      autoComplete={"off"}
    />
  </>;
};

export default VehicleDropDown;