import React from "react";
import { Stack } from "office-ui-fabric-react"
import { Formik, Form } from "formik";
import { useRegisterUser } from "../../../../hooks/useRegisterUser";
import UserFields from "./UserFields";
import FormBtns from "../../../../../common/layout/form/NewFormBtns";
import {
    validationSchemaUserShort,
    validationSchemaUser,
} from "../../../../actions/validationSchema";
import DepartmentsDropDown from "../department/DepartmentsDD";
import { containerS } from "../../../../styles/registerCompany.styles";
import MessageContainer from "../MessageContainer";
import ActivityIndicator from "../../../../../common/layout/ActivityIndicator";
import { useUserData } from "../../../../../common/hooks/useUserData";

const RegisterUser: React.FC = () => {
    const { cancelForm, handleSubmit, multiDepartment, isLoading } = useRegisterUser();
    const { user } = useUserData();
    const requireEmail = true;
    return (
        <Formik
            initialValues={{
                email: requireEmail ? '' : user && user.emails[0],
            }}
            onSubmit={handleSubmit}
            validationSchema={
                multiDepartment ? validationSchemaUser : validationSchemaUserShort
            }
            validateOnChange={false}
            validateOnBlur={false}
        >
            {({ handleSubmit }) => (
                <Stack verticalFill styles={containerS}>
                    <Form translate="yes" onSubmit={handleSubmit}>
                        {multiDepartment && <DepartmentsDropDown />}
                        <UserFields />
                        <FormBtns positionAbsolute={true} cancelForm={cancelForm} submitBtnText="Zarejestruj" />
                        {isLoading ? <ActivityIndicator /> : <MessageContainer />}
                    </Form>
                </Stack>
            )}
        </Formik>
    );
};

export default RegisterUser;