import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toggleOrdersReportPanel } from '../../../config/redux/client/clientActions';
import { FormikValues } from 'formik';
import { IResetForm } from '../../../features/common/models/resetForm.type';
import { getOrdersReport } from '../../../connectors/orders/connectors';
import { setErrorAlert, setListMessage } from '../../../config/redux/alerts/alertsActions';
import { useDownloadFile } from '../../../features/common/hooks/useDownloadFile';
import { useTranslation } from 'react-i18next';

export const useOrdersReportForm = () => {
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()
  const { t } = useTranslation("common");
  const preDownloading = () => {
    setIsLoading(true);
  };
  const postDownloading = () => {
    setIsLoading(false);
    dispatch(toggleOrdersReportPanel({ isOpen: false }));
    var response = t("operationSuceeded");
    dispatch(setListMessage(response));
  };

  const onErrorDownloadFile = () => {
    setIsLoading(false);
    var response = t("operationFailed");
    dispatch(setErrorAlert(response));
  };
  
  const getFileName = () => {
    return t("reportFileName")+".xlsx";
  };

  const { ref, url, download, name } = useDownloadFile({
    getOrdersReport,
    preDownloading,
    postDownloading,
    onError: onErrorDownloadFile,
    getFileName,
  });


  const handleSubmit = async (values: FormikValues,
    { resetForm }: IResetForm) => {
    const data = {
      createdFrom: values.createdFrom,
      createdTo: values.createdTo,
      currentStatus: values.currentStatus,
      orderBy: values.orderBy,
      desc: (values.desc === 'true'),
    }
    download(data)
  }

  const cancelForm = () => {
    dispatch(toggleOrdersReportPanel({ isOpen: false }))
  }

  return {
    handleSubmit,
    cancelForm,
    isLoading,
  }
}