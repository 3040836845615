import {
    IStackStyles,
    IDetailsListStyles,
    mergeStyles,
  } from "office-ui-fabric-react";

export const footer = mergeStyles({
    width: "100%",
    height: 50,
    backgroundColor: "transparent",
  });
  
export  const listContainerS: IStackStyles = {
    root: {
      height: "90%",
      marginRight: "auto",
      marginBottom: "50px",
      marginLeft: "auto",
      width: "95%",
    },
  };
  
export  const listS: Partial<IDetailsListStyles> = {
    contentWrapper: {
      selectors: {
        "div[data-automationid=DetailsRowCell]": {
          cursor: "pointer",
        },
        "div[data-automationid=DetailsRowCheck]": {
          cursor: "pointer",
        },
      },
    },
    root: {
      position: "relative",
      zIndex: 123
    }
  };