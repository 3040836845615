import React from "react";
import { Image, IImageProps, ImageFit } from "office-ui-fabric-react/lib/Image";

const Map: React.FC = () => {
  const imageProps: IImageProps = {
    src: "/map.jpg",
    imageFit: ImageFit.contain,
    height: "300px",
    width: "300px",
  };

  return (
      <Image {...imageProps} />
  );
};

export default Map;
