import { ITextFieldStyles } from "office-ui-fabric-react";
import { FontSizes } from "@uifabric/fluent-theme";
import { FontWeights } from "office-ui-fabric-react";

export const textfieldS: Partial<ITextFieldStyles> = {
  root: {
    marginBottom: "16px",
    width: "100%",
  },

  subComponentStyles: {
    label: {
      fontSize: FontSizes.size14,
      fontWeight: FontWeights.semibold,
    },
  },
};
