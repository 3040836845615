import * as React from "react";
import ActivityIndicator from "../../common/layout/ActivityIndicator";
import { useStoreMap } from "../hooks/useStoreMap";
import StoreMapPanel from "../panel/StoreMapPanel";
import StoreMapMain from "./StoreMapMain";
import ListSuccessMessage from "../../common/layout/ListSuccessMessage";
import StoreMapSettingsPanel from "../panel/StoreMapSettingsPanel";

export const StoreMap: React.FC = () => {

    const {
        receptionPlacePoints,
        receptionPlacePointLocalizations,
        containerPoints,
        containerPointsLoading,
        receptionPlacePointsLoading,
        internalStorePoints,
        internalStoreBorderPoints,
        internalStorePointsPointsLoading,
        mapType,
        setMapType
    } = useStoreMap();

    return <>
        {
            (containerPointsLoading == false && receptionPlacePointsLoading == false && internalStorePointsPointsLoading == false) ?
                <StoreMapMain
                    receptionPlacePoints={receptionPlacePoints}
                    receptionPlacePointLocalizations={receptionPlacePointLocalizations}
                    containerPoints={containerPoints}
                    internalStorePoints={internalStorePoints}
                    mapType={mapType}
                    internalStoreBorderPoints={internalStoreBorderPoints}
                />
                :
                <ActivityIndicator text="Ładowanie mapy" />
        }
        <ListSuccessMessage />
        <StoreMapPanel />
        <StoreMapSettingsPanel mapType={mapType} setMapType={setMapType} />
    </>
}