export const parseOrders = (orders: Array<any>) => {
  return orders.map((order: any) => {
    const data = {
      id: order.id,
      number: order.number,
      optimaNumber : order.optimaNumber,
      invoiceNumber : order.invoiceNumber,
      optimaStatus: order.optimaStatus,
      optimaStatusName: order.optimaStatusName,
      createdAt: order.createdAt.split("T").splice(0, 1).join(""),
      finishedAt: order.finishedAt ? order.finishedAt.split("T").splice(0, 1).join("") : null,
      receivingDate: order.receivingDate ? order.receivingDate.split("T").splice(0, 1).join("") : null,
      acceptedDate: order.acceptedDate
        ? order.acceptedDate.split("T").splice(0, 1).join("")
        : "",
      company: order.company,
      receptionPlace: order.receptionPlace,
      type: order.type,
      status: order.currentStatus,
      departmentId: order.departmentId,
      orderTypeItem: order.orderTypeItemName ? order.orderTypeItemName : "",
      quantity: order.quantity.toString(),
      vehicle: order.vehicle ? order.vehicle : "Brak",
      operator: order.operator,
      contactPhone: order.contactPhone ? order.contactPhone : "",
      notificationSent: order.notificationSent ,
      route: order.route,
      sequenceInRoute: order.sequenceInRoute,
      receptionCount: order.receptionCount + "/" + order.quantity
    };

    return data;
  });
};
