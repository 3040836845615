import React, { useEffect, useState } from "react";
import {
  Stack,
  PrimaryButton,
  IButtonStyles,
  mergeStyles,
} from "office-ui-fabric-react";
import { Formik, Form } from "formik";
import { validationSchemaNipNotRequired } from "../../../actions/validationSchema";
import { useVerification } from "../../../hooks/useVerification";
import ActivityIndicator from "../../../../common/layout/ActivityIndicator";
import NewFormField from "../../../../common/layout/form/NewFormField";
import MessageContainer from "./MessageContainer";
import { LangEnum } from "../../../../common/enums/lang.enum";
import FormDropDown from "../../../../common/layout/form/FormDropDown";

const formS = {
  height: "100%",
};

const buttonS: Partial<IButtonStyles> = {
  root: {
    width: "200px",
    position: "absolute",
    bottom: 30,
    left: 40,
  },
};

const padding = mergeStyles({
  paddingTop: 15,
});

export interface IInitValues {
  nip: string;
  country: number;
}

const initValues = {
  nip: "",
  country: LangEnum.Polish
};

const NipForm: React.FC = () => {
  const { handleVerification, loading } = useVerification();

  const [lang, setLang] = useState<LangEnum>(LangEnum.Polish)

  return (
    <>
      <Formik
        initialValues={initValues}
        onSubmit={handleVerification}
        validateOnBlur = {true}
        validateOnChange = {true}
        validationSchema={lang == LangEnum.Polish ? validationSchemaNipNotRequired : undefined}
      >
        {({ handleSubmit }) => (
          <Form translate="yes" onSubmit={handleSubmit} style={formS}>
            <Stack verticalFill verticalAlign="start" horizontalAlign="start">
              <NewFormField
                name="nip"
                label="NIP"
                placeholder="podaj nip bez kresek"
                required={false}
              />
              <FormDropDown
                name={"country"}
                headerText={"Kraj"}
                options={[
                  { key: LangEnum.Polish, text: "Polska" },
                  // { key: 2, text: "Anglia" },
                  { key: LangEnum.German, text: "Niemcy" },
                  { key: LangEnum.Slovak, text: "Słowacja" },
                  { key: LangEnum.Estonian, text: "Estonia" },
                  { key: LangEnum.Czech, text: "Czechy" }
                ]}
                onChange={setLang}
              />
              <PrimaryButton
                text="Dalej"
                styles={buttonS}
                type="submit"
              />

              <div className={padding}>
                {loading ? <ActivityIndicator /> : <MessageContainer />}
              </div>
            </Stack>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default NipForm;