import { IValidatorParams, IValidatorsTypesParams } from "../models";
import api from "../../../config/axios/configAxios";

export const getValidators = async (params?: IValidatorParams) =>
  api.get(`/api/Admin/Validators`, { params });

export const runValidators = async (validatorTypes?: any) =>
  api.post(`/api/Admin/Validators/run`, { validatorTypes });

export const approveValidator = async (validatorId: number, comment: string) =>
  api.post(`/api/Admin/Validators/${validatorId}/approve`, { comment });

  export const getValidatorTypes = async () => api.get(`/api/Admin/Validators/types`);