export const columns = [
    {
      key: "brand",
      name: "Firma",
      fieldName: "brand",
      minWidth: 150,
      maxWidth: 200,
      isResizable: true,
    },
    {
      key: "model",
      name: "Model",
      fieldName: "model",
      minWidth: 100,
      maxWidth: 100,
    },
    {
      key: "registerNumber",
      name: "Numer rejestracyjny",
      fieldName: "registerNumber",
      minWidth: 200,
      maxWidth: 250,
    },
  ];