import React, { } from 'react';
import { Stack } from 'office-ui-fabric-react';
import { Form, Formik } from 'formik';
import NewFormField from '../../common/layout/form/NewFormField';
import NewFormBtns from '../../common/layout/form/NewFormBtns';
import FormDropDown from '../../common/layout/form/FormDropDown';
import OrderDetailTxt from '../../orders/components/forms/edditOrder/OrderDetailTxt';
import ActivityIndicator from '../../common/layout/ActivityIndicator';
import MessageContainer from '../../auth/components/register/forms/MessageContainer';

interface IContainerEditForm {
    initValues: any;
    handleSubmit: any;
    cancelForm: any;
    loading: boolean,
    gpsDeviceStatuses: any
}

const ContainerEditForm: React.FC<IContainerEditForm> = ({ initValues, handleSubmit, cancelForm, loading, gpsDeviceStatuses }) => {

    return (
        <Formik
            initialValues={initValues}
            onSubmit={handleSubmit}
            enableReinitialize={true}
        >
            {({ handleSubmit }) => (
                <Form translate="yes" onSubmit={handleSubmit} >
                    <Stack>
                        <NewFormField name="containerNumber" label="Numer kontenera" required={false} />
                        {/* <NewFormField name="identifier" label="Kod QR / Identyfikator kontenera" /> */}
                        <NewFormField name="description" label="Opis" multiline={true} required={false} />
                        {/* <NewFormField name="uhfIdentifier" label="Kod UHF bramki" /> */}
                        <FormDropDown
                            name="status"
                            options={gpsDeviceStatuses}
                            headerText="Status kontenera"
                        />
                        <OrderDetailTxt
                            title="Numer ICCID"
                            value={initValues.iccid}
                        />
                        <OrderDetailTxt
                            title="Numer IMEI"
                            value={initValues.imei}
                        />
                        <OrderDetailTxt
                            title="Numer Seryjny"
                            value={initValues.serNo}
                        />
                        <OrderDetailTxt
                            title="Ostatnia aktywność"
                            value={initValues.lastActiveDateStr}
                        />
                        <NewFormBtns
                            cancelForm={cancelForm}
                            submitBtnText={"Zapisz"}
                            positionAbsolute={true}
                        />
                    </Stack>
                    {loading ? <ActivityIndicator /> : <MessageContainer />}
                </Form>
            )}
        </Formik>
    )
};

export default ContainerEditForm;