import * as React from "react";
import { connect, RootStateOrAny } from "react-redux";
import { withRouter } from "react-router-dom";
import { getAllReceptionPlaces } from "../../../connectors/clients/connectors/index";
import { filter, sort, toggleFiltration } from "../../../config/redux/filters/filterActions"
import { IPaginationProps, IPaginationState } from "./pagination.types";
import { generateItems } from "../actions/generateReceptionPlaces"
import { AxiosResponse } from 'axios';
import { reloadReceptionPlaces } from "../../../config/redux/receptionPlaces/receptionPlacesActions";

class Pagination extends React.Component<IPaginationProps, IPaginationState> {
  private _data: any = [];

  footerRef = React.createRef<HTMLDivElement>();

  state = {
    data: [],
    limit: 35,
    total: 0,
    page: 1,
    loading: false,
  };

  async componentDidMount() {

    await this.props.dispatch(sort({ column: undefined }));
    await this.props.dispatch(toggleFiltration({ filter: false }))
    await this.props.dispatch(filter({ searchPhrase: undefined }));

    if (this.props.footerRef.current !== null) {
      this._observer.observe(this.props.footerRef.current);
    }

    this._setReceptionPlaces();
  }

  componentDidUpdate(prevProps: IPaginationProps) {
    const { reload, searchPhrase, filter, postalCode, column, dispatch } = this.props

    if (reload) {
      this._reload();
    }

    if (filter == true) {
      this._reload();
      if (column) {
        this._filterReceptionPlaces(filter == true ? searchPhrase : "", filter==true ? postalCode: "", column.key, column.isSortedDescending)
      }
      else {
        this._filterReceptionPlaces(filter == true ? searchPhrase : "", filter==true ? postalCode: "")
      }
      dispatch(toggleFiltration({ filter: false }))
    }
  }

  componentWillUnmount() {
    if (this.props.footerRef.current !== null) {
      this._observer.unobserve(this.props.footerRef.current);
    }
  }

  private _handleObserver = (entry: any, observer: any) => {
    if (this.state.data.length < this.state.total) {
      this._setReceptionPlaces();
    }
  };

  private _observer = new IntersectionObserver(this._handleObserver, {
    root: null,
    rootMargin: "200px",
    threshold: 1,
  });

  private _reload = async () => {
    this.props.dispatch(reloadReceptionPlaces({ reload: false }));
  };

  private _filterReceptionPlaces = async (searchPhrase: string, postalCode: string, orderBy: undefined | string = undefined, desc: undefined | boolean = false) => {
    const { limit } = this.state;

    var res: AxiosResponse<any>;
    if (orderBy !== undefined) {
      res = await getAllReceptionPlaces({ page: 1, limit, OrderBy: orderBy, Desc: desc, allFields: searchPhrase, postalCode: postalCode });
    }
    else {
      res = await getAllReceptionPlaces({ page: 1, limit, allFields: searchPhrase, postalCode: postalCode });
    }
    const receptionPlaces = generateItems(res.data.data);
    this.setState((prevState: IPaginationState) => {
      return {
        data: [...receptionPlaces],
        page: 2,
        total: res.data.total,
      };
    });
  }

  private _setReceptionPlaces = async (newPage?: number) => {
    const { searchPhrase, postalCode, column } = this.props;
    const { limit, page } = this.state;
    const p = newPage ? newPage : page;
    let res: any;
    if (column != undefined) {
      res = await getAllReceptionPlaces({ page: p, limit, OrderBy: column.key, Desc: column.isSortedDescending, allFields: searchPhrase, postalCode: postalCode });
    } else {
      res = await getAllReceptionPlaces({ page: p, limit, allFields: searchPhrase, postalCode: postalCode});
    }
    const pickupPoints = generateItems(res.data.data);
    this.setState((prevState: IPaginationState) => {
      return {
        data: newPage ? [...pickupPoints] : [...prevState.data, ...pickupPoints],
        page: newPage ? newPage : prevState.page + 1,
        total: res.data.total,
      };
    });
  };

  public render() {
    const renderProps = {
      data: this.state.data,
      loading: this.state.loading
    };

    return this.props.render(renderProps);
  }
}

const mapStateToProps = (state: RootStateOrAny) => {
  return {
    reload: state.receptionPlaces.reload,
    searchPhrase: state.filtration.searchPhrase, 
    postalCode: state.receptionPlaces.postalCode,
    selectedReceptionPlaces: state.receptionPlaces.selectedReceptionPlaces,
    filter: state.filtration.filter,
    column: state.filtration.column
  };
};

export default connect(mapStateToProps)(withRouter(Pagination));