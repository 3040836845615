import React from "react";

import { useNewOperatorForm } from "../../hooks/useNewOperatorForm";
import OperatorForm from "./OperatorForm";

interface AddNewOperatorProps {
  cancelForm: any;
}

const AddNewOperator: React.FC<AddNewOperatorProps> = ({ cancelForm }) => {
  const { add, loading , selectedOperator} = useNewOperatorForm();

  return (
    <OperatorForm
      initValues={{}}
      handleSubmit={add}
      cancelForm={cancelForm}
      loading={loading}
      selectedOperator={selectedOperator}
    />
  );
};

export default AddNewOperator;
