import { IUsersAction } from "./usersActions.types";
import { UsersActions } from "./usersActions.enum";

const initState = {
  selectedUsers: [],
  activeUser: {},
  userPanelOpen: false,
  reloadUsersList: false,
  addUser: false,
  refetch: false,
};

export const usersReducer = (state = initState, action: IUsersAction) => {
  const { type, payload } = action;

  switch (type) {
    case UsersActions.SET_SELECTED_USERS:
      return { ...state, selectedUsers: payload };
    case UsersActions.TOGGLE_USERS_PANEL:
      return { ...state, userPanelOpen: payload.open };
    case UsersActions.RELOAD_USERS:
      return { ...state, reloadUsersList: payload.reload };
    case UsersActions.SET_USER:
      return { ...state, activeUser: payload };
    case UsersActions.ADD_USER:
      return { ...state, addUser: payload.add };
    case UsersActions.CLEAR:
      return { ...initState };
    case UsersActions.REFETCH:
      return { ...state, refetch: payload };
    default:
      return state;
  }
};
