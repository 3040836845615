import { canceledOrdersColumns } from './../components/columns/canceled-orders-columns';
import { completedOrdersColumns } from './../components/columns/completed-orders-columns';
import { newOrdersColumns } from './../components/columns/new-orders-columns';
import { NavigationPath } from './../../../config/routing/NavigationPath';
import { allOrdersColumns } from "../components/columns/all-orders-columns"
import { acceptedOrdersColumns } from '../components/columns/accepted-orders-columns';
import { inProgressOrdersColumns } from '../components/columns/inProgress-orders-columns';
import { invoicedOrdersColumns } from '../components/columns/invoiced-orders-columns';

export const generateColumns = (pathname: string) => {
  if (pathname === NavigationPath.ACCEPTED_ORDERS) {
    return acceptedOrdersColumns
  } 
  if (pathname === NavigationPath.NEW_ORDERS) {
    return newOrdersColumns
  } 
  if (pathname === NavigationPath.IN_PROGRESS_ORDERS) {
    return inProgressOrdersColumns
  } 
  if (pathname === NavigationPath.COMPLETED_ORDERS) {
    return completedOrdersColumns
  } 
  if (pathname === NavigationPath.INVOICED_ORDERS) {
    return invoicedOrdersColumns
  }
  if (pathname === NavigationPath.CANCELED_ORDERS) {
    return canceledOrdersColumns
  } 
  else {
    return allOrdersColumns
  }
};