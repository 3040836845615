import * as React from "react";
import { connect, RootStateOrAny } from "react-redux";
import {
  CheckboxVisibility,
  DetailsListLayoutMode,
  IColumn,
  Selection,
  SelectionMode,
} from "office-ui-fabric-react/lib/DetailsList";
import { MarqueeSelection } from "office-ui-fabric-react/lib/MarqueeSelection";
import { ShimmeredDetailsList, Stack } from "office-ui-fabric-react";
import { listContainerS, listS, footer } from "../../clients/components/clientsList.styles";
import { IRenderProps } from "../../orders/providers/Pagination.types";
import { sort, toggleFiltration } from "../../../config/redux/filters/filterActions";
import { IDispatchProps, IProps, IReceptionPlaceListState, IStateProps } from "../models/ReceptionPlacesList.types";
import { allReceptionPlacesColumns } from "../columns/allReceptionPlacesColumns";
import Pagination from "../providers/Pagination";
import { setReceptionPlaces, toggleReceptionPlacesPanel } from "../../../config/redux/receptionPlaces/receptionPlacesActions";

class ReceptionPlacesList extends React.Component<IProps, IReceptionPlaceListState> {
  private _selection: Selection;
  private _columns: IColumn[];
  private _generatedColumns: IColumn[];
  private _onItemInvoked: (item?: any, index?: number, e?: Event) => void;
  private _onColumnHeaderClick: (ev?: React.MouseEvent<HTMLElement>, column?: IColumn) => void;
  protected _footerRef: React.RefObject<HTMLDivElement>;
  constructor(props: IProps) {
    super(props);

    this._selection = new Selection({
      onSelectionChanged: () => {
        const items = this._getSelectedKeys();
        const ids = items.map((receptionPlace: any) => {
          return {
            id: receptionPlace.id,
            key: receptionPlace.id,
            receptionPlaceAddress: receptionPlace.receptionPlaceAddress,
            bdoNumber: receptionPlace.bdoNumber,
            orderType: receptionPlace.orderType,
            phone: receptionPlace.phone,
            companyName: receptionPlace.companyName,
            containersAmount: receptionPlace.containersAmount,
            deactivationReason: receptionPlace.deactivationReason,
            receptionPlacesOrderTypes: receptionPlace.receptionPlacesOrderTypes
          };
          
        });
        const data = [...ids] ;
        this.props.dispatch(setReceptionPlaces(data));
        //props.dispatch(toggleReceptionPlacesPanel({isOpen: true}));
      },
    });

    this._footerRef = React.createRef();

    this._generatedColumns = allReceptionPlacesColumns;

    this._onItemInvoked = (item: any) => {
      // this.props.dispatch(setActiveClient({ activeClient: item }));
      props.dispatch(toggleReceptionPlacesPanel({isOpen: true}));
    };

    this._columns = [...this._generatedColumns];

    this._onColumnHeaderClick = (item: any, column: any) => {
      if(column.key === "receptionPlacesOrderTypes") return;

      const newColumns: IColumn[] = this._columns.slice();

      const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
      newColumns.forEach((newCol: IColumn) => {
        if (newCol === currColumn) {
          currColumn.isSortedDescending = !currColumn.isSortedDescending;
          currColumn.isSorted = true;
        } else {
          newCol.isSorted = false;
          newCol.isSortedDescending = true;
        }
      });

      props.dispatch(toggleFiltration({ filter: true }));
      props.dispatch(sort({ column: currColumn }));

      this.setState({
        _cols: newColumns,
      });
    };

    this.state = {
      items: [],
      loading: false,
      _cols: this._columns
    };
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(setReceptionPlaces([]));
  }

  componentDidUpdate() {
    if (this.props.reload) {
      this._selection.selectToKey("", true);
    }

    if (!this.props.selectedReceptionPlaces[0]) {
      this._selection.selectToKey("", true);
    }
  }


  //!this.props.isPanelOpen
  public render(): JSX.Element {
    return (
        <>
          <Pagination
              footerRef={this._footerRef}
              render={({ data, loading }: IRenderProps) => (
                  <>
                    <Stack styles={listContainerS}>
                      <MarqueeSelection isEnabled={!this.props.isPanelOpen} selection={this._selection}>
                        <ShimmeredDetailsList
                            items={data}
                            columns={allReceptionPlacesColumns}
                            setKey="items"
                            layoutMode={DetailsListLayoutMode.justified}
                            selection={this._selection}
                            selectionPreservedOnEmptyClick={true}
                            onItemInvoked={this._onItemInvoked}
                            usePageCache={true}
                            styles={listS}
                            onColumnHeaderClick={this._onColumnHeaderClick}
                            enableShimmer={loading}
                            checkboxVisibility={CheckboxVisibility.onHover}
                            selectionMode={SelectionMode.single}                          
                        />
                      </MarqueeSelection>
                    </Stack>
                    <div className={footer} ref={this._footerRef} />
                  </>
              )}
          />
        </>
    );
  }

  private _getSelectedKeys = () => {
    const keys = this._selection.getSelection();
    return keys;
  };
}

const mapStateToProps = (state: RootStateOrAny) => state.receptionPlaces;

export default connect<IStateProps, IDispatchProps>(
    mapStateToProps
)(ReceptionPlacesList);