import { ValidatorsActions } from "./validatorsActions.enum";
import { IValidatorsAction } from "./validatorsActions.types";

const initState = {
  selectedValidators: [],
  activeValidators: {},
  reloadValidators: false,
};

export const validatorsReducer = (
  state = initState,
  action: IValidatorsAction
) => {
  const { type, payload } = action;
  switch (type) {
    case ValidatorsActions.SET_ACTIVE_VALIDATOR:
      return { ...state, activeValidator: payload };
    case ValidatorsActions.SET_VALIDATORS:
      return { ...state, selectedValidators: payload.selectedValidators };
    case ValidatorsActions.REMOVE_DATA:
      return { ...state, reload: payload.reload };
    case ValidatorsActions.ACCEPT_VALIDATOR:
      return { ...state, validatorAccepted: payload };
    case ValidatorsActions.CANCEL_VALIDATOR:
      return { ...state, validatorCanceled: payload };
    case ValidatorsActions.RELOAD:
      return { ...state, reloadValidator: payload };
    default:
      return state;
  }
};
