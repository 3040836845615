import React from "react";
import { useSelector, RootStateOrAny } from "react-redux";

import { containerS } from "../../../styles/containerStyles";
import Comment from "./Comment";

export interface OrderCommentsProps {}

const OrderComments: React.FC<OrderCommentsProps> = () => {
  const {orderComments} = useSelector((state: RootStateOrAny) => state.orderPanel)

  return (
    <div className={containerS}>
      {orderComments && orderComments.map(({ id, content, user, createdAt }: any) => (
        <Comment
          key={id}
          content={content}
          id={id}
          user={user}
          createdAt={createdAt}
        />
      ))}
    </div>
  );
};

export default OrderComments;
