import { OperatorMessages } from './../models/operatorsMessages.enum';
import { setSuccessAlert, setErrorAlert, clearAlerts } from './../../../config/redux/alerts/alertsActions';
import { FormikValues } from "formik";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { useMutation, useQueryCache } from "react-query";

import { IResetForm } from "./../../common/models/resetForm.type";
import { addOperator } from "./../../../connectors/operators/connectors/index";
import { toggleOperatorsPanel } from '../../../config/redux/operators/operatorsActions';
import { useState } from 'react';

export const useNewOperatorForm = () => {
  const [loading, setIsLoading] = useState(false)
  const cache = useQueryCache();
  const dispatch = useDispatch();
  const { selectedOperator } = useSelector(
    (state: RootStateOrAny) => state.operators
  );
  const [mutate] = useMutation(addOperator, {
    onSuccess: () => {
      cache.invalidateQueries("operators");
      dispatch(setSuccessAlert(OperatorMessages.ADDED))
      dispatch(toggleOperatorsPanel(false))
    },
    onError: (err) => {
      throw new Error();
    },
  });

  const add = async (values: FormikValues, { resetForm }: IResetForm) => {
    dispatch(clearAlerts())
    setIsLoading(true)
    const body = {
      name: values.name,
      isActive: values.isActive,
    };
    try {
      await mutate(body);
      setIsLoading(false)
    } catch (err) {
      dispatch(setErrorAlert("Operator o podanej nazwie istnieje"))
      setIsLoading(false)
    }
  };

  return {
    add,
    loading,
    selectedOperator
  };
};
