import React from "react";
import { mergeStyles } from "office-ui-fabric-react";
import { Text, ITextStyles } from "office-ui-fabric-react/lib/Text";
import { FontSizes } from "@uifabric/fluent-theme";
import { FontWeights } from "office-ui-fabric-react";
import { NeutralColors } from "@uifabric/fluent-theme";

const commentContainer = mergeStyles({
  padding: 10,
  borderBottom: `1px solid ${NeutralColors.gray40}`,
});

const userData = mergeStyles({
  marginBottom: 10,
});

const dateTxtS: ITextStyles = {
  root: {
    color: NeutralColors.gray70,
  },
};

const userTxtS: ITextStyles = {
  root: {
    fontSize: FontSizes.size16,
    fontWeight: FontWeights.bold,
    marginRight: 20,
  },
};

export interface CommentProps {
  id: string;
  content: string;
  user: any;
  createdAt: string;
}

const Comment: React.FC<CommentProps> = ({ content, id, user, createdAt }) => {
  const { firstName, lastName } = user;

  return (
    <div className={commentContainer}>
      <div className={userData}>
        <Text styles={userTxtS}>{`${firstName} ${lastName}:`}</Text>
        <Text styles={dateTxtS}>{`${createdAt
          .split("T")
          .splice(0, 1)
          .join("")}  
          ${createdAt
            .split("T")
            .splice(1, 1)
            .join("")
            .split(".")
            .splice(0, 1)
            .join("")}`}</Text>
      </div>
      <Text id={id}>{content}</Text>
    </div>
  );
};

export default Comment;
