import {
    IBreadcrumbStyles,
    FontWeights,
    getTheme,
  } from "office-ui-fabric-react";
  import { FontSizes } from "@uifabric/fluent-theme";

const theme = getTheme();

export const breadcrumbS: Partial<IBreadcrumbStyles> = {
  chevron: {
    color: theme.palette.white,
  },

  root: {
    margin: 0,
    width: '400px',
  },

  list: {
    selectors: {
      "li:last-child div": {
        color: theme.palette.white,
        fontWeight: FontWeights.regular,
      },
      "li:first-child div": {
        color: theme.palette.white,
        fontWeight: FontWeights.semibold,
      },
    },
  },

  item: {
    fontSize: FontSizes.size16,
    color: theme.palette.white,
    borderBottom: "1px solid transparent",
    transition: "0.2s ease",

    selectors: {
      "&:hover": {
        color: theme.palette.white,
      },
      "&:last-child": {
        color: theme.palette.white,
      },
      "&:active:hover": {
        color: theme.palette.white,
        backgroundColor: "transparent",
      },
    },
  },

  itemLink:{
    selectors: {
      "&:hover": {
        color: theme.palette.white,
        backgroundColor: 'rgb(0, 90, 158)',
      },

      "&:active:hover": {
        color: theme.palette.white,
        backgroundColor: 'rgb(0, 90, 158)',
      },
    }
  }
};