import { IUser, IUsersPanel, IReload, IAdd } from "./usersActions.types";
import {UsersActions} from './usersActions.enum'

export const setUser = (data: IUser) => {
    return{
        type: UsersActions.SET_USER,
        payload: data
    }
}

export const setSelectedUsers = (data: Array<IUser>) => {
  return {
    type: UsersActions.SET_SELECTED_USERS,
    payload: data
  }
}

export const toggleUsersPanel = (data: IUsersPanel) => {
  return {
    type: UsersActions.TOGGLE_USERS_PANEL,
    payload: data
  }
}

export const reloadUsers = (data: IReload) => {
  return {
    type: UsersActions.RELOAD_USERS,
    payload: data
  }
}

export const clearUsers = () => {
  return {
    type: UsersActions.CLEAR,
  }
}

export const addNewUser = (data: IAdd) => {
  return {
    type: UsersActions.ADD_USER,
    payload: data
  }
}

export const refetchUsers = (refetch: boolean) => {
  return {
    type: UsersActions.REFETCH,
    payload: refetch
  }
} 