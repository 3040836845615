import React, { useState } from "react";
import { useSelector, RootStateOrAny, useDispatch } from "react-redux";
import { Panel } from "office-ui-fabric-react";

import { toggleSMSHistoryPanel } from "../../../config/redux/drivers/driversActions";
import { panelS } from "../../common/styles/panelStyles"
import SMSHistoryListContainer from "./SMSHistoryListContainer";

const SMSHistoryPanel: React.SFC = () => {
  const dispatch = useDispatch();
  const { SMSHistoryPanelOpen, selectedDrivers, activeDriver } = useSelector(
    (state: RootStateOrAny) => state.drivers
  );

  const dissmisPanel = () => {
    dispatch(toggleSMSHistoryPanel({ open: false }));
  };

  return (
    <Panel
      headerText="Historia wysłanych wiadomości"
      isOpen={SMSHistoryPanelOpen}
      closeButtonAriaLabel="Close"
      type={5}
      onDismiss={dissmisPanel}
      isLightDismiss={false}
      isHiddenOnDismiss={false}
      styles={panelS}
    >
      <SMSHistoryListContainer />
    </Panel>
  );
};

export default SMSHistoryPanel;

