import React from "react";
import CancelReason from "../../orders/components/CancelReason";
import RealizationError from "../../orders/components/RealizationError";
import { useLocation } from "react-router-dom";
import ListSuccessMessage from "../../common/layout/ListSuccessMessage";
import PrintingLoader from "../../orders/components/PrintingLoader";
import { connect, RootStateOrAny } from "react-redux";
import OrderOptimaPanel from "../../orders/components/OrderOptimaPanel";
import ValidatorsList from "./ValidatorsList";
import ValidatorPanel from "./panel/ValidatorPanel";
import ValidatorPanelApproved from "./panel/ValidatorPanelApprove";

export interface IStateProps {
  isPanelOpen: boolean,
}

const ValidatorsLayout: React.FC<IStateProps> = ({ isPanelOpen }) => {
  const location = useLocation();
  return (
    <>
      <ListSuccessMessage />
      <ValidatorsList pathname={location.pathname} isPanelOpen={isPanelOpen} />
      <ValidatorPanel />
      <ValidatorPanelApproved />
      <OrderOptimaPanel />
      <CancelReason />
      <RealizationError />
      <PrintingLoader />
    </>
  );
};

const mapStateToProps = (state: RootStateOrAny) => {
  return {
    isPanelOpen: state.validatorPanel.validatorPanelOpen,
  };
};

export default connect(mapStateToProps)(ValidatorsLayout);