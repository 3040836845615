import * as React from "react";
import { connect, RootStateOrAny } from "react-redux";
import {
  DetailsListLayoutMode,
  IColumn,
  Selection,
} from "office-ui-fabric-react/lib/DetailsList";
import { MarqueeSelection } from "office-ui-fabric-react/lib/MarqueeSelection";
import { ShimmeredDetailsList, Stack } from "office-ui-fabric-react";
import {
  IProps,
  IClientsListState,
  IStateProps,
  IDispatchProps,
  IClientsListProps,
} from "../models/ClientsListTypes";
import { listContainerS, listS, footer } from "./clientsList.styles";
import Pagination from "../providers/Pagination";
import { IRenderProps } from "../../orders/providers/Pagination.types";
import { inActiveClientsColumns } from "./colums/inActiveClientsColumns";
import { activeClientsColumns } from "./colums/clients-columns";
import { toggleClientsPanel } from "../../../config/redux/clients/panel/clientPanelActions";
import {
  setActiveClient,
  setClients,
} from "../../../config/redux/clients/clientsActions";
import { NavigationPath } from "../../../config/routing/NavigationPath";
import { sort, toggleFiltration } from "../../../config/redux/filters/filterActions";
import { setSelectedClients } from "../actions/clientList";

class ClientsList extends React.Component<IProps, IClientsListState> {
  private _selection: Selection;
  private _columns: IColumn[];
  private _generatedColumns: IColumn[];
  private _onItemInvoked: (item?: any, index?: number, e?: Event) => void;
  private _onColumnHeaderClick: (ev?: React.MouseEvent<HTMLElement>, column?: IColumn) => void;
  protected _footerRef: React.RefObject<HTMLDivElement>;
  constructor(props: IProps) {
    super(props);

    this._selection = new Selection({
      onSelectionChanged: () => {
        const items = this._getSelectedKeys();
        const ids = items.map((item: any) => {
          return item.id;
        });
        const data = { selectedClients: [...ids] };
        this.props.dispatch(setClients(data));
      },
    });

    this._footerRef = React.createRef();

    this._generatedColumns = inActiveClientsColumns;

    this._onItemInvoked = (item: any) => {
      this.props.dispatch(setActiveClient({ activeClient: item }));
      props.dispatch(toggleClientsPanel(true));
    };

    this._columns = [...this._generatedColumns];

    this._onColumnHeaderClick = (item: any, column: any) => {
      if (column.key === "receptionPlacesOrderTypes") return;

      const newColumns: IColumn[] = this._columns.slice();

      const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
      newColumns.forEach((newCol: IColumn) => {
        if (newCol === currColumn) {
          currColumn.isSortedDescending = !currColumn.isSortedDescending;
          currColumn.isSorted = true;
        } else {
          newCol.isSorted = false;
          newCol.isSortedDescending = true;
        }
      });

      props.dispatch(toggleFiltration({ filter: true }));
      props.dispatch(sort({ column: currColumn }));

      this.setState({
        _cols: newColumns,
      });
    };

    this.state = {
      items: [],
      loading: false,
      _cols: this._columns
    };

  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(setSelectedClients({ selectedClients: [] }));
  }

  componentDidUpdate() {
    if (this.props.reload) {
      this._selection.selectToKey("", true);
    }

    if (!this.props.selectedClients[0]) {
      this._selection.selectToKey("", true);
    }
  }

  public render(): JSX.Element {
    return (
      <>
        <Pagination
          footerRef={this._footerRef}
          render={({ data, loading }: IRenderProps) => (
            <>
              <Stack styles={listContainerS}>
                <MarqueeSelection isEnabled={!this.props.isPanelOpen} selection={this._selection}>
                  <ShimmeredDetailsList
                    items={data}
                    columns={
                      this.props.pathname === NavigationPath.INACTIVE_CLIENTS
                        ? inActiveClientsColumns
                        : activeClientsColumns
                    }
                    setKey="items"
                    layoutMode={DetailsListLayoutMode.justified}
                    selection={this._selection}
                    selectionPreservedOnEmptyClick={true}
                    onItemInvoked={this._onItemInvoked}
                    usePageCache={true}
                    styles={listS}
                    onColumnHeaderClick={this._onColumnHeaderClick}
                    enableShimmer={loading}
                  />
                </MarqueeSelection>
              </Stack>
              <div className={footer} ref={this._footerRef} />
            </>
          )}
        />
      </>
    );
  }

  private _getSelectedKeys = () => {
    const keys = this._selection.getSelection();
    return keys;
  };
}

const mapStateToProps = (state: RootStateOrAny) => state.clients;

export default connect<IStateProps, IDispatchProps, IClientsListProps>(
  mapStateToProps
)(ClientsList);