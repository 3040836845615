import * as yup from "yup";

export const validationSchemaPostalCode = yup.object().shape({
  postalCode: yup
    .string()
    .matches(/^[0-9]+$/)
    .max(6),
});


export const completeCorrectOrderValidation = yup.object().shape({  
  tonnage: yup
    .string()
    .nullable()
    .matches(/^(\d+(?:[\.,]\d{1,2})?)$/, "Podaj liczbę z maksymalnie dwoma miejscami po przecinku lub kropce")
});

export const completeOrderValidation = yup.object().shape({
  driver: yup.string().required("wybierz kierowcę"),
  tonnage: yup
    .string()
    .required("podaj tonaż")
    .matches(/^(\d+(?:[\.\,]\d+)?)$/, "podaj liczbę"),
  kpo: yup
    .string()
    .required("podaj kpo"),
  operator: yup.string().required("wybierz operatora"),
  quantity: yup
    .string()
    .required("podaj ilość")
    .matches(/^[0-9]+$/, "podaj liczbę całkowitą"),
  quantityTaken: yup
    .string()
    .required("podaj ilość")
    .matches(/^[0-9]+$/, "podaj liczbę całkowitą"),
  // vatRate: yup
  //   .string()
  //   .required("podaj wartość VAT")
  //   .matches(/^[0-100]+$/, "podaj liczbę całkowitą")
  internalStoreId: yup
      .string()
      .required("wybierz magazyn")
});

export const newOrderValidation = yup.object().shape({
  receptionPlace: yup.string().required("wybierz punkt odbioru"),
  department: yup.string().required("wybierz oddział"),
  orderTypeItemId: yup.string().required("wybierz pojazd"),
});

export const newOrderValidationShort = yup.object().shape({
  receptionPlace: yup.string().required("wybierz punkt odbioru"),
  orderTypeItemId: yup.string().required("wybierz pojazd"),
});

export const reportClientValidation = yup.object().shape({
  createdFrom: yup.string().required("wybierz datę"),
  createdTo: yup.string().required("wybierz datę"),
  currentStatus: yup.string().required("wybierz status"),
  orderBy: yup.string().required("wybierz kolumnę"),
  desc: yup.string().required("wybierz kierunek"),
});

export const validationSchemaSyncOrder = yup.object().shape({
  numberOptima: yup.string().required("Podaj numer"),
});
