import { NavigationPath } from "../../../config/routing/NavigationPath";
import {LinksNames} from "./LinkNames.enum"

export const clientsPaths = [
   {id: NavigationPath.UNVERIFIED_CLIENTS, name: LinksNames.UNVERIFIED_CLIENTS},
//   {id: NavigationPath.NEW_CLIENTS, name: LinksNames.NEW_CLIENTS},
   {id: NavigationPath.ACTIVE_CLIENTS, name: LinksNames.ACTIVE_CLIENTS},
   {id: NavigationPath.INACTIVE_CLIENTS, name: LinksNames.INACTIVE_CLIENTS},
   {id: NavigationPath.RECEPTION_PLACES, name: LinksNames.RECEPTION_PLACES},
];

export const validatorsPaths = [
   {id: NavigationPath.CONTAINERS_VALIDATORS, name: LinksNames.CONTAINERS_VALIDATORS},
   {id: NavigationPath.RECEPTION_PLACES_VALIDATORS, name: LinksNames.RECEPTION_PLACES_VALIDATORS},
   {id: NavigationPath.ORDERS_VALIDATORS, name: LinksNames.ORDERS_VALIDATORS},
]

export const priceListPaths = [
   {id: NavigationPath.GENERAL_PRICE_LIST, name: LinksNames.GENERAL_PRICE_LIST},
   {id: NavigationPath.DETAILED_PRICE_LIST, name: LinksNames.DETAILED_PRICE_LIST},
]

export const ordersPaths = [
   {id: NavigationPath.NEW_ORDERS, name: LinksNames.NEW_ORDERS},
   {id: NavigationPath.ACCEPTED_ORDERS, name: LinksNames.ACCEPTED_ORDERS},
   {id: NavigationPath.IN_PROGRESS_ORDERS, name: LinksNames.IN_PROGRESS_ORDERS},
   {id: NavigationPath.COMPLETED_ORDERS, name: LinksNames.COMPLETED_ORDERS},
   {id: NavigationPath.INVOICED_ORDERS, name: LinksNames.INVOICED_ORDERS},
   {id:NavigationPath.CANCELED_ORDERS, name: LinksNames.CANCELED_ORDERS},
   {id: NavigationPath.ALL_ORDERS, name: LinksNames.ALL_ORDERS},
]

export const configPaths = [
   {id: NavigationPath.ORDER_TYPES, name: LinksNames.ORDER_TYPES},
   {id: NavigationPath.VEHICLES, name: LinksNames.VEHICLES},
   {id: NavigationPath.DRIVERS, name: LinksNames.DRIVERS},
]