import React from "react";
import { useUserData } from "../../../../../common/hooks/useUserData";
import NewFormField from "../../../../../common/layout/form/NewFormField";
import {
  container,
  divider,
  fieldContainer,
} from "../../../../styles/fieldsContainer.styles";


const UserFields: React.FC = () => {

  const requireEmail = true;
  const { user } = useUserData();
  return (
    <div className={container}>
      <div className={divider}>
        <div className={fieldContainer}>
          <NewFormField name="firstName" label="Imię" />
        </div>
        <div className={fieldContainer}>
          <NewFormField name="lastName" label="Nazwisko" />
        </div>
      </div>
      <div className={divider}>
        <div className={fieldContainer}>
          <NewFormField value={user ? user.emails[0] : ''} required={requireEmail} disabled={!requireEmail} name="email" label="Email" type="email" />
        </div>
        {/* <div className={fieldContainer}>
          <NewFormField name="phoneNumber" label="Nr telefonu"/>
        </div> */}
      </div>
    </div>
  );
};

export default UserFields;