import React from "react";
import { RootStateOrAny, useSelector, useDispatch } from "react-redux";
import { panelS } from "../../common/styles/panelStyles";
import { toggleContainersPanel } from "../../../config/redux/container/containersActions";
import ContainerShowForm from "../components/ContainerShowForm";
import ContainerEditForm from "../components/ContainerEditForm";
import { useContainerForm } from "../hooks/useContainerForm";
import { Panel } from "office-ui-fabric-react";
import ContainerRfidHistoryList from "../components/ContainerInternalStoreHistoryList";

const ContainersPanel: React.FC = () => {
    const dispatch = useDispatch();
    const { containerPanelOpen, isEdit, isShow, isRfidHistory } = useSelector(
        (state: RootStateOrAny) => state.containers
    );

    const dismissPanel = () => {
        dispatch(toggleContainersPanel({ isOpen: false }));
    };

    const { initValues, handleSubmit, cancelForm, loading, gpsDeviceStatuses } = useContainerForm()

    return (
        <Panel
            isOpen={containerPanelOpen}
            closeButtonAriaLabel="Close"
            headerText={isEdit ? "Edytuj kontener" : isShow ? "Szczegóły konteneru" : "Odczyty w magazynach wewnętrznych"}
            onDismiss={dismissPanel}
            type={3}
            styles={panelS}
            isHiddenOnDismiss={true}
        >
            {isEdit ?
                <ContainerEditForm
                    initValues={initValues}
                    handleSubmit={handleSubmit}
                    cancelForm={cancelForm}
                    loading={loading}
                    gpsDeviceStatuses= {gpsDeviceStatuses}
                />
                : <></>}
            {isShow ?
                <ContainerShowForm
                    initValues={initValues} />
                : <></>}
            {isRfidHistory ? <ContainerRfidHistoryList/>
                : <></>}
        </Panel>
    );
};

export default ContainersPanel;