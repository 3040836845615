import * as React from "react";
import { connect, RootStateOrAny } from "react-redux";
import { withRouter } from "react-router-dom";
import { filter, sort, toggleFiltration } from "../../../config/redux/filters/filterActions"
import { IPaginationProps, IPaginationState } from "./pagination.types";
import { getParams } from "../../clients/actions/getClientsParams"
import { AxiosResponse } from 'axios';
import { generateItems } from "../actions/generateContainers";
import { getAllContainers } from "../../../connectors/containers/connectors";
import { reloadContainers } from "../../../config/redux/container/containersActions";

class Pagination extends React.Component<IPaginationProps, IPaginationState> {
    private _data: any = [];

    footerRef = React.createRef<HTMLDivElement>();

    state = {
        data: [],
        limit: 35,
        total: 0,
        page: 1,
        loading: false,
    };

    async componentDidMount() {
        //await has effect on the type of this expression !!!
        await this.props.dispatch(sort({column: undefined}));
        await this.props.dispatch(toggleFiltration({ filter: false }))
        await this.props.dispatch(filter({ searchPhrase: undefined }));
        
        if (this.props.footerRef.current !== null) {
            this._observer.observe(this.props.footerRef.current);
        }

        this._setContainers();
    }

    componentDidUpdate(prevProps: IPaginationProps) {
        const { reload, searchPhrase, filter, column, dispatch } = this.props

        if (reload) {
            this._reload();
        }
        
        if (filter == true) {
            this._reload();
            if (column) {
                this._filterContainers(searchPhrase, column.key, column.isSortedDescending)
            }
            else {
                this._filterContainers(searchPhrase)
            }

            dispatch(toggleFiltration({ filter: false }))
        }
    }

    componentWillUnmount() {
        if (this.props.footerRef.current !== null) {
            this._observer.unobserve(this.props.footerRef.current);
        }
    }

    private _handleObserver = (entry: any, observer: any) => {
        if (this.state.data.length < this.state.total) {
            this._setContainers();
        }
    };

    private _observer = new IntersectionObserver(this._handleObserver, {
        root: null,
        rootMargin: "200px",
        threshold: 1,
    });

    private _reload = async () => {
        this.props.dispatch(reloadContainers({ reload: false }));
    };

    private _filterContainers = async (searchPhrase: string, orderBy: undefined | string = undefined, desc: undefined | boolean = false) => {
        const { limit } = this.state;

        var res: AxiosResponse<any>;
        if (orderBy !== undefined) {
            res = await getAllContainers({ page: 1, limit,  OrderBy: orderBy, Desc: desc, allFields: searchPhrase });
        }
        else {
            res = await getAllContainers({ page: 1, limit, allFields: searchPhrase });
        }
        const containers = generateItems(res.data.data);
        this.setState((prevState: IPaginationState) => {
            return {
                data: [...containers],
                page: 2,
                total: res.data.total,
            };
        });
    }

    private _setContainers = async (newPage?: number) => {
        const { searchPhrase, column } = this.props;
        const { limit, page } = this.state;

        const p = newPage ? newPage : page;
        let res: any;
        if (column != undefined) {
            res = await getAllContainers({ page: p, limit, OrderBy: column.key, Desc: column.isSortedDescending, allFields: searchPhrase});
        } else {
            res = await getAllContainers({ page: p, limit, allFields: searchPhrase});
        }
        const containers = generateItems(res.data.data);
        this.setState((prevState: IPaginationState) => {
            return {
                data: newPage ? [...containers] : [...prevState.data, ...containers],
                page: newPage ? newPage : prevState.page + 1,
                total: res.data.total,
            };
        });
    };

    public render() {
        const renderProps = {
            data: this.state.data,
            loading: this.state.loading
        };

        return this.props.render(renderProps);
    }
}

const mapStateToProps = (state: RootStateOrAny) => {
    return {
        reload: state.receptionPlaces.reload,
        searchPhrase: state.filtration.searchPhrase,
        selectedContainers: state.containers.selectedContainers,
        filter: state.filtration.filter,
        column: state.filtration.column
    };
};

export default connect(mapStateToProps)(withRouter(Pagination));
