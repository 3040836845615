import { useEffect } from 'react';
import { useState } from 'react';
import { getMatchedVehicles } from '../../../connectors/vehicles/connectors';

export const useMatchedVehicles = (ordersId: Array<string>) => {
    const [vehicles, setVehicles] = useState<Array<any>>([])

    useEffect(() => {
        if (ordersId[0]) {
            fetch()
        }
    }, [ordersId[0]])

    const fetch = async () => {
        var query = `?OrdersId=` + ordersId[0]
        const res = await getMatchedVehicles(query)

        const matchedVehicles: Array<any> = []

        res.data.data.forEach((item: any) => {
            matchedVehicles.push({
                key: item.id,
                text: `${item.lastName} ${item.firstName} ${item.registerNumber}`,
                data: item
            })
        });
        setVehicles(matchedVehicles)
    }

    return {
        vehicles
    }
}