import React, { useCallback } from "react";
import { useField } from "formik";

import { Checkbox } from "office-ui-fabric-react/lib/Checkbox";
import {
  MessageBar,
  MessageBarType,
  IMessageBarStyles,
} from "office-ui-fabric-react";

const errorMessageS: IMessageBarStyles = {
  root: {
    marginTop: "15px",
    width: "100%",
  },
};

export interface NewFormCheckboxProps {
  name: string;
  label: string;
}

const NewFormCheckbox: React.FC<NewFormCheckboxProps> = ({ name, label }) => {
  const [field, { value, touched, error }, { setValue }] = useField(name);


  const handleChange = useCallback(
    async (e: any, value: any) => {
      setValue(value);
    },
    [value]
  );

  return (
    <>
      <Checkbox
        label={label}
        checked={value ? value : false}
        onChange={handleChange}
      />
      {touched && error && (
        <MessageBar
          messageBarType={MessageBarType.error}
          isMultiline={false}
          dismissButtonAriaLabel="Close"
          styles={errorMessageS}
        >
          {error}
        </MessageBar>
      )}
    </>
  );
};

export default NewFormCheckbox;
