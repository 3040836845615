import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toggleValidatorPanel } from '../../../config/redux/validators/validatorsPanel/validatorPanelActions';
import { setListMessage, setErrorAlert } from '../../../config/redux/alerts/alertsActions';
import { OrdersAlerts } from '../../orders/models/Alerts.enum';
import { runValidators } from '../../../connectors/validators/connectors';
import { reloadValidators } from '../../../config/redux/validators/validatorsActions';
import { toggleFiltration } from '../../../config/redux/filters/filterActions';

export const useCheckValidator = (cancelForm: () => void) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (values: { validatorType: string }) => {
        try {
            setIsLoading(true);
            await runValidators([values.validatorType]);
            dispatch(toggleValidatorPanel(false));
            dispatch(reloadValidators(true));
            dispatch(toggleFiltration({ filter: true })); 
            dispatch(setListMessage("Operacja przebiegła pomyślnie"));
        } catch (err) {
            dispatch(setErrorAlert(OrdersAlerts.FILL_ALL_FILEDS));
        } finally {
            setIsLoading(false);
        }
    }

    const cancelSubmit = () => {
        cancelForm();
    }

    return {
        handleSubmit,
        cancelSubmit,
        isLoading,
    };
};
