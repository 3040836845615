import React from "react";
import { Stack } from "office-ui-fabric-react/lib/Stack";
import {
    DetailsList,
    DetailsListLayoutMode,
    SelectionMode,
    Selection,
} from "office-ui-fabric-react/lib/DetailsList";
import { listS } from "../../orders/styles/OrdersList.styles";
import { generateSMSHistoryColumns } from "../../clients/components/colums/smshistory-columns";

export interface SMSHistoryListProps {
    selection?: Selection;
    items: Array<any>;
    handleInvoke?: any;
}

const SMSHistoryList: React.FC<SMSHistoryListProps> = ({
    items,
    selection,
    handleInvoke,
}) => {

    return (
        <Stack>
            <DetailsList
                items={items}
                columns={generateSMSHistoryColumns(true)}
                styles={listS}
                setKey="itemsSMSHistory"
                selection={selection}
                layoutMode={DetailsListLayoutMode.justified}
                selectionPreservedOnEmptyClick={true}
                selectionMode={SelectionMode.none}
                usePageCache={true}
            />
        </Stack>
    );
};
export default SMSHistoryList;