import { setListMessage } from './../../../../config/redux/alerts/alertsActions';
import { reloadDrivers } from './../../../../config/redux/drivers/driversActions';
import { useSelector, RootStateOrAny, useDispatch } from "react-redux";

import {toggleDriversPanel, toggleSMSHistoryPanel} from "../../../../config/redux/drivers/driversActions"
import { deleteUser } from "../../../../connectors/clients/connectors";
import { checkQuantity } from "../../actions/checkQuantity";
import { generateItems } from "../../actions/generateCommands/generateDriversCommands";

export const useDriversCommandBar = () => {
  const dispatch = useDispatch();
  const { selectedDrivers } = useSelector(
    (state: RootStateOrAny) => state.drivers
  );

    const openPanelToAdd = () => {
      selectedDrivers.splice(0, selectedDrivers.length)
      dispatch(toggleDriversPanel({open: true}))
    }

  const openPanelToEdit = () => {
    dispatch(toggleDriversPanel({open: true}))
  }

  const openPanelSMSHistory = () => {
    dispatch(toggleSMSHistoryPanel({open: true}))
  }

  const deleteDriver = async () => {
    try{
      await deleteUser(selectedDrivers[0].id)
      dispatch(setListMessage("Operacja przebiegła pomyślnie"))
      dispatch(reloadDrivers({reload: true}))
    }catch(err){

    }
  }

  const actions = {
      deleteDriver,
      openPanelToAdd,
      openPanelToEdit,
      openPanelSMSHistory
  }

  const quantity = checkQuantity(selectedDrivers.length);

  const items = generateItems(quantity, actions)

  return {
    items,
  };
};