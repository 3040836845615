import { useState, useMemo } from "react";
import { FormikValues } from "formik";
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";
import {
  toggleEditing,
  setSelectedIds,
} from "../../../config/redux/clients/panel/clientPanelActions";
import { fieldNames } from "../components/edditionPanel/forms/PickUpPointsForm";
import { generateInitValues } from "./../../common/actions/generateInitValues";
import {
  addPickUpPoint,
  addSpecialPricePickUpPoint,
  editPickUpPoint,
} from "../../../connectors/clients/connectors";
import { IResetForm } from "../../common/models/resetForm.type";
import { Messages } from "../models/message.enum";
import { setErrorAlert, setSuccessAlert } from "./../../../config/redux/alerts/alertsActions";
import { useQuery } from "react-query";
import { getOrderTypes } from "../../../connectors/orderTypes/connectors";
import { AxiosError } from "axios";

export const usePickUpPointForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { selectedIds, basicInfo } = useSelector(
    (state: RootStateOrAny) => state.clientPanel
  );

  function isAxiosError(err: unknown): err is AxiosError {
    return !!err && typeof (err as AxiosError).isAxiosError === 'boolean';
  }

  const initValues = selectedIds[0]
    ? {
      city: selectedIds[0].city,
      street: selectedIds[0].street,
      postalCode: selectedIds[0].postalCode,
      default: selectedIds[0].isDefault,
      department: selectedIds[0].departmentId,
      bdoNumber: selectedIds[0].bdoNumber,
      phone: selectedIds[0].phone,
      orderTypeId: selectedIds[0].orderTypeId
    }
    : generateInitValues(fieldNames, "");

  const add = async (values: FormikValues, { resetForm }: IResetForm) => {
    setIsLoading(true);
    try {
      const body = getBody(values);
      const departmentId = values.department ? values.department : basicInfo.departments[0].id;
      await addPickUpPoint(body, departmentId);
      setIsLoading(false);
      whileSucces();
    } catch { }
  };

  const edit = async (values: FormikValues, { resetForm }: IResetForm) => {
    setIsLoading(true);
    try {
      const body = getBody(values);
      const departmentId = values.department ? values.department : basicInfo.departments[0].id;
      await editPickUpPoint(
        selectedIds[0].id,
        body,
        departmentId,
      );
      setIsLoading(false);
      whileSucces();
    } catch { }
  };

  const editSpecialPrices = async (values: FormikValues, specialPrices: any, { resetForm }: IResetForm) => {
    setIsLoading(true);
    try {
      const body = getBody(values);
      const departmentId = values.department ? values.department : basicInfo.departments[0].id;
      const result = await editPickUpPoint(
        selectedIds[0].id,
        body,
        departmentId,
      );
      try {
        const result = await addSpecialPricePickUpPoint(
          selectedIds[0].id,
          specialPrices
        );
        whileSucces();
      } catch (err: unknown) {
        if (isAxiosError(err)) {
          const axiosError = err as AxiosError;
          if (axiosError.response && axiosError.response.data.Errors[0].ErrorMessage) {
            dispatch(setErrorAlert(axiosError.response.data.Errors[0].ErrorMessage));
          } else if (axiosError.response && axiosError.response.data.Errors[0]) {
            dispatch(setErrorAlert(axiosError.response.data.Errors[0]));
          } else {
            dispatch(setErrorAlert("Wystąpił nieznany błąd"));
          }
        }
      }
    } catch (err: unknown) {
      if (isAxiosError(err)) {
        const axiosError = err as AxiosError;
        if (axiosError.response && axiosError.response.data.Errors[0].ErrorMessage) {
          dispatch(setErrorAlert(axiosError.response.data.Errors[0].ErrorMessage));
        } else if (axiosError.response && axiosError.response.data.Errors[0]) {
          dispatch(setErrorAlert(axiosError.response.data.Errors[0]));
        } else {
          dispatch(setErrorAlert("Wystąpił nieznany błąd"));
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  const addSpecialPrices = async (values: FormikValues, specialPrices: any, { resetForm }: IResetForm) => {
    setIsLoading(true);
    try {
      const body = getBody(values);
      const departmentId = values.department ? values.department : basicInfo.departments[0].id;
      const result = await addPickUpPoint(body, departmentId);
      try {
        await addSpecialPricePickUpPoint(
          result.data.data.id,
          specialPrices
        );
        whileSucces();
      } catch (err: unknown) {
        if (isAxiosError(err)) {
          const axiosError = err as AxiosError;
          if (axiosError.response && axiosError.response.data.Errors[0].ErrorMessage) {
            dispatch(setErrorAlert(axiosError.response.data.Errors[0].ErrorMessage));
          } else if (axiosError.response && axiosError.response.data.Errors[0]) {
            dispatch(setErrorAlert(axiosError.response.data.Errors[0]));
          } else {
            dispatch(setErrorAlert("Wystąpił nieznany błąd"));
          }
        }
      }
    } catch (err: unknown) {
      if (isAxiosError(err)) {
        const axiosError = err as AxiosError;
        if (axiosError.response && axiosError.response.data.Errors[0].ErrorMessage) {
          dispatch(setErrorAlert(axiosError.response.data.Errors[0].ErrorMessage));
        } else if (axiosError.response && axiosError.response.data.Errors[0]) {
          dispatch(setErrorAlert(axiosError.response.data.Errors[0]));
        } else {
          dispatch(setErrorAlert("Wystąpił nieznany błąd"));
        }
      }
    } finally {
      setIsLoading(false);
    }
  }

  const getBody = (values: any) => ({
    city: values.city,
    postalCode: values.postalCode,
    street: values.street,
    isDefault: values.default,
    bdoNumber: values.bdoNumber,
    phone: values.phone,
    orderTypeId: values.orderTypeId
  });

  const whileSucces = () => {
    dispatch(setSuccessAlert(Messages.OPERATION_SUCCESS));
    dispatch(toggleEditing({ editing: false }));
    dispatch(setSelectedIds({ selectedIds: [] }));
  };

  const departments = useMemo(() => {
    return basicInfo.departments.map((department: any) => {
      return {
        key: department.id,
        text: `${department.city} ${department.street}`,
      };
    });
  }, [basicInfo]);

  const { data } = useQuery("orderTypes", getOrderTypes);
  const prepareOrderTypes = () => {
    var orderTypesMap = data ? data.data.data : []
    var orderTypes = Array<any>();
    orderTypesMap.forEach((x: any) => {
      var order = {
        key: x.id,
        text: x.name
      };
      orderTypes.push(order);
    })
    return orderTypes;
  };

  const orderTypes = prepareOrderTypes();

  return {
    add,
    addSpecialPrices,
    edit,
    editSpecialPrices,
    initValues,
    selectedIds,
    isLoading,
    activeClient: basicInfo,
    departments,
    orderTypes,
  };
};